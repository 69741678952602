import { Box, Typography } from '@mui/material'
import Comment from 'components/Comment'
import Loader from 'components/Loader'
import CommentForm from 'forms/CommentForm'
import { useEffect, useRef, useState } from 'react'

export default function Comments({
  comments,
  resource,
  resourceId,
  activeUserId,
  refreshState = null,
  parentElOffsetTop = null
}) {
  const commentsRef = useRef(null)
  const commentFormRef = useRef(null)
  const commentTitleRef = useRef(null)
  const [commentsHeight, setCommentsHeight] = useState(0)
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (commentTitleRef.current && commentFormRef.current) {
        setCommentsHeight(
          window.innerHeight -
            commentTitleRef.current.offsetTop -
            commentTitleRef.current.offsetHeight -
            commentFormRef.current.offsetHeight
        )
      }
    })

    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [])

  useEffect(() => {
    if (commentTitleRef.current && commentFormRef.current) {
      setCommentsHeight(
        window.innerHeight -
          commentTitleRef.current.offsetTop -
          commentTitleRef.current.offsetHeight -
          commentFormRef.current.offsetHeight
      )
    }
  }, [commentsRef, commentFormRef, comments, parentElOffsetTop])

  return (
    <Box mt={1} mx={2}>
      <Box display="flex" flexDirection="row" ref={commentTitleRef}>
        <Box>
          <Typography variant="subtitle1">{`Comments (${
            comments ? comments.length : 0
          })`}</Typography>
        </Box>
      </Box>
      {comments ? (
        <Box
          ref={commentsRef}
          flexGrow={1}
          pr={1}
          sx={{
            overflowY: 'auto',
            height: commentsHeight
          }}
        >
          {comments && comments.length > 0 ? (
            comments.map((comment, index) => {
              return (
                <Comment
                  key={`comment-${index}-${comment.id}`}
                  comment={comment}
                  displayActions={
                    activeUserId === comment.commenter_id ||
                    activeUserId === comment.commenter
                  }
                  refreshState={refreshState}
                />
              )
            })
          ) : (
            <Box textAlign="center" color="#aaa" mt={1}>
              {'No Comments'}
            </Box>
          )}
        </Box>
      ) : (
        <Loader title="" message="Retrieving comments..." />
      )}

      <Box ref={commentFormRef}>
        <CommentForm
          resourceId={resourceId}
          resource={resource}
          exitFunction={() => {
            // Scroll to bottom of comments after delay
            setTimeout(() => {
              if (commentsRef && commentsRef.current) {
                commentsRef.current.scroll({
                  top: commentsRef.current.scrollHeight,
                  behavior: 'smooth'
                })
              }
            }, 500)
            if (refreshState) refreshState()
          }}
        />
      </Box>
    </Box>
  )
}
