import { DatePicker } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { EntryBox, Loader, MainContent } from 'components'
import { format, parseISO } from 'date-fns'
import has from 'lodash/has'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Redirect, useHistory } from 'react-router-dom'
import { Flag, api } from 'services'

export default function EditView({ id }) {
  const [validation, setValidation] = useState()
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm()

  const [name, setName] = useState('')
  const [duration, setDuration] = useState('')
  const [isSetBaseline, setIsSetBaseline] = useState(true)

  const [selectedLines, setSelectedLines] = useState()
  const [checkedLines, setCheckedLines] = useState()

  const [startDate, setStartDate] = useState(new Date())

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const handleStartDateChange = (date) => {
    setStartDate(date)
    setValue('start_date', date !== null ? format(date, 'yyyy-MM-dd') : null)
  }

  const handleLineChange = (id) => (event) => {
    setCheckedLines({ ...checkedLines, [id]: event.target.checked })
  }

  useEffect(() => {
    if (checkedLines) {
      let newSelectedLines = Object.keys(checkedLines).reduce(
        (newLines, key) => {
          if (!newLines) {
            newLines = []
          }
          if (checkedLines[key]) newLines.push(Number(key))
          return newLines
        },
        []
      )
      if (newSelectedLines.length <= 0) newSelectedLines = undefined
      setSelectedLines(newSelectedLines)
      setValue('lines', newSelectedLines)
    }
  }, [checkedLines, setValue])

  const { data: lines, isLoading: linesAreLoading } = useQuery(
    ['validation-lines', id],
    () =>
      api.lines
        .list({ size: 9999, archived: false, ordering: 'name' })
        .then((data) => {
          const newCheckedLines = {}
          const currentLines = validation.lines.map((line) => line.id)
          data.items.map((line, index) => {
            newCheckedLines[line.id] = currentLines
              ? currentLines.includes(line.id)
              : false
            return null
          })
          setCheckedLines(newCheckedLines)
          setSelectedLines(currentLines || [])
          return data.items
        }),
    { enabled: !!validation }
  )

  const handleDurationChange = (event) => {
    setDuration(event.target.value)
    setValue('duration_days', event.target.value)
  }

  const handleSetBaselineChange = (event) => {
    setIsSetBaseline(event.target.checked)
    setValue('baseline', event.target.checked)
  }

  useEffect(() => {
    let unmounted = false
    register('duration_days', { required: true })
    register('lines', { required: true })
    register('start_date', { required: true })
    register('baseline')
    api.validations
      .get(id)
      .then((data) => {
        if (!unmounted) {
          if (data.status !== 'Completed') {
            setValidation(data)
            setName(data.name)
            setDuration(data.duration_days)
            setStartDate(parseISO(data.start_date))
            setValue('name', data.name)
            setValue('start_date', data.start_date)
            setValue('duration_days', data.duration_days)
            setIsSetBaseline(data.baseline)
            setValue(
              'lines',
              data.lines.map((line) => line.id)
            )
          } else {
            enqueueSnackbar('Completed validations cannot be edited.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            history.push(`/validation/${id}`)
          }
        }
      })
      .catch((error) => {
        console.warn(error)
      })
    return () => {
      unmounted = true
    }
  }, [id, setValue, register, enqueueSnackbar, history])

  const onSubmit = (data) => {
    api.validations
      .update(id, data)
      .then((data) => {
        enqueueSnackbar(`${data.name} has been updated.`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        history.push(`/validation/${data.id}`)
      })
      .catch((error) => {
        console.warn(error)
        if (error.status === 400) {
          enqueueSnackbar(error.data.detail, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      })
  }

  return (
    <Flag
      name={'validation'}
      alternate={<Redirect to={{ pathname: '/dashboard' }} />}
    >
      <MainContent>
        <Box p={2}>
          <Container maxWidth="sm">
            <Box pb={2}>
              <Typography variant="h1">
                {`${validation && validation.name} Settings`}
              </Typography>
            </Box>
            <form
              id={`edit-validation-${id}`}
              data-testid="edit-validation-form-test"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                display="flex"
                flexDirection="row"
                pb={3}
                justifyContent="space-between"
              >
                <EntryBox label="Name">
                  <TextField
                    {...register('name', { required: true, maxLength: 80 })}
                    name="name"
                    id="name"
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={(event) => {
                      setValue('name', event.target.value)
                      setName(event.target.value)
                    }}
                    fullWidth
                    inputProps={{ 'data-testid': 'validation-name-test' }}
                    error={has(errors, 'name')}
                    helperText={
                      errors.name
                        ? errors.name.type === 'required'
                          ? 'Name is a required field.'
                          : 'Name is too long.'
                        : ''
                    }
                  />
                </EntryBox>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                pb={3}
                justifyContent="space-between"
              >
                <Box mr={3} alignSelf="center">
                  <Typography variant="subtitle1" display="inline">
                    {' '}
                    {'Duration'}{' '}
                  </Typography>
                </Box>
                <FormControl size="small">
                  <Select
                    name="duration_days"
                    id="duration_days"
                    variant="outlined"
                    size="small"
                    value={duration}
                    onChange={handleDurationChange}
                    SelectDisplayProps={{
                      'data-testid': 'validation-duration-select'
                    }}
                    inputProps={{
                      'data-testid': 'validation-duration-input'
                    }}
                  >
                    <MenuItem value={1}>{'1 Day'}</MenuItem>
                    <MenuItem value={2}>{'2 Days'}</MenuItem>
                    <MenuItem value={3}>{'3 Days'}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Flag name="monitoring">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb={2}
                >
                  <Box
                    mr={3}
                    alignSelf="center"
                    style={{ minWidth: 'max-content' }}
                    flexGrow={1}
                  >
                    <Typography variant="subtitle1" display="inline">
                      {' '}
                      {'Update Baseline'}{' '}
                    </Typography>
                    <FormHelperText>
                      {
                        "Use this validation's results for line summaries and as a baseline for monitoring."
                      }
                    </FormHelperText>
                  </Box>
                  <Box alignContent="flex-start">
                    <Checkbox
                      {...register('baseline')}
                      name="baseline"
                      id="baseline"
                      inputProps={{
                        'data-testid': 'validation-checkbox-baseline'
                      }}
                      checked={isSetBaseline}
                      onChange={handleSetBaselineChange}
                      color="primary"
                    />
                  </Box>
                </Box>
              </Flag>
              <Box
                display="flex"
                flexDirection="row"
                pb={3}
                justifyContent="space-between"
              >
                <Box mr={3} alignSelf="center">
                  <Typography variant="subtitle1" display="inline">
                    {' '}
                    {'Start Date'}{' '}
                  </Typography>
                </Box>
                <DatePicker
                  id="start_date"
                  name="start_date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  disableToolbar
                  disableMaskedInput={true}
                  inputFormat="dd MMM yyyy"
                  inputProps={{ placeholder: '' }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={has(errors, 'date')}
                      helperText={
                        errors.start_date && errors.start_date.message
                      }
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="subtitle1" display="inline">
                    {' '}
                    {'Lines'}{' '}
                  </Typography>
                </Box>
                <FormControl component="fieldset">
                  <FormLabel focused={false}>
                    {' '}
                    {`Select the lines you will validate: ${
                      selectedLines ? selectedLines.length : 0
                    } selected.`}
                  </FormLabel>
                  <FormHelperText error={has(errors, 'lines')}>
                    {has(errors, 'lines') &&
                      'Please select at least 1 line from the list below.'}
                  </FormHelperText>
                  <Box style={{ overflowY: 'scroll' }} height={150}>
                    <FormGroup>
                      {linesAreLoading ? (
                        <Loader title={null} message="Loading Lines..." />
                      ) : (
                        checkedLines &&
                        lines.map((line, index) => {
                          return (
                            <Box
                              py={1}
                              style={{ borderBottom: '1px solid #D6DAE0' }}
                              key={`line-${line.id}-${index}`}
                            >
                              <FormControlLabel
                                size="small"
                                control={
                                  <Checkbox
                                    checked={checkedLines[line.id]}
                                    value={line.id}
                                    onChange={handleLineChange(line.id)}
                                    name={line.name}
                                  />
                                }
                                label={line.name}
                              />
                            </Box>
                          )
                        })
                      )}
                    </FormGroup>
                  </Box>
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                pt={2}
              >
                <Box pr={1}>
                  <Button
                    type="submit"
                    role="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Save Changes
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </MainContent>
    </Flag>
  )
}
