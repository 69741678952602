import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import has from 'lodash/has'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { api } from 'services'

export default function AnalyzerSettingsForm({
  id,
  exitFunction,
  closeModalFunction
}) {
  const [asatThreshold, setASATThreshold] = useState('')
  const [ldhThreshold, setLDHThreshold] = useState('')
  const [potassiumThreshold, setPotassiumThreshold] = useState('')

  const [analyzerMake, setAnalyzerMake] = useState('')
  const [analyzerModel, setAnalyzerModel] = useState('')

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    if (closeModalFunction) closeModalFunction()
    if (exitFunction) exitFunction()
  }

  const { data: instituteAnalyzerMakes } = useQuery(
    ['institute-analyzer-makes'],
    () => api.types.analyzers()
  )

  const handleASATThresholdChange = (event) => {
    setASATThreshold(event.target.value)
    setValue('asat_threshold', event.target.value)
  }

  const handleLDHThresholdChange = (event) => {
    setLDHThreshold(event.target.value)
    setValue('ldh_threshold', event.target.value)
  }

  const handlePotassiumThresholdChange = (event) => {
    setPotassiumThreshold(event.target.value)
    setValue('potassium_threshold', event.target.value)
  }

  const handleAnalyzerMakeChange = (event) => {
    setAnalyzerMake(event.target.value)
    setValue('make', event.target.value)
  }

  const handleAnalyzerModelChange = (event) => {
    setAnalyzerModel(event.target.value)
    setValue('model', event.target.value)
  }

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue
  } = useForm()
  const unmounted = useRef(false)

  const onSubmit = (data) => {
    if (!isSubmitting) {
      api.analyzer
        .update(id, data)
        .then(function (data) {
          enqueueSnackbar('Changes to the organization have been saved.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          handleClose()
        })
        .catch(function (status) {
          console.log(status)
        })
    }
  }

  useEffect(() => {
    register('make')
    api.analyzer.get(id).then((data) => {
      if (!unmounted.current) {
        setValue('asat_threshold', data.asat_threshold)
        setASATThreshold(data.asat_threshold ? data.asat_threshold : '')
        setValue('ldh_threshold', data.ldh_threshold)
        setLDHThreshold(data.ldh_threshold ? data.ldh_threshold : '')
        setValue('potassium_threshold', data.potassium_threshold)
        setPotassiumThreshold(
          data.potassium_threshold ? data.potassium_threshold : ''
        )
        setValue('make', data.make)
        setAnalyzerMake(data.make ? data.make : '')
        setValue('model', data.model)
        setAnalyzerModel(data.model ? data.model : '')
      }
    })
  }, [register, setValue])

  return (
    <form
      id="analyzer_settings_form"
      data-testid="analyzer-settings-form-test"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography variant="subtitle1">Analyzer</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ color: '#828385' }} variant="subtitle1">
            Supplier
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            size="small"
            variant="outlined"
            fullWidth
            error={has(errors, 'analyzer_make')}
          >
            <Select
              name="analyzer_make"
              labelId="analyzer-make-input-label"
              id="analyzer-make-input"
              value={analyzerMake}
              fullWidth
              SelectDisplayProps={{ 'data-testid': 'analyzer-make-select' }}
              inputProps={{ 'data-testid': 'analyzer-make-input' }}
              onChange={handleAnalyzerMakeChange}
            >
              <MenuItem value="">
                <em>{'No Analyzer Make'}</em>
              </MenuItem>
              {instituteAnalyzerMakes &&
                instituteAnalyzerMakes.map((analyzerMake, index) => {
                  return (
                    <MenuItem
                      key={`${analyzerMake.name}-${index}`}
                      value={analyzerMake.value}
                    >
                      {analyzerMake.value === null ? (
                        <em>{analyzerMake.name}</em>
                      ) : (
                        analyzerMake.name
                      )}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <Typography style={{ color: '#828385' }} variant="subtitle1">
            Model Name
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="model"
            {...register('model')}
            id="model"
            size="small"
            variant="outlined"
            fullWidth
            value={analyzerModel}
            inputProps={{ 'data-testid': 'analyzer-model-test' }}
            onChange={handleAnalyzerModelChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ backgroundColor: '#D6DAE0', marginBlock: 10 }} />
        </Grid>

        <Grid item xs={5}>
          <Typography variant="subtitle1">
            {'Hemolysis Index Cutoffs'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ color: '#828385' }} variant="subtitle1">
            LDH
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="ldh_threshold"
            id="ldh-threshold"
            type="number"
            size="small"
            variant="outlined"
            fullWidth
            value={ldhThreshold}
            inputProps={{ 'data-testid': 'ldh-threshold-test' }}
            {...register('ldh_threshold', {
              required: 'LDH threshold is required.'
            })}
            onChange={handleLDHThresholdChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mg/dL</InputAdornment>
              )
            }}
            error={has(errors, 'ldh_threshold')}
            helperText={errors.ldh_threshold && errors.ldh_threshold.message}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <Typography style={{ color: '#828385' }} variant="subtitle1">
            ASAT
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="asat_threshold"
            id="asat-threshold"
            type="number"
            size="small"
            variant="outlined"
            fullWidth
            value={asatThreshold}
            inputProps={{ 'data-testid': 'asat-threshold-test' }}
            {...register('asat_threshold', {
              required: 'ASAT threshold is required.'
            })}
            onChange={handleASATThresholdChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mg/dL</InputAdornment>
              )
            }}
            error={has(errors, 'asat_threshold')}
            helperText={errors.asat_threshold && errors.asat_threshold.message}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <Typography style={{ color: '#828385' }} variant="subtitle1">
            K
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="potassium_threshold"
            id="potassium-threshold"
            type="number"
            size="small"
            variant="outlined"
            fullWidth
            value={potassiumThreshold}
            inputProps={{ 'data-testid': 'potassium-threshold-test' }}
            {...register('potassium_threshold', {
              required: 'Potassium threshold is required.'
            })}
            onChange={handlePotassiumThresholdChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mg/dL</InputAdornment>
              )
            }}
            error={has(errors, 'potassium_threshold')}
            helperText={
              errors.potassium_threshold && errors.potassium_threshold.message
            }
          />
        </Grid>

        <Grid item xs={12}>
          <FormHelperText>
            {
              'Hemolysis Index cutoff values for lab analysis of each analyte. Units are mg/dL. The sample rejection probability is calculated on these cutoff values.'
            }
          </FormHelperText>
        </Grid>

        <Grid item xs={12} container justifyContent="space-between">
          <Button
            color="error"
            size="small"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Box pr={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
