import { Add, Dangerous, Edit, ExpandMore, Send } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Typography
} from '@mui/material'
import { Modal, ServerDataGrid } from 'components'
import { AnalyzerSettingsForm, UserForm } from 'forms'
import UserCreateEditForm from 'forms/UserCreateEditForm'
import { QUERIES, SnackbarUtil } from 'helpers'

import { useGetAnalyzer, useGetAnalyzerMakes } from 'hooks/analyzer'
import { useGetInstitute, useGetInstitutes } from 'hooks/institutes'
import {
  useGetAllInstituteUsers,
  useResendInvite,
  useRevokeAccess
} from 'hooks/useUserDataManager'
import { startCase } from 'lodash'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { apiConfig, hasAnyRole } from 'services'

const AnalyzerSettings = () => {
  const queryClient = useQueryClient()
  const { data: instituteData, isLoading } = useGetInstitute({
    id: apiConfig.currentApiInstitute
  })

  const { data: analyzerData } = useGetAnalyzer({
    instituteId: apiConfig.currentApiInstitute
  })

  const { data: analyzerMakesData } = useGetAnalyzerMakes({})
  return (
    <>
      {hasAnyRole(['staff', 'owner', 'admin']) && (
        <Box flexGrow={1} display={'flex'} justifyContent={'flex-end'}>
          <Modal
            button={
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Edit />}
              >
                {'Edit Analyzer'}
              </Button>
            }
            label={'Edit Analyzer'}
          >
            <AnalyzerSettingsForm
              id={instituteData?.id}
              exitFunction={async () => {
                await queryClient.invalidateQueries([
                  QUERIES.GET_ANALYZER,
                  instituteData?.id
                ])
              }}
            />
          </Modal>
        </Box>
      )}
      <Box>
        <Grid container columnSpacing={10} paddingX={10}>
          <Grid item sm={12} md={6}>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="h6">Supplier</Typography>
              <Typography variant="body1">
                {analyzerMakesData?.find(
                  (item) => item.value === analyzerData?.make || ''
                )?.name || 'NA'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="h6">Model</Typography>
              <Typography variant="body1">
                {analyzerData?.model || 'NA'}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="h6">LDH Cutoff</Typography>
              <Typography variant="body1">
                {analyzerData?.ldh_threshold || 'NA'}{' '}
                <span>
                  <Typography variant="caption">mg/dL</Typography>
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="h6">ASAT Cutoff</Typography>
              <Typography variant="body1">
                {analyzerData?.asat_threshold || 'NA'}{' '}
                <span>
                  <Typography variant="caption">mg/dL</Typography>
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="h6">K Cutoff</Typography>
              <Typography variant="body1">
                {analyzerData?.potassium_threshold || 'NA'}{' '}
                <span>
                  <Typography variant="caption">mg/dL</Typography>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const VitalVialSettings = () => {
  return <Typography variant="body1">Coming Soon</Typography>
}

const UserSettings = () => {
  const queryClient = useQueryClient()

  const { data: getInstitutesData, isLoading: isGetInstituteLoading } =
    useGetInstitutes()

  const resendInviteMutation = useResendInvite({
    onSuccess: (data) => {
      SnackbarUtil.success('Invite resent successfully')
    },
    onError: (error) => {
      SnackbarUtil.error('Error resending invite')
    }
  })

  const revokeUserAccessMutation = useRevokeAccess({
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(QUERIES.GET_ALL_INSTITUTE_USERS)
      SnackbarUtil.success('User access revoked successfully')
    },
    onError: (error) => {
      SnackbarUtil.error('Error revoking user access')
    }
  })
  const userDataColumns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => {
        return (
          <Modal
            maxWidth={'sm'}
            button={
              <Button size="small">{params.row.email.toLowerCase()}</Button>
            }
            disabled={!hasAnyRole(['staff', 'owner', 'admin'])}
          >
            <UserCreateEditForm
              user={params.row}
              elevation={0}
              institutes={getInstitutesData?.items || []}
            />
          </Modal>
        )
      }
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      renderCell: (params) => (
        <Typography variant={'body1'}>{params.row.first_name}</Typography>
      )
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      renderCell: (params) => (
        <Typography variant={'body1'}>{params.row.last_name}</Typography>
      )
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: (params) => {
        const role = params.row.role
        const chipColor =
          role === 'admin'
            ? '#ff5151'
            : role === 'member'
            ? '#71d171'
            : role === 'guest'
            ? '#7acfd1'
            : role === 'owner'
            ? '#FFA500'
            : role === 'staff'
            ? '#8500ff'
            : '#000000'
        return (
          <Chip
            label={startCase(role)}
            sx={{
              backgroundColor: chipColor,
              color: '#ffffff'
            }}
          />
        )
      }
    },
    {
      field: 'manage-access',
      headerName: 'Manage Access',
      flex: 1,
      hide: !hasAnyRole(['staff', 'owner', 'admin']),
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<Dangerous />}
            onClick={() => {
              revokeUserAccessMutation.mutate({
                email: params.row.email,
                instituteId: apiConfig.currentApiInstitute
              })
            }}
          >
            Revoke Access
          </Button>
        )
      }
    },
    {
      field: 'email-status',
      headerName: 'Email Status',
      flex: 1,
      hide: !hasAnyRole(['staff', 'owner', 'admin']),
      renderCell: (params) => {
        const unregistered = params.row.unregistered
        return (
          <Box display={'flex'}>
            {unregistered ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => resendInviteMutation.mutate(params.row.email)}
                startIcon={<Send />}
              >
                Resend Invite
              </Button>
            ) : (
              <Chip
                label={'Verified'}
                variant="outlined"
                sx={{
                  borderColor: '#71d171',
                  color: '#71d171'
                }}
              />
            )}
          </Box>
        )
      }
    }
  ]

  return (
    <>
      {hasAnyRole(['staff', 'owner', 'admin']) && (
        <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'}>
          <Modal
            maxWidth={'md'}
            button={
              <Button
                color="primary"
                variant="contained"
                size="small"
                startIcon={<Add />}
              >
                {'Add User'}
              </Button>
            }
          >
            <UserForm
              emailOnly={true}
              instituteId={apiConfig.currentApiInstitute}
              exitFunction={() => {
                queryClient.invalidateQueries(QUERIES.GET_ALL_INSTITUTE_USERS)
              }}
              saveLabel={'Add'}
            />
          </Modal>
        </Box>
      )}
      <ServerDataGrid
        columns={userDataColumns}
        query={useGetAllInstituteUsers}
        queryParams={{
          id: apiConfig.currentApiInstitute
        }}
        getRowId={(row) => row.id}
      />
    </>
  )
}

const SettingsAccordian = ({ title, caption, children }) => {
  return (
    <Accordion
      sx={{
        padding: 0,
        margin: 0
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box>
          <Typography flexGrow={1} variant="h5">
            {title}
          </Typography>
          <Typography variant="caption">{caption}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

const InstituteSettings = () => {
  const history = useHistory()
  return (
    <Box
      paddingY={2}
      paddingX={4}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Box display={'flex'}>
        <Typography flexGrow={1} variant="h1">
          Settings
        </Typography>
        {hasAnyRole(['staff', 'owner']) && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Edit />}
            onClick={() =>
              history.push(
                `/organization/${apiConfig.currentApiInstitute}/settings`
              )
            }
          >
            Edit Settings
          </Button>
        )}
      </Box>
      <Box>
        <SettingsAccordian
          title={'Analyzer'}
          caption={'Manage analyzer settings for the organization'}
        >
          <AnalyzerSettings />
        </SettingsAccordian>
        <SettingsAccordian
          title={'Vitals'}
          caption={'Manage vitalvials for the organization'}
        >
          <VitalVialSettings />
        </SettingsAccordian>
        <SettingsAccordian
          title={'Users'}
          caption={'Manage users and their access'}
        >
          <UserSettings />
        </SettingsAccordian>
      </Box>
    </Box>
  )
}

export default InstituteSettings
