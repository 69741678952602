import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { authenticationService } from 'services'

const RoleBlockedComponent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      fullWidth
      height="100%"
    >
      <Typography variant="h1">Premission Required</Typography>
      <Typography
        variant="caption"
        fontSize={18}
        alignContent={'justify'}
        textAlign={'center'}
      >
        You currently do not have the permission level to edit this.
      </Typography>
      <Typography
        variant="caption"
        fontSize={18}
        alignContent={'justify'}
        textAlign={'center'}
      >
        If you think you need it, please contact your organization admin
      </Typography>
    </Box>
  )
}

const MainContent = React.forwardRef(
  ({ sidebar = null, ml = 360, mr = 360, children }, ref) => {
    const history = useHistory()
    const [currentPath, setCurrentPath] = useState(null)
    const [multiInstitute, setMultiInstitute] = useState()

    useEffect(() => {
      if (history && history.location.pathname !== currentPath) {
        setCurrentPath(history.location.pathname)
      }
    }, [history])

    useEffect(() => {
      if (authenticationService) {
        setMultiInstitute(authenticationService.multiInstitute)
      }
    }, [authenticationService, multiInstitute])

    const useStyles = makeStyles((theme) => ({
      root: {
        height: 'calc(100vh - 117px)',
        overflowY: 'auto',
        marginRight: sidebar === 'right' ? mr : 0,
        marginLeft: sidebar === 'left' ? ml : 0
      }
    }))
    const classes = useStyles()
    return (
      <div className={classes.root} ref={ref}>
        {children}
      </div>
    )
  }
)

export default MainContent
