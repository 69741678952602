import React from 'react'
import { Box, Typography } from '@mui/material'

export default function TabPanel (props) {
  const { children, value, index, label, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`${label}-tabpanel-${index}`}
      aria-labelledby={`${label}-tab-${index}`}
      {...other}
    >
      {value === index && <Box {...other}>{children}</Box>}
    </Typography>
  )
}
