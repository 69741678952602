import OutlinedCircle from '@mui/icons-material/RadioButtonUnchecked'
import Square from '@mui/icons-material/Stop'
import { Box, Button, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import {
  DropDownSelect,
  InfoBox,
  InfoButton,
  InlineError,
  LeveyJenningsPlot,
  Loader,
  NumberWidget,
  Validated
} from 'components'
import { openDocsPage } from 'helpers'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, Redirect, useParams } from 'react-router-dom'
import { Flag, api, useMetricsContext } from 'services'

export default function GraphView({
  lineStats,
  lineData,
  validation,
  range,
  url
}) {
  const { id } = useParams()
  const { currentMetricConfig, metricType, metricLabel } = useMetricsContext()
  const { data: lineRecordData, isLoading } = useQuery(
    ['line-record-graph-data', { id, url, metricType }],
    () => {
      const params = {
        line: id,
        exclude: false,
        size: 9999,
        rejection: `${metricType}`
      }

      for (const [key, value] of url.searchParams.entries()) {
        params[key] = value
      }

      return api.records.list(params).then((data) => {
        return data.items
      })
    }
  )

  const [recordsOutOfRange, setRecordsOutOfRange] = useState(0)
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')

  useEffect(() => {
    if (lineRecordData && range) {
      const outOfRangeCount = lineRecordData.filter(
        (record) =>
          (lineData.validation
            ? record.date >= lineData.validation.start_date
            : true) &&
          (record.metrics[metricType] <= range.lower ||
            record.metrics[metricType] >= range.upper)
      ).length
      setRecordsOutOfRange(outOfRangeCount)
    }
  }, [range, lineRecordData, lineData, metricType])

  return (
    <Flag name="monitoring" alternate={<Redirect to={`/line/${id}/records`} />}>
      <Box display="flex" flexDirection="column">
        {recordsOutOfRange >= 4 && (
          <Box pt={1}>
            <Alert
              variant="outlined"
              severity="info"
              action={
                <Link to={'/validation/new'}>
                  <Button size="small"> Start Validation </Button>
                </Link>
              }
            >
              {`Re-Validation is recommended to set the new baseline as ${recordsOutOfRange} records are out of range.`}
            </Alert>
          </Box>
        )}
        <Box
          py={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
          width="100%"
        >
          <NumberWidget
            mr={10}
            num={
              <Validated
                iconSize="small"
                validated={lineData && lineData.validation}
                keepIconEmpty={false}
                childrenSpacing={1}
              >
                {range
                  ? `${range.lower.toFixed(0)} - ${range.upper.toFixed(0)}`
                  : '-'}
              </Validated>
            }
            range={true}
            label={
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
                justifyContent="center"
                alignSelf="center"
              >
                <Box display="flex" flexDirection="row" pr={'2px'}>
                  <Box mr={metricType === currentMetricConfig ? '4px' : 1}>
                    {metricLabel}
                  </Box>
                  <Box>{'Range'}</Box>
                </Box>
                <InfoButton placement="bottom">
                  <InfoBox onLearnMoreClick={() => openDocsPage('/monitoring')}>
                    {
                      <div>
                        VitalMetric Range specifies the expected limits for 99%
                        of measurements on a PTS line. The range is based on
                        validation records if the line is validated and on all
                        records otherwise.
                        <br />
                        Motryx recommends reviewing the records that produce
                        VitalMetric values outside of the VitalMetric Range.
                      </div>
                    }
                  </InfoBox>
                </InfoButton>
              </Box>
            }
          />
          <NumberWidget
            num={recordsOutOfRange}
            label={'Records Out of Range'}
            color={recordsOutOfRange > 0 ? '#CA7A85' : 'inherit'}
          />
          <Box
            styles={{
              display: 'flex',
              justifyContent: 'end'
            }}
          >
            <DropDownSelect
              labelId="image-format-select"
              label="Image Format"
              value={downloadImageFormat}
              onValueChange={(newValue) => {
                setDownloadImageFormat(newValue)
              }}
              items={[
                { content: 'PNG', value: 'png' },
                { content: 'SVG', value: 'svg' }
              ]}
              styles={{ minWidth: '130px', maxHeight: '40px' }}
            />
          </Box>
        </Box>
        <Box
          mx={8}
          mt={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box alignItems="center" display="flex" flexDirection="row" mr={3}>
            <OutlinedCircle color="primary" />
            <Typography display="inline" variant="subtitle2">
              {'Validation Transit Records'}
            </Typography>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            fontWeight={600}
          >
            <OutlinedCircle htmlColor="#367b79" />
            <Typography display="inline" variant="subtitle2">
              {'Routine Transit Records'}
            </Typography>
          </Box>

          {lineStats && lineStats.record_count > 2 && (
            <Box
              alignItems="center"
              justify="flex-end"
              color="#FACCD3"
              display="flex"
              flexDirection="row"
            >
              <Square color="inherit" fontSize="large" />
              <Typography display="inline" variant="subtitle2">
                {'Out of Range Area'}
              </Typography>
            </Box>
          )}
        </Box>

        <Box>
          {lineRecordData && !isLoading ? (
            lineRecordData.length > 0 ? (
              <LeveyJenningsPlot
                records={lineRecordData}
                variant={metricType}
                lineName={lineData.name}
                summarystats={
                  validation
                    ? validation[metricType]
                    : lineStats && lineStats.record_count > 2
                    ? lineStats[metricType]
                    : { mean: null, stddev: null }
                }
                downloadImageFormat={downloadImageFormat}
              />
            ) : (
              <InlineError
                title="No Data"
                detail="There are no records associated with this line."
              />
            )
          ) : (
            <Loader />
          )}
        </Box>
        <Box>
          <Box
            display={
              lineRecordData && lineRecordData.length > 0 ? 'block' : 'none'
            }
            pt={2}
          >
            {lineRecordData && lineRecordData.length > 0 && !isLoading ? (
              <LeveyJenningsPlot
                records={lineRecordData}
                variant="temperature"
                lineName={lineData.name}
                summarystats={
                  validation
                    ? validation.temperature
                    : lineStats && lineStats.record_count > 2
                    ? lineStats.temperature
                    : { mean: null, stddev: null }
                }
                downloadImageFormat={downloadImageFormat}
              />
            ) : (
              <Loader />
            )}
          </Box>
        </Box>
      </Box>
    </Flag>
  )
}
