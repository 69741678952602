import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import styles from './styles'

const useStyles = makeStyles(styles)

export default function BackLink ({ noBorder = false, ...rest }) {
  const classes = useStyles()
  const history = useHistory()

  return history.location.state
    ? (
    <Box
      px={4}
      py={2}
      className={classes.root}
      style={{ borderBottom: !noBorder && '1px solid #D6DAE0' }}
      display={
        !history.location.state.name
          ? 'none'
          : (
              history.location.state.referrer
                ? (
                    history.location.state.referrer.path ? 'flex' : 'none'
                  )
                : 'flex'
            )
      }
      alignContent="center"
      onClick={() => {
        history.location.state.referrer
          ? (
              history.push(
                history.location.state.referrer.path,
                {
                  name: history.location.state.referrer.back && history.location.state.referrer.back.label,
                  referrer: { path: history.location.state.referrer.back && history.location.state.referrer.back.path }
                }
              )
            )
          : (
              history.goBack()
            )
      }}
      {...rest}
    >
      { history.location.state.name ? `< Back to ${history.location.state.name}` : '< Back'}
    </Box>
      )
    : null
}
