import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { FeedbackOutlined } from '@mui/icons-material'

import styles from './styles'
const useStyles = makeStyles(styles)

export default function InlineError ({ title, detail, icon, color = '#33403F' }) {
  const classes = useStyles()

  const ErrorIcon = withStyles({
    root: {
      color: color,
      fontSize: '90px'
    }
  })(icon || FeedbackOutlined)

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center" justifyContent="center" className={classes.grid}>
        <Grid item xs={12}>
          <ErrorIcon />
          <Typography variant="h1" component="h1" style={{ color: color }} gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {detail}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
