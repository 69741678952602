import { InstituteLock } from 'helpers'
import { useQuery } from 'react-query'
import { Route, Switch, useParams } from 'react-router-dom'
import { api, hasRole } from 'services'
import MeasurementsView from './MeasurementsView'
import SettingsView from './SettingsView'

export default function HemolysisDetail() {
  const { id } = useParams()
  const { data: hemolysisData, refetch } = useQuery(
    ['hemolysis-data', id],
    () => api.hemolysisData.get(id)
  )
  return (
    <InstituteLock
      id={hemolysisData && hemolysisData.institute.id}
      redirectUrl="/lines"
    >
      <Switch>
        {!hasRole('guest') && (
          <Route
            path="/hemolysis-index-data/:id/settings"
            children={
              <SettingsView hemolysisData={hemolysisData} refresh={refetch} />
            }
          />
        )}

        <Route
          path="/hemolysis-index-data/:id"
          children={<MeasurementsView hemolysisData={hemolysisData} />}
        />
      </Switch>
    </InstituteLock>
  )
}
