import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledDocLink } from 'components'

export default function Welcome () {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Welcome to VitalQC!</Typography>
      <Box my={1}>
        <Typography variant="body1" fontSize={16}>
          {'Start collecting data by going through the  '}
          <StyledDocLink to="/getting-started">{'Getting Started Guide'}</StyledDocLink>
          {' or begin a '}
          <StyledDocLink to="/validation">{'validation.'}</StyledDocLink>
        </Typography>
      </Box>
      <Box my={1}></Box>
    </Box>
  )
}
