export default (theme) => ({
  loginavatar: {
    borderRadius: 0,
    height: 'auto',
    width: 'auto',
    margin: '20px',
    backgroundColor: 'transparent',
    '& .MuiAvatar-img': {
      backgroundColor: 'transparent'
    },
    maxWidth: 400
  },

  title: {
    marginBottom: theme.spacing(3)
  },
  alt_link: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    textAlign: 'right'
  }
})
