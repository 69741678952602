import React from 'react'
import { InfoBox, InfoButton } from 'components'
import { openDocsPage } from 'helpers'

export default function RejectionProbabilityInfo () {
  return (
    <InfoButton>
      <InfoBox onLearnMoreClick={() => openDocsPage('/insights/rejection-probability')}>
        {
          'Rejection Probability is the probability of exceeding the lab-specific hemolysis thresholds for LDH, ASAT, and Potassium (K). Observed clinical hemolysis rates may differ.'
        }
      </InfoBox>
    </InfoButton>
  )
}
