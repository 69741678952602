import React from 'react'
import { Text, Font } from '@react-pdf/renderer'

Font.registerHyphenationCallback(word => ([word]))
Font.register({
  family: 'neue-haas-grotesk-display',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayLight.woff' },
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayRoman.woff' },
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayMediu.woff', fontWeight: 'semibold' },
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayBold.woff', fontWeight: 'bold' }
  ]
})

const StyledText = ({ fontSize = 7, fontWeight = 400, underline = false, color = '#333A40', margin = 2, children, style }) => (
  <Text style={style || {
    fontFamily: 'neue-haas-grotesk-display',
    fontWeight: fontWeight,
    textDecoration: underline ? 'underline' : 'none',
    fontSize: fontSize,
    color: color,
    opacity: 1,
    margin: margin
  }}>
    {children}
  </Text>
)

export default StyledText
