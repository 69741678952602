import { Box, Button, FormHelperText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import VialIcon from 'icons/Vial'

import {
  apiConfig,
  authenticationService,
  useDeviceUploadWorker
} from 'services'

function VitalVialReadyToUpload({ device, instituteId, upload }) {
  if (upload && device.uploadStatus === 'Ready to Upload') {
    device.uploadStatus = 'Uploading'
    device.instituteId = instituteId
    useDeviceUploadWorker(device)
  }

  return (
    <Box
      display={device.uploadStatus === 'Ready to Upload' ? 'flex' : 'none'}
      flexDirection="row"
      alignItems="center"
      mb={2}
    >
      <VialIcon
        fontSize="inherit"
        htmlColor="#728093"
        style={{ marginRight: 5 }}
      />
      {device.vialLabel}
    </Box>
  )
}

export default function VitalVialUpload({ devices, readyToUploadDevices }) {
  const multiInstitute = authenticationService.multiInstitute
  const [currentInstituteId, setCurrentInstituteId] = useState('')
  const [currentInstituteLabel, setCurrentInstituteLabel] = useState('')

  const [upload, setUpload] = useState(false)
  const handleUpload = (event) => {
    setUpload(true)
  }

  useEffect(() => {
    if (readyToUploadDevices && readyToUploadDevices.length <= 0) {
      setUpload(false)
    }
  }, [readyToUploadDevices])

  useEffect(() => {
    const apiConfigSubscription = apiConfig.currentApi.subscribe(
      (currentApi) => {
        if (currentApi?.institute) {
          if (currentInstituteId !== currentApi.institute.toString()) {
            setCurrentInstituteId(currentApi.institute.toString())
            setCurrentInstituteLabel(currentApi.institute_name)
          }
        }
      }
    )

    return () => {
      apiConfigSubscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (
      apiConfig.currentApiInstitute &&
      apiConfig.currentApiInstitute !== currentInstituteId
    ) {
      setCurrentInstituteId(apiConfig.currentApiInstitute.toString())
    }

    if (
      apiConfig.currentApiInstituteName &&
      apiConfig.currentApiInstituteName !== currentInstituteLabel
    ) {
      setCurrentInstituteLabel(apiConfig.currentApiInstituteName)
    }
  }, [apiConfig])

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box mb={2}>
        <Typography variant="h5">{'Ready to Upload'}</Typography>
      </Box>

      <Box display="flex" flexDirection="column" mb={1}>
        {devices &&
          devices.map((device, index) => {
            return (
              <div key={`ready-to-upload-vial-${device.vialLabel}`}>
                <VitalVialReadyToUpload
                  device={device}
                  instituteId={currentInstituteId}
                  upload={upload}
                />
              </div>
            )
          })}
      </Box>

      <Box mb={2} display="flex" justifyContent="center">
        <FormHelperText component="div">
          Upload to{' '}
          <span
            style={{
              fontWeight: 'bold'
            }}
          >
            {currentInstituteLabel}
          </span>
        </FormHelperText>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="center">
        {/* <Box flexGrow={multiInstitute ? 0 : 1}> */}
        <Button
          fullWidth
          variant="contained"
          size="small"
          color="primary"
          onClick={handleUpload}
          disabled={upload}
        >
          Upload
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  )
}
