import Done from '@mui/icons-material/Done'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Typography
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { format, parseJSON } from 'date-fns'
import InlineRecordForm from 'forms/InlineRecordForm'
import { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import {
  api,
  apiConfig,
  hasRole,
  useMetricsContext,
  useRecordSummary
} from 'services'
import ReviewDataDetail from './ReviewDataDetail'

const columns = [
  { field: 'id', hide: true },
  {
    field: 'vial',
    headerName: 'VitalVial',
    width: 160,
    sortable: false,
    renderCell: ({ value }) => value
  },
  {
    field: 'date',
    headerName: 'Date Recorded',
    type: 'dateTime',
    renderCell: ({ value }) => format(parseJSON(value), 'dd MMM yyyy HH:mm'),
    width: 200
  },
  {
    field: 'metrics',
    renderHeader: () => {
      const { metricLabel } = useMetricsContext()
      return (
        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
          {metricLabel}
        </Typography>
      )
    },
    width: 200,
    sortable: false,
    renderCell: ({ value }) => {
      const { metricType } = useMetricsContext()
      return value[metricType]
    }
  },
  {
    field: 'data',
    sortable: false,
    renderHeader: () => {
      return (
        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
          {'Data'}
        </Typography>
      )
    },
    renderCell: (params) => {
      const id = params.getValue(params.id, 'id')
      const date = parseJSON(params.getValue(params.id, 'date'))
      return (
        <Modal
          button={
            <Button color="primary" size="small">
              {'View '}
              {hasRole('staff') ? `${id}` : 'Data'}
            </Button>
          }
          label={`${params.getValue(params.id, 'vial')} - ${format(
            date,
            'dd MMM yyyy HH:mm'
          )}`}
          closeButtonColor="default"
        >
          <ReviewDataDetail id={id} />
        </Modal>
      )
    },
    width: 200
  }
]

function NoRowsOverlay() {
  return (
    <GridOverlay>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Box>
          <Done color="primary" fontSize="large" />
        </Box>
        <Box alignItems="center">
          <Typography variant="body1"> No Records to Review</Typography>
        </Box>
      </Box>
    </GridOverlay>
  )
}

function LoadingOverlay() {
  return (
    <GridOverlay>
      <Loader title="Retrieving records..." message={null} />
    </GridOverlay>
  )
}

export default function ToReview() {
  const [showForm, setShowForm] = useState(false)

  const tableRef = useRef()
  const drawerRef = useRef()

  const [tableHeight, setTableHeight] = useState(490)
  const [refreshState, setRefreshState] = useState(false)

  const handleTableHeight = () => {
    if (tableRef.current && drawerRef.current) {
      setTableHeight(
        window.innerHeight -
          tableRef.current.offsetTop -
          drawerRef.current.offsetHeight -
          5
      )
    }
  }

  const [recordsSelected, setRecordsSelected] = useState([])
  const { data: recordsToReview, loading: isLoading } = useQuery(
    ['records-to-review', apiConfig.currentApiInstitute, refreshState],
    () =>
      api.records
        .list({
          size: 9999,
          is_processed: true,
          is_reviewed: false,
          hidden: false
        })
        .then((data) => {
          setShowForm(data.total > 0)
          handleTableHeight()
          setRefreshState(false)
          return data.items
        }),
    { initialData: [], refetchInterval: 3000 }
  )

  const selectRecords = (records) => {
    setRecordsSelected(records)
  }

  const { data: recordSummary } = useRecordSummary()

  const [sortModel, setSortModel] = useState([
    {
      field: 'date',
      sort: 'asc'
    }
  ])
  return (
    <>
      {recordSummary && recordSummary.unprocessed > 0 && (
        <Box pb={2}>
          <Alert
            icon={<CircularProgress size={22} color="inherit" thickness={4} />}
            severity="info"
            variant="outlined"
          >
            {
              'Records are being processed and will show up below when finished.'
            }
          </Alert>
        </Box>
      )}
      <Box
        ref={tableRef}
        style={{
          height: tableHeight || 200,
          width: '100%',
          marginBottom: drawerRef.current ? drawerRef.current.offsetHeight : 100
        }}
      >
        <DataGrid
          rows={recordsToReview}
          columns={columns}
          onSelectionModelChange={(selection) => {
            return selectRecords(selection)
          }}
          hideFooter
          loading={isLoading}
          checkboxSelection
          disableColumnMenu
          disableSelectionOnClick
          components={{
            NoRowsOverlay: (params) => {
              return <NoRowsOverlay />
            },
            LoadingOverlay: (params) => {
              return <LoadingOverlay />
            }
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
      {!hasRole('guest') && (
        <Box data-testid="inline-record-from">
          <Drawer variant="persistent" anchor="bottom" open={showForm}>
            <Box py={2} px={4} ref={drawerRef}>
              <InlineRecordForm
                ids={recordsSelected}
                refreshFunction={setRefreshState}
              />
            </Box>
          </Drawer>
        </Box>
      )}
    </>
  )
}
