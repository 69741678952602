import React from 'react'
import { Svg, Text, Path } from '@react-pdf/renderer'
import { arc } from 'd3-shape'
import { scaleLinear } from 'd3-scale'

const Gauge = ({ value = 5, min = 0, max = 10, color = '#4FC1BE', width = 50 }) => {
  const backgroundArc = arc()
    .innerRadius(width * 0.85)
    .outerRadius(width)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(width)()

  const percentScale = scaleLinear()
    .domain([min, max])
    .range([0, width])
  const percent = percentScale(value)

  const angleScale = scaleLinear()
    .domain([0, width])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(percent)

  const filledArc = arc()
    .innerRadius(width * 0.85)
    .outerRadius(width)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(width)()

  return (
    <Svg
      style={{ overflow: 'visible' }}
      width={ width }
      viewBox={[
        -(width), -(width),
        width * 2, width
      ].join(' ')}>
      <Text x="-10" y="0" style={{
        fontFamily: 'neue-haas-grotesk-display',
        fontSize: 30,
        fontWeight: 600,
        fill: '#333A40'
      }}
      >{value}</Text>
      <Path
          d={backgroundArc}
          fill="#dbdbe7"
        />
      <Path
          d={filledArc}
          fill={color}
        />
    </Svg>
  )
}

export default Gauge
