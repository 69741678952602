import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Flag } from 'services'
import GraphView from './GraphView'
import TableView from './TableView'

export default function Hemolysis () {
  return (
    <Flag name={'hemolysisTracking'} alternate={<Redirect to={{ pathname: '/dashboard' }} />}>
      <Switch>
        <Route path="/hemolysis-index" children={<TableView />} />
        <Route
          path="/rejection-insights"
          children={<GraphView/>}
        />
      </Switch>
    </Flag>
  )
}
