import React from 'react'
import { useParams, Switch, Route, Redirect } from 'react-router-dom'
import { Flag } from 'services'
import EditView from './EditView'
import MainView from './MainView'
export default function ValidationDetail () {
  const { id } = useParams()

  return (
    <Flag name={'validation'} alternate={<Redirect to={{ pathname: '/dashboard' }} />}>
      <Switch>
        <Route path="/validation/:id/edit" children={<EditView id={id} />} />
        <Route path="/validation/:id" children={<MainView id={id} />} />
      </Switch>
    </Flag>
  )
}
