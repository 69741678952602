import { intervalToDuration, parseISO, addDays, format } from 'date-fns'

export function formatDuration (duration) {
  duration = intervalToDuration({ start: 0, end: duration * 1000 })
  const days = duration.days > 0 ? (duration.days === 1 ? `${duration.days} day,` : `${duration.days} days,`) : ''
  const hours = duration.hours
  const minutes = duration.minutes >= 10 ? duration.minutes : `0${duration.minutes}`
  const seconds = duration.seconds >= 10 ? duration.seconds : `0${duration.seconds}`
  return `${days} ${hours}:${minutes}:${seconds}`
}

export function formatDateRange (start_date, days = null, end_date = null) {
  const start = parseISO(start_date)
  const end = end_date ? parseISO(end_date) : addDays(start, days - 1)
  const date_range =
    days === 1
      ? format(start, 'd MMM yyyy')
      : `${format(start, start.year !== end.year ? 'd MMM yyyy' : 'd MMM')} - ${format(end, 'd MMM yyyy')}`
  return date_range
}

export function formatDate (value) {
  const date = new Date(value)
  const options = { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' }
  return date.toLocaleDateString('en-GB', options)
}

export function nextValidationDate (startDate, months, addRange = false) {
  const date = new Date(startDate)
  const options = { month: 'short', year: 'numeric' }
  date.setMonth(date.getMonth() + months)
  if (addRange) {
    const month = date.toLocaleDateString('en-GB', { month: 'short' })
    date.setMonth(date.getMonth() + 1)
    return month + ' or ' + date.toLocaleDateString('en-GB', options)
  }
  return date.toLocaleDateString('en-GB', options)
}
