import { MainContent } from 'components'
import { Route, Switch } from 'react-router-dom'
import { hasAnyRole } from 'services'
import InstituteSettings from './InstituteSettingsView'
import SettingsView from './SettingsView'

export default function InstituteDetail() {
  return (
    <MainContent>
      <Switch>
        {hasAnyRole(['owner', 'staff']) && (
          <Route
            path="/organization/:id/settings"
            children={<SettingsView />}
          />
        )}
        {/* <Route path="/organization/:id" children={<MainView />} /> */}
        <Route path="/settings" children={<InstituteSettings />} />
      </Switch>
    </MainContent>
  )
}
