import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import StyledText from '../StyledText/index.js'

const rejectionColor = (value, low = 3, high = 15) => {
  if (value > high) {
    return '#fa964a'
  } else if (value < low) {
    return '#4FC184'
  }
  return '#333A40'
}

const NumberWidget = ({ num, label, isRejection = false, unit = '', isGauge = false }) => {
  const styles = StyleSheet.create({
    num: {
      fontFamily: 'neue-haas-grotesk-display',
      fontSize: 14,
      fontWeight: 600,
      color: isRejection ? rejectionColor(num) : '#333A40',
      paddingBottom: 5
    },
    label: {
      fontFamily: 'neue-haas-grotesk-display',
      fontSize: 10,
      fontWeight: 400,
      color: '#333A40'
    }
  })

  return (
    <View style={{ marginRight: 20 }}>
      {
        isGauge
          ? <View style={{ paddingBottom: 5 }}>{ num }</View>
          : <StyledText style={ styles.num }>
            { num }{ unit }
          </StyledText>
      }

      <StyledText style={ styles.label }>
        { label }
      </StyledText>
    </View>
  )
}

export default NumberWidget
