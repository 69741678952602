import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Box, Typography } from '@mui/material'
import { api } from 'services'
import { Label } from 'components'
import { useSnackbar } from 'notistack'

export default function DeleteLabelForm ({ label, closeModalFunction, exitFunction }) {
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit } = useForm()

  const onSubmit = (data) => {
    if (label.id) {
      api.labels
        .delete(label.id)
        .then(function (response) {
          enqueueSnackbar('Label has been deleted.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          exitFunction()
          closeModalFunction()
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
  return (
    <form id={`delete_label_form_${label.id}`} onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems='center'>
        <Box flexGrow={1}>
          <Typography variant="subtitle1" color="error">
            {'Deleting this label will remove it from all records and lines.'}<br/>{'Are you sure you want to delete '}
            <Label size="small" label={label.label} color={label.color} /> {' ?'}
          </Typography>
        </Box>
        <Box pr={2}>
          <Button type="submit" variant="contained" color="error" size="small">
            {'Delete Label'}
          </Button>
        </Box>
        <Box>
          <Button variant="contained" color="primary" size="small" onClick={closeModalFunction}>
            {'Cancel'}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
