import React from 'react'
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import makeStyles from '@mui/styles/makeStyles'
import styles from './styles'
import { useDeviceWorker } from 'services'

const useStyles = makeStyles(styles)

export default function VitalVial ({ connected, id, label, device }) {
  const classes = useStyles()
  const { progress } = useDeviceWorker(device)

  return (
    <Grid container justifyContent="center" alignItems="stretch" direction="column">
      <Grid item>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}>
            <Typography variant="caption">{label}</Typography>
          </Box>
          {!device.registered
            ? (
            <Box display="flex">
              <Box pr={1} alignItems="center">
                <ErrorIcon fontSize="small" color="error" />
              </Box>
              <Typography variant="caption" color="error">
                {'NOT REGISTERED'}
              </Typography>
            </Box>
              )
            : device.endOfLife
              ? (
            <Box display="flex">
              <Box pr={1} alignItems="center">
                <ErrorIcon fontSize="small" color="error" />
              </Box>
              <Typography variant="caption" color="error">
                {'NO LONGER SUPPORTED'}
              </Typography>
            </Box>
                )
              : (
            <div>
              {progress < 1
                ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="caption">{'Uploading'}</Typography>
                  </Box>
                </Box>
                  )
                : (
                <Box>
                  <Typography variant="caption">Connected</Typography>
                </Box>
                  )}
            </div>
                )}
        </Box>
      </Grid>

      {device && device.registered && !device.endOfLife && <Divider variant="fullWidth" />}
      {device && device.registered && !device.endOfLife && (
        <Grid item>
          <LinearProgress
            color="primary"
            variant="determinate"
            value={100 * progress}
            className={classes.progressBar}
          />
        </Grid>
      )}
    </Grid>
  )
}
