import React from 'react'
import { Text, Font } from '@react-pdf/renderer'

Font.registerHyphenationCallback(word => ([word]))
Font.register({
  family: 'neue-haas-grotesk-display',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayLight.woff' },
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayRoman.woff' },
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayMediu.woff', fontWeight: 'semibold' },
    { src: 'https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayBold.woff', fontWeight: 'bold' }
  ]
})

const variant_map = {
  h1: { fontSize: 26, fontWeight: 200 },
  h2: { fontSize: 20, fontWeight: 200 },
  h3: { fontSize: 15, fontWeight: 600 },
  h4: { fontSize: 12, fontWeight: 500 },
  body1: { fontSize: 12, fontWeight: 100 },
  body2: { fontSize: 12, fontWeight: 600 },
  reference: { fontSize: 8, fontWeight: 100 }
}

const StyledText = ({ fontSize = 7, fontWeight = 400, underline = false, color = '#333A40', margin = 2, variant, children, style }) => (
  <Text style={style || {
    fontFamily: 'neue-haas-grotesk-display',
    fontWeight: variant ? variant_map[variant].fontWeight : fontWeight,
    textDecoration: underline ? 'underline' : 'none',
    fontSize: variant ? variant_map[variant].fontSize : fontSize,
    color: color,
    opacity: 1,
    margin: margin
  }}>
    {children}
  </Text>
)

export default StyledText
