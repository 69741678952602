import { QUERIES } from 'helpers'
import { useQuery } from 'react-query'
import { api } from 'services'

// React Query Hooks
export const useGetInstitutes = (onSuccess, onError, filters = {}) => {
  return useQuery({
    queryKey: [QUERIES.GET_INSTITUTES, { ...filters }],
    queryFn: async () => {
      if (!filters?.page || !filters?.size) {
        filters = {
          ...filters,
          page: 1,
          size: 1000
        }
      }
      return await api.institutes.list(filters)
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

export const useGetInstitute = ({ onSuccess, onError, id }) => {
  return useQuery({
    queryKey: [QUERIES.GET_INSTITUTE, id],
    queryFn: async () => await api.institutes.get(id),
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    },
    enabled: !!id
  })
}
