import React from 'react'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import VialIcon from 'icons/Vial'

export default function VitalVialStatus ({ uploadCompleteDevices }) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display={uploadCompleteDevices && uploadCompleteDevices.length > 0 ? 'flex' : 'none'} mb={2}>
        <Typography variant='h5'>{'Vials'}</Typography>
      </Box>
      {uploadCompleteDevices &&
        uploadCompleteDevices.map((device, index) => {
          return (
            <div key={`connected-vial-${device.vialLabel}`}>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" height={23} mb={2}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >
                  <VialIcon fontSize="inherit" htmlColor="#728093" style={{ marginRight: 5 }}/>
                  {device.vialLabel}
                </Box>
                <Tooltip title={device.uploadStatus} placement="right-start">
                  {((device.uploadStatus === 'Upload Complete') && <CheckCircleIcon color="primary" />) ||
                    ((device.uploadStatus === 'Uploading') && (
                      <Box style={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" size={'1.3rem'} style={{ color: '#0B8DDE', padding: 1.5 }} value={Math.ceil(device.uploadProgress * 100)}/>
                        <Box
                          style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <CircularProgress variant="determinate" value={100} size={'1.3rem'} style={{ color: '#0B8DDE50', padding: 1.5 }} />
                        </Box>
                      </Box>
                    )) ||
                    (<ErrorIcon color="error" />)}
                </Tooltip>
              </Box>
            </div>
          )
        })}
    </Box>
  )
}
