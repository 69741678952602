import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QUERIES } from '../helpers'
import { api } from '../services'

const useVitalVialManager = () => {
  const queryClient = useQueryClient()

  const invalidateGetVitalVialsQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: [QUERIES.GET_VITAL_VIALS]
    })
  }

  const getVitalVials = ({ onSuccess = null, onError = null, params = {} }) => {
    return useQuery({
      queryKey: [QUERIES.GET_VITAL_VIALS, { ...params }],
      queryFn: async () => await api.vials.list(params),
      onSuccess: (data) => {
        onSuccess && onSuccess(data)
      },
      onError: (error) => {
        onError && onError(error)
      }
    })
  }

  const useCreateVitalVial = ({ onSuccess = null, onError = null }) => {
    return useMutation({
      mutationKey: [QUERIES.CREATE_VITAL_VIAL],
      mutationFn: async (data) => await api.vials.create(data),
      onSuccess: (data) => {
        onSuccess && onSuccess(data)
      },
      onError: (error) => {
        onError && onError(error)
      }
    })
  }

  const useUpdateVitalVial = ({ onSuccess = null, onError = null }) => {
    return useMutation({
      mutationKey: [QUERIES.UPDATE_VITAL_VIAL],
      mutationFn: async ({ id, data }) => await api.vials.update(id, data),
      onSuccess: (data) => {
        onSuccess && onSuccess(data)
      },
      onError: (error) => {
        onError && onError(error)
      }
    })
  }

  return {
    invalidateGetVitalVialsQuery,
    getVitalVials,
    useCreateVitalVial,
    useUpdateVitalVial
  }
}

export default useVitalVialManager
