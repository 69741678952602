import { Add, Flag } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { MainContent, Modal, ServerSideDataGrid, StyledLink } from 'components'
import { InstituteForm } from 'forms'
import { instituteRelations, instituteSubscriptions } from 'helpers'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Role, api, hasRole } from 'services'
function mapOption(value, options) {
  if (value)
    value = options.find((option) => option.value === value)?.name || value
  return value
}

const columns = [
  { field: 'id', hide: true },
  { field: 'relationship_label', hide: true },
  { field: 'subscription_label', hide: true },
  { field: 'has_feature_flags', hide: true },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: (params) => {
      const name = params.getValue(params.id, 'name')
      const id = params.getValue(params.id, 'id')
      const featureFlag = params.getValue(params.id, 'has_feature_flags')
      return (
        <StyledLink
          to={{
            pathname: `/organization/${id}/settings`,
            state: {
              name: 'Organizations'
            }
          }}
        >
          <Box display="flex" flexDirection="row">
            <Role restrict={['staff']}>
              <Box mr={1}>
                <Flag
                  fontSize="small"
                  color={featureFlag ? 'primary' : 'disabled'}
                />
              </Box>
            </Role>
            {name}
          </Box>
        </StyledLink>
      )
    }
  },
  {
    field: 'partner_id',
    headerName: 'Partner ID',
    width: 130
  },
  {
    field: 'relationship',
    headerName: 'Relationship',
    renderCell: ({ value }) => mapOption(value, instituteRelations),
    width: 200,
    hide: !hasRole('staff')
  },
  {
    field: 'subscription',
    headerName: 'Subscription',
    renderCell: ({ value }) => mapOption(value, instituteSubscriptions),
    width: 200,
    hide: !hasRole('staff')
  }
]

export default function Institutes(props) {
  const queryClient = useQueryClient()
  const queryName = 'allInstitutes'
  const [searchString, setSearchString] = useState('')
  const [excludeArchived, setExcludeArchived] = useState(
    localStorage.getItem('excludeArchivedInstitutes') === null
      ? true
      : localStorage.getItem('excludeArchivedInstitutes') === 'false'
  )

  const handleExcludeArchived = (event) => {
    setExcludeArchived(event.target.checked)
    localStorage.setItem('excludeArchivedInstitutes', event.target.checked)
  }

  return (
    <MainContent>
      <Box display="flex" flexDirection="column" p={4} gap={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h1">Organizations</Typography>

          <Modal
            maxWidth={'xs'}
            button={
              <Button
                color="primary"
                variant="contained"
                size="small"
                startIcon={<Add />}
              >
                {'Add Organization'}
              </Button>
            }
            label={'Add Organization'}
          >
            <InstituteForm
              exitFunction={() => queryClient.invalidateQueries(queryName)}
            />
          </Modal>
        </Box>

        <Role restrict={['staff']}>
          <Box display="flex" gap={2} alignItems="center">
            <TextField
              id="organization-search"
              label="Search Organization"
              variant="outlined"
              size="small"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              sx={{
                width: '30ch'
              }}
            />
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={excludeArchived}
                    onChange={handleExcludeArchived}
                    id="ExcludeArchived"
                    name="ExcludeArchived"
                  />
                }
                label="Exclude Archived"
              />
            </Box>
          </Box>
        </Role>

        <ServerSideDataGrid
          apiCall={api.institutes.list}
          queryName={queryName}
          columns={columns}
          searchParams={{
            exclude_archived: excludeArchived,
            search_string: searchString
          }}
          noRowsText={'No Organizations'}
        />
      </Box>
    </MainContent>
  )
}
