import React from 'react'
import { Box, Typography } from '@mui/material'
import { Loader, CalendarHeatmap } from 'components'
import { api, apiConfig } from 'services'
import { format, subYears, parseISO } from 'date-fns'
import { useQuery } from 'react-query'

export default function SummaryCalendar (props) {
  const { data: summaryData, isLoading } = useQuery(['institute-daily-summary', apiConfig.currentApiInstitute], api.institutes.getUploadSummary)
  return (
    <Box>
      <Typography variant="h5">{'Activity in the Last Year'}</Typography>
      <Box mt={1}>
        { isLoading
          ? <Loader/>
          : <CalendarHeatmap
            startDate={format(subYears(new Date(), 1), 'yyyy-MM-dd')}
            endDate={format(new Date(), 'yyyy-MM-dd')}
            values={summaryData}
            tooltipDataAttrs={(value) => {
              return {
                'data-tip': value.count ? `${format(parseISO(value.date), 'dd MMM yyyy')} : ${value.count} records` : 'No records'
              }
            }}
          />
        }
      </Box>
    </Box>
  )
}
