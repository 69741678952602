import { Box, Typography } from '@mui/material'
import { DropDownSelect } from 'components'
import { axisFont, colors, getCumulativeMetricStatistics } from 'helpers'
import { useState } from 'react'
import Plot from 'react-plotly.js'
import { useHistory } from 'react-router-dom'
import { useMetricsContext } from 'services'

export default function BoxPlot({ data, variant }) {
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')
  const history = useHistory()
  const { currentMetricConfig } = useMetricsContext()
  const traces = []
  data.map((line, index) => {
    const { min, q1, median, q3, max } = getCumulativeMetricStatistics(
      line,
      variant
    )
    const boxTrace = {
      y: [min, q1, q1, median, q3, q3, max],
      url: `/line/${line.id}`,
      type: 'box',
      hoverinfo: 'y',
      marker: {
        color: colors[index % colors.length]
      },
      name: line.name
    }
    traces.push(boxTrace)
    return boxTrace
  })
  const layout = {
    autosize: true,
    hovermode: 'closest',
    showlegend: false,
    margin: {
      l: 75,
      r: 50,
      b: 100,
      t: 30,
      pad: 4
    },
    yaxis: {
      title: {
        text:
          variant === 'temperature'
            ? 'Temperature (°C)'
            : variant === currentMetricConfig
            ? 'VitalMetric'
            : 'VitalMetric (β)',
        font: axisFont
      }
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'end',
        paddingTop: '10px'
      }}
    >
      <DropDownSelect
        labelId="image-format-select"
        label="Image Format"
        value={downloadImageFormat}
        onValueChange={(newValue) => {
          setDownloadImageFormat(newValue)
        }}
        items={[
          { content: <Typography variant="h7">PNG</Typography>, value: 'png' },
          { content: <Typography variant="h7">SVG</Typography>, value: 'svg' }
        ]}
      />
      {traces.length > 0 ? (
        <Plot
          data={traces}
          layout={layout}
          useResizeHandler={true}
          style={{ width: '100%', height: '500px' }}
          config={{ responsive: true, displaylogo: false }}
          onClick={(event) => {
            const url = event.points[0].data.url
            return history.push(url)
          }}
        />
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          height={'500px'}
        >
          <Typography color={'textSecondary'} variant="h6">
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  )
}
