export const colors = [
  '#4FC1BE',
  '#CA7A85',
  '#4FC184',
  '#29ABE2',
  '#6E8FBC',
  '#ED546B',
  '#367B79',
  '#33403F',
  '#9DB4B3',
  '#47CC3B',
  '#FFE200',
  '#FF964A',
  '#BDEA27',
  '#6961CC',
  '#EF99D4'
]

export const labelColors = [
  '#ED546B',
  '#FF964A',
  '#9B601C',
  '#CBB260',
  '#47CC3B',
  '#4FC184',
  '#03AD8D',
  '#367B79',
  '#4FC1BE',
  '#29ABE2',
  '#0089FF',
  '#6961CC',
  '#6E8FBC',
  '#914786',
  '#CA7A85',
  '#EF99D4',
  '#33403F',
  '#9DB4B3'
]

export const rejectionColor = (value, low = 3, high = 15) => {
  if (value > high) {
    return '#fa964a'
  } else if (value < low) {
    return '#4FC184'
  }
  return '#333A40'
}

export const statusColor = (status) => {
  return status === 'completed' ? '#4FC184' : '#333A40'
}

export const COMPARE_RECRODS_GRAPH_COLORS = [
  '#FF7F7F', // Muted Red
  '#7FFF7F', // Muted Green
  '#7F7FFF', // Muted Blue
  '#DAA520', // Goldenrod
  '#FF7FFF', // Muted Magenta
  '#708090', // SlateGray
  '#FFA07A', // Muted Orange Red
  '#B0A2D2', // Muted Blue Violet
  '#B79595', // Muted Brown
  '#BFFF7F', // Muted Chartreuse
  '#D2A37D', // Muted Chocolate
  '#B07FB0', // Muted Dark Magenta
  '#859582', // Muted Dark Olive Green
  '#FFDF7F', // Muted Gold
  '#C7FF8F', // Muted Green Yellow
  '#FFAEC1', // Muted Hot Pink
  '#D7A0A0', // Muted Indian Red
  '#856090', // Muted Indigo
  '#FFFFE0', // Muted Ivory
  '#F5DEB3', // Muted Khaki
  '#A9A9A9', // DarkGray
  '#BDB76B', // DarkKhaki
  '#8B4513', // SaddleBrown
  '#6A5ACD', // SlateBlue
  '#2E8B57', // SeaGreen
  '#A0522D', // Sienna
  '#C71585', // MediumVioletRed
  '#DB7093', // PaleVioletRed
  '#FFC0CB', // Pink
  '#DDA0DD', // Plum
  '#800080', // Purple
  '#BC8F8F', // RosyBrown
  '#4169E1', // RoyalBlue
  '#FA8072', // Salmon
  '#F4A460', // SandyBrown
  '#2E8B57', // SeaGreen
  '#FFF5EE', // SeaShell
  '#A0522D', // Sienna
  '#C0C0C0', // Silver
  '#87CEEB', // SkyBlue
  '#6A5ACD', // SlateBlue
  '#708090', // SlateGray
  '#FFFAFA', // Snow
  '#00FF7F', // SpringGreen
  '#4682B4', // SteelBlue
  '#D2B48C', // Tan
  '#008080', // Teal
  '#D8BFD8', // Thistle
  '#FF6347', // Tomato
  '#40E0D0' // Turquoise
]
