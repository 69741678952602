import Add from '@mui/icons-material/Add'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { MainContent, Modal, StyledTab, StyledTabs } from 'components'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import LabelToggle, { unlabeled } from 'components/LabelToggle'
import { ClinicalDataForm } from 'forms'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from 'react-router-dom'
import { Flag, api, apiConfig, hasRole } from 'services'
import GraphView from './GraphView'
import TableView from './TableView'

import { DatePicker } from '@mui/lab'
import { addDays, format } from 'date-fns'

const analytes = [
  { key: 'h_index', label: 'Hemolysis Index' },
  { key: 'ldh', label: 'LDH (U/L)' },
  { key: 'asat', label: 'ASAT (U/L)' },
  { key: 'potassium', label: 'K (mmol/L)' },
  { key: 'pfhb', label: 'PFHb (mg/dL)' }
]

export default function ClinicalData(props) {
  const { view } = useParams()
  const history = useHistory()
  const lineLabel =
    apiConfig.currentApiInstituteType === 'courier' ? 'Route' : 'PTS Line'

  const [viewIndex, setViewIndex] = useState(view === 'graph' ? 1 : 0)
  const [analyte, setAnalyte] = useState(0)
  const [refreshState, setRefreshState] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const [url, setUrl] = useState(new URL(window.location))
  const urlParams = new URLSearchParams(url.search)
  const [lineSelected, setLineSelected] = useState(
    urlParams.get('line_id') === null ? '' : Number(urlParams.get('line_id'))
  )
  const { data: lineOptions } = useQuery(
    ['line-options', apiConfig.currentApiInstitute],
    () => api.lines.list({ size: 9999 }).then((data) => data.items),
    { initialData: [] }
  )
  const [selectedLabels, setSelectedLabels] = useState([])
  const { data: labelOptions } = useQuery(
    ['label-options', apiConfig.currentApiInstitute, { url }],
    () => {
      return api.labels.list().then((data) => {
        if (url.searchParams.has('labels')) {
          const labelsList = url.searchParams.get('labels').split(',')
          const param_labels = labelsList.map((id) => parseInt(id))
          const newSelectedLabels = data.filter((label) =>
            param_labels.includes(label.id)
          )
          if (labelsList.includes(unlabeled.id)) {
            newSelectedLabels.push(unlabeled)
          }
          setSelectedLabels(newSelectedLabels)
        }
        return data
      })
    }
  )

  const [selectedStartDate, setSelectedStartDate] = useState(function () {
    if (urlParams.has('date__gte')) {
      return urlParams.get('date__gte')
    } else {
      return null
    }
  })

  const [selectedEndDate, setSelectedEndDate] = useState(function () {
    if (urlParams.has('date__lt')) {
      return urlParams.get('date__lt')
    } else {
      return null
    }
  })

  const toggleView = (event, newView) => {
    setViewIndex(newView)
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const handleChangeAnalyte = (event, newAnalyte) => {
    setAnalyte(newAnalyte)
  }

  const applyLineSearch = (value) => {
    if (value === null && urlParams.has('line_id')) {
      urlParams.delete('line_id')
    } else {
      if (urlParams.has('line_id')) {
        urlParams.set('line_id', value)
      } else {
        urlParams.append('line_id', value)
      }
    }

    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleLineChange = (event, line) => {
    if (line) {
      setLineSelected(line.id)
      applyLineSearch(line.id)
    } else {
      setLineSelected(null)
      applyLineSearch(null)
    }
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
    if (date === null) {
      urlParams.delete('date__lt')
    } else {
      urlParams.delete('date__lt')
      const newDate = addDays(date, 1)
      const dateFormatted = format(newDate, 'yyyy-MM-dd')
      urlParams.append('date__lt', dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
    if (date === null) {
      urlParams.delete('date__gte')
    } else {
      urlParams.delete('date__gte')
      const dateFormatted = format(date, 'yyyy-MM-dd')
      urlParams.append('date__gte', dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  // label
  const handleSelectedLabels = (event, labels) => {
    setSelectedLabels(labels)
    if (labels.length === 0) {
      urlParams.delete('labels')
    } else {
      const label_ids = labels.map((label) => label.id).join(',')
      if (urlParams.has('labels')) {
        urlParams.set('labels', label_ids)
      } else {
        urlParams.append('labels', label_ids)
      }
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const resetLabels = () => {
    setSelectedLabels([])
    urlParams.delete('labels')
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  useEffect(() => {
    history.replace({ pathname: window.location.pathname, search: url.search })
  }, [url, history])

  return (
    <MainContent>
      <Flag
        name={'clinicalData'}
        alternate={<Redirect to={{ pathname: '/dashboard' }} />}
      >
        <Box p={4}>
          <Grid container spacing={2} direction="column">
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h1">{'Clinical Data'}</Typography>
              <Box alignSelf="center">
                <Modal
                  disabled={hasRole('guest')}
                  button={
                    <Tooltip title="Add Clinical Data">
                      <IconButton variant="primary" disabled={hasRole('guest')}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ClinicalDataForm
                    exitFunction={() => setRefreshState(true)}
                    showNextButtonFlag={true}
                  />
                </Modal>
              </Box>
            </Grid>
            <Grid item>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box display="flex" gap={2}>
                  <StyledTabs
                    value={viewIndex}
                    onChange={toggleView}
                    style={{ maxHeight: '39px' }}
                  >
                    <StyledTab
                      label="Table View"
                      disableRipple
                      component={Link}
                      to="/clinical-data"
                    />
                    <StyledTab
                      label="Graph View"
                      disableRipple
                      component={Link}
                      to="/clinical-data/graph"
                    />
                  </StyledTabs>
                  {viewIndex === 1 ? (
                    <StyledTabs
                      value={analyte}
                      onChange={handleChangeAnalyte}
                      style={{ maxHeight: '39px' }}
                    >
                      <StyledTab label="HI" disableRipple />
                      <StyledTab label="LDH" disableRipple />
                      <StyledTab label="ASAT" disableRipple />
                      <StyledTab label="K" disableRipple />
                      <StyledTab label="PFHb" disableRipple />
                    </StyledTabs>
                  ) : null}
                </Box>
                <Box alignSelf="center" mr={1}>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<FilterListIcon />}
                    onClick={toggleFilters}
                  >
                    Filter Data
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Collapse in={showFilters}>
              <Grid item>
                <Box
                  display="flex"
                  flexDirection="column"
                  pb={1}
                  justifyContent="flex-start"
                >
                  <Box display="flex" flexDirection="column" mb={1} ml={1}>
                    <Box>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          paddingBottom: 16,
                          paddingLeft: 4
                        }}
                        display="inline"
                      >
                        {'Filter Clinical Data By Label'}
                      </Typography>
                      <Button
                        size="small"
                        color="secondary"
                        onClick={resetLabels}
                      >
                        {'Reset'}
                      </Button>
                    </Box>
                    {labelOptions && (
                      <LabelToggle
                        labels={labelOptions}
                        selectedLabels={selectedLabels}
                        handleSelectedLabels={handleSelectedLabels}
                      />
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={2}
                    justifyContent="flex-start"
                  >
                    <Box style={{ minWidth: '350px' }} pr={2}>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          paddingBottom: 4
                        }}
                      >{`Filter By ${lineLabel}`}</Typography>

                      <Autocomplete
                        id="line-selection-box"
                        value={
                          lineOptions.find((line) => line.id === lineSelected)
                            ? lineOptions.filter(
                                (line) => line.id === lineSelected
                              )[0]
                            : null
                        }
                        options={lineOptions}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          )
                        }}
                        getOptionLabel={(option) => option.name}
                        onChange={handleLineChange}
                        size="small"
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={lineLabel}
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          paddingBottom: 4
                        }}
                      >
                        {'Filter By Date'}
                      </Typography>

                      <DatePicker
                        label={'Start'}
                        id="upload-date-start"
                        name="date-start"
                        value={selectedStartDate}
                        disableMaskedInput={true}
                        inputFormat="dd MMM yyyy"
                        style={{ maxWidth: '180px' }}
                        disableFuture={true}
                        onChange={handleStartDateChange}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />

                      <Typography
                        display="inline"
                        style={{
                          fontSize: '12px',
                          color: '#6b6b6b',
                          fontWeight: 700,
                          margin: 7,
                          verticalAlign: 'sub'
                        }}
                      >
                        {'– to –'}
                      </Typography>

                      <DatePicker
                        label={'End'}
                        id="upload-date-end"
                        name="date-end"
                        value={selectedEndDate}
                        disableMaskedInput={true}
                        inputFormat="dd MMM yyyy"
                        onChange={handleEndDateChange}
                        disableFuture={true}
                        style={{ maxWidth: '180px' }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Collapse>

            <Grid item>
              <Switch>
                <Route
                  path="/clinical-data/graph"
                  children={
                    <GraphView
                      analyte={analytes[analyte]}
                      refreshState={refreshState}
                      setRefreshState={setRefreshState}
                      url={url}
                    />
                  }
                />
                <Route
                  path="/clinical-data/:view?"
                  children={
                    <TableView
                      url={url}
                      setUrl={setUrl}
                      refreshState={refreshState}
                      setRefreshState={setRefreshState}
                    />
                  }
                />
              </Switch>
            </Grid>
          </Grid>
        </Box>
      </Flag>
    </MainContent>
  )
}
