import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  Radio
} from '@mui/material'
import { api, useMetricsContext, Role } from 'services'
import Autocomplete from '@mui/material/Autocomplete'
import Add from '@mui/icons-material/Add'
import { DatePicker } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { parseJSON, parseISO, format, addDays, subDays } from 'date-fns'
import has from 'lodash/has'
import { Modal, Label } from 'components'
import LabelForm from 'forms/LabelForm'

import { Link } from 'react-router-dom'

import withStyles from '@mui/styles/withStyles'

const styles = (theme) => ({
  root: {
    marginTop: '12px',
    marginBottom: '12px',
    backgroundColor: '#D6DAE0'
  }
})

const FormDivider = withStyles(styles)(Divider)

export default function ClinicalDataForm ({ id, exitFunction, closeModalFunction, showNextButtonFlag = false }) {
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, getValues, formState: { errors, isSubmitting } } = useForm()
  const [selectedDate, setSelectedDate] = useState(null)
  const [dateOpen, setDateOpen] = useState(false)

  const [formValues, setFormValues] = useState({
    exclude_from_model: false,
    labels: []
  })

  const [record, setRecord] = useState(false)
  const [recordOptions, setRecordOptions] = useState()
  const [loadingOptions, setLoadingOptions] = useState(false)

  const [refreshLabels, setRefreshLabels] = useState(true)
  const [labelOptions, setLabelOptions] = useState([])
  const [addLabelOpen, setAddLabelOpen] = useState(false)

  const [fromSubmitNextButton, setFromSubmitNextButton] = useState(false)

  const { metricType, metricLabel } = useMetricsContext()

  const handleRecordChange = (event) => {
    setRecord(event.target.value)
    setValue('record', event.target.value.id || null)
  }
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    setValue('date', newDate !== null ? format(newDate, 'yyyy-MM-dd') : null)
  }

  const handleExcludeFromModel = (event) => {
    const val = event.target && event.target.checked
    setFormValues(prevState => { return { ...prevState, exclude_from_model: val } })
    setValue('exclude_from_model', val)
  }

  const handleDonorSample = (event) => {
    const val = event.target.value === 'true'
    setFormValues(prevState => { return { ...prevState, donor_sample: val } })
    setValue('donor_sample', val)
  }

  const handleLabelChange = (event, labels) => {
    setFormValues(prevState => { return { ...prevState, labels: labels } })
    const newLabels = labels.map((label) => label.id)
    setValue('labels', newLabels)
  }

  useEffect(() => {
    register('labels')
    if (refreshLabels) {
      api.labels.list().then((data) => {
        setLabelOptions(data)
        const newLabels = formValues.labels.map((label) => label.id)
        setValue('labels', newLabels)
      })
      setRefreshLabels(false)
    }
  }, [register, refreshLabels, formValues, setValue])

  function resetForm () {
    setRecord('')
    setValue('record', null)
    setValue('h_index', '')
    setValue('ldh', '')
    setValue('asat', '')
    setValue('potassium', '')
    setValue('pfhb', '')
  }

  // Form Submission Handling
  const onSubmit = async (data) => {
    if (!isSubmitting) {
      // Converts empty strings to null as the database will not accept an empty string
      Object.keys(data).forEach(function (key) {
        if (data[key] === '') data[key] = null
      })
      if (id) {
        api.clinicalData
          .update(id, data)
          .then((data) => {
            enqueueSnackbar('Clinical data has been updated.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            closeModalFunction()
            exitFunction()
          })
          .catch(function (status) {
            console.log(status)
          })
      } else {
        api.clinicalData
          .create(data)
          .then((data) => {
            enqueueSnackbar('Clinical data has been created.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            if (!fromSubmitNextButton) {
              closeModalFunction()
            } else {
              resetForm()
            }
            exitFunction()
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    }
  }
  useEffect(() => {
    if (selectedDate) {
      setLoadingOptions(true)
      api.records
        .list({
          exclude: false,
          ordering: 'date',
          is_processed: true,
          size: 9999,
          date__gte: format(subDays(selectedDate, 1), 'yyyy-MM-dd'),
          date__lt: format(addDays(selectedDate, 2), 'yyyy-MM-dd')
        })
        .then((data) => {
          setRecordOptions(data.items)
          if (getValues('record')) {
            const selectedRecord = data.items.find((option) => option.id === getValues('record'))
            setRecord(selectedRecord)
          }
          setLoadingOptions(false)
        })
        .catch((error) => {
          console.warn(JSON.stringify(error, null, 2))
        })
    }
  }, [selectedDate, getValues])

  useEffect(() => {
    register('date', { required: 'You cannot have an empty date.' })
    register('record')
    setValue('record', null)
    setValue('exclude_from_model', true)
    if (id) {
      api.clinicalData
        .get(id)
        .then((data) => {
          setValue('record', data.record ? data.record.id : null)
          setSelectedDate(parseISO(data.date))
          setValue('date', data.date)
          setValue('h_index', data.h_index)
          setValue('h_index_baseline', data.h_index_baseline)
          setValue('ldh', data.ldh)
          setValue('ldh_baseline', data.ldh_baseline)
          setValue('asat', data.asat)
          setValue('asat_baseline', data.asat_baseline)
          setValue('potassium', data.potassium)
          setValue('potassium_baseline', data.potassium_baseline)
          setValue('pfhb', data.pfhb)
          setValue('pfhb_baseline', data.pfhb_baseline)
          setValue('exclude_from_model', data.exclude_from_model)
          setValue(
            'labels',
            data.labels.map((label) => label.id)
          )
          setValue('donor_sample', data.donor_sample)
          setFormValues(data)
        })
        .catch((error) => {
          console.warn(JSON.stringify(error, null, 2))
        })
    }
  }, [id, setValue, register])

  return (
    <form id="clinical_data_form" data-testid="clinical-data-form-test" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Box mr={10}>
            <Typography variant="subtitle1"> {'Date'} </Typography>
            <DatePicker
              name="date"
              id="date"
              value={selectedDate}
              onChange={handleDateChange}
              open={dateOpen}
              disableMaskedInput={true}
              inputFormat="dd MMM yyyy"
              inputProps={{ placeholder: '' }}
              onOpen={() => {
                setDateOpen(true)
              }}
              onClose={() => setDateOpen(false)}
              renderInput={(params) => <TextField data-testid='clinical-data-date' onChange={handleDateChange} size='small' onClick={() => setDateOpen(true)} label="Date" error={has(errors, 'date')}
              helperText={errors && errors.date && errors.date.message} {...params}/>}
            />
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-end" flexGrow={1}>
            <Box mr={2} style={{ marginBottom: '4px' }}>
              <Typography variant="subtitle1"> {'Record'} </Typography>
            </Box>
            <Box flexGrow={1}>
              {loadingOptions
                ? (
                <Box alignItems="center" display="flex" flexDirection="row" mt={1}>
                  <Box mr={1}>
                    <CircularProgress size={16} />
                  </Box>
                  <Box>
                    <Typography variant="caption" display="inline">
                      {' Loading Record Options '}
                    </Typography>
                  </Box>
                </Box>
                  )
                : (
                <FormControl variant="outlined" fullWidth size="small">
                  <FormHelperText>
                    {selectedDate && recordOptions
                      ? recordOptions.length > 0
                        ? null
                        : `No records found on ${format(selectedDate, 'dd MMM yyyy')}`
                      : 'Select a date to see record options.'}
                  </FormHelperText>

                  <Select
                    name="record"
                    labelId="record-input-label"
                    id="record"
                    value={record || ''}
                    displayEmpty={true}
                    renderValue={(record) => record.date ? format(parseJSON(record.date), 'dd MMM yyyy HH:mm') : 'None'}
                    disabled={!selectedDate || !recordOptions || recordOptions.length <= 0}
                    onChange={handleRecordChange}
                    SelectDisplayProps={{
                      'data-testid': 'record-select'
                    }}
                    inputProps={{
                      'data-testid': 'record-input'
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {recordOptions &&
                      recordOptions.map((record, index) => {
                        return (
                          <MenuItem key={index} value={record}>
                            <Box display="flex" flexDirection="column" pt={1} pb={1}>
                              <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box>{format(parseJSON(record.date), 'dd MMM yyyy HH:mm')}</Box>
                                <Box ml={3}>{record.line && record.line.name}</Box>
                                <Box ml={3}>{record.vial.label}</Box>
                                <Box ml={3} display="flex" flexDirection="row">
                                  <Box>{metricLabel}</Box>
                                  <Box>{`: ${record.metrics[metricType]}`}</Box>
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box>
                                  {record.clinical_data_count > 0
                                    ? record.clinical_data_count > 1
                                      ? `${record.clinical_data_count} Clinical Records`
                                      : `${record.clinical_data_count} Clinical Record`
                                    : 'No Clinical Data Assigned'}
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection="row">
                                {record.labels && record.labels.map((label, label_index) => {
                                  return (
                                    <Label
                                      key={`label-${label.id}-${label_index}`}
                                      label={label.label}
                                      color={label.color}
                                      size="small"
                                    />
                                  )
                                })}
                              </Box>
                            </Box>
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
                  )}
            </Box>
          </Box>
        </Box>
        <FormDivider />
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} alignSelf="center">
            <Typography variant="subtitle1"> {'Hemolysis Index'} </Typography>
          </Box>
          <Box mr={8} display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Value'}
              </Typography>
            </Box>
            <TextField
              {...register('h_index')}
              name="h_index"
              size="small"
              id="h_index"
              placeholder="HI"
              variant="outlined"
              type="number"
              value={formValues.h_index || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, h_index: event.target.value } })
                setValue('h_index', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'h-index-test' }}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Baseline'}
              </Typography>
            </Box>
            <TextField
              {...register('h_index_baseline')}
              name="h_index_baseline"
              size="small"
              id="h_index_baseline"
              placeholder="HI"
              variant="outlined"
              type="number"
              value={formValues.h_index_baseline || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, h_index_baseline: event.target.value } })
                setValue('h_index_baseline', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'h-index-baseline-test' }}
            />
          </Box>
        </Box>
        <FormDivider />
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} alignSelf="center">
            <Typography variant="subtitle1"> {'LDH (U/L)'} </Typography>
          </Box>
          <Box mr={8} display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Value'}
              </Typography>
            </Box>
            <TextField
              {...register('ldh')}
              name="ldh"
              size="small"
              id="ldh"
              variant="outlined"
              type="number"
              value={formValues.ldh || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, ldh: event.target.value } })
                setValue('ldh', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'ldh-test' }}
              placeholder="LDH (U/L)"
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">
                {'Baseline'}
              </Typography>
            </Box>
            <TextField
              {...register('ldh_baseline')}
              name="ldh_baseline"
              size="small"
              id="ldh_baseline"
              variant="outlined"
              type="number"
              value={formValues.ldh_baseline || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, adh_baseline: event.target.value } })
                setValue('ldh_baseline', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'ldh-baseline-test' }}
              placeholder="LDH (U/L)"
            />
          </Box>
        </Box>
        <FormDivider />
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} alignSelf="center">
            <Typography variant="subtitle1"> {'ASAT (U/L)'} </Typography>
          </Box>
          <Box mr={8} display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">
                {'Value'}
              </Typography>
            </Box>
            <TextField
              {...register('asat')}
              name="asat"
              size="small"
              id="asat"
              variant="outlined"
              type="number"
              value={formValues.asat || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, asat: event.target.value } })
                setValue('asat', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'asat-test' }}
              placeholder="ASAT (U/L)"
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Baseline'}
              </Typography>
            </Box>
            <TextField
              {...register('asat_baseline')}
              name="asat_baseline"
              size="small"
              id="asat_baseline"
              variant="outlined"
              type="number"
              value={formValues.asat_baseline || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, asat_baseline: event.target.value } })
                setValue('asat_baseline', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'asat-baseline-test' }}
              placeholder="ASAT (U/L)"
            />
          </Box>
        </Box>
        <FormDivider />
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} alignSelf="center">
            <Typography variant="subtitle1"> {'K (mmol/L)'} </Typography>
          </Box>
          <Box mr={8} display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Value'}
              </Typography>
            </Box>
            <TextField
              {...register('potassium')}
              name="potassium"
              size="small"
              id="potassium"
              variant="outlined"
              type="number"
              value={formValues.potassium || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, potassium: event.target.value } })
                setValue('potassium', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'potassium-test' }}
              placeholder="K (mmol/L)"
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Baseline'}
              </Typography>
            </Box>
            <TextField
              {...register('potassium_baseline')}
              name="potassium_baseline"
              size="small"
              id="potassium_baseline"
              variant="outlined"
              type="number"
              value={formValues.potassium_baseline || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, potassium_baseline: event.target.value } })
                setValue('potassium_baseline', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'potassium-baseline-test' }}
              placeholder="K (mmol/L)"
            />
          </Box>
        </Box>
        <FormDivider />
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} alignSelf="center">
            <Typography variant="subtitle1"> {'PFHb (mg/dL)'} </Typography>
          </Box>
          <Box mr={8} display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">

                {'Value'}
              </Typography>
            </Box>
            <TextField
              {...register('pfhb')}
              name="pfhb"
              size="small"
              id="pfhb"
              variant="outlined"
              type="number"
              value={formValues.pfhb || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, pfhb: event.target.value } })
                setValue('pfhb', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'pfhb-test' }}
              placeholder="PFHb (mg/dL)"
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2} alignSelf="center">
              <Typography variant="subtitle1" display="inline">
                {'Baseline'}
              </Typography>
            </Box>
            <TextField
              {...register('pfhb_baseline')}
              name="pfhb_baseline"
              size="small"
              id="pfhb_baseline"
              variant="outlined"
              type="number"
              value={formValues.pfhb_baseline || ''}
              onChange={ (event) => {
                event.persist()
                setFormValues(prevState => { return { ...prevState, pfhb_baseline: event.target.value } })
                setValue('pfhb_baseline', event.target.value)
              }}
              inputProps={{ step: '0.01', 'data-testid': 'pfhb-baseline-test' }}
              placeholder="PFHb (mg/dL)"
            />
          </Box>
        </Box>
        <FormDivider />
        <Grid item>
          {labelOptions && (
            <Autocomplete
              multiple
              id="labels"
              name="labels"
              value={formValues.labels}
              options={labelOptions}
              getOptionLabel={(option) => option.label}
              onChange={handleLabelChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Label label={option.label} color={option.color} size="small" {...getTagProps({ index })} />
                ))
              }
              renderOption={(props, option) => <li {...props}><Label key={`label-${option.id}`} label={option.label} color={option.color} size="small" /></li>}
              renderInput={(params) => <TextField {...params} label={'Labels'} variant="outlined" />}
            />
          )}
          <Box display="flex" flexDirection="row" pt={1}>
            <Box flexGrow={1}>
              <Button size="small" color="secondary" startIcon={<Add />} onClick={() => setAddLabelOpen(true)}>
                {'New Label'}
              </Button>
            </Box>
            <Box>
              <Link
                to={{
                  pathname: '/labels',
                  state: { name: id ? getValues('date') : 'Clinical Data' }
                }}
              >

                <Button size="small" color="secondary">Manage Labels</Button>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Role restrict={['staff']}>
          <Box display={'flex'} pb={2} flexDirection="row">
            <Box flexGrow={1}>
              <FormControlLabel
                size="small"
                control={
                  <Checkbox
                    name="exclude_from_model"
                    {...register('exclude_from_model')}
                    color="primary"
                    checked={formValues.exclude_from_model}
                    onChange={handleExcludeFromModel}
                  />
                }
                label="Exclude from Model"
              />
            </Box>
            <Box>
              <RadioGroup aria-label="Sample Type" name="donor_sample" onChange={handleDonorSample} row>
                <FormControlLabel
                  size="small"
                  control={<Radio {...register('donor_sample')} checked={formValues.donor_sample} value="true" />}
                  label="Donor Sample"
                />
                <FormControlLabel
                  size="small"
                  style={{ marginRight: 0 }}
                  control={<Radio {...register('donor_sample')} checked={!formValues.donor_sample} value="false" />}
                  defaultChecked
                  label="Routine Sample"
                />
              </RadioGroup>
            </Box>
          </Box>
        </Role>
        <Box display="flex" flexDirection="row" alignSelf="flex-end">
          <Box pr={1} alignSelf="center">
            <Button
              onClick={() => setFromSubmitNextButton(true)}
              type="submit"
              style={{ visibility: showNextButtonFlag ? 'visible' : 'hidden' }}
              variant="contained"
              color="primary"
              size="small"
            >
              {'Save and Next'}
            </Button>
          </Box>
          <Box pr={1} alignSelf="center">
            <Button
              onClick={() => setFromSubmitNextButton(false)}
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              {'Save and Close'}
            </Button>
          </Box>
          <Box alignSelf="center">
            <Button
              onClick={() => closeModalFunction()}
              type="cancel"
              variant="contained"
              color="error"
              size="small"
            >
              {'Cancel'}
            </Button>
          </Box>
        </Box>
      </Box>
      {addLabelOpen && (
        <Modal maxWidth={'xs'} open={addLabelOpen} label={'Create New Label'} onClose={() => setAddLabelOpen(false)}>
          <LabelForm exitFunction={setRefreshLabels} updateForm={{ labels: formValues.labels }} />
        </Modal>
      )}
    </form>
  )
}
