import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  api,
  apiConfig,
  authenticationService,
  Flag,
  hasRole,
  useFeaturesContext,
  useMetricsContext
} from 'services'

import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Comments,
  MainContent,
  Modal,
  Sidebar,
  StyledTab,
  StyledTabs
} from 'components'
import LabelToggle, { unlabeled } from 'components/LabelToggle'

import { Add, Edit } from '@mui/icons-material'
import { DatePicker } from '@mui/lab'
import { addDays, format, parseISO } from 'date-fns'
import { LabelForm, LabelsOnlyForm } from 'forms'
import {
  getCumulativeStatistics,
  getValidationStatistics,
  QUERIES
} from 'helpers'
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'
import GraphView from './GraphView'
import SummaryView from './SummaryView'
import TransitRecordsView from './TransitRecordsView'
import ValidationsView from './ValidationsView'

export default function MainView({ lineData, refreshLineData }) {
  const queryClient = useQueryClient()
  const contentEl = useRef(null)
  const { metricType } = useMetricsContext()
  const { flags, overrides } = useFeaturesContext()
  const { state: previousState } = useLocation()
  const { id, view } = useParams()
  const label =
    apiConfig.currentApiInstituteType === 'courier' ? 'Route' : 'PTS Line'
  const [archived, setArchived] = useState(lineData && lineData.archived)
  const [lineStats, setLineStats] = useState()
  const [validation, setValidation] = useState()
  const multiInstitute = authenticationService.multiInstitute
  const history = useHistory()

  const { data: lineComments, refetch: refreshComments } = useQuery(
    ['line-comments', id],
    () => api.comments.list('lines', id)
  )

  const [url, setUrl] = useState(new URL(window.location))
  const urlParams = new URLSearchParams(url.search)

  const [labelsEditMode, setLabelsEditMode] = useState(false)
  const [selectedLabels, setSelectedLabels] = useState([])
  const { data: labelOptions } = useQuery(['label-options', url], () => {
    return api.labels.list().then((data) => {
      if (url.searchParams.has('labels')) {
        const labelsList = url.searchParams.get('labels').split(',')
        const param_labels = labelsList.map((id) => parseInt(id))
        const newSelectedLabels = data.filter((label) =>
          param_labels.includes(label.id)
        )
        if (labelsList.includes(unlabeled.id)) newSelectedLabels.push(unlabeled)
        setSelectedLabels(newSelectedLabels)
      }
      return data
    })
  })

  const { data: validationHistory, isLoading: validationsLoading } = useQuery(
    ['validations', id],
    () => api.lines.getValidations(id)
  )

  const [filterValidation, setFilterValidation] = useState('')

  const [range, setRange] = useState()

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [selectedStartDate, setSelectedStartDate] = React.useState(function () {
    if (urlParams.has('upload_date__gte')) {
      return urlParams.get('upload_date__gte')
    } else if (urlParams.has('date__gte')) {
      return urlParams.get('date__gte')
    } else {
      return null
    }
  })

  const [selectedEndDate, setSelectedEndDate] = React.useState(function () {
    if (urlParams.has('upload_date__lt')) {
      return urlParams.get('upload_date__lt')
    } else if (urlParams.has('date__lt')) {
      return urlParams.get('date__lt')
    } else {
      return null
    }
  })

  const handleSelectedLabels = (event, labels) => {
    setSelectedLabels(labels)
    if (labels.length === 0) {
      urlParams.delete('labels')
    } else {
      const label_ids = labels.map((label) => label.id).join(',')
      if (urlParams.has('labels')) {
        urlParams.set('labels', label_ids)
      } else {
        urlParams.append('labels', label_ids)
      }
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const resetLabels = () => {
    setSelectedLabels([])
    urlParams.delete('labels')
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
    if (date === null) {
      urlParams.delete('date__lt')
    } else {
      urlParams.delete('date__lt')
      const newDate = addDays(date, 1)
      const dateFormatted = format(newDate, 'yyyy-MM-dd')
      urlParams.append('date__lt', dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
    if (date === null) {
      urlParams.delete('date__gte')
    } else {
      urlParams.delete('date__gte')
      const dateFormatted = format(date, 'yyyy-MM-dd')
      urlParams.append('date__gte', dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleValidationChange = (event) => {
    setFilterValidation(event.target.value)
    if (event.target.value === '') {
      handleStartDateChange(null)
    } else {
      handleStartDateChange(parseISO(event.target.value))
    }
  }
  const handleArchive = async (event) => {
    setArchived(event.target.checked)
    await api.lines
      .update(id, { archived: event.target.checked })
      .then(() => refreshLineData())
  }

  useEffect(() => {
    setArchived(lineData.archived)

    const cumulativeStatistics = getCumulativeStatistics(lineData)
    const validationStatistics = getValidationStatistics(lineData)

    setLineStats(cumulativeStatistics)
    setValidation(validationStatistics)

    if (cumulativeStatistics) setRange(cumulativeStatistics[metricType].range)

    if (validationStatistics) {
      setRange(validationStatistics[metricType].range)
    }
  }, [id, lineData, metricType])

  function getActiveFlagValue(name) {
    return overrides ? overrides && overrides[name] : flags && flags[name]
  }

  useEffect(async () => {
    await queryClient.invalidateQueries(QUERIES.GET_ALL_LINES)
  }, [archived])

  return (
    <>
      <Sidebar>
        <Box m={2} mt={multiInstitute ? 0 : 6}>
          <Box display="flex" flexDirection="row" pt={1}>
            <Box flexGrow={1}>
              <FormControlLabel
                disabled={hasRole('guest')}
                control={
                  <Checkbox
                    checked={archived}
                    onChange={handleArchive}
                    name="archive"
                    color="primary"
                  />
                }
                label="Archive"
                data-testid="archive-line-checkbox"
              />
            </Box>
            <Tooltip title="Edit PTS Line Settings">
              <IconButton
                variant="primary"
                disabled={hasRole('guest')}
                onClick={() => {
                  history.push(`/line/${id}/settings`)
                }}
                data-testid="edit-line-settings-button"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider variant="middle" style={{ backgroundColor: '#D6DAE0' }} />

        <ClickAwayListener
          onClickAway={() => {
            setLabelsEditMode(false)
            refreshLineData()
          }}
        >
          <Box display="flex" flexDirection="column" ml={2} mr={2} mt={2}>
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{'Labels'}</Typography>
              </Box>
              <Box>
                <Tooltip
                  title={`${
                    lineData && lineData.labels && lineData.labels.length > 0
                      ? 'Edit'
                      : 'Add'
                  } Labels`}
                >
                  <IconButton
                    variant="primary"
                    disabled={hasRole('guest')}
                    onClick={() => setLabelsEditMode(!labelsEditMode)}
                    data-testid="change-label-button"
                  >
                    {lineData &&
                    lineData.labels &&
                    lineData.labels.length > 0 ? (
                      <Edit />
                    ) : (
                      <Add />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box>
              {lineData && (
                <LabelsOnlyForm
                  id={lineData.id}
                  labels={lineData.labels}
                  resourceApi={api.lines}
                  editMode={labelsEditMode}
                />
              )}
            </Box>
            <Box
              display={labelsEditMode ? 'flex' : 'none'}
              flexDirection="row"
              pt={1}
            >
              <Box flexGrow={1}>
                <Modal
                  maxWidth={'xs'}
                  button={
                    <Button size="small" color="secondary" startIcon={<Add />}>
                      {'New Label'}
                    </Button>
                  }
                  label={'Create New Label'}
                  refreshState={refreshLineData}
                >
                  <LabelForm
                    exitFunction={refreshLineData}
                    updateResource={{
                      id: lineData.id,
                      labels: lineData.labels,
                      api: api.lines
                    }}
                  />
                </Modal>
              </Box>
              <Box>
                <Link
                  to={{
                    pathname: '/labels',
                    state: { name: `${lineData && lineData.name}` }
                  }}
                >
                  {' '}
                  <Button size="small" color="secondary">
                    Manage Labels
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>

        <Divider variant="middle" style={{ backgroundColor: '#D6DAE0' }} />
        <Comments
          comments={lineComments}
          resource="lines"
          resourceId={lineData && lineData.id}
          activeUserId={authenticationService.currentUserValue.user_id}
          refreshState={refreshComments}
        />
      </Sidebar>

      <MainContent sidebar={'right'} ref={contentEl}>
        <Box px={4} pt={2}>
          {lineData && (
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1}>
                <Typography variant="h1">
                  {`${lineData.name}`}
                  {lineData.length && (
                    <Box
                      color="#6B6B6B"
                      display="inline"
                      fontSize="20px"
                      pl={1}
                    >
                      {`${lineData.length} m`}
                    </Box>
                  )}
                  {lineData.archived ? (
                    <Box
                      color="#00000088"
                      display="inline"
                      fontSize="20px"
                      pl={1}
                    >
                      {'Archived'}
                    </Box>
                  ) : null}
                </Typography>
                <Typography variant="subtitle2">
                  {lineData.description}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-end">
                <Link
                  to={{
                    pathname: `/line/${id}/records/compare`,
                    state: { name: `${lineData && lineData.name}` }
                  }}
                >
                  <Button variant="contained" size="small">
                    Compare Records
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
          <Box display="flex" flexDirection="row" py={2}>
            {getActiveFlagValue('monitoring') ||
            getActiveFlagValue('validation') ? (
              <Box flexGrow={1}>
                <StyledTabs value={view || ''} style={{ maxHeight: '39px' }}>
                  <StyledTab
                    value=""
                    label="Summary"
                    disableRipple
                    component={Link}
                    to={{ pathname: `/line/${id}`, state: previousState }}
                  />
                  {getActiveFlagValue('monitoring') && (
                    <StyledTab
                      value="monitoring"
                      label="Monitoring"
                      disableRipple
                      component={Link}
                      to={{
                        pathname: `/line/${id}/monitoring`,
                        state: previousState
                      }}
                    />
                  )}
                  <StyledTab
                    value="records"
                    label="Transit Records"
                    disableRipple
                    component={Link}
                    to={{
                      pathname: `/line/${id}/records`,
                      state: previousState
                    }}
                  />
                  {getActiveFlagValue('validation') && (
                    <StyledTab
                      value="validations"
                      label="Validation History"
                      disableRipple
                      component={Link}
                      to={{
                        pathname: `/line/${id}/validations`,
                        state: previousState
                      }}
                    />
                  )}
                </StyledTabs>
              </Box>
            ) : null}
            {view && view !== 'validations' && (
              <Box>
                <Button
                  color="secondary"
                  startIcon={<FilterListIcon />}
                  onClick={toggleFilters}
                >
                  {'Search & Filter Records'}
                </Button>
              </Box>
            )}
          </Box>
          {view !== 'validations' && (
            <Collapse in={showFilters}>
              <Box display="flex" flexDirection="row" p={3}>
                <Box pr={2} flexGrow={1}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        paddingBottom: 16,
                        paddingLeft: 4
                      }}
                      display="inline"
                    >
                      {'Filter Records By Label'}
                    </Typography>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={resetLabels}
                    >
                      {'Reset'}
                    </Button>
                  </Box>
                  {labelOptions && (
                    <LabelToggle
                      labels={labelOptions}
                      selectedLabels={selectedLabels}
                      handleSelectedLabels={handleSelectedLabels}
                    />
                  )}
                </Box>
                <Box style={{ minWidth: '420px' }}>
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      paddingBottom: 16
                    }}
                  >
                    {'Filter Records By Date'}
                  </Typography>

                  <Flag name="validation">
                    <Box mb={2}>
                      <FormControl variant="outlined" size="small">
                        <Select
                          name="validation"
                          labelId="validation-input-label"
                          id="validation"
                          value={filterValidation}
                          onChange={handleValidationChange}
                          displayEmpty
                          placeholder={'Choose Validation to Start From'}
                        >
                          <MenuItem value="">
                            <em>{'Choose Validation to start from'}</em>
                          </MenuItem>
                          {validationHistory &&
                            validationHistory.map((validation, index) => {
                              return (
                                <MenuItem
                                  key={`validation-${validation.validation_id}`}
                                  value={validation.validation_start_date}
                                >
                                  {validation.validation_name}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Flag>
                  <Box display="flex" flexDirection="row">
                    <DatePicker
                      label={'Start'}
                      id="upload-date-start"
                      name="date-start"
                      value={selectedStartDate}
                      disableMaskedInput={true}
                      inputFormat="dd MMM yyyy"
                      style={{ maxWidth: '180px' }}
                      disableFuture={true}
                      onChange={handleStartDateChange}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <Typography
                      display="inline"
                      style={{
                        fontSize: '12px',
                        color: '#6b6b6b',
                        fontWeight: 700,
                        margin: 7,
                        verticalAlign: 'sub',
                        width: 33
                      }}
                    >
                      {'– to –'}
                    </Typography>
                    <DatePicker
                      label={'End'}
                      id="upload-date-end"
                      name="date-end"
                      value={selectedEndDate}
                      disableMaskedInput={true}
                      inputFormat="dd MMM yyyy"
                      onChange={handleEndDateChange}
                      style={{ maxWidth: '180px' }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Collapse>
          )}
        </Box>
        <Box px={4}>
          <Switch>
            <Route
              path="/line/:id/records"
              children={
                <TransitRecordsView
                  activeStats={validation || lineStats}
                  parentEl={contentEl}
                  url={url}
                />
              }
            />
            <Route
              path="/line/:id/validations"
              children={
                <ValidationsView
                  validationHistory={validationHistory}
                  isLoading={validationsLoading}
                  lineName={lineData.name}
                />
              }
            />
            <Route
              path="/line/:id/monitoring"
              children={
                <GraphView
                  lineData={lineData}
                  lineStats={lineStats}
                  validation={validation}
                  range={range}
                  url={url}
                />
              }
            />
            <Route
              path="/line/:id"
              children={<SummaryView lineData={lineData} />}
            />
          </Switch>
        </Box>
      </MainContent>
    </>
  )
}
