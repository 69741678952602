import Add from '@mui/icons-material/Add'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material'
import {
  EntryBox,
  InfoBox,
  InfoButton,
  Label,
  Modal,
  StyledDropzone
} from 'components'
import { LabelForm } from 'forms'
import { openDocsPage } from 'helpers'
import has from 'lodash/has'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { api, apiConfig } from 'services'

export default function HemolysisDataForm({
  id,
  closeModalFunction,
  exitFunction
}) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [title, setTitle] = useState('')
  const { data: lineOptions } = useQuery(
    ['line-options', apiConfig.currentApiInstitute],
    () => api.lines.list({ size: 9999 }).then((data) => data.items)
  )
  const [line, setLine] = useState('')

  const handleLineChange = (event) => {
    setLine(event.target.value)
    setValue('line', event.target.value)
  }

  const [selectedLabels, setSelectedLabels] = useState([])
  const [addLabelOpen, setAddLabelOpen] = useState(false)

  const handleLabelChange = (event, labels) => {
    setSelectedLabels(labels)
    const newLabels = labels.map((label) => label.id)
    setValue('labels', newLabels)
  }

  const { data: labelOptions, refetch: refetchLabels } = useQuery(
    ['label-options', apiConfig.currentApiInstitute],
    () =>
      api.labels.list().then((data) => {
        const newLabels = selectedLabels.map((label) => label.id)
        setValue('labels', newLabels)
        return data
      })
  )

  const [file, setFile] = useState()
  const dropzone = useDropzone({
    accept: '.csv, application/csv',
    onDrop: (files) => {
      setFile(files[0])
      setValue('file', files[0])
    }
  })
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFile(file)
    setValue('file', file)
    if (title === '') {
      const name = file.name.replace('.csv', '')
      setTitle(name)
      setValue('title', name)
    }
  }

  const clearFile = () => {
    setFile()
    setValue('file', null)
  }

  const [donorSample, setDonorSample] = useState(false)

  const handleDonorSample = (event) => {
    const val = event.target.value === 'true'
    setDonorSample(val)
    setValue('donor_sample', val)
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    register('line', { required: 'A line is required for the data.' })
    if (!id) register('file', { required: 'A CSV file must be attached.' })
  }, [register])
  useQuery(
    ['hemolysis-data-edit', id],
    () =>
      api.hemolysisData.get(id).then((data) => {
        setValue('line', data.line.id)
        setLine(data.line.id)
        setValue('title', data.title)
        setTitle(data.title)
        setValue('donor_sample', data.donor_sample)
        setDonorSample(data.donor_sample)
        setValue(
          'labels',
          data?.labels ? data.labels.map((label) => label.id) : []
        )
        setSelectedLabels(data.labels || [])
      }),
    { enabled: !!id }
  )

  const handleClose = () => {
    if (closeModalFunction) closeModalFunction()
    if (exitFunction) exitFunction()
  }
  // Form Submission Handling
  const onSubmit = async (data) => {
    setIsSubmitting(true)
    Object.keys(data).forEach(function (key) {
      if (data[key] === '') data[key] = null
    })
    if (id) {
      api.hemolysisData
        .update(id, data)
        .then((data) => {
          enqueueSnackbar('Hemolysis data has been updated.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          handleClose()
        })
        .catch((error) => {
          if (error.response.status === 400) {
            enqueueSnackbar(error?.response?.data?.detail, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
          }
        })
    } else {
      const formData = new FormData()
      const file = getValues('file')
      formData.append('file', file, file.name)
      formData.append('line', getValues('line'))
      formData.append('title', getValues('title'))
      formData.append('donor_sample', getValues('donor_sample'))
      formData.append(
        'timezone_name',
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      if (getValues('labels').length > 0)
        formData.append('labels', getValues('labels'))
      api.hemolysisData
        .create(formData)
        .then((data) => {
          enqueueSnackbar('Hemolysis data has been uploaded.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          setIsSubmitting(false)
          handleClose()
        })
        .catch((error) => {
          if (error.response.status === 400) {
            enqueueSnackbar(error?.response?.data?.detail, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
          }
          setIsSubmitting(false)
        })
    }
  }

  return (
    <>
      <form
        id="hemolysis-index-data-form"
        data-testid="hemolysis-data-form-test"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" flexDirection="column" my={2} disabled>
          <EntryBox label="Title" fullWidth>
            <TextField
              {...register('title', { required: true, maxLength: 255 })}
              name="title"
              id="title"
              value={title || ''}
              onChange={(event) => {
                setTitle(event.target.value)
                setValue('title', event.target.value)
              }}
              size="small"
              error={has(errors, 'title')}
              helperText={
                errors.title
                  ? errors.title.type === 'required'
                    ? 'Title is a required field.'
                    : 'Title is too long.'
                  : ''
              }
              inputProps={{ 'data-testid': 'title-test' }}
              fullWidth
            />
          </EntryBox>
          <Box mt={2}>
            <EntryBox label="Line">
              <FormControl variant="outlined" fullWidth>
                <Select
                  name="line"
                  id="line-input"
                  size="small"
                  value={line}
                  onChange={handleLineChange}
                  SelectDisplayProps={{
                    'data-testid': 'line-select'
                  }}
                  inputProps={{
                    'data-testid': 'line-input'
                  }}
                >
                  {lineOptions &&
                    lineOptions.map((line, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={line.id}
                          disabled={line.archived}
                        >
                          {line.name}
                        </MenuItem>
                      )
                    })}
                </Select>
                {errors.line && (
                  <FormHelperText error={has(errors, 'line')}>
                    {errors.line.message}
                  </FormHelperText>
                )}
              </FormControl>
            </EntryBox>
          </Box>
          <Box my={2}>
            <EntryBox label="Sample Type">
              <RadioGroup
                aria-label="Sample Type"
                name="donor_sample"
                onChange={handleDonorSample}
                row
              >
                <FormControlLabel
                  size="small"
                  control={
                    <Radio
                      {...register('donor_sample')}
                      value="true"
                      checked={donorSample}
                    />
                  }
                  label="Donor Sample"
                />
                <FormControlLabel
                  size="small"
                  style={{ marginRight: 0 }}
                  control={
                    <Radio
                      {...register('donor_sample')}
                      value="false"
                      checked={!donorSample}
                    />
                  }
                  defaultChecked
                  label="Routine Sample"
                />
              </RadioGroup>
            </EntryBox>
          </Box>
          <EntryBox label="Labels">
            {labelOptions && (
              <Autocomplete
                multiple
                id="labels"
                name="labels"
                value={selectedLabels}
                size="small"
                options={labelOptions}
                getOptionLabel={(option) => option.label}
                onChange={handleLabelChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Label
                      key={`${option.label}-${option.id}`}
                      label={option.label}
                      color={option.color}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Label label={option.label} color={option.color} />
                  </li>
                )}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          </EntryBox>
          <EntryBox label="">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                size="small"
                color="secondary"
                startIcon={<Add />}
                onClick={() => setAddLabelOpen(true)}
              >
                {'New Label'}
              </Button>
              <Link
                to={{
                  pathname: '/labels',
                  state: { name: 'Hemolysis Index Data' }
                }}
              >
                {' '}
                <Button size="small" color="secondary">
                  Manage Labels
                </Button>
              </Link>
            </Box>
          </EntryBox>
          {!id && (
            <Box my={3}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={2}
              >
                <Box
                  display="flex"
                  fontSize={19}
                  fontWeight={600}
                  color={'#33403F'}
                >
                  {'Data Upload'}
                </Box>
                <Box display="flex" pl={1}>
                  <InfoButton>
                    <InfoBox
                      onLearnMoreClick={() =>
                        openDocsPage('/rejection-insights')
                      }
                    >
                      {
                        'Datafile must follow specific format as mentioned in the box below. Click "Learn More" to find the detailed descriptions of the acceptable data file.'
                      }
                    </InfoBox>
                  </InfoButton>
                </Box>
              </Box>
              <StyledDropzone
                dropzone={dropzone}
                file={file && file}
                clearFiles={clearFile}
                onChange={handleFileChange}
                text={'Drag and drop the data file here.'}
                emText={
                  'Only csv files with "timestamp" and "h_index" columns will be accepted'
                }
              />
              {errors.file && (
                <FormHelperText error={has(errors, 'file')}>
                  {errors.file.message}
                </FormHelperText>
              )}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            py={1}
          >
            <Box pr={1}>
              <Button
                type="submit"
                role="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                size="small"
              >
                Save
              </Button>
            </Box>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      {addLabelOpen && (
        <Modal
          maxWidth={'xs'}
          open={addLabelOpen}
          label={'Create New Label'}
          onClose={() => setAddLabelOpen(false)}
        >
          <LabelForm
            exitFunction={refetchLabels}
            updateForm={{ labels: selectedLabels }}
          />
        </Modal>
      )}
    </>
  )
}
