import React from 'react'
import { Box } from '@mui/material'
import { openDocsPage } from 'helpers'

export default function StyledDocLink ({ children, to, fontWeight = 500 }) {
  return (
    <Box
      component="span"
      display="inline"
      onClick={() => openDocsPage(to)}
      color="#0B8DDE"
      fontWeight={fontWeight}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </Box>
  )
}
