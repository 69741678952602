import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import React, { forwardRef, useEffect, useState } from 'react'

const Modal = forwardRef(
  (
    {
      refreshState,
      label,
      children,
      button,
      maxWidth = 'md',
      open = false,
      disabled = false,
      onClose = () => null,
      showCloseButton = false,
      showCloseIcon = true,
      closeButtonColor = '#CA7A85'
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(open)

    useEffect(() => {
      if (refreshState !== undefined && modalOpen) {
        refreshState(true)
      }
    }, [refreshState, modalOpen])

    function handleClose() {
      setModalOpen(false)
      if (onClose) onClose()
      if (refreshState !== undefined) {
        refreshState(false)
      }
    }

    const modal_children = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        closeModalFunction: handleClose
      })
    })

    return (
      <Box>
        <div
          onClick={() => {
            if (!disabled) setModalOpen(true)
          }}
        >
          {button}
        </div>
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          maxWidth={maxWidth}
          fullWidth
        >
          {label && (
            <DialogTitle
              sx={{
                '&.MuiDialogTitle-root': {
                  paddingX: '10px',
                  paddingBottom: '5px'
                }
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <Box flexGrow={1}>
                  <Typography variant="h1">{label}</Typography>
                </Box>
                <Box display={showCloseIcon ? 'flex' : 'none'}>
                  <IconButton
                    aria-label="Close"
                    onClick={handleClose}
                    style={{ color: closeButtonColor }}
                    size="large"
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
          )}
          <DialogContent>
            {modalOpen === true && modal_children}
            {showCloseButton && (
              <Box pt={1} justifyContent="flex-end">
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleClose}
                >
                  {'Close'}
                </Button>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    )
  }
)

export default Modal
