import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import MUISwitch from '@mui/material/Switch'
import {
  HemolysisRejectionBarPlot,
  InlineError,
  Loader,
  MainContent,
  StyledTab,
  StyledTabs
} from 'components'
import { format, startOfYear, subMonths } from 'date-fns'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { api, apiConfig, useMetricsContext } from 'services'

function getFilterItem(name) {
  const filter = JSON.parse(localStorage.getItem('rejectionInsightsFilter'))
  return filter === null
    ? null
    : filter[name] === undefined
    ? null
    : filter[name]
}

function setFilterItem(key, value) {
  const filter =
    JSON.parse(localStorage.getItem('rejectionInsightsFilter')) || {}
  filter[key] = value
  localStorage.setItem('rejectionInsightsFilter', JSON.stringify(filter))
}

const LegendLine = ({ color = 'primary.main', text = '' }) => {
  return (
    <Box display="flex" flexDirecton={'row'} alignItems={'center'} mr={4}>
      <Box display="flex" flexDirection="row" mr={2}>
        <Box
          sx={{
            width: 15,
            height: 3,
            backgroundColor: color
          }}
        />
        <Box
          sx={{
            width: 15,
            height: 3,
            marginRight: 1,
            marginLeft: 1,
            backgroundColor: color
          }}
        />
        <Box
          sx={{
            width: 15,
            height: 3,
            backgroundColor: color
          }}
        />
      </Box>
      <Typography variant={'body1'} noWrap fontSize={16}>
        {text}
      </Typography>
    </Box>
  )
}

export default function GraphView() {
  const history = useHistory()
  const { metricType } = useMetricsContext()
  const [showRejection, setShowRejection] = useState(
    getFilterItem('showRejection') !== null
      ? getFilterItem('showRejection')
      : true
  )
  const [showThreshold, setShowThreshold] = useState(
    getFilterItem('showThreshold') !== null
      ? getFilterItem('showThreshold')
      : true
  )
  const [showFilters, setShowFilters] = useState(false)

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const timeOptions = {
    '3m': { label: '3M', start: subMonths(new Date(), 3) },
    '6m': { label: '6M', start: subMonths(new Date(), 6) },
    '12m': { label: '12M', start: subMonths(new Date(), 12) },
    ytd: { label: 'YTD', start: startOfYear(new Date()) },
    all: { label: 'All', start: null }
  }

  const analyteOptions = [
    { key: 'ldh', label: 'LDH' },
    { key: 'asat', label: 'ASAT' },
    { key: 'potassium', label: 'K' }
  ]

  const frequencies = ['daily', 'weekly', 'monthly', 'yearly']

  const { data: lineOptions } = useQuery(
    ['line-hi-options', apiConfig.currentApiInstitute],
    () =>
      api.lines
        .list({ size: 9999, has_hemolysis: true })
        .then((data) => data.items)
  )

  const [selectedLines, setSelectedLines] = useState(
    getFilterItem('lines') || []
  )

  const handleLineChange = (event, lines) => {
    setSelectedLines(lines)
    setFilterItem('lines', lines)
  }

  const [timePeriod, setTimePeriod] = useState(getFilterItem('period') || 'all')
  const [startDate, setStartDate] = useState(timeOptions[timePeriod].start)
  const [endDate, setEndDate] = useState(new Date())

  const [frequency, setFrequency] = useState(
    getFilterItem('frequency') || 'daily'
  )

  const [threshold, setThreshold] = useState(getFilterItem('threshold') || 15)

  const [selectedAnalytes, setSelectedAnalytes] = useState(
    getFilterItem('analytes') || analyteOptions
  )

  const toggleTimePeriod = (event, newPeriod) => {
    setTimePeriod(newPeriod)
    setStartDate(timeOptions[newPeriod].start)
    setEndDate(new Date())
    setFilterItem('period', newPeriod)
  }

  const handleFrequency = (event) => {
    setFrequency(event.target.value)
    setFilterItem('frequency', event.target.value)
  }

  const handleThreshold = (event) => {
    setThreshold(event.target.value)
    setFilterItem('threshold', event.target.value)
  }

  const handleSelectedAnalytes = (event, analytes) => {
    setSelectedAnalytes(analytes)
    window.dispatchEvent(new Event('resize'))
    setFilterItem('analytes', analytes)
  }

  const handleShowRejection = (event) => {
    setShowRejection(event.target.checked)
    setFilterItem('showRejection', event.target.checked)
  }

  const handleShowThreshold = (event) => {
    setShowThreshold(event.target.checked)
    setFilterItem('showThreshold', event.target.checked)
  }

  const [yRange, setYRange] = useState()

  const { data: hemolysisData, isFetching } = useQuery(
    [
      'hemolysis-data',
      apiConfig.currentApiInstitute,
      { frequency, selectedLines, startDate, endDate }
    ],
    () => {
      const lineIds =
        selectedLines.length > 0
          ? selectedLines.map((line) => line.id).join(',')
          : null
      return api.hemolysisData
        .summary({
          frequency: frequency,
          line_ids: lineIds,
          timestamp__lte: format(endDate, 'yyyy-MM-dd'),
          timestamp__gte: startDate && format(startDate, 'yyyy-MM-dd')
        })
        .then((response) => {
          setYRange(response.range)
          return response.data
        })
    },
    { initialData: [] }
  )

  return (
    <MainContent>
      <Box display="flex" p={4} flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexGrow={1}>
            <Typography variant="h1">Lab Rejection Insights</Typography>
          </Box>
          <Box display="flex" alignSelf="flex-end" mr={1}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                history.push({
                  pathname: '/hemolysis-index'
                })
              }}
              endIcon={<KeyboardDoubleArrowRight />}
            >
              Manage HI Data
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirecton={'row'}
          justifyContent="space-between"
          mt={3}
        >
          <Box flexGrow={1} mr={2}>
            {lineOptions && (
              <Autocomplete
                multiple
                id="line-selection-box"
                limitTags={2}
                options={lineOptions}
                value={selectedLines}
                onChange={handleLineChange}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )
                }}
                ChipProps={{ color: 'primary' }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Lines'}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          </Box>
          <Box mr={2}>
            <StyledTabs
              value={timePeriod}
              onChange={toggleTimePeriod}
              style={{ height: 39 }}
            >
              {Object.entries(timeOptions).map(([key, option]) => (
                <StyledTab
                  key={`time-option-${key}`}
                  value={key}
                  label={option.label}
                  disableRipple
                />
              ))}
            </StyledTabs>
          </Box>
          <Box mr={2}>
            <Select
              name="frequency"
              size="small"
              style={{ textTransform: 'capitalize' }}
              value={frequency}
              onChange={handleFrequency}
            >
              {frequencies.map((group, index) => (
                <MenuItem
                  key={`${group}-${index}`}
                  value={group}
                  style={{ textTransform: 'capitalize' }}
                >
                  {group}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              endIcon={<FilterListIcon />}
              onClick={toggleFilters}
            >
              {'Filters'}
            </Button>
          </Box>
        </Box>
        <Collapse in={showFilters}>
          <Box display="flex" my={2}>
            <Box mr={2} width={290}>
              <Autocomplete
                multiple
                id="analyte-selection"
                options={analyteOptions}
                value={selectedAnalytes}
                onChange={handleSelectedAnalytes}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )
                }}
                size="small"
                ChipProps={{ color: 'secondary' }}
                renderInput={(params) => (
                  <TextField {...params} label={'Analyte'} variant="outlined" />
                )}
              />
            </Box>
            <Box width={100} mr={2}>
              <TextField
                label="Threshold"
                size="small"
                value={threshold}
                onChange={handleThreshold}
                type="number"
              />
            </Box>
            <FormControlLabel
              control={
                <MUISwitch
                  color="red"
                  name="threshold"
                  checked={showThreshold}
                  onChange={handleShowThreshold}
                />
              }
              label="Threshold"
            />
            <FormControlLabel
              control={
                <MUISwitch
                  color="primary"
                  name="rejection"
                  checked={showRejection}
                  onChange={handleShowRejection}
                />
              }
              label="Rejection Probability"
            />
          </Box>
        </Collapse>

        <Box display="flex" flexDirection="column" mt={5}>
          <Box
            display="flex"
            flexDirecton={'row'}
            justifyContent="flex-end"
            mb={1}
          >
            <LegendLine color={'red.main'} text={'Threshold'} />
            <LegendLine text={'Rejection Probability'} />
          </Box>
          <Box display="flex" flexDirecton={'row'} justifyContent="center">
            {isFetching ? (
              <Loader />
            ) : hemolysisData && hemolysisData.length > 0 ? (
              selectedAnalytes.map((analyte, analyteIndex) => {
                return (
                  <Box
                    key={`analyte-graph-${analyteIndex}`}
                    flexGrow={1}
                    px={3}
                    style={{
                      borderRight:
                        analyteIndex < selectedAnalytes.length - 1
                          ? 'thin solid rgba(0, 0, 0, 0.12)'
                          : 'none'
                    }}
                  >
                    <Typography variant="h6">{`${analyte.label} Rejection`}</Typography>
                    {hemolysisData.map((line, lineIndex) => {
                      const selectedLine =
                        lineOptions &&
                        lineOptions.find((option) => option.id === line.line.id)
                      const validation = selectedLine && selectedLine.validation
                      const line_statistics =
                        selectedLine && selectedLine.line_statistics
                      const lineStats = validation || line_statistics
                      return (
                        <Box
                          key={`line-graph-${lineIndex}`}
                          mt={lineIndex > 0 && 2}
                        >
                          <Typography variant="caption">
                            {line.line.name}
                          </Typography>
                          <HemolysisRejectionBarPlot
                            data={line.data}
                            analyte={analyte.key}
                            rejection={
                              lineStats &&
                              lineStats[metricType].rejection_rates[analyte.key]
                            }
                            show_rejection={showRejection}
                            rejection_threshold={threshold}
                            show_rejection_threshold={showThreshold}
                            frequency={frequency}
                            yRange={yRange}
                          />
                        </Box>
                      )
                    })}
                  </Box>
                )
              })
            ) : (
              <InlineError
                title="No Data"
                detail="No Data has been found with the selected parameters. Please revise your search criteria."
              />
            )}
          </Box>
        </Box>
      </Box>
    </MainContent>
  )
}
