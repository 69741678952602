import React from 'react'
import { View } from '@react-pdf/renderer'
import { BulletPoint, StyledText } from '../index.js'

const BulletPointDefinition = ({ name, children }) => (
  <BulletPoint>
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ width: 125 }}>
        <StyledText variant={'body2'}>{name}</StyledText>
      </View>
      <View style={{ width: 500 }}>
        <StyledText variant={'body1'}>{children}</StyledText>
      </View>
    </View>
  </BulletPoint>
)

export default BulletPointDefinition
