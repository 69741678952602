import React, { useCallback, useEffect, useRef, useState } from 'react'

import Button from '@mui/material/Button'
import { useSnackbar } from 'notistack'
import { api, apiConfig } from 'services'

const hasNodeIntegration = window.require !== undefined
// Load node modules and functions if they are available.
const { fs, path, process, readdir, readFile, writeFile, stat, exec, unlink } =
  (() => {
    // Electron provides the window.require function to load node modules.
    if (hasNodeIntegration) {
      const fs = window.require('fs')
      const path = window.require('path')
      const util = window.require('util')
      const process = window.require('process')
      const readdir = util.promisify(fs.readdir)
      const readFile = util.promisify(fs.readFile)
      const writeFile = util.promisify(fs.writeFile)
      const stat = util.promisify(fs.stat)
      const exec = util.promisify(window.require('child_process').exec)
      const unlink = util.promisify(fs.unlink)
      return {
        fs,
        path,
        util,
        process,
        readdir,
        readFile,
        writeFile,
        stat,
        exec,
        unlink
      }
    }
    return {}
  })()

const MAX_FILE_SIZE = 250000000

async function getMountpoints() {
  if (process !== undefined) {
    if (process.platform === 'win32') {
      const { stdout } = await exec('wmic logicaldisk get name')
      return stdout.split(/\s*\n/).filter((ln) => ln.endsWith(':'))
    }
    // platform unix-like (probably)
    try {
      const { stdout } = await exec('df -l')
      const lines = stdout.split(/\n+/g).filter((ln) => ln)
      const offset = lines[0].matchAll(/(.*?)Mounted on/g).next()
        .value[1].length
      return lines.slice(1).map((ln) => ln.slice(offset))
    } catch (error) {
      console.log(error)
    }
  }
  return []
}

async function updateDifFile(path, enqueueSnackbar) {
  const data = await readFile(path)

  const t = Math.floor(Date.now() / 1000)
  if (data.length !== 88) {
    enqueueSnackbar(
      'One or more vials has been corrupted or is not properly configured. Please unplug the vial and contact support@motryx.com',
      {
        variant: 'error',
        preventDuplicate: true,
        persist: true
      }
    )
    console.error('Invalid DIF file.')
    return Promise.reject(new Error('Invalid DIF file.'))
  }
  // Set the new time in the DIF file. We place the timestamp in the correct
  // buffer position using little endian encoding
  data[84] = (t >> (8 * 0)) % (1 << 8)
  data[85] = (t >> (8 * 1)) % (1 << 8)
  data[86] = (t >> (8 * 2)) % (1 << 8)
  data[87] = (t >> (8 * 3)) % (1 << 8)
  const vialLabel = data.toString('utf8', 0x12, 0x12 + 64).replace(/\0*$/, '')
  if (vialLabel.length <= 0) {
    enqueueSnackbar(
      'A VitalVial is not properly configured. Please unplug the vial and contact support@motryx.com',
      {
        variant: 'error',
        preventDuplicate: true,
        persist: true
      }
    )
    console.error('Serial number on the tag is empty')
    return Promise.reject(new Error('Serial number on the tag is empty'))
  }
  await writeFile(path, data)
  return vialLabel
}

// Find dat files that need to be uploaded and update DIF files. The list of
// DAT files is returned.
async function deviceScan(prevDeviceScan, enqueueSnackbar) {
  const scan = {}
  const devicePromises = (await getMountpoints()).map(async (mountPoint) => {
    const prev = prevDeviceScan[mountPoint]
    if (prev) {
      scan[mountPoint] = prev
      return
    }
    let uploadStatus = 'Upload Complete'
    const uploadProgress = 0
    const instituteId = null
    const entries = await readdir(mountPoint, { withFileTypes: true }).catch(
      (err) => [err]
    )
    const files = entries.filter((entry) => entry.isFile())

    const toAbsPath = ({ name }) => path.join(mountPoint, name)
    const datFiles = files
      .filter(({ name }) => name.toLowerCase().endsWith('.dat'))
      .map(toAbsPath)
      .sort()

    const sizesPromises = datFiles.map(async (file) => {
      const st = await stat(file)
      return st.size
    })
    const sizes = await Promise.all(sizesPromises)
    const totalDataSize = sizes.reduce((a, b) => a + b, 0)
    if (totalDataSize > 0) {
      uploadStatus = 'Ready to Upload'
    }

    const difsUpdatePromises = files
      .filter(({ name }) => name === 'MOTRYX.DIF')
      .map(toAbsPath)
      .map(function (path) {
        return updateDifFile(path, enqueueSnackbar)
      })

    const FAIL_TOKEN = {}
    const vialLabels = await Promise.all(
      difsUpdatePromises.map((promise) => promise.catch((e) => FAIL_TOKEN))
    ).then((values) => values.filter((v) => v !== FAIL_TOKEN))
    if (vialLabels.length > 0) {
      const vialLabel = vialLabels[0]
      let registered = true
      const endOfLife = await api.vials
        .get(vialLabel)
        .then((data) => {
          if (data.is_deleted) {
            uploadStatus = `${vialLabel} is no longer supported and should be returned to Motryx.`
          }
          return data.is_deleted
        })
        .catch((error) => {
          if (error.response.status === 404) {
            registered = false
            uploadStatus = `${vialLabel} is not regsistered, please contact Motryx.`
            return false
          } else {
            console.warn(error, null, 2)
          }
        })
      scan[mountPoint] = {
        vialLabel,
        datFiles,
        totalDataSize,
        endOfLife,
        registered,
        uploadStatus,
        uploadProgress,
        instituteId
      }
    }
  })

  const DEVICE_FAIL_TOKEN = {}
  await Promise.all(
    devicePromises.map((promise) => promise.catch((e) => DEVICE_FAIL_TOKEN))
  ).then((values) => values.filter((v) => v !== DEVICE_FAIL_TOKEN))
  return scan
}

// This hook provides a device list and worker items
export function useDeviceScan() {
  const [devices, setDevices] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (process !== undefined) {
      let shouldExit = false
      let prevDeviceScan = {}

      setInterval(async () => {
        if (!shouldExit) {
          setDevices(prevDeviceScan)
          prevDeviceScan = await deviceScan(prevDeviceScan, enqueueSnackbar)
        }
      }, 3000)
      return () => {
        shouldExit = true
      }
    }
  }, [enqueueSnackbar])

  if (!hasNodeIntegration) {
    return {
      devices: [],
      readyToUploadDevices: [],
      uploadCompleteDevices: []
    }
  }
  return {
    devices: Object.values(devices).sort((a, b) =>
      a.vialLabel.localeCompare(b.vialLabel)
    ),
    readyToUploadDevices: Object.values(devices)
      .sort((a, b) => a.vialLabel.localeCompare(b.vialLabel))
      .filter((device) => device.uploadStatus === 'Ready to Upload'),
    uploadCompleteDevices: Object.values(devices)
      .sort((a, b) => a.vialLabel.localeCompare(b.vialLabel))
      .filter((device) => device.uploadStatus !== 'Ready to Upload')
  }
}

export function useDeviceWorker(device) {
  const { vialLabel, datFiles, totalDataSize, endOfLife, registered } = device
  const [progress, setProgress] = useState(0)
  const unmounted = useRef(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const worker = useCallback(async () => {
    enqueueSnackbar(`Vial ${vialLabel} connected.`, { variant: 'success' })
    let currentRead = 0
    while (datFiles.length > 0) {
      const filename = datFiles.shift()
      const file_stats = await stat(filename)

      if (file_stats.size > MAX_FILE_SIZE) {
        console.log('test')
        const action = (key) => (
          <React.Fragment>
            <Button
              onClick={() => {
                closeSnackbar(key)
                unlink(filename)
              }}
              color="inherit"
              size="small"
            >
              {'Remove File'}
            </Button>
            <Button
              onClick={() => closeSnackbar(key)}
              color="inherit"
              size="small"
            >
              {'Dismiss'}
            </Button>
          </React.Fragment>
        )

        enqueueSnackbar(
          `You have a large file on ${vialLabel} that cannot be uploaded. Would you like to remove it from the device?`,
          {
            variant: 'warning',
            persist: true,
            action: action
          }
        )
      } else {
        const stream = fs.createReadStream(filename)
        const chunks = []
        // eslint-disable-next-line
        await new Promise((resolve, reject) => {
          stream.on('data', (chunk) => {
            if (unmounted.current) {
              stream.destroy()
              return
            }
            chunks.push(chunk)
            currentRead += chunk.length
            setProgress(currentRead / totalDataSize - 0.01)
          })
          stream.on('end', resolve)
          stream.on('error', reject)
        })

        const fileBlob = new Blob(chunks, { type: 'application/octet-stream' })
        const form = new FormData()
        form.append('file', fileBlob, filename)
        form.append('vial_uuid', vialLabel)
        api.records
          .upload(apiConfig.currentApiInstitute, form)
          .then((response) => {
            unlink(filename)
          })
          .catch((error) => {
            if (error.response.status === 403) {
              enqueueSnackbar(
                'There was a permission issue uploading your data, please contact Motryx at support@motryx.com',
                {
                  variant: 'error',
                  preventDuplicate: true,
                  persist: true
                }
              )
            } else {
              enqueueSnackbar(error.response.data.detail, {
                variant: error.response.data.s3_backup ? 'info' : 'error',
                preventDuplicate: true,
                persist: true
              })
              if (error.response.data.s3_backup === 'True') {
                unlink(filename)
              }
            }
          })
      }
    }
    setProgress(1)
    if (datFiles.length > 0) {
      enqueueSnackbar(
        `Records could not be uploaded from ${vialLabel} and still remain on the vial.`,
        {
          variant: 'error',
          preventDuplicate: true,
          persist: true
        }
      )
    }
  }, [datFiles, enqueueSnackbar, closeSnackbar, vialLabel, totalDataSize])

  useEffect(() => {
    // eslint-disable-next-line
    if (!unmounted.current) {
      if (endOfLife) {
        enqueueSnackbar(
          `Vial ${vialLabel} is no longer supported and should be returned to Motryx.`,
          {
            variant: 'error',
            preventDuplicate: true,
            persist: true
          }
        )
        setProgress(1)
      } else if (!registered) {
        enqueueSnackbar(
          `Vial ${vialLabel} is not regsistered, please contact Motryx.`,
          {
            variant: 'error',
            preventDuplicate: true,
            persist: true
          }
        )
        setProgress(1)
      } else {
        if (vialLabel && datFiles && totalDataSize > 0) {
          worker()
        } else {
          setProgress(1)
        }
      }
    }
    return () => {
      unmounted.current = true
    }
  }, [
    vialLabel,
    datFiles,
    enqueueSnackbar,
    totalDataSize,
    worker,
    endOfLife,
    registered
  ])

  return { progress: progress, datFiles: datFiles }
}

export function useDeviceUploadWorker(device) {
  const { vialLabel, datFiles, totalDataSize, endOfLife, registered } = device
  const unmounted = useRef(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const worker = useCallback(async () => {
    let currentRead = 0
    while (datFiles.length > 0) {
      const filename = datFiles.shift()
      const file_stats = await stat(filename)

      if (file_stats.size > MAX_FILE_SIZE) {
        console.log('test')
        const action = (key) => (
          <React.Fragment>
            <Button
              onClick={() => {
                closeSnackbar(key)
                unlink(filename)
              }}
              color="inherit"
              size="small"
            >
              {'Remove File'}
            </Button>
            <Button
              onClick={() => closeSnackbar(key)}
              color="inherit"
              size="small"
            >
              {'Dismiss'}
            </Button>
          </React.Fragment>
        )

        enqueueSnackbar(
          `You have a large file on ${vialLabel} that cannot be uploaded. Would you like to remove it from the device?`,
          {
            variant: 'warning',
            persist: true,
            action: action
          }
        )
      } else {
        const stream = fs.createReadStream(filename)
        const chunks = []
        // eslint-disable-next-line
        await new Promise((resolve, reject) => {
          stream.on('data', (chunk) => {
            if (unmounted.current) {
              stream.destroy()
              return
            }
            chunks.push(chunk)
            currentRead += chunk.length
            device.uploadProgress = currentRead / totalDataSize - 0.01
          })
          stream.on('end', resolve)
          stream.on('error', reject)
        })

        const fileBlob = new Blob(chunks, { type: 'application/octet-stream' })
        const form = new FormData()
        form.append('file', fileBlob, filename)
        form.append('vial_uuid', vialLabel)
        api.records
          .upload(device.instituteId, form)
          .then((response) => {
            unlink(filename)
          })
          .catch((error) => {
            if (error.response.status === 403) {
              device.uploadStatus =
                'Upload Error: Permission issue, Please contact Motryx at support@motryx.com'
            } else {
              device.uploadStatus = `Upload Error: ${error.response.data}`
              if (error.response.data.s3_backup === 'True') {
                unlink(filename)
              }
            }
          })
      }
    }

    if (!device.uploadStatus.includes('Upload Error')) {
      device.uploadStatus = 'Upload Complete'
      device.uploadProgress = 1
    }

    if (datFiles.length > 0) {
      device.uploadStatus = `Upload Error: Some records still  remain on ${vialLabel}`
    }
  }, [datFiles, enqueueSnackbar, closeSnackbar, vialLabel, totalDataSize])

  useEffect(() => {
    // eslint-disable-next-line
    if (!unmounted.current) {
      if (vialLabel && datFiles && totalDataSize > 0) {
        worker()
      }
    }
    return () => {
      console.log('unmounted')
      unmounted.current = true
    }
  }, [
    vialLabel,
    datFiles,
    enqueueSnackbar,
    totalDataSize,
    worker,
    endOfLife,
    registered
  ])
}
