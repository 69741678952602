import {
  Assignment,
  Close,
  NotInterested,
  TimerOff,
  TrendingFlat,
  VisibilityOff
} from '@mui/icons-material'
import { DatePicker } from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'
import {
  DropDownSelect,
  Labels,
  ServerSideDataGrid,
  StyledLink,
  ToggleFlag,
  Validated
} from 'components'
import LabelToggle, { unlabeled } from 'components/LabelToggle'
import { paramsToSearchObject } from 'components/ServerSideDataGrid'
import { addDays, format, formatDistanceToNowStrict, parseJSON } from 'date-fns'
import { formatDuration } from 'helpers'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Role, api, apiConfig, useMetricsContext } from 'services'
import styles from './styles'

const useStyles = makeStyles(styles)

const RecordFlags = ({ record }) => {
  return (
    <Box display="flex" flexDirection="column">
      {record.exclude ? (
        <Chip
          size="small"
          color="default"
          icon={<Close />}
          label={'Excluded'}
          variant="outlined"
        />
      ) : null}

      <Role restrict={['staff']}>
        {record.hidden ? (
          <Chip
            size="small"
            color="default"
            icon={<VisibilityOff />}
            label={'Hidden'}
            variant="outlined"
          />
        ) : null}
        {record.test_run ? (
          <Chip
            size="small"
            color="primary"
            icon={<Assignment />}
            label={'Test Run'}
            variant="outlined"
          />
        ) : null}
        {record.metrics && record.metrics.short_run_error ? (
          <Chip
            size="small"
            color="secondary"
            icon={<TimerOff />}
            label={'Short Run'}
            variant="outlined"
          />
        ) : null}
        {record.metrics && record.metrics.flatline_error ? (
          <Chip
            size="small"
            color="secondary"
            icon={<TrendingFlat />}
            label={'Flatline'}
            variant="outlined"
          />
        ) : null}
        {record.metrics && record.metrics.no_run_error ? (
          <Chip
            size="small"
            color="secondary"
            icon={<NotInterested />}
            label={'No Run'}
            variant="outlined"
          />
        ) : null}
      </Role>
    </Box>
  )
}

export default function MainView({
  showFilters,
  setReviewedCount = () => null
}) {
  const classes = useStyles()

  const { metricType, metricLabel } = useMetricsContext()

  const [url, setUrl] = useState(new URL(window.location))
  const urlParams = new URLSearchParams(url.search)

  const [lineSelected, setLineSelected] = React.useState(
    urlParams.get('line') === null ? '' : Number(urlParams.get('line'))
  )

  const [validationSelected, setValidationSelected] = React.useState(
    urlParams.get('validation__id') === null
      ? ''
      : Number(urlParams.get('validation__id'))
  )

  const [selectedStartDate, setSelectedStartDate] = React.useState(function () {
    if (urlParams.has('upload_date__gte')) {
      return urlParams.get('upload_date__gte')
    } else if (urlParams.has('date__gte')) {
      return urlParams.get('date__gte')
    } else {
      return null
    }
  })

  const [selectedEndDate, setSelectedEndDate] = React.useState(function () {
    if (urlParams.has('upload_date__lt')) {
      return urlParams.get('upload_date__lt')
    } else if (urlParams.has('date__lt')) {
      return urlParams.get('date__lt')
    } else {
      return null
    }
  })

  const [dateTypeRadioButton, setDateTypeRadioButton] = useState(
    urlParams.has('upload_date__gte') || urlParams.has('upload_date__lt')
      ? 'uploaded'
      : 'recorded'
  )

  // const [exclude, setExclude] = useState(urlParams.get('exclude'))
  const [exclude, setExclude] = useState(false)
  const [includeHidden, setIncludeHidden] = useState(false)
  const [selectedLabels, setSelectedLabels] = useState([])
  const [numRecords, setNumRecords] = useState(10)

  const handleClearSearch = (event) => {
    for (const key of urlParams) {
      urlParams.delete(key)
    }
    setLineSelected(null)
    setValidationSelected(null)
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setExclude(null)
    setSelectedLabels([])
    setUrl(new URL(`${url.origin}${url.pathname}`))
  }
  const handleRadioButtonChange = (event) => {
    setDateTypeRadioButton(event.target.value)
    if (event.target.value === 'uploaded') {
      if (urlParams.get('date__gte') !== null) {
        urlParams.set('upload_date__gte', urlParams.get('date__gte'))
      }
      if (urlParams.get('date__lt') !== null) {
        urlParams.set('upload_date__lt', urlParams.get('date__lt'))
      }
      urlParams.delete('date__gte')
      urlParams.delete('date__lt')
    } else if (event.target.value === 'recorded') {
      if (urlParams.get('upload_date__gte') !== null) {
        urlParams.set('date__gte', urlParams.get('upload_date__gte'))
      }
      if (urlParams.get('upload_date__lt') !== null) {
        urlParams.set('date__lt', urlParams.get('upload_date__lt'))
      }
      urlParams.delete('upload_date__gte')
      urlParams.delete('upload_date__lt')
    }

    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleExclude = (event, value) => {
    setExclude(value)
    if (!urlParams.has('exclude')) {
      urlParams.append('exclude', '')
    }
    if (value === true) {
      urlParams.set('exclude', value)
    } else if (value === false) {
      urlParams.set('exclude', value)
    } else {
      urlParams.delete('exclude')
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleIncludeHidden = (event) => {
    const include = event.target.checked
    setIncludeHidden(include)
    if (include) {
      urlParams.set('include_hidden', include)
    } else {
      urlParams.delete('include_hidden')
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleEndDateChange = (date) => {
    let date_searchParam = ''
    if (dateTypeRadioButton === 'uploaded') {
      date_searchParam = 'upload_date__lt'
    } else {
      date_searchParam = 'date__lt'
    }
    setSelectedEndDate(date)
    if (date === null) {
      urlParams.delete('upload_date__lt')
      urlParams.delete('date__lt')
    } else {
      urlParams.delete('upload_date__lt')
      urlParams.delete('date__lt')
      const newDate = addDays(date, 1)
      const dateFormatted = format(newDate, 'yyyy-MM-dd')
      urlParams.append(date_searchParam, dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleStartDateChange = (date) => {
    let date_searchParam = ''
    if (dateTypeRadioButton === 'uploaded') {
      date_searchParam = 'upload_date__gte'
    } else {
      date_searchParam = 'date__gte'
    }
    setSelectedStartDate(date)
    if (date === null) {
      urlParams.delete('upload_date__gte')
      urlParams.delete('date__gte')
    } else {
      urlParams.delete('upload_date__gte')
      urlParams.delete('date__gte')
      const dateFormatted = format(date, 'yyyy-MM-dd')
      urlParams.append(date_searchParam, dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const applyLineSearch = (value) => {
    if (value === null && urlParams.has('line')) {
      urlParams.delete('line')
    } else {
      if (urlParams.has('line')) {
        urlParams.set('line', value)
      } else {
        urlParams.append('line', value)
      }
    }

    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleLineChange = (event, line) => {
    if (line) {
      setLineSelected(line.id)
      applyLineSearch(line.id)
    } else {
      setLineSelected(null)
      applyLineSearch(null)
    }
  }

  const applyValidationSearch = (value) => {
    if (value === null && urlParams.has('validation__id')) {
      urlParams.delete('validation__id')
    } else {
      if (urlParams.has('validation__id')) {
        urlParams.set('validation__id', value)
      } else {
        urlParams.append('validation__id', value)
      }
    }

    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handleValidationChange = (event, validation) => {
    if (validation) {
      setValidationSelected(validation.id)
      applyValidationSearch(validation.id)
    } else {
      setValidationSelected(null)
      applyValidationSearch(null)
    }
  }

  const handleSelectedLabels = (event, labels) => {
    setSelectedLabels(labels)
    if (labels.length === 0) {
      urlParams.delete('labels')
    } else {
      const label_ids = labels.map((label) => label.id).join(',')
      if (urlParams.has('labels')) {
        urlParams.set('labels', label_ids)
      } else {
        urlParams.append('labels', label_ids)
      }
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const { data: labelOptions } = useQuery(
    ['record-filter-labels', apiConfig.currentApiInstitute, { url }],
    () => {
      return api.labels.list().then((data) => {
        if (url.searchParams.has('labels')) {
          const labelsList = url.searchParams.get('labels').split(',')
          const param_labels = labelsList.map((id) => parseInt(id))
          const newSelectedLabels = data.filter((label) =>
            param_labels.includes(label.id)
          )
          if (labelsList.includes(unlabeled.id))
            newSelectedLabels.push(unlabeled)
          setSelectedLabels(newSelectedLabels)
        }
        return data
      })
    }
  )

  const { data: lineOptions } = useQuery(
    ['line-options', apiConfig.currentApiInstitute],
    () => api.lines.list({ size: 9999 }).then((data) => data.items)
  )

  const { data: validationOptions } = useQuery(
    ['validations-list', apiConfig.currentApiInstitute],
    () => api.validations.list({ size: 9999 }).then((data) => data.items)
  )

  const columns = [
    { field: 'id', hide: true },
    { field: 'metric', hide: true },
    { field: 'line', hide: true },
    { field: 'vial', hide: true },
    {
      field: 'date',
      headerName: 'Recorded',
      width: 190,
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        const date = params.getValue(params.id, 'date')
        const validation = params.getValue(params.id, 'validation')
        return (
          <Validated
            justifyContent="flex-start"
            validated={validation}
            childrenSpacing={1}
            validationId={validation && validation.id}
          >
            <Link
              to={{
                pathname: `/record/${id}`,
                state: { name: 'Transit Records' }
              }}
              style={{ color: date ? '#0B8DDE' : '#CA7A85' }}
            >
              {date
                ? format(parseJSON(date), 'dd MMM yyyy HH:mm')
                : 'Unprocessed Record'}
            </Link>
          </Validated>
        )
      }
    },
    {
      field: 'vial__uuid',
      headerName: 'VitalVial',
      width: 90,
      renderCell: (params) => {
        return params.getValue(params.id, 'vial')
      }
    },
    {
      field: 'upload_date',
      headerName: 'Uploaded',
      width: 120,
      renderCell: ({ value }) =>
        formatDistanceToNowStrict(parseJSON(value), { addSuffix: true })
    },
    {
      field: 'recordmetrics__duration',
      headerName: 'Duration (h:m:s)',
      width: 150,
      renderCell: (params) => {
        const metrics = params.getValue(params.id, 'metrics')
        return formatDuration(metrics.duration)
      }
    },
    {
      field: 'recordmetrics__auc',
      renderHeader: () => {
        return (
          <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
            {metricLabel}
          </Typography>
        )
      },
      width: 110,
      renderCell: (params) => {
        const metrics = params.getValue(params.id, 'metrics')
        return metrics[metricType]
      }
    },
    {
      field: 'range',
      headerName: 'Range',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const out_of_range = params.getValue(params.id, 'out_of_range')

        return (
          <Box width={70}>
            {out_of_range?.range
              ? `${out_of_range.range.lower} - ${out_of_range.range.upper}`
              : '-'}
          </Box>
        )
      }
    },
    {
      field: 'line__name',
      headerName: 'Line',
      // width: 200,
      renderCell: (params) => {
        const line = params.getValue(params.id, 'line')
        return (
          line && (
            <StyledLink
              to={{
                pathname: `/line/${line.id}/`,
                state: {
                  name: 'Transit Records',
                  referrer: {
                    path: `${window.location.pathname}${window.location.search}`
                  }
                }
              }}
            >
              {line.name}
            </StyledLink>
          )
        )
      }
    },
    {
      field: 'labels',
      headerName: 'Labels',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const labels = params.getValue(params.id, 'labels')
        return (
          <Labels
            shownLabels={labels && labels.slice(0, 2)}
            hiddenLabels={labels && labels.slice(2)}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="flex-start"
          />
        )
      }
    },
    {
      field: 'flags',
      headerName: 'Flags',
      sortable: false,
      flex: 1,
      renderCell: (params) => <RecordFlags record={params.row} />
    }
  ]

  const handleNumRecords = (newValue) => {
    setNumRecords(newValue)
  }

  useEffect(() => {
    handleExclude(null, false)
  }, [])

  return (
    <Grid container spacing={1} direction="column">
      <Collapse in={showFilters} data-testid="record-filter-collapse">
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            gap={2}
          >
            <Grid
              item
              style={{
                flexGrow: 1
              }}
            >
              <Grid container direction="row" gap={2}>
                <Grid container direction="column" spacing={1} md={6}>
                  <Grid item>
                    <Typography className={classes.searchLabel}>
                      Filter By Line
                    </Typography>
                  </Grid>
                  <Grid item>
                    {lineOptions && (
                      <Autocomplete
                        data-testid="line-selection-autocomplete-test_id"
                        id="line-selection-autocomplete"
                        value={
                          lineOptions.find((line) => line.id === lineSelected)
                            ? lineOptions.filter(
                                (line) => line.id === lineSelected
                              )[0]
                            : null
                        }
                        options={lineOptions}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          )
                        }}
                        onChange={handleLineChange}
                        size="small"
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={'Select Line'}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="column" spacing={1} md={6}>
                  <Grid item>
                    <Typography className={classes.searchLabel}>
                      Filter By Validation
                    </Typography>
                  </Grid>
                  <Grid item>
                    {validationOptions && (
                      <Autocomplete
                        data-testid="validation-selection-autocomplete-test_id"
                        id="validation-selection-autocomplete"
                        value={
                          validationOptions.find(
                            (validation) => validation.id === validationSelected
                          )
                            ? validationOptions.filter(
                                (validation) =>
                                  validation.id === validationSelected
                              )[0]
                            : null
                        }
                        options={validationOptions}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                          console.log(option)
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          )
                        }}
                        onChange={handleValidationChange}
                        size="small"
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={'Select Validation'}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography className={classes.searchLabel}>Flags</Typography>
                </Grid>
                <Grid item>
                  <ToggleFlag
                    value={exclude}
                    onChange={handleExclude}
                    trueLabel="Excluded"
                    falseLabel="Not Excluded"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                flexShrink: 0
              }}
            >
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                  >
                    <Typography
                      className={classes.searchLabel}
                      display="inline"
                    >
                      {'Date'}{' '}
                    </Typography>
                    <RadioGroup
                      name="date"
                      value={dateTypeRadioButton}
                      onChange={handleRadioButtonChange}
                      row
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            style={{ padding: '0px 9px 0px 45px' }}
                          />
                        }
                        value="recorded"
                        label="Recorded"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            style={{ padding: '0px 9px 0px 20px' }}
                          />
                        }
                        value="uploaded"
                        label="Uploaded"
                      />
                    </RadioGroup>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <DatePicker
                      label={'Start'}
                      id="upload-date-start"
                      name="uploadstart"
                      value={selectedStartDate}
                      disableMaskedInput={true}
                      inputFormat="dd MMM yyyy"
                      clearable={true}
                      style={{ maxWidth: '180px' }}
                      onChange={handleStartDateChange}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />

                    <Typography
                      className={classes.searchSeperator}
                      display="inline"
                    >
                      –to–
                    </Typography>

                    <DatePicker
                      label={'End'}
                      id="upload-date-end"
                      name="uploadend"
                      value={selectedEndDate}
                      disableMaskedInput={true}
                      inputFormat="dd MMM yyyy"
                      onChange={handleEndDateChange}
                      style={{ maxWidth: '180px' }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography className={classes.searchLabel}>
                  {'Filter Records By Label'}
                </Typography>
              </Box>
              {labelOptions && (
                <LabelToggle
                  labels={labelOptions}
                  selectedLabels={selectedLabels}
                  handleSelectedLabels={handleSelectedLabels}
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Role retsrict={['staff']}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={includeHidden}
                      onChange={handleIncludeHidden}
                      id="includeHidden"
                      name="includeHidden"
                    />
                  }
                  label="Include Hidden Records"
                />
              </Role>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClearSearch}
                sx={{
                  marginTop: '10px',
                  marginBottom: '5px',
                  fontSize: '12px'
                }}
              >
                Clear Filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      <Grid item xs={12}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <Grid item>
            <DropDownSelect
              labelId="num-records-select"
              label="Records per Page"
              value={numRecords}
              onValueChange={(newValue) => {
                setNumRecords(newValue)
              }}
              items={[
                { content: '10', value: '10' },
                { content: '20', value: '20' },
                { content: '50', value: '50' },
                { content: '100', value: '100' }
              ]}
              styles={{ width: '130px' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ServerSideDataGrid
          apiCall={api.records.listReviewedOnly}
          queryName={'recordData'}
          searchParams={paramsToSearchObject(urlParams)}
          updateTotal={setReviewedCount}
          columns={columns}
          noRowsText={'No Records'}
          pageSize={numRecords}
        />
      </Grid>
    </Grid>
  )
}
