import React from 'react'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import styles from './styles'

const Footer = (props) => {
  const { classes } = props
  const year = new Date().getFullYear()
  return (
    <footer className={classes.root}>
      <Typography variant="body1" style={{ fontSize: 14 }}>
        {`© ${year} Motryx. All rights reserved.`}
      </Typography>
    </footer>
  )
}

export default withStyles(styles)(Footer)
