import React, { useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Typography
} from '@mui/material'
import { openDocsPage, rejectionColor } from 'helpers'
import { InfoBox, InfoButton, NumberChangeWidget, StyledLink, Loader, Labels, Validated } from 'components'
import { api, Flag, useMetricsContext } from 'services'
import { format, parseJSON } from 'date-fns'
import { useInfiniteQuery } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'

function TableRejectionDelta ({ rejectionRates }) {
  return (
    <Box display="flex" flexDirection="row">
      <NumberChangeWidget
        num={rejectionRates.ldh}
        label={'LDH'}
        rejectionColor={rejectionColor(rejectionRates.ldh, 0, 0)}
      />
      <NumberChangeWidget
        num={rejectionRates.asat}
        label={'ASAT'}
        rejectionColor={rejectionColor(rejectionRates.asat, 0, 0)}
      />
      <NumberChangeWidget
        num={rejectionRates.potassium}
        label={'K'}
        rejectionColor={rejectionColor(rejectionRates.potassium, 0, 0)}
      />
    </Box>
  )
}

function TableRow ({ record, activeStats, metricType }) {
  const history = useHistory()
  const metrics = record.metrics || null
  const validation = record.validation || null
  const recordDate = format(parseJSON(record.date), 'd MMM yyyy HH:mm')
  return (
    <Box py={1} style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Box mr={3} minWidth={150}>
          <Flag name="troubleshooting" alternate={recordDate}>
            <StyledLink
              to={{
                pathname: `/record/${record.id}`,
                state: { name: `${record.line.name}` }
              }}
            >
              {recordDate}
            </StyledLink>
          </Flag>
        </Box>
        <Box mr={3}>
          <Typography fontSize={'18px'} >{record.vial}</Typography>
        </Box>
        <Box flexGrow={1} mr={3}>
          { validation && (
            <Validated justifyContent='flex-start'>
              <StyledLink
                to={{
                  pathname: `/validation/${validation.id}`,
                  state: {
                    name: `${record.line.name}`,
                    referrer: {
                      path: `${window.location.pathname}${window.location.search}`,
                      back: {
                        label: history.location.state ? history.location.state.name : null,
                        path: history.location.state && history.location.state.referrer && history.location.state.referrer.path
                      }
                    }
                  }
                }}
              >
                {validation.name}
              </StyledLink>
            </Validated>)
          }
        </Box>
        <Box width={100}>{metrics[metricType] || '-'}</Box>
        <Flag name="monitoring">
          <Box width={225}>
            { activeStats && metrics.rejection &&
                      metrics.rejection[metricType] !== null &&
                      activeStats[metricType].rejection_rates !== null
              ? (
                <TableRejectionDelta rejectionRates={{
                  ldh: metrics.rejection[metricType].ldh - activeStats[metricType].rejection_rates.ldh,
                  asat: metrics.rejection[metricType].asat - activeStats[metricType].rejection_rates.asat,
                  potassium: metrics.rejection[metricType].potassium - activeStats[metricType].rejection_rates.potassium
                }}
                />)
              : '-'
            }
          </Box>
        </Flag>
      </Box>
      <Labels shownLabels={record.labels}/>
    </Box>
  )
}

function TablePage ({ page, ...rest }) {
  return <React.Fragment>
    {page.items.map(record => {
      return <TableRow key={record.id} record={record} {...rest} />
    })}
  </React.Fragment>
}

export default function TransitRecordsView ({ activeStats = [], parentEl = null, url }) {
  const { metricType, metricLabel } = useMetricsContext()
  const { id } = useParams()

  const recordQuery = ({ pageParam = 0 }) => {
    const params = { size: 20, page: pageParam, line: id, rejection: `${metricType}`, ordering: '-date', exclude: false }
    for (const [key, value] of url.searchParams.entries()) {
      params[key] = value
    }
    return api.records.list(params)
  }

  const {
    data: lineRecordData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(['line-record-data', { id, url, metricType }], recordQuery,
    {
      cacheTime: 0,
      getPreviousPageParam: firstPage => firstPage.previous,
      getNextPageParam: lastPage => lastPage.next
    })

  useEffect(() => {
    const onScroll = async () => {
      if (!isFetchingNextPage && hasNextPage) {
        if (parentEl.current && parentEl.current.scrollTop >= (parentEl.current.offsetHeight - 500)) {
          await fetchNextPage()
        }
      }
    }
    parentEl.current.addEventListener('scroll', onScroll)
    return () => parentEl.current !== null && parentEl.current.removeEventListener('scroll', onScroll)
  }, [parentEl, hasNextPage, isFetchingNextPage])

  return (
    <Box pt={3}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{'Transit Records'}</Typography>
      </Box>
      <Box mt={4} display='flex' flexDirection='row' justifyContent={'space-beween'} style={{ borderBottom: '1px solid #000' }}>
        <Box minWidth={150} mr={3} style={{ fontSize: '18px', fontWeight: 600, color: '#33403F' }} flexGrow={1}>{'Details'}</Box>
        <Box width={100}>
          <Typography style={{ fontSize: '18px', fontWeight: 600 }}>{metricLabel}</Typography>
        </Box>
        <Flag name="monitoring">
          <Box display="flex" style={{ fontSize: '18px', fontWeight: 600, color: '#33403F' }} width={225} flexDirection="row" alignItems="center" justifyContent="center">
            {'Change in Rejection'}
            <Box display="flex" pl={1} >
              <InfoButton>
                <InfoBox onLearnMoreClick={() => openDocsPage('/insights/rejection-probability')}>
                  {
                    'Change in Rejection is the difference in the rejection probability from the line average to the individual record.'
                  }
                </InfoBox>
              </InfoButton>
            </Box>
          </Box>
        </Flag>
      </Box>
      {isLoading
        ? <Loader/>
        : lineRecordData && lineRecordData.pages.map(page => {
          return <TablePage key={page.page} page={page} activeStats={activeStats} metricType={metricType}/>
        })}
      { isFetchingNextPage &&
        <Box p={5} alignItems='center' display='flex' flexDirection='column'>
          <CircularProgress size={30}/>
          <Typography color='primary'>{'Loading more records'}</Typography>
        </Box>
      }
    </Box>
  )
}
