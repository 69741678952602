import { Add } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Modal, ServerDataGrid } from 'components'
import { DeleteVialForm, VitalVialForm } from 'forms'
import { useGetInstitutes } from 'hooks/institutes'
import useVitalVialManager from 'hooks/useVitalVialManager'
import { useState } from 'react'

export default function VitalVials(props) {
  const { invalidateGetVitalVialsQuery, getVitalVials } = useVitalVialManager()
  const [vialSearchText, setVialSearchText] = useState('')
  const [filterInstituteID, setFilterInstituteID] = useState(0)
  const { data: getInstitutes, isLoading: getInstitutesLoading } =
    useGetInstitutes(null, null, { page: 0, size: 10000 })

  const columns = [
    {
      field: 'label',
      headerName: 'Vial ID',
      flex: 1,
      renderCell: (params) => {
        const label = params.row['label']
        const [open, setOpen] = useState(false)
        return (
          <Modal
            button={
              <Button size="small" color="primary">
                {label}
              </Button>
            }
            maxWidth={'sm'}
            label={`Edit Vial ${label}`}
          >
            <VitalVialForm
              id={label}
              vial={params.row}
              exitFunction={async () => {
                await invalidateGetVitalVialsQuery()
              }}
            />
          </Modal>
        )
      }
    },
    {
      field: 'institute',
      headerName: 'Organization - Partner ID',
      flex: 1,
      renderCell: (params) => {
        const namedInstitute = params.row['institute']
        if (getInstitutesLoading) return <>Loading...</>
        const institute = getInstitutes?.items?.find(
          (institute) => institute?.id === namedInstitute?.id
        )
        const partnerId = institute?.partner_id
        if (!institute) return <>NA</>
        if (partnerId) {
          return (
            <>
              {institute.name} - {partnerId}
            </>
          )
        }
        return <>{institute.name}</>
      }
    },
    { field: 'version', headerName: 'Version', flex: 1 },
    {
      field: 'delete',
      headerName: 'Remove Vial',
      flex: 1,
      renderCell: (params) => {
        const label = params.row['label']
        return (
          <Modal
            button={
              <IconButton color={'error'}>
                <DeleteIcon />
              </IconButton>
            }
            label={'Confirmation Required'}
          >
            <DeleteVialForm
              label={label}
              exitFunction={() => invalidateGetVitalVialsQuery()}
            />
          </Modal>
        )
      }
    }
  ]

  // useEffect(() => {
  //   console.log('vialSearchText', vialSearchText)
  //   console.log('filterInstituteID', filterInstituteID)
  // }, [vialSearchText, filterInstituteID])

  return (
    <Box display={'flex'} flexDirection={'column'} p={2} gap={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography variant={'h5'}>VitalVials</Typography>
        <Modal
          label={'Add New Vial'}
          showCloseIcon
          button={
            <Tooltip title={'Add New Vial'}>
              <IconButton color={'primary'} size={'large'}>
                <Add fontSize={'large'} />
              </IconButton>
            </Tooltip>
          }
        >
          <VitalVialForm
            exitFunction={async () => {
              await invalidateGetVitalVialsQuery()
            }}
          />
        </Modal>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} gap={2}>
          <TextField
            label={'Search Vials'}
            variant={'outlined'}
            size={'small'}
            value={vialSearchText}
            onChange={(event) => setVialSearchText(event.target.value)}
            sx={{
              width: '18rem'
            }}
          />
          <FormControl>
            <InputLabel id={'institute-filter-dropdown-label'}>
              Filter By Organization
            </InputLabel>
            <Select
              size={'small'}
              labelId={'institute-filter-dropdown-label'}
              label={'Filter By Organization'}
              id={'institute-filter-dropdown'}
              value={filterInstituteID}
              onChange={(event) => setFilterInstituteID(event.target.value)}
              sx={{
                width: '25rem'
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {getInstitutes?.items &&
                getInstitutes.items.map((institute, index) => (
                  <MenuItem key={index} value={institute.id}>
                    {institute.partner_id
                      ? `${institute.name} - ${institute.partner_id}`
                      : institute.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <ServerDataGrid
          columns={columns}
          query={getVitalVials}
          queryParams={{
            search: vialSearchText === '' ? null : vialSearchText,
            institute_id: filterInstituteID === 0 ? null : filterInstituteID
          }}
          getRowId={(row) => row['label']}
          keepNonExistentRowsSelected={false}
        />
      </Box>
    </Box>
  )
}
