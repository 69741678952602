import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { Flag } from 'services'

export default function Validated({
  children = null,
  childrenSpacing = 0,
  justifyContent = 'center',
  alignItems = 'center',
  iconSize = 'default',
  validated = true,
  keepIconEmpty = true,
  validationId = null
}) {
  const size =
    iconSize === 'xsmall'
      ? '13px'
      : iconSize === 'small'
      ? '20px'
      : iconSize === 'large'
      ? '35px'
      : '24px'
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      <Box
        display={validated || keepIconEmpty ? 'flex' : 'none'}
        alignSelf="center"
        height={size}
        width={size}
      >
        {validated && (
          <Flag name="validation">
            {validationId ? (
              <Link to={`/validation/${validationId}`}>
                <EventAvailableIcon
                  color="primary"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'secondary.main'
                    },
                    fontSize: size
                  }}
                />
              </Link>
            ) : (
              <EventAvailableIcon
                color="primary"
                sx={{
                  fontSize: size
                }}
              />
            )}
          </Flag>
        )}
      </Box>
      {children && <Box ml={childrenSpacing}>{children}</Box>}
    </Box>
  )
}
