import React from 'react'
import { arc } from 'd3-shape'
import { scaleLinear } from 'd3-scale'

export default function Gauge ({
  value = 5,
  min = 0,
  max = 10,
  color = '#4FC1BE',
  width = 50
}) {
  const backgroundArc = arc()
    .innerRadius(width * 0.85)
    .outerRadius(width)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(width)()

  const percentScale = scaleLinear()
    .domain([min, max])
    .range([0, width])
  const percent = percentScale(value)

  const angleScale = scaleLinear()
    .domain([0, width])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(percent)

  const filledArc = arc()
    .innerRadius(width * 0.85)
    .outerRadius(width)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(width)()

  return (
    <div
      style={{
        textAlign: 'center',
        width: width * 2
      }}>
      <svg
        style={{ overflow: 'visible' }}
        width={ width }
        viewBox={[
          -(width * 0.5), -(width),
          width, width
        ].join(' ')}>
        <text textAnchor="middle" style={{
          fontSize: width - 10,
          fontWeight: 600,
          fill: '#333A40'
        }}
        >{value}</text>
        <path
          d={backgroundArc}
          fill="#dbdbe7"
        />
        <path
          d={filledArc}
          fill={color}
        />
      </svg>
    </div>
  )
}
