import { DateTimePicker } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { formatISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Flag, Role, api, hasRole } from 'services'

export default function RecordForm({
  id,
  closeModalFunction,
  exitFunction,
  variant = 'onSubmit'
}) {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({ mode: variant })
  const unmounted = useRef(false)

  const [displayValidation, setDisplayValidation] = useState(true)

  const [line, setLine] = useState('')
  const [archived, setArchived] = useState(false)
  const [exclude, setExclude] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [dateRecorded, setDateRecorded] = useState()
  const [institute, setInstitute] = useState('')

  const [recordData, setRecordData] = useState()

  const [validation, setValidation] = useState('')
  const [validationOptions, setValidationOptions] = useState()

  const { data: lineOptions } = useQuery(['record-lines', id], () =>
    api.lines.list({ size: 9999 }).then((data) => data.items)
  )
  const { data: instituteOptions } = useQuery(
    ['record-institutes', id],
    () => api.institutes.list({ size: 9999 }).then((data) => data.items),
    { enabled: hasRole('staff') }
  )

  const handleExclude = (event) => {
    setExclude(event.target.checked)
    setValue('exclude', event.target.checked)
    if (variant === 'onChange') {
      onSubmit(getValues())
    }
  }

  const handleLineChange = (event) => {
    setLine(event.target.value)
    setValue('line', event.target.value)
    setValue('validation', null)
    if (variant === 'onChange') {
      onSubmit(getValues())
    }
  }

  const handleHidden = (event) => {
    setHidden(event.target.checked)
  }

  const handleDateChange = (date) => {
    setDateRecorded(date)
    setValue('date', date !== null ? formatISO(date) : null)
  }

  const handleInstituteChange = (event) => {
    setInstitute(event.target.value)
    setValue('institute', event.target.value)
    if (event.target.value === recordData.institute.id) {
      setLine(recordData.line ? recordData.line.id : '')
      setValue('line', recordData.line ? recordData.line.id : '')
    } else {
      setLine('')
      setValue('line', '')
    }
  }

  const handleValidationChange = (event) => {
    setValidation(event.target.value)
    setValue('validation', event.target.value)
    if (variant === 'onChange') {
      onSubmit(getValues())
    }
  }

  const handleClose = () => {
    if (closeModalFunction) closeModalFunction()
    if (exitFunction) exitFunction(true)
  }

  // Form Submission Handling
  const onSubmit = (data) => {
    Object.keys(data).forEach(function (key) {
      if (data[key] === '') data[key] = null
    })
    api.records
      .update(id, data)
      .then(function (data) {
        enqueueSnackbar('Changes to the record have been saved.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        handleClose()
      })
      .catch(function (status) {
        console.log(status)
      })
  }

  useEffect(() => {
    register('line')
    register('validation')
    if (hasRole('staff')) {
      register('date', { required: 'You cannot have an empty date.' })
      register('institute', {
        required: 'You must assign an institute to a record'
      })
    }

    api.records
      .get(id)
      .then((data) => {
        if (!unmounted.current) {
          setRecordData(data)
          setDisplayValidation(true)
          setValidation(data.validation ? data.validation.id : '')
          setValue('validation', data.validation ? data.validation.id : '')
          setLine(data.line ? data.line.id : '')
          setValue('line', data.line ? data.line.id : '')
          setExclude(data.exclude)
          setArchived(data.line && data.line.archived)
          if (hasRole('staff')) {
            setDateRecorded(data.date)
            setValue('date', data.date)
            setHidden(data.hidden)
            setInstitute(data.institute ? data.institute.id : '')
            setValue('institute', data.institute ? data.institute.id : '')
          }
        }
      })
      .catch((error) => {
        if (!unmounted.current) {
          console.warn(JSON.stringify(error, null, 2))
        }
      })
    return () => {
      unmounted.current = true
    }
  }, [id, setValue, register])

  useEffect(() => {
    let unmounted = false
    const params = { size: 9999 }
    if (line !== '') params.line_id = line
    api.validations
      .list(params)
      .then((data) => {
        if (!unmounted) {
          setValidationOptions(data.items)
          const validationIds = data.items.map((validation) => validation.id)
          if (!validationIds.includes(getValues('validation'))) {
            setValue('validation', null)
            setValidation('')
          }
        }
      })
      .catch((error) => {
        console.warn(error)
      })

    return () => {
      unmounted = true
    }
  }, [register, line, setValue, getValues])

  return (
    <form
      id={`record-form-${id || 'new'}`}
      data-testid="record-form-test"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
      >
        <Role restrict={['staff']}>
          <Grid
            item
            style={{ display: variant === 'onSubmit' ? 'block' : 'none' }}
            xs={variant === 'onSubmit' ? 12 : 6}
          >
            <DateTimePicker
              name="date"
              style={{ width: '100%' }}
              mask="____-__-__ __:__"
              inputFormat="yyyy-MM-dd HH:mm"
              label={'Date Recorded'}
              ampm={false}
              value={dateRecorded}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'Date Recorded'
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              size="small"
              error={errors.hasOwnProperty('date')}
              helperText={errors.date && errors.date.message}
            />
          </Grid>
        </Role>
        <Grid item xs={variant === 'onSubmit' ? 12 : 6}>
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            disabled={archived}
          >
            <InputLabel id="line-input-label">Line</InputLabel>
            <Select
              name="line"
              label="Line"
              labelId="line-input-label"
              id="line-input"
              value={line}
              onChange={handleLineChange}
              SelectDisplayProps={{
                'data-testid': 'line-select'
              }}
              inputProps={{
                'data-testid': 'line-input'
              }}
            >
              {lineOptions &&
                lineOptions.map((line, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={line.id}
                      disabled={line.archived}
                    >
                      {line.name}
                    </MenuItem>
                  )
                })}
            </Select>
            {archived && (
              <FormHelperText style={{ whiteSpace: 'nowrap', marginLeft: 0 }}>
                {'The line has been archived and cannot be changed.'}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={variant === 'onSubmit' ? 12 : 6}>
          <FormControlLabel
            disabled={hasRole('guest')}
            control={
              <Checkbox
                name="exclude"
                checked={exclude}
                {...register('exclude')}
                onChange={handleExclude}
                color="primary"
                size="small"
              />
            }
            label="Exclude"
          />
          <Role restrict={['staff']}>
            <FormControlLabel
              style={{ display: variant === 'onSubmit' ? 'block' : 'none' }}
              control={
                <Checkbox
                  name="hidden"
                  checked={hidden}
                  {...register('hidden')}
                  onChange={handleHidden}
                  color="primary"
                  size="small"
                />
              }
              label="Hidden"
            />
          </Role>
        </Grid>
        <Flag name="validation">
          <Grid
            item
            xs={12}
            style={{ display: displayValidation ? 'block' : 'none' }}
          >
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="validation-input-label">
                {'Validation'}
              </InputLabel>
              <Select
                name="validation"
                label="Validation"
                labelId="validation-input-label"
                id="validation"
                value={validation}
                onChange={handleValidationChange}
                SelectDisplayProps={{
                  'data-testid': 'validation-select'
                }}
                inputProps={{
                  'data-testid': 'validation-input'
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {validationOptions &&
                  validationOptions.map((validation, index) => {
                    return (
                      <MenuItem
                        key={`validation-${index}`}
                        value={validation.id}
                      >
                        {validation.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              {validationOptions && validationOptions.length <= 0 && (
                <FormHelperText component="div">
                  {
                    'There are no validations in progress for the selected line.'
                  }
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Flag>
        <Role restrict={['staff']}>
          <Grid
            item
            style={{ display: variant === 'onSubmit' ? 'block' : 'none' }}
            xs={12}
          >
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={errors.hasOwnProperty('institute')}
            >
              <InputLabel id="institute-input-label">Organization</InputLabel>
              <Select
                name="institute"
                label="Organization"
                labelId="institute-input-label"
                id="institute"
                value={institute}
                onChange={handleInstituteChange}
                SelectDisplayProps={{
                  'data-testid': 'institute-select'
                }}
                inputProps={{
                  'data-testid': 'institute-input'
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {instituteOptions &&
                  instituteOptions.map((institute, index) => {
                    return (
                      <MenuItem key={index} value={institute.id}>
                        {institute.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              <FormHelperText>
                {errors.institute && errors.institute.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Role>
        {variant === 'onSubmit' && (
          <Grid item container justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
              >
                Save Changes
              </Button>
            </Box>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  )
}
