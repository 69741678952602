import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import React, { useCallback } from 'react'
import { authenticationService } from 'services'

const minDrawerWidth = 360
const maxDrawerWidth = 1000

const styles = (theme) => ({
  root: {
    minHeight: authenticationService.multiInstitute ? '117px' : '70px'
  }
})

const SideToolbar = withStyles(styles)(Toolbar)

const Sidebar = React.forwardRef(
  ({ anchor = 'right', children, drawerWidth = minDrawerWidth }, ref) => {
    const useStyles = makeStyles((theme) => ({
      drawer: {
        flexGrow: 1
      },
      drawerContainer: {
        overflow: 'auto',
        height: '100%'
      },
      dragger: {
        cursor: 'ew-resize',
        padding: '4px 0 0',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: '#D6DAE0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible'
      },
      draggerIcon: {
        position: 'absolute',
        top: '96%'
      }
    }))
    const classes = useStyles()
    const [sideDrawerWidth, setSideDrawerWidth] = React.useState(drawerWidth)
    const [draggerWidth, setDraggerWidth] = React.useState('5px')
    const [showDragger, setShowDragger] = React.useState(false)

    // Handle Mouse Down event
    const handleMouseDown = (e) => {
      document.addEventListener('mousemove', handleMouseMove, true)
      document.addEventListener('mouseup', handleMouseUp, true)
      // Disable the text selection
      document.body.style.userSelect = 'none'
    }

    // Handle Mouse Up event
    const handleMouseUp = (e) => {
      document.removeEventListener('mouseup', handleMouseUp, true)
      document.removeEventListener('mousemove', handleMouseMove, true)

      // Enable the text selection
      document.body.style.userSelect = ''
    }

    // Handle Mouse Move event
    const handleMouseMove = useCallback((e) => {
      const newWidth = window.innerWidth - e.clientX
      if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
        setSideDrawerWidth(newWidth)
      }
    })

    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        PaperProps={{
          style: {
            width: sideDrawerWidth,
            borderLeft: '1px solid #D6DAE0'
          }
        }}
        anchor={anchor}
      >
        <div
          onMouseDown={(e) => handleMouseDown(e)}
          className={classes.dragger}
          style={{
            width: draggerWidth,
            transition: 'width 0.2s'
          }}
          onMouseEnter={(e) => {
            setShowDragger(true)
            setDraggerWidth('15px')
          }}
          onMouseLeave={(e) => {
            setShowDragger(false)
            setDraggerWidth('5px')
          }}
          onDoubleClick={() => {
            setSideDrawerWidth(minDrawerWidth)
          }}
        >
          {showDragger && <DragIndicatorIcon className={classes.draggerIcon} />}
        </div>
        <SideToolbar />
        <div className={classes.drawerContainer} ref={ref}>
          {children}
        </div>
      </Drawer>
    )
  }
)

export default Sidebar
