export default (theme) => ({
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '50%': {
      transform: 'scale(1.05)',
      opacity: 0.15
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1
    }
  },
  infoOutlinedIcon: {
    color: '#A9AAAC',
    padding: 1,
    animation: '$pulse 2s infinite',
    '&:hover': {
      color: '#777777'
    },
    cursor: 'pointer'
  }
})
