import React from 'react'
import Plot from 'react-plotly.js'
import { format, parseISO, addDays, addWeeks, addMonths, min as minDate, max as maxDate } from 'date-fns'

const frequencies = {
  daily: { format: 'dd MMM yyyy', template: '%{hovertext}<br>%{y}%', padFunction: addDays },
  weekly: { format: 'dd MMM yyyy', template: 'Week of %{hovertext}<br>%{y}%', padFunction: addWeeks },
  monthly: { format: 'MMM yyyy', template: '%{hovertext}<br>%{y}%', padFunction: addMonths },
  yearly: { format: 'yyyy', template: '%{hovertext}<br>%{y}%', padFunction: addDays }
}

function getColor (value, count_threshold, rejection_threshold) {
  if (value.count < count_threshold) {
    return '#B6B6B63A'
  }
  if (value.count >= count_threshold && value.rejection > rejection_threshold && rejection_threshold) {
    return '#CA7A85AA'
  }
  return '#B6B6B6'
}

function padValues (values) {
  const empytValue = { count: 0, rejection: 0 }
  values.unshift(empytValue)
  values.push(empytValue)
}

function padDates (dates, frequency) {
  const max = maxDate(dates)
  const min = minDate(dates)
  dates.unshift(frequencies[frequency].padFunction(min, -1))
  dates.push(frequencies[frequency].padFunction(max, 1))
}

export default function HemolysisRejectionBarPlot ({ data, analyte = 'ldh', rejection, show_rejection = true, rejection_threshold = 15, show_rejection_threshold = true, count_threshold = 25, frequency = 'daily', yRange = null }) {
  const values = data.map((measurement) => { return { count: measurement.count, rejection: measurement.rejection[analyte] } })
  padValues(values)

  const dates = data.map((measurement) => parseISO(measurement.date))
  if (dates.length > 0) padDates(dates, frequency)

  const text = dates.map((date) => format(date, frequencies[frequency].format))
  const colors = values.map((value) => getColor(value, count_threshold, rejection_threshold))

  const traces = [{
    type: 'bar',
    x: dates,
    y: values.map((value) => value.rejection),
    offset: frequency !== 'yearly' && dates.length > 1 && 1,
    hovertext: text,
    hovertemplate: frequencies[frequency].template,
    marker: {
      color: colors,
      width: 24
    },
    name: ''
  }, {
    type: 'scatter',
    visible: show_rejection,
    x: dates,
    y: new Array(dates.length).fill(rejection),
    mode: 'lines',
    line: {
      color: '#4FC1BE',
      width: 1,
      dash: 'dash'
    },
    name: '',
    hovertemplate: `Rejection Probability: ${rejection}%`
  }, {
    type: 'scatter',
    visible: show_rejection_threshold && rejection_threshold,
    x: dates,
    y: new Array(dates.length).fill(rejection_threshold),
    mode: 'lines',
    line: {
      color: '#CA7A85',
      width: 1,
      dash: 'dash'
    },
    name: '',
    hovertemplate: `Threshold: ${rejection_threshold}%`
  }]

  const xaxis = frequency !== 'yearly'
    ? {
        showgrid: false,
        ticklabelmode: 'period',
        ticklabelposition: 'inside left',
        tickvals: dates.length <= 1 ? dates : null,
        tickformatstops: [
          {
            dtickrange: [null, 604800000],
            value: '%d %b'
          },
          {
            dtickrange: [604800000, 'M1'],
            value: '%b'
          },
          {
            dtickrange: ['M1', 'M12'],
            dticks: 1,
            value: '%b %Y'
          },
          {
            dtickrange: ['M12', null],
            value: '%Y'
          }
        ]
      }
    : {
        showgrid: false,
        tickformat: '%Y',
        tickvals: text
      }

  const layout = {
    hovermode: 'closest',
    showlegend: false,
    margin: {
      l: 25,
      t: 0,
      b: 50,
      r: 25
    },
    bargap: 0.1,
    xaxis: xaxis,
    yaxis: {
      range: yRange && [yRange[0], Math.max(yRange[1], (show_rejection_threshold && rejection_threshold), (show_rejection && rejection)) + 3]
    }
  }

  return (
    <Plot
      data={traces}
      layout={layout}
      useResizeHandler={true}
      style={{ width: 'inherit', height: 150 }}
      config={{ responsive: true, displaylogo: false }}
    />
  )
}
