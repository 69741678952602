import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip
} from '@mui/material'
import { Labels, Loader, Modal, StyledLink } from 'components'
import DeleteIcon from '@mui/icons-material/Delete'
import { useHistory } from 'react-router-dom'
import { api, apiConfig } from 'services'
import { ClinicalDataForm, DeleteClinicalDataForm } from 'forms'
import { parseJSON, parseISO, format } from 'date-fns'

export default function TableView ({ url, setUrl, refreshState = false, setRefreshState }) {
  const history = useHistory()

  const { data: clinicalData } = useQuery(['clinical-data-table', apiConfig.currentApiInstitute, { refreshState, setRefreshState, url }], () => {
    const params = new URLSearchParams(url.search)
    if (!params.has('ordering')) {
      params.set('ordering', '-date')
    }
    return api.clinicalData.list(params).then((data) => {
      history.replace({ pathname: window.location.pathname, search: url.search })
      setRefreshState(false)
      return data
    })
  })
  const lineLabel = apiConfig.currentApiInstituteType === 'courier' ? 'Route' : 'PTS Line'
  const urlParams = new URLSearchParams(url.search)
  const [order, setOrder] = useState(
    urlParams.has('ordering') && urlParams.get('ordering').startsWith('-') ? 'asc' : 'desc'
  )
  const [orderBy, setOrderBy] = useState(urlParams.has('ordering') ? urlParams.get('ordering') : 'date')
  const handleSorting = (event, property) => {
    const isAsc = order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    urlParams.delete('ordering')
    const direction = order === 'asc' ? '-' : ''
    urlParams.set('ordering', direction + property)
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const handlePageChange = (event, page) => {
    urlParams.set('page', page)
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  return clinicalData
    ? (
    <TableContainer>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'date')}
                active={orderBy === 'date'}
                direction={order}
              >
                {'Date'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'record__date')}
                active={orderBy === 'record__date'}
                direction={order}
              >
                {'Record'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'record__line__name')}
                active={orderBy === 'record__line__name'}
                direction={order}
              >
                {lineLabel}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'h_index')}
                active={orderBy === 'h_index'}
                direction={order}
              >
                {'HI'}
              </TableSortLabel>
            </TableCell>

            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'ldh')}
                active={orderBy === 'ldh'}
                direction={order}
              >
                {'LDH (U/L)'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'asat')}
                active={orderBy === 'asat'}
                direction={order}
              >
                {'ASAT (U/L)'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'potassium')}
                active={orderBy === 'potassium'}
                direction={order}
              >
                {'K (mmol/L)'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={(event) => handleSorting(event, 'pfhb')}
                active={orderBy === 'pfhb'}
                direction={order}
              >
                {'PFHb (mg/dL)'}
              </TableSortLabel>
            </TableCell>
            <TableCell>{'Labels'}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clinicalData.items.map((sample, index) => {
            return (
              <TableRow key={`clinical-data-${sample.id}-${index}`}>
                <TableCell>
                  <Modal
                    button={
                      <Tooltip title="Edit Data">
                        <Button size="small" color="secondary">{format(parseISO(sample.date), 'dd MMM yyyy')}</Button>
                      </Tooltip>
                    }
                    label={'Update Clinical Data'}
                  >
                    <ClinicalDataForm id={sample.id} exitFunction={() => { setRefreshState(true) }} />
                  </Modal>
                </TableCell>
                <TableCell>
                  {sample.record
                    ? (
                    <StyledLink
                      to={{
                        pathname: `/record/${sample.record.id}`,
                        state: { name: 'Clinical Data' }
                      }}
                    >
                      {format(parseJSON(sample.record.date), 'dd MMM yyyy HH:mm')}
                    </StyledLink>
                      )
                    : (
                        'No Record Assigned'
                      )}
                </TableCell>
                <TableCell>
                  {sample.record && sample.record.line
                    ? (
                    <StyledLink
                      to={{
                        pathname: `/line/${sample.record.line.id}`,
                        state: {
                          name: 'Clinical Data',
                          referrer: {
                            path: '/clinical-data'
                          }
                        }
                      }}
                    >
                      {sample.record.line.name}
                    </StyledLink>
                      )
                    : (
                        'No Line'
                      )}
                </TableCell>
                <TableCell>{sample.h_index}</TableCell>
                <TableCell>{sample.ldh}</TableCell>
                <TableCell>{sample.asat}</TableCell>
                <TableCell>{sample.potassium}</TableCell>
                <TableCell>{sample.pfhb}</TableCell>
                <TableCell>
                  <Labels
                    shownLabels={sample.labels && sample.labels.slice(0, 2)}
                    hiddenLabels={sample.labels && sample.labels.slice(2)}
                    display="flex"
                    flexDirection="row"
                    flexGrow={1}
                    justifyContent="flex-start"
                  />
                </TableCell>
                <TableCell>
                  <Modal
                    button={
                      <Tooltip title={'Delete'}>
                        <Button color="error" size="small">
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    }
                    label={'Confirmation Required'}
                    showCloseIcon={false}
                  >
                    <DeleteClinicalDataForm id={sample.id} exitFunction={setRefreshState} />
                  </Modal>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={12}
              count={clinicalData ? clinicalData.total : 0}
              rowsPerPage={10}
              page={clinicalData ? clinicalData.page : 0}
              onPageChange={handlePageChange}
              backIconButtonProps={{
                'aria-label': 'previous page'
              }}
              nextIconButtonProps={{
                'aria-label': 'next page'
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
      )
    : (
    <Loader />
      )
}
