import { DatePicker } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import chroma from 'chroma-js'
import { DropDownSelect } from 'components'
import { format, parseISO, parseJSON } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js'
import { useInfiniteQuery, useQueries, useQuery } from 'react-query'
import { useParams } from 'react-router'

import FilterListIcon from '@mui/icons-material/FilterList'

import {
  BlurWrapper,
  InlineError,
  Labels,
  Loader,
  MainContent,
  Sidebar
} from 'components'
import { axisFont } from 'helpers'
import {
  Flag,
  api,
  apiConfig,
  useFeaturesContext,
  useMetricsContext
} from 'services'

function AddPlot({ acceleration, cumulative, lineName = 'Line' }) {
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')
  const dataNum = acceleration.length

  const getDomains = (dataNum) => {
    const domains = []
    for (let i = 0; i < dataNum; i++) {
      const x = 1 - (i + 1) / dataNum
      const y = 1 - i / dataNum

      // round to 2 decimal places
      domains.push([
        Math.round(x * 100) / 100 + 0.01,
        Math.round(y * 100) / 100
      ])
    }

    return domains
  }
  let domains = []
  for (let i = 1; i < dataNum + 1; i++) {
    domains.push(getDomains(i + 1))
  }

  const layout = {
    margin: {
      l: 100,
      r: 30,
      b: 50,
      t: 10,
      pad: 10
    },
    autoSize: true,
    xaxis: {
      zeroline: false,
      title: {
        text: "<b style='font-weight:500 !important'>Time (s)</b>",
        font: axisFont
      },
      anchor: `y${dataNum + 1}`
    },
    yaxis: {
      title: {
        text: "<b style='font-weight:500 !important'>VitalMetric</b>",
        font: axisFont
      },
      domain: domains[dataNum - 1][0]
    },
    annotations: [
      ...acceleration.map((acceleration, index) => {
        return {
          text: acceleration.name,
          font: {
            ...axisFont,
            size: 13
          },
          x: 1,
          y:
            (domains[dataNum - 1][index + 1][1] +
              domains[dataNum - 1][index][0]) /
            2,
          xshift: -10,
          showarrow: false,
          xanchor: 'right',
          yanchor: 'top',
          xref: 'paper',
          yref: 'paper',
          showlegend: false
        }
      }),
      {
        text: 'Acceleration (g)',
        font: axisFont,
        x: 0,
        y:
          (domains[dataNum - 1][1][1] - domains[dataNum - 1].slice(-1)[0][0]) /
          2,
        xshift: -75,
        xanchor: 'middle',
        yanchor: 'middle',
        xref: 'paper',
        yref: 'paper',
        textangle: 270,
        showarrow: false
      },
      {
        text: lineName.toUpperCase(),
        font: {
          ...axisFont,
          size: 16
        },
        x: 0,
        y: 1,
        yshift: -10,
        xanchor: 'middle',
        yanchor: 'middle',
        xref: 'paper',
        yref: 'paper',
        showarrow: false
      }
    ],
    showlegend: false
  }
  const maxAccelerations = acceleration.map(
    (data, index) => data.maximum_acceleration
  )
  const accelerationTrace = acceleration.map((data, index) => {
    data.yaxis = `y${index + 2}`
    layout[`yaxis${index + 2}`] = {
      domain: domains[dataNum - 1][index + 1],
      range: [0, Math.max(...maxAccelerations)],
      showlegend: true
    }

    return data
  })

  const data = cumulative
  data.push(...accelerationTrace)

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="end">
        <Box>
          <DropDownSelect
            labelId="image-format-select"
            label="Image Format"
            value={downloadImageFormat}
            onValueChange={(newValue) => {
              setDownloadImageFormat(newValue)
            }}
            items={[
              {
                content: <Typography variant="h7">PNG</Typography>,
                value: 'png'
              },
              {
                content: <Typography variant="h7">SVG</Typography>,
                value: 'svg'
              }
            ]}
          />
        </Box>
      </Box>
      <Plot
        layout={layout}
        data={data}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        config={{
          responsive: true,
          toImageButtonOptions: { format: downloadImageFormat }
        }}
      />
    </>
  )
}

const RecordsPageTable = ({
  page,
  recordsSelected,
  metricType,
  onChange,
  overrideColor = null
}) => {
  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="records-page-table">
          <TableHead>
            <TableRow>
              <TableCell>Details</TableCell>
              <TableCell>VMetric</TableCell>
              <TableCell>Labels</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.items.map((record) => {
              const recordDate = format(
                parseJSON(record.date),
                'd MMM yy HH:mm'
              )

              const checked = recordsSelected
                .map((record) => record.id)
                .includes(record.id)
              const CustomCheckbox = withStyles({
                root: {
                  color: overrideColor || record.color,
                  '&$checked': {
                    color: overrideColor || record.color
                  }
                },
                checked: {}
              })(Checkbox)

              return (
                <TableRow key={record.id}>
                  <TableCell
                    sx={{
                      minWidth: '100px'
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={checked}
                            value={record.id}
                            onChange={onChange}
                            size="small"
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: '14px'
                            }}
                          >
                            {recordDate}
                          </p>
                        }
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>{record.metrics[metricType]}</TableCell>
                  <TableCell>
                    <Labels
                      shownLabels={record.labels && record.labels.slice(0, 2)}
                      hiddenLabels={record.labels && record.labels.slice(2)}
                      display="flex"
                      flexDirection="column"
                      flexGrow={1}
                      justifyContent="flex-start"
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default function RecordCompare() {
  const contentEl = useRef(null)
  const { id } = useParams()
  const { metricType, metricLabel } = useMetricsContext()

  const [url, setUrl] = useState(new URL(window.location))
  const urlParams = new URLSearchParams(url.search)

  const { data: validationHistory } = useQuery(['validations', id], () => {
    return api.lines.getValidations(id)
  })

  const defaultSelectNo = 2
  const colorsList = useRef(
    Array.from({ length: 999 }, () => {
      const color = chroma.random().darken().hex()
      return color
    })
  )

  const [recordsSelected, setRecordsSelected] = useState([])
  const [availableColors, setAvailableColors] = useState(colorsList.current)

  const { data: lineData, refetch: refreshLineData } = useQuery(
    ['line-data', id],
    () => api.lines.get(id)
  )

  const recordQuery = ({ pageParam = 0 }) => {
    const params = {
      size: 9999,
      page: pageParam,
      line: id,
      rejection: `${metricType}`,
      ordering: '-date',
      exclude: false
    }
    for (const [key, value] of url.searchParams.entries()) {
      params[key] = value
    }
    return api.records.list(params).then((data) => {
      if (pageParam === 0 && recordsSelected.length === 0) {
        const initialSelected = data.items
          .slice(0, defaultSelectNo)
          .map((item, index) => {
            item.color = availableColors[index]
            return item
          })
        setRecordsSelected(initialSelected)
        setAvailableColors(
          availableColors.slice(defaultSelectNo, availableColors.length)
        )
      }
      return data
    })
  }

  const [allRecords, setAllRecords] = useState([])
  const {
    data: lineRecordData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    [
      'line-record-data',
      apiConfig.currentApiInstitute,
      { id, url, metricType }
    ],
    recordQuery,
    {
      cacheTime: 0,
      getPreviousPageParam: (firstPage) => firstPage.previous,
      getNextPageParam: (lastPage) => lastPage.next
    }
  )

  useEffect(() => {
    const onScroll = async () => {
      if (!isFetchingNextPage && hasNextPage) {
        if (
          contentEl.current &&
          contentEl.current.offsetHeight >=
            contentEl.current.scrollHeight - contentEl.current.scrollTop
        ) {
          await fetchNextPage()
        }
      }
    }
    contentEl.current.addEventListener('scroll', onScroll)
    return () =>
      contentEl.current !== null &&
      contentEl.current.removeEventListener('scroll', onScroll)
  }, [contentEl, hasNextPage, isFetchingNextPage])

  useEffect(() => {
    setAllRecords(lineRecordData?.pages.map((page) => page.items).flat())
  }, [lineRecordData])

  const accelerationData = useQueries(
    recordsSelected.map((item, index) => {
      return {
        queryKey: ['record-accel', item.id],
        queryFn: () =>
          api.records.acceleration(item.id).then((data) => {
            return {
              x: data.acceleration_time,
              y: data.acceleration,
              name: format(parseJSON(item.date), 'd MMM yyyy HH:mm'),
              marker: {
                color: item.color
              },
              text: data.acceleration_time.map(
                (time, index) =>
                  `${data.acceleration[index].toFixed(2)}g at ${time.toFixed(
                    2
                  )} seconds`
              ),
              hoverinfo: 'text',
              maximum_acceleration: data.maximum_acceleration
            }
          })
      }
    }) ?? []
  )

  const cumulativeData = useQueries(
    recordsSelected.map((item, index) => {
      return {
        queryKey: ['cumulative', item.id, metricType],
        queryFn: () =>
          api.records
            .cumulative(item.id, { metric: metricType })
            .then((data) => {
              return {
                x: data.time,
                y: data.cumulative_metric,
                name: format(parseJSON(item.date), 'd MMM yyyy HH:mm'),
                marker: {
                  color: item.color
                },
                text: data.time.map(
                  (time, index) =>
                    `${data.cumulative_metric[index].toFixed(
                      2
                    )} at ${time.toFixed(2)} seconds`
                ),
                hoverinfo: 'text'
              }
            })
      }
    }) ?? []
  )

  const handleRecordSelectChange = (event) => {
    const selectedRecordId = parseInt(event.target.value)
    const alreadySelected = recordsSelected
      .map((item) => item.id)
      .includes(selectedRecordId)
    const selectedRecord = allRecords.filter(
      (record) => record.id === selectedRecordId
    )[0]

    if (selectedRecord) {
      if (event.target.checked && !alreadySelected) {
        selectedRecord.color = availableColors[0]
        availableColors.shift()
        setRecordsSelected((recordsSelected) => [
          ...recordsSelected,
          selectedRecord
        ])
      } else {
        setRecordsSelected(
          recordsSelected.filter((item) => item.id !== selectedRecordId)
        )
        setAvailableColors((availableColors) => [
          ...availableColors,
          selectedRecord.color
        ])
      }
    }
  }

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [blurInfo, setBlurInfo] = useState(false)

  const { flags, overrides, getFlag } = useFeaturesContext()

  const [selectedStartDate, setSelectedStartDate] = useState(function () {
    if (urlParams.has('upload_date__gte')) {
      return urlParams.get('upload_date__gte')
    } else if (urlParams.has('date__gte')) {
      return urlParams.get('date__gte')
    } else {
      return null
    }
  })

  const resetRecordSelect = () => {
    setRecordsSelected([])
    setAvailableColors(colorsList.current)
  }

  const updateStartDate = (date) => {
    resetRecordSelect()
    setSelectedStartDate(date)
    if (date === null) {
      urlParams.delete('date__gte')
    } else {
      urlParams.delete('date__gte')
      const dateFormatted = format(date, 'yyyy-MM-dd')
      urlParams.append('date__gte', dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }
  const handleStartDateChange = (date) => {
    updateStartDate(date)
    setFilterValidation('')
  }

  const [selectedEndDate, setSelectedEndDate] = useState(function () {
    if (urlParams.has('upload_date__lte')) {
      return urlParams.get('upload_date__lte')
    } else if (urlParams.has('date__lte')) {
      return urlParams.get('date__lte')
    } else {
      return null
    }
  })
  const handleEndDateChange = (date) => {
    resetRecordSelect()
    setSelectedEndDate(date)
    if (date == null) {
      urlParams.delete('date__lte')
    } else {
      urlParams.delete('date__lte')
      const dateFormatted = format(date, 'yyyy-MM-dd')
      urlParams.append('date__lte', dateFormatted)
    }
    setUrl(new URL(`${url.origin}${url.pathname}?${urlParams.toString()}`))
  }

  const [filterValidation, setFilterValidation] = useState('')
  const handleValidationChange = (event) => {
    setFilterValidation(event.target.value)
    if (event.target.value === '') {
      updateStartDate(null)
    } else {
      updateStartDate(parseISO(event.target.value))
    }
  }

  const handleClearFilters = () => {
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setFilterValidation('')
    resetRecordSelect()
    setUrl(new URL(`${url.origin}${url.pathname}`))
  }

  useEffect(() => {
    if (getFlag) {
      setBlurInfo(!getFlag('troubleshooting'))
    }
  }, [flags, overrides, getFlag])

  return (
    <>
      <Sidebar ref={contentEl}>
        <Box display="flex" flexDirection="column" m={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1">{'Records'}</Typography>
            <Button
              color="secondary"
              startIcon={<FilterListIcon />}
              size="small"
              onClick={toggleFilters}
            >
              {'Search & Filter Records'}
            </Button>
          </Box>
          <Collapse in={showFilters}>
            <Box display="flex" flexDirection="column">
              <Flag name="validation">
                <FormControl variant="outlined" size="small">
                  <Select
                    name="validation"
                    labelId="validation-input-label"
                    id="validation"
                    value={filterValidation}
                    onChange={handleValidationChange}
                    displayEmpty
                    placeholder={'Choose Validation to Start From'}
                  >
                    <MenuItem value="">
                      <em>{'Choose Validation to start from'}</em>
                    </MenuItem>
                    {validationHistory &&
                      validationHistory.map((validation, index) => {
                        return (
                          <MenuItem
                            key={`validation-${validation.validation_id}`}
                            value={validation.validation_start_date}
                          >
                            {validation.validation_name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Flag>
              <Box display="flex" flexDirection="row" mt={1}>
                <DatePicker
                  label={'Start'}
                  id="upload-date-start"
                  name="date-start"
                  value={selectedStartDate}
                  disableMaskedInput={true}
                  inputFormat="dd MMM yyyy"
                  style={{ maxWidth: '180px' }}
                  disableFuture={true}
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography
                    display="inline"
                    style={{
                      fontSize: '12px',
                      color: '#6b6b6b',
                      fontWeight: 700,
                      margin: 7,
                      verticalAlign: 'sub',
                      width: 33
                    }}
                  >
                    {'– to –'}
                  </Typography>
                </Box>
                <DatePicker
                  label={'End'}
                  id="upload-date-end"
                  name="date-end"
                  value={selectedEndDate}
                  disableMaskedInput={true}
                  inputFormat="dd MMM yyyy"
                  onChange={handleEndDateChange}
                  style={{ maxWidth: '180px' }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  style={{ padding: 0 }}
                  color="secondary"
                  size="small"
                  onClick={handleClearFilters}
                >
                  {'Clear Filters'}
                </Button>
              </Box>
            </Box>
          </Collapse>
          <FormHelperText component="div">
            {'Select records to view and compare details.'}
          </FormHelperText>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {lineRecordData &&
                lineRecordData.pages.map((page, index) => {
                  return (
                    <BlurWrapper
                      key={index}
                      doBlur={blurInfo}
                      showCTAComponent={false}
                      blurLevel={40}
                    >
                      <RecordsPageTable
                        key={page.page}
                        page={page}
                        recordsSelected={recordsSelected}
                        metricType={metricType}
                        onChange={handleRecordSelectChange}
                      />
                    </BlurWrapper>
                  )
                })}
            </>
          )}
          {isFetchingNextPage && (
            <Box
              p={5}
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <CircularProgress size={30} />
              <Typography color="primary">{'Loading more records'}</Typography>
            </Box>
          )}
        </Box>
      </Sidebar>
      <MainContent sidebar="right">
        <BlurWrapper doBlur={blurInfo} showCTAComponent={true} blurLevel={40}>
          <Box sx={{ height: '70vh' }} margin={5}>
            {cumulativeData.length === 0 || accelerationData.length === 0 ? (
              <InlineError title="No Data" detail="Select records to compare" />
            ) : cumulativeData.some((data) => data.isLoading) ||
              accelerationData.some((data) => data.isLoading) ? (
              <Loader />
            ) : (
              <AddPlot
                lineName={lineData?.name || 'Line'}
                acceleration={accelerationData.map((data) => data.data)}
                cumulative={cumulativeData.map((data) => data.data)}
              />
            )}
          </Box>
        </BlurWrapper>
      </MainContent>
    </>
  )
}
