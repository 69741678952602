import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { EntryBox, FlagSwitch } from 'components'
import { instituteRelations } from 'helpers'
import assignIn from 'lodash/assignIn'
import has from 'lodash/has'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Role, api, authenticationService } from 'services'

export default function InstituteForm({
  id,
  exitFunction,
  closeModalFunction
}) {
  const [name, setName] = useState('')
  const [metadataType, setMetadataType] = useState('')
  const [relationship, setRelationship] = useState('')
  const [subscription, setSubscription] = useState('')
  const [partnerId, setPartnerId] = useState('')
  const [totalLines, setTotalLines] = useState('')

  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm()
  const unmounted = useRef(false)

  const [featureFlags, setFeatureFlags] = useState()
  const [defaultFeatureFlags, setDefaultFeatureFlags] = useState()
  const [useDefaults, setUseDefaults] = useState(false)

  const handleFeatureFlagChange = (event) => {
    setFeatureFlags({
      flags: assignIn({}, featureFlags.flags, {
        [event.target.name]: event.target.checked
      })
    })
  }

  const handleUseDefaultsChange = (event) => {
    setUseDefaults(event.target.checked)
    if (event.target.checked) {
      setFeatureFlags(defaultFeatureFlags)
    }
  }

  const handleTotalLinesChange = (event) => {
    setTotalLines(event.target.value)
    setValue('total_lines', event.target.value)
  }

  const handleMetadataTypeChange = (event) => {
    setMetadataType(event.target.value)
    setValue('metadata_type', event.target.value)
  }

  const handleRelationshipChange = (event) => {
    setRelationship(event.target.value)
    setValue('relationship', event.target.value)
  }

  const handleSubscriptionChange = (event) => {
    setSubscription(event.target.value)
    setValue('subscription', event.target.value)
  }

  const handlePartnerIdChange = (event) => {
    setPartnerId(event.target.value)
    setValue('partner_id', event.target.value)
  }

  const handleClose = () => {
    if (closeModalFunction) closeModalFunction()
    if (exitFunction) exitFunction()
  }

  const onSubmit = (data) => {
    if (data.total_lines === '') data.total_lines = null
    if (!isSubmitting) {
      data.feature_flags = !useDefaults ? featureFlags : null
      if (id) {
        api.institutes
          .update(id, data)
          .then(function (data) {
            enqueueSnackbar('Changes to the institute have been saved.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            handleClose()
          })
          .catch(function (status) {
            console.log(status)
          })
      } else {
        api.institutes
          .create(data)
          .then(function (data) {
            authenticationService.refresh()
            enqueueSnackbar('The new intitute has been added.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            if (closeModalFunction) closeModalFunction()
            if (exitFunction) exitFunction()
          })
          .catch(function (status) {
            console.log(status)
          })
      }
    }
  }

  useEffect(() => {
    register('metadata_type')
    register('relationship')
    register('subscription')

    if (id) {
      api.institutes.get(id).then((data) => {
        if (!unmounted.current) {
          setName(data.name)
          setValue('name', data.name)
          setMetadataType(data.metadata_type)
          setValue('metadata_type', data.metadata_type)
          setValue('total_lines', data.total_lines)
          setTotalLines(data.total_lines ? data.total_lines : '')
          setRelationship(data.relationship ? data.relationship : '')
          setValue('relationship', data.relationship ? data.relationship : '')
          setSubscription(data.subscription ? data.subscription : '')
          setValue('subscription', data.subscription ? data.subscription : '')
          setPartnerId(data.partner_id || '')
          setValue('partner_id', data.partner_id || '')
          api.featureFlags.getDefaults().then((defaults) => {
            setDefaultFeatureFlags(defaults)
            const newFlags = defaults.flags
            setUseDefaults(data.feature_flags === null)
            if (data.feature_flags) {
              assignIn(newFlags, defaults.flags, data.feature_flags.flags)
            }
            setFeatureFlags({ flags: newFlags })
          })
        }
      })
    } else {
      setUseDefaults(true)
      api.featureFlags.getDefaults().then((defaults) => {
        setDefaultFeatureFlags(defaults)
        setFeatureFlags(defaults)
      })
    }
  }, [id, register, setValue])

  return (
    <form
      id="insitute_form"
      data-testid="institute-form-test"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box my={2}>
          <EntryBox label="Name">
            <TextField
              name="name"
              id="name"
              {...register('name', { required: true, maxLength: 80 })}
              value={name || ''}
              onChange={(event) => {
                setName(event.target.value)
                setValue('name', event.target.value)
              }}
              error={has(errors, 'name')}
              variant="outlined"
              size="small"
              helperText={
                errors.name
                  ? errors.name.type === 'required'
                    ? 'Name is a required field.'
                    : 'Name is too long.'
                  : ''
              }
              inputProps={{ 'data-testid': 'name-test' }}
              fullWidth
            />
          </EntryBox>
        </Box>
        <Role restrict={['staff']}>
          <Box my={2}>
            <EntryBox label="Metadata Type">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                error={has(errors, 'metadata_type')}
              >
                <Select
                  name="metadata_type"
                  labelId="metadata-input-label"
                  id="metadata-input"
                  value={metadataType}
                  onChange={handleMetadataTypeChange}
                  SelectDisplayProps={{
                    'data-testid': 'metadata-select'
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="pts">{'Pneumatic Tube System'}</MenuItem>
                  <MenuItem value="courier">{'Courier'}</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.metadata_type && errors.metadata_type.message}
                </FormHelperText>
              </FormControl>
            </EntryBox>
          </Box>
          <Box my={2}>
            <EntryBox label="Relationship">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                error={has(errors, 'relationship')}
              >
                <Select
                  name="relationship"
                  labelId="relationship-input-label"
                  id="relationship-input"
                  value={relationship}
                  onChange={handleRelationshipChange}
                >
                  {instituteRelations.map((relation, index) => {
                    return (
                      <MenuItem
                        key={`${relation.name}-${index}`}
                        value={relation.value}
                      >
                        {relation.value === null ? (
                          <em>{relation.name}</em>
                        ) : (
                          relation.name
                        )}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </EntryBox>
          </Box>
          <Box my={2}>
            <EntryBox label="Subscription">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                error={has(errors, 'subscription')}
              >
                <Select
                  name="subscription"
                  labelId="subscription-input-label"
                  id="subscription-input"
                  value={subscription}
                  onChange={handleSubscriptionChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="one_time">{'One Time'}</MenuItem>
                  <MenuItem value="annual">{'Annual'}</MenuItem>
                </Select>
              </FormControl>
            </EntryBox>
          </Box>
        </Role>
        <Box my={2}>
          <EntryBox label="Total Lines">
            <TextField
              name="total_lines"
              size="small"
              id="total_lines"
              {...register('total_lines')}
              fullWidth
              type="number"
              variant="outlined"
              value={totalLines}
              onChange={handleTotalLinesChange}
              inputProps={{
                step: '1',
                'data-testid': 'total-line-test'
              }}
            />
          </EntryBox>
        </Box>
        <Box my={2}>
          <EntryBox label="Partner ID">
            <TextField
              name="partner_id"
              size="small"
              id="partner_id"
              {...register('partner_id', { maxLength: 80 })}
              fullWidth
              type="text"
              variant="outlined"
              value={partnerId}
              onChange={handlePartnerIdChange}
              inputProps={{
                step: '1',
                'data-testid': 'total-line-test'
              }}
            />
          </EntryBox>
        </Box>
        <Role restrict={['staff']}>
          <Box my={2}>
            <Box
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">{'Feature Flags'}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useDefaults}
                    onChange={handleUseDefaultsChange}
                    name="useDefaults"
                    color="primary"
                  />
                }
                label="Use Defaults"
                labelPlacement="start"
              />
            </Box>
            <Collapse in={!useDefaults} data-testid="feature-flags-collapse">
              <Divider style={{ backgroundColor: 'black' }} />
              <FlagSwitch
                value={featureFlags?.flags?.clinicalData}
                title={'Clinical Data'}
                name={'clinicalData'}
                description={
                  'Gives users access to create, view, edit, and delete their own clinical data.'
                }
                testid={'clinical-data-test'}
                handleFlagChange={handleFeatureFlagChange}
              />
              <FlagSwitch
                value={featureFlags?.flags?.monitoring}
                title={'Monitoring'}
                name={'monitoring'}
                description={'Enables Monitoring in the lines detail view.'}
                testid={'monitoring-test'}
                handleFlagChange={handleFeatureFlagChange}
              />
              <FlagSwitch
                value={featureFlags?.flags?.troubleshooting}
                title={'Troubleshooting'}
                name={'troubleshooting'}
                description={
                  'Enables Troubleshooting features, and Performance metrics on the line details view.'
                }
                testid={'troubleshooting-test'}
                handleFlagChange={handleFeatureFlagChange}
              />
              <FlagSwitch
                value={featureFlags?.flags?.validation}
                title={'Validation'}
                name={'validation'}
                description={
                  'Enables the Validation views in the UI allowing the insitute to create validations and assign them to lines and records.'
                }
                testid={'validation-test'}
                handleFlagChange={handleFeatureFlagChange}
              />
              <FlagSwitch
                value={featureFlags?.flags?.hemolysisTracking}
                title={'Rejection Insights'}
                name={'hemolysisTracking'}
                description={
                  'Enables the Rejection Insights tab at the top giving access to upload CSV hemolysis measurments and explore the data.'
                }
                testid={'hemolysis-test'}
                handleFlagChange={handleFeatureFlagChange}
              />
              <FlagSwitch
                value={featureFlags?.flags?.betaMetrics}
                title={'Beta Metrics'}
                name={'betaMetrics'}
                description={
                  'Enables the metric selector at the top to enable uses to select among AUC 225, Power, and Kinetic Energy.'
                }
                testid={'beta-metrics-test'}
                handleFlagChange={handleFeatureFlagChange}
                divider={false}
              />
            </Collapse>
          </Box>
        </Role>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          my={2}
        >
          <Box pr={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={isSubmitting}
            >
              Save Changes
            </Button>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleClose}
          >
            {'Cancel'}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
