import React from 'react'
import { Box, Divider, Switch, Typography } from '@mui/material'

export default function FlagSwitch ({ value, title, description, name, testid, handleFlagChange = () => null, divider = true }) {
  return (
    <>
      <Box flexDirection="row" display="flex" justifyContent="space-between" mb={1}>
        <Box alignSelf="center">
          <Typography variant="caption">{title}</Typography>
          <Typography variant="body2">
            {description}
          </Typography>
        </Box>
        <Box alignSelf="center">
          <Switch
            color="primary"
            checked={value || false}
            onChange={handleFlagChange}
            name={name}
            inputProps={{ 'data-testid': testid }}
          />
        </Box>
      </Box>
      {divider && <Divider />}
    </>
  )
}
