import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Chip from '@mui/material/Chip'

export default function Label ({ label, color, ...rest }) {
  const useStyles = makeStyles({
    root: {
      color: 'white',
      backgroundColor: color,
      width: 'max-content',
      margin: '2px 2px 2px 2px',
      '&:focus': {
        color: 'white',
        backgroundColor: color
      },
      '&:hover': {
        color: 'white',
        backgroundColor: color
      }
    },
    label: {
      fontSize: '14px'
    },
    sizeSmall: {
      height: '20px'
    },
    outlined: {
      color: color,
      backgroundColor: 'white',
      borderColor: color,
      '&:focus': {
        backgroundColor: 'white',
        color: color
      },
      '&:hover': {
        backgroundColor: 'white',
        color: color
      }
    }
  })
  const classes = useStyles()

  return (
    <Chip
      classes={{ root: classes.root, label: classes.label, sizeSmall: classes.sizeSmall, outlined: classes.outlined }}
      label={label}
      {...rest}
    />
  )
}
