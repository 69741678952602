export default (theme) => ({
  logo: {
    borderRadius: 0,
    height: 'inherit',
    backgroundColor: 'transparent',
    '& .MuiAvatar-img': {
      backgroundColor: 'transparent'
    }
  }
})
