import EqualizerIcon from '@mui/icons-material/Equalizer'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Box, Button, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Modal, StepProgress, StyledChip, StyledLink } from 'components'
import { format, parseISO } from 'date-fns'
import { CompleteValidationForm } from 'forms'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { api, hasRole, useFeaturesContext } from 'services'

function LineSummary({ line, days = 1, validationName }) {
  const history = useHistory()
  const total = days * 4
  return (
    <Box
      display="flex"
      flexDirection="row"
      py={1}
      style={{ borderBottom: '1px solid #D6DAE0' }}
    >
      <Box alignSelf="center" mr={1} flexGrow={1}>
        <StyledLink
          to={{
            pathname: `/line/${line.line_id}`,
            state: {
              name: validationName,
              referrer: {
                path: `${window.location.pathname}${window.location.search}`,
                back: {
                  label: history.location.state && history.location.state.name,
                  path:
                    history.location.state &&
                    history.location.state.referrer.path
                }
              }
            }
          }}
        >
          {line.line_name}
        </StyledLink>
      </Box>
      <Box alignSelf="center" mx={1}>
        <StepProgress
          current={line.records}
          total={total}
          multipleColors={days > 1}
        />
      </Box>
      <Box
        fontWeight={600}
        alignSelf="center"
        ml={1}
        minWidth={100}
        width={'max-content'}
        justify="flex-end"
      >{`${line.records}/${total} Records`}</Box>
    </Box>
  )
}

export default function InProgress({ validation, setRefreshState }) {
  const history = useHistory()
  const { data: summary } = useQuery(['summary', validation], () =>
    api.validations.getSummary(validation.id).then((data) => data.summary)
  )
  const { data: recordsRemaining } = useQuery(
    ['records-remaining', validation],
    () =>
      api.validations
        .getSummary(validation.id)
        .then((data) => data.total_records_remaining)
  )
  const { data: outlierRecords } = useQuery(
    ['outlier-records', validation],
    () =>
      api.records
        .list({
          validation__id: validation.id,
          date__lt: validation.start_date
        })
        .then((data) => data.items)
  )
  const completeValidation = () => {
    api.validations
      .completeValidation(validation.id)
      .then((data) => {
        setRefreshState()
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const { flags, overrides } = useFeaturesContext()
  const showBaselineUpdateLabel = () => {
    if (overrides) {
      return validation.baseline && overrides.monitoring
    }

    if (flags && flags.monitoring) {
      return validation.baseline && flags.monitoring
    }

    return validation.baseline
  }

  return (
    <>
      <Box
        pb={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        {validation && (
          <Box>
            <Typography variant="h1">{validation.name}</Typography>
            <Typography variant="subtitle2">{`Start Date:  ${format(
              parseISO(validation.start_date),
              'd MMM yyyy'
            )}`}</Typography>
            <Box display="flex" flexDirection="row">
              {showBaselineUpdateLabel() && (
                <Box pr={1}>
                  <StyledChip
                    size="small"
                    icon={<TrendingUpIcon />}
                    label={'Baseline Update'}
                    variant="outlined"
                  />
                </Box>
              )}
              {validation.generate_report && (
                <Box>
                  <StyledChip
                    size="small"
                    icon={<EqualizerIcon />}
                    label={'Report Requested'}
                    variant="outlined"
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          <Box alignSelf="flex-end">
            <Button
              disabled={hasRole('guest')}
              size="small"
              variant="contained"
              onClick={() => {
                history.push(`/validation/${validation.id}/edit`)
              }}
              data-testid="edit-validation-button"
            >
              Edit Validation
            </Button>
          </Box>
          <Box
            ml={2}
            color={
              validation && validation.status === 'completed'
                ? '#4FC1BE'
                : '#00000088'
            }
            pt={1}
          >
            {validation && (
              <Typography variant="h5" color="inherit">
                {validation.status === 'completed'
                  ? 'Completed'
                  : 'In Progress'}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {outlierRecords && outlierRecords.length > 0 && (
        <Box mb={2}>
          <Alert variant="outlined" severity="info">
            {
              'Some selected records are from before the validation start date. Confirm that these records are part of the validation.'
            }
          </Alert>
        </Box>
      )}
      <Typography variant="subtitle2">
        {validation.duration_days > 1
          ? `Collect ${validation.duration_days * 4} records over ${
              validation.duration_days
            } days for each line.`
          : 'Collect 4 records for each line.'}
      </Typography>
      <Box display="flex" flexDirection="column" pb={5} pt={1}>
        {summary &&
          summary.map((line, index) => {
            return (
              <LineSummary
                key={index}
                line={line}
                days={validation.duration_days}
                validationName={validation.name}
              />
            )
          })}
      </Box>
      {recordsRemaining > 0 && (
        <Box mb={2}>
          <Alert
            variant="outlined"
            severity="info"
          >{`Validation requires ${recordsRemaining} additional Transit Records.`}</Alert>
        </Box>
      )}
      {validation &&
        (recordsRemaining ? (
          <Box>
            {hasRole('guest') ? (
              <Button disabled size="small" variant="contained" color="primary">
                Complete Validation
              </Button>
            ) : (
              <Modal
                button={
                  <Button size="small" variant="contained" color="primary">
                    Complete Validation
                  </Button>
                }
                label={'Complete Validation'}
              >
                <CompleteValidationForm
                  id={validation.id}
                  message={`Validation requires ${recordsRemaining} additional Transit Records.`}
                  exitFunction={setRefreshState}
                />
              </Modal>
            )}
          </Box>
        ) : (
          <Box>
            <Button
              onClick={completeValidation}
              size="small"
              variant="contained"
              color="primary"
            >
              Complete Validation
            </Button>
          </Box>
        ))}
    </>
  )
}
