import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import Label from 'components/Label'
import Paper from '@mui/material/Paper'

export default function Labels ({ shownLabels = [], hiddenLabels = [], hiddenColor = '#000', ...rest }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleHover = (value) => {
    setAnchorEl(value)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'hidden-labels-popper' : undefined
  return (
    <Box {...rest}>
      {shownLabels && shownLabels.map((label, index) => (
        <Label key={`line-label-${label.id}-${index}`} label={label.label} color={label.color} size="small" />
      ))}
      {hiddenLabels && hiddenLabels.length > 0 && (
        <>
          <Label
            label={`+${hiddenLabels.length}`}
            color={hiddenColor}
            size="small"
            aria-describedby={id}
            onMouseEnter={(event) => handleHover(event.currentTarget)}
            onMouseLeave={(event) => handleHover(null)}
          />
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Paper style={{ padding: '5px', borderRadius: '19px' }} elevation={5}>
              <Box display="flex" flexDirection="column">
                {hiddenLabels.map((label, index) => (
                  <Box style={{ padding: '3px 0px' }} key={`line-label-${label.id}-${index}`}>
                    <Label label={label.label} color={label.color} size="small" />
                  </Box>
                ))}
              </Box>
            </Paper>
          </Popper>
        </>
      )}
    </Box>
  )
}
