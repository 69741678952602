import { Box, CircularProgress, Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

// Component styles
import styles from './styles'

const useStyles = makeStyles(styles)
export default function Loader({
  title = 'Loading',
  message = 'Please be patient, large data sets take time.'
}) {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m={10}
    >
      <CircularProgress
        size={60}
        color="primary"
        thickness={4}
        className={classes.spinner}
      />
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
      <Typography variant="h5" color="primary">
        {message}
      </Typography>
    </Box>
  )
}
