import { createContext, useContext } from 'react'

export const MetricsContext = createContext({
  currentMetricConfig: null,
  metricType: null,
  setMetricType: () => null,
  metricLabel: null,
  setMetricLabel: () => null
})

export const useMetricsContext = () => useContext(MetricsContext)

export const MetricsProvider = MetricsContext.Provider
