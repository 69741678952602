import { QUERIES } from 'helpers'
import { useQuery } from 'react-query'
import { api } from 'services'

export const useGetAnalyzer = ({ onSuccess, onError, instituteId }) => {
  return useQuery({
    queryKey: [QUERIES.GET_ANALYZER, instituteId],
    queryFn: async () => await api.analyzer.get(instituteId),
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    },
    enabled: !!instituteId
  })
}
export const useGetAnalyzerMakes = ({ onSuccess, onError }) => {
  return useQuery({
    queryKey: [QUERIES.GET_ANALYZER_MAKES],
    queryFn: async () => await api.types.analyzers(),
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}
