import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import withStyles from '@mui/styles/withStyles'
import { InfoBox } from 'components'
import { openDocsPage } from 'helpers'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const RejectionTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 'none',
    backgroundColor: 'rgba(0,0,0,.8)'
  }
}))(Tooltip)

const RejectionButton = withStyles((theme) => ({
  root: {
    lineHeight: 1,
    color: '#6B6B6B',
    '&:hover': {
      backgroundColor: '#6B6B6B20'
    }
  }
}))(Button)

export default function RejectionInfo () {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <RejectionTooltip
        open={open}
        onClose={handleClose}
        interactive
        placement="top"
        title={
          <InfoBox onLearnMoreClick={() => openDocsPage('/insights/rejection-probability')}>
            <Box width={'max-content'} fontWeight={500} fontSize={'14px'}>
              <Box>{'Motryx uses the following categories for rejection probabilities:'}</Box>
              <Box>{'- Below 3% is a low probability of sample rejection'}</Box>
              <Box>{'- Between 3% and 15% is an acceptable probability of sample rejection'}</Box>
              <Box>{'- Greater than 15% is a substantial probability of sample rejection'}</Box>
            </Box>
          </InfoBox>
        }
      >
        <RejectionButton onClick={handleOpen} size="small" endIcon={<InfoOutlinedIcon />}>
          {'Rejection Probability'}
        </RejectionButton>
      </RejectionTooltip>
    </ClickAwayListener>
  )
}
