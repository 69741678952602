import Chip from '@mui/material/Chip'
import withStyles from '@mui/styles/withStyles'

const styles = (theme) => ({
  root: {
    margin: 0
  }
})

const StyledChip = withStyles(styles)(Chip)

export default StyledChip
