export default (theme) => ({
  grid: {
    flex: 1,
    minHeight: '10vh',
    color: theme.palette.primary.main,
    margin: 10,
    padding: 10
  },
  spinner: {
    marginBottom: 40
  }
})
