import { Add, CompareArrows } from '@mui/icons-material'
import { Box, Fade, IconButton, Tooltip, Typography } from '@mui/material'
import { MainContent, Modal, StyledTab, StyledTabs, TabPanel } from 'components'
import { LineForm } from 'forms'
import { QUERIES } from 'helpers'
import React from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import GraphView from './GraphView'
import TableView from './TableView'

const InstituteLines = (props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [view, setView] = React.useState('tableView')
  const [graphViewMetric, setGraphViewMetric] =
    React.useState('vitalMetricView')
  const handleViewChange = (event, newValue) => {
    setView(newValue)
  }
  const handleGraphViewMetricChange = (event, newValue) => {
    setGraphViewMetric(newValue)
  }
  return (
    <MainContent>
      <Box
        sx={{
          paddingY: 1,
          paddingX: 4
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Typography flexGrow={1} variant={'h1'}>
            PTS Lines
          </Typography>
          <Box display={'flex'} gap={1}>
            <Tooltip title={'Compare Lines'}>
              <IconButton
                variant={'primary'}
                onClick={() => {
                  history.push({
                    pathname: 'line/compare'
                  })
                }}
              >
                <CompareArrows
                  sx={{
                    width: '1.5em',
                    height: '1.5em'
                  }}
                />
              </IconButton>
            </Tooltip>

            <Modal
              maxWidth="sm"
              button={
                <Tooltip title={'Add new line'}>
                  <IconButton variant={'primary'}>
                    <Add
                      sx={{
                        width: '1.5em',
                        height: '1.5em'
                      }}
                    />
                  </IconButton>
                </Tooltip>
              }
              label={`Add New line`}
            >
              <LineForm
                exitFunction={async () => {
                  await queryClient.invalidateQueries([QUERIES.GET_ALL_LINES])
                }}
              />
            </Modal>
          </Box>
        </Box>
        <Box display={'flex'} gap={1}>
          <StyledTabs value={view} onChange={handleViewChange} centered>
            <StyledTab label={'Table View'} value={'tableView'} />
            <StyledTab label={'Graph View'} value={'graphView'} />
          </StyledTabs>

          <Fade in={view === 'graphView'} timeout={500}>
            <StyledTabs
              value={graphViewMetric}
              onChange={handleGraphViewMetricChange}
            >
              <StyledTab label={'VitalMetric'} value={'vitalMetricView'} />
              <StyledTab label={'Temperature'} value={'temperatureView'} />
              <StyledTab label={'Rejection'} value={'rejectionView'} />
            </StyledTabs>
          </Fade>
        </Box>
        <Box>
          <TabPanel value={view} index={'tableView'}>
            <TableView />
          </TabPanel>
          <TabPanel value={view} index={'graphView'}>
            <GraphView metric={graphViewMetric} />
          </TabPanel>
        </Box>
      </Box>
    </MainContent>
  )
}

export default InstituteLines
