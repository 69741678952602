import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { BackLink, MainContent } from 'components'
import { useHistory } from 'react-router-dom'
import { AccountForm } from 'forms'

export default function Account () {
  const history = useHistory()

  return (
    <MainContent>
      <Box p={2}>
        <Container maxWidth="sm">
          <BackLink noBorder px={0} />
          <Box pb={2}>
            <Typography variant="h1">
              {'Account Settings'}
            </Typography>
          </Box>
          <AccountForm
            exitFunction={() => {
              if (history) {
                history.goBack()
              } else {
                history.push('/dashboard')
              }
            }}
          />
        </Container>
      </Box>
    </MainContent>
  )
}
