import Tab from '@mui/material/Tab'
import { withStyles } from '@mui/styles'

const styles = (theme) => ({
  root: {
    padding: '7px 12px',
    fontSize: '18px',
    minHeight: 'fit-content',
    fontWeight: 500,
    '&.Mui-selected': {
      padding: '7px 12px',
      zIndex: 2,
      color: 'white',
      fontWeight: 600
    }
  },
  wrapper: {
    fontWeight: 500
  }
})

const StyledTab = withStyles(styles)(Tab)

export default StyledTab
