import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { ColorPicker, Label } from 'components'
import { api } from 'services'
import { useSnackbar } from 'notistack'

export default function LabelForm ({
  id,
  exitFunction,
  closeModalFunction,
  updateResource = false,
  updateForm = false
}) {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register: labelRegister,
    handleSubmit: labelHandleSubmit,
    setValue: labelSetValue,
    formState: { errors: labelErrors, isSubmitting: labelsSubmitting }
  } = useForm()

  const [previewLabel, setPreviewLabel] = useState('Preview Text')
  const [previewColor, setPreviewColor] = useState('')

  const handleClose = () => {
    if (exitFunction) exitFunction()
    if (closeModalFunction) closeModalFunction()
  }

  const onSubmit = (data) => {
    if (!labelsSubmitting) {
      if (id) {
        api.labels
          .update(id, data)
          .then((data) => {
            enqueueSnackbar('Label edits have been saved.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            handleClose()
          })
          .catch(function (status) {
            console.log(status)
          })
      } else {
        api.labels
          .create(data)
          .then((data) => {
            let message = 'New label has been created.'
            if (updateResource) {
              const newLabels = updateResource.labels.map((label) => label.id)
              newLabels.push(data.id)
              updateResource.api
                .update(updateResource.id, { labels: newLabels })
                .then(data => setTimeout(exitFunction, 100))
                .catch(function (status) {
                  console.log(status)
                })
              message = 'New label has been created and added.'
            } else if (updateForm) {
              setTimeout(updateForm.labels.push(data), 2000)
            }

            enqueueSnackbar(message, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            exitFunction()
            closeModalFunction()
          })
          .catch(function (status) {
            console.log(status)
          })
      }
    }
  }
  useEffect(() => {
    let unmounted = false
    labelRegister('color', { required: true })
    if (id) {
      api.labels.get(id).then((data) => {
        if (!unmounted) {
          labelSetValue('label', data.label)
          setPreviewLabel(data.label)
          labelSetValue('color', data.color)
          setPreviewColor(data.color)
        }
      })
    } else {
      setPreviewColor('#4FC1BE')
      labelSetValue('color', '#4FC1BE')
    }
    return () => {
      unmounted = true
    }
  }, [labelRegister, id, labelSetValue])

  return (
    <form id="label_form" data-testid="label-form-test" onSubmit={labelHandleSubmit(onSubmit)}>
      <Grid container spacing={2} direction="column" style={{ maxWidth: '100%', margin: 0 }}>
        {previewLabel && previewLabel !== '' && (
          <Grid item style={{ maxWidth: 'inherit' }}>
            <Typography variant="h6" noWrap={true}>
              {'Preview'}
            </Typography>
            <Label color={previewColor} label={previewLabel} style={{ maxWidth: 'inherit' }} />
          </Grid>
        )}

        <Grid item xs>
          <Typography variant="h6">{'Label Name'}</Typography>
          <TextField
            {...labelRegister('label', {
              required: 'Label name is required.',
              maxLength: {
                value: 40,
                message: 'Label is too long.' // <p>error message</p>
              }
            })}
            name="label"
            size="small"
            id="label"
            inputProps={{
              'data-testid': 'label-test-field'
            }}
            variant="outlined"
            onChange={(event) => {
              setPreviewLabel(event.target.value !== '' ? event.target.value : 'Preview Text')
              labelSetValue('label', event.target.value)
            }}
            error={labelErrors.hasOwnProperty('label')}
            helperText={labelErrors.label && labelErrors.label.message}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item style={{ maxWidth: 'inherit' }} xs>
          <Typography variant="h6">{'Colour'}</Typography>
          <ColorPicker
            defaultColor={previewColor}
            onChange={(color) => {
              setPreviewColor(color)
              labelSetValue('color', color)
            }}
          />
        </Grid>

        <Grid item style={{ marginTop: 20 }} xs container justifyContent='flex-end'>
          <Box pr={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={labelsSubmitting}
            >
              Save Label
            </Button>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
