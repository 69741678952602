import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Box, Button, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { DataGrid } from '@mui/x-data-grid'
import {
  BarPlot,
  Gauge,
  InfoBox,
  InfoButton,
  Modal,
  RejectionInfo,
  RejectionProbabilityInfo,
  StyledChip,
  StyledDocLink,
  StyledLink,
  StyledTab,
  StyledTabs,
  TabPanel,
  TableRejectionRates,
  ValidationReportStatus
} from 'components'
import { LoadingOverlay, NoRowsOverlay } from 'components/ServerSideDataGrid'
import { GenerateReportForm } from 'forms'
import { formatDate, nextValidationDate } from 'helpers'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Flag, api, useFeaturesContext, useMetricsContext } from 'services'
import ReportStatus from '../../../../components/ReportStatus'

export default function Completed({
  validation,
  refreshValidation = () => null
}) {
  const history = useHistory()
  const { currentMetricConfig, metricType, metricLabel } = useMetricsContext()
  const { isLoading: isLoadingLineStats, data: lineStats } = useQuery(
    ['line-stats', validation],
    () => api.validations.getLines(validation.id)
  )
  const [tab, setTab] = useState(0)
  const [resultsColumns, setResultsColumns] = useState()
  const [generateReport, setGenerateReport] = useState(false)
  const nextDate = nextValidationDate(validation.start_date, 6)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleGenerateReportExit = () => {
    setGenerateReport(true)
    refreshValidation()
  }

  const { flags, overrides } = useFeaturesContext()

  useEffect(() => {
    setResultsColumns([
      { field: 'line_id', hide: true },
      { field: 'auc', hide: true },
      { field: 'auc_255', hide: true },
      { field: 'power', hide: true },
      { field: 'kinetic_energy', hide: true },
      {
        field: 'line_name',
        headerName: 'Line',
        sortable: false,
        width: 180,
        renderCell: (params) => {
          return (
            <StyledLink
              to={{
                pathname: `/line/${params.getValue(params.id, 'line_id')}`,
                state: {
                  name: validation.name,
                  referrer: {
                    path: `${window.location.pathname}${window.location.search}`,
                    back: {
                      label:
                        history.location.state && history.location.state.name,
                      path:
                        history.location.state &&
                        history.location.state.referrer &&
                        history.location.state.referrer.path
                    }
                  }
                }
              }}
            >
              {params.getValue(params.id, 'line_name')}
            </StyledLink>
          )
        }
      },
      {
        field: 'record_count',
        headerName: 'Records',
        sortable: false,
        width: 90
      },
      {
        field: 'mean',
        renderHeader: () => {
          return (
            <Box py={metricType !== currentMetricConfig ? 2 : 0}>
              <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
                {metricLabel}
              </Typography>
            </Box>
          )
        },
        sortable: false,
        width: 110,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return statistics.mean ? statistics.mean : '-'
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'cv',
        headerName: 'Variability',
        sortable: false,
        width: 100,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return statistics.cv !== null ? `${statistics.cv} %` : '-'
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'performance',
        hide: overrides ? !overrides.troubleshooting : !flags.troubleshooting,
        headerName: (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {'Performance'}
            <Box display="flex" pl={1}>
              <InfoButton>
                <InfoBox>
                  {
                    'Performance rating, where higher performance indicates less vibration per meter of PTS line.'
                  }
                </InfoBox>
              </InfoButton>
            </Box>
          </Box>
        ),
        sortable: false,
        flex: 0.6,
        height: 300,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return (
            statistics.performance && (
              <Gauge value={statistics.performance} width={36} />
            )
          )
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'rejection_rates',
        headerName: (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {'Rejection Probability'}
            <Box display="flex" pl={1}>
              <RejectionProbabilityInfo />
            </Box>
          </Box>
        ),
        sortable: false,
        flex: 0.8,
        height: 300,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return statistics && statistics.rejection_rates !== null ? (
            <TableRejectionRates rejectionRates={statistics.rejection_rates} />
          ) : (
            '-'
          )
        }
      }
    ])
  }, [metricType, overrides])

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {validation && <Typography variant="h1">{validation.name}</Typography>}
        <Box ml={2} pt={1}>
          {validation && (
            <Typography
              variant="h6"
              color="primary"
              align="right"
            >{`Completed on ${formatDate(
              validation.completed_at
            )}`}</Typography>
          )}
          <Flag name="monitoring">
            {validation && validation.baseline && (
              <Box display="flex" justifyContent="flex-end" py={'4px'}>
                <StyledChip
                  size="small"
                  icon={<TrendingUpIcon />}
                  label={'Baseline Updated'}
                  variant="outlined"
                />
              </Box>
            )}
          </Flag>
          {validation.baseline && (
            <Typography variant="subtitle2" align="right">
              {`Recommended Next Validation: ${nextDate}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box py={2} display="flex" flexDirection="row">
        <Box fontSize={20} fontWeight={600} flexGrow={1} alignSelf="center">
          Results
        </Box>
        <ReportStatus
          validation={validation}
          exitFunction={refreshValidation}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <StyledTabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            style={{ maxHeight: '39px' }}
          >
            <StyledTab disableRipple label={'Summary'} />
            <StyledTab disableRipple label={'Rejection'} />
          </StyledTabs>
        </Box>
        {tab === 1 && (
          <Box>
            <RejectionInfo />
          </Box>
        )}
      </Box>

      <Box>
        <TabPanel
          value={tab}
          label={`validation-completed-${validation.id}`}
          index={0}
          p={0}
        >
          <Box>
            {resultsColumns && lineStats && (
              <DataGrid
                rows={lineStats}
                getRowId={(row) => row.line_id}
                columns={resultsColumns}
                rowHeight={78}
                hideFooter
                disableColumnMenu
                autoHeight={true}
                loading={isLoadingLineStats}
                components={{
                  LoadingOverlay: () => (
                    <LoadingOverlay text={'Loading Validation Results...'} />
                  ),
                  NoRowsOverlay: () => (
                    <NoRowsOverlay text={'No Validation Results'} />
                  )
                }}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel
          value={tab}
          label={`validation-completed-${validation.id}`}
          index={1}
          p={0}
        >
          {lineStats && (
            <BarPlot
              data={lineStats}
              metric={metricType}
              height={500}
              field={'rejection_rates'}
              variant={'grouped'}
              yLabel={'Rejection Probability (%)'}
              unit={'%'}
              keyLabels={{ ldh: 'LDH', asat: 'AST', potassium: 'K' }}
              keys={['ldh', 'asat', 'potassium']}
              lineTraces={[3, 15]}
              rejectionAnnotations={true}
            />
          )}
        </TabPanel>
      </Box>
      <Box py={tab === 0 ? 2 : 1}>
        <Alert severity="info" variant="outlined">
          {
            'Validation provides a baseline to begin monitoring PTS lines over time. Learn more from the '
          }
          <StyledDocLink to="/monitoring">{'Monitoring Guide'}</StyledDocLink>
          {' and get started on '}
          <StyledLink fontWeight={500} to="/lines">
            PTS Lines
          </StyledLink>
          {'.'}
        </Alert>
      </Box>
    </>
  )
}
