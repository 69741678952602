import TuneIcon from '@mui/icons-material/Tune'
import { Badge, Box, Button, Typography } from '@mui/material'
import { MainContent, StyledTab, StyledTabs } from 'components'
import { useEffect, useState } from 'react'
import { Link, Route, Switch, useParams } from 'react-router-dom'
import { useRecordSummary } from 'services'
import MainView from './MainView'
import ToReview from './ToReview'

export default function Records(props) {
  const { view } = useParams()

  const [showFilters, setShowFilters] = useState(true)
  const [recordNavValue, setRecordNavValue] = useState(
    view === 'to-review' ? 0 : 1
  )

  const [reviewedCount, setReviewedCount] = useState()
  const handleRecordNavChange = (event, newValue) => {
    setRecordNavValue(newValue)
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const queryParamCount = () => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    params.delete('page')
    params.delete('ordering')
    const matches = params.toString().match(/[a-z\d]+=[a-z\d]+/gi)
    const count = matches ? matches.length : 0
    return count
  }

  const { data: recordSummary } = useRecordSummary()

  useEffect(() => {
    if (view === 'to-review') setReviewedCount()
  }, [view])

  return (
    <MainContent>
      <Box px={3} py={4} display="flex" flexDirection="row">
        <Box flexGrow={1}>
          <Typography variant="h1">{'Transit Records'}</Typography>
        </Box>
        <Box
          alignSelf="center"
          display={recordNavValue === 1 ? 'block' : 'none'}
          mr={1}
        >
          <Badge
            badgeContent={queryParamCount()}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              '& .MuiBadge-badge': {
                color: 'white',
                backgroundColor: '#3e8b89'
              }
            }}
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<TuneIcon />}
              onClick={toggleFilters}
            >
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </Badge>
        </Box>
        <Box alignSelf="center">
          <StyledTabs
            value={recordNavValue}
            onChange={handleRecordNavChange}
            indicatorColor="primary"
            style={{ maxHeight: '39px' }}
          >
            <StyledTab
              disableRipple
              label={`To Review (${
                recordSummary ? recordSummary.not_reviewed : '0'
              })`}
              component={Link}
              to="/records/to-review"
            />
            <StyledTab
              disableRipple
              label={`Reviewed (${
                reviewedCount !== undefined
                  ? reviewedCount
                  : recordSummary
                  ? recordSummary.reviewed
                  : '0'
              })`}
              component={Link}
              to="/records"
            />
          </StyledTabs>
        </Box>
      </Box>
      <Box px={4}>
        <Switch>
          <Route
            path="/records/to-review"
            children={<ToReview />}
            setReviewedCount={setReviewedCount}
          />
          <Route
            path="/records/:view?"
            children={
              <MainView
                showFilters={showFilters}
                setReviewedCount={setReviewedCount}
              />
            }
          />
        </Switch>
      </Box>
    </MainContent>
  )
}
