import { Box, Typography } from '@mui/material'
import { DropDownSelect } from 'components'
import {
  addMilliseconds,
  max as dateMax,
  min as dateMin,
  parseJSON
} from 'date-fns'
import { axisFont } from 'helpers'
import { useState } from 'react'
import Plot from 'react-plotly.js'

export default function VibrationPlot({
  acceleration,
  time,
  duration,
  startdate,
  timeFormat = 'seconds',
  yRange = [0, 45],
  showXaxis = true,
  height = '400px',
  margin = { l: 75, r: 75, b: 75, t: 30, pad: 4 },
  yaxisLabel = 'Acceleration (g)',
  plotUnit = 'g',
  color,
  plotId = '',
  onHover
}) {
  let localized_time = time
  let xrange = [localized_time[0], localized_time.slice(-1)[0] * 1.05]
  let marker_text = localized_time.map(
    (time, index) =>
      `${acceleration[index].toFixed(2)}${plotUnit} at ${time.toFixed(
        2
      )} seconds`
  )

  const greater_than_an_hour = duration > 3600

  if (greater_than_an_hour || timeFormat === 'timestamp') {
    const time_padding = xrange[1] - localized_time.slice(-1)[0]

    localized_time = time.map(function (seconds) {
      const new_time = addMilliseconds(parseJSON(startdate), seconds * 1000)
      return new_time
    })

    xrange = [
      dateMin(localized_time),
      addMilliseconds(dateMax(localized_time), time_padding * 1000)
    ]
    marker_text = localized_time.map(
      (time, index) => `${acceleration[index].toFixed(2)}${plotUnit} at ${time}`
    )
  }

  const xaxisLabel = showXaxis
    ? greater_than_an_hour || timeFormat === 'timestamp'
      ? "<b style='font-weight:500 !important'>Time (h:m:s)</b>"
      : "<b style='font-weight:500 !important'>Time (s)</b>"
    : ''

  const data = [
    {
      x: localized_time,
      y: acceleration,
      text: marker_text,
      hoverinfo: 'text',
      mode: 'lines',
      marker: { color: color }
    }
  ]

  const layout = {
    autosize: true,
    showlegend: false,
    margin: margin,
    xaxis: {
      range: xrange,
      zeroline: false,
      title: {
        text: xaxisLabel,
        font: axisFont
      }
    },
    yaxis: {
      title: {
        text: `<b style='font-weight:500 !important'>${yaxisLabel}</b>`,
        font: axisFont
      },
      range: yRange
    }
  }
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'end',
        paddingTop: '10px'
      }}
    >
      <DropDownSelect
        labelId="image-format-select"
        label="Image Format"
        value={downloadImageFormat}
        onValueChange={(newValue) => {
          setDownloadImageFormat(newValue)
        }}
        items={[
          { content: <Typography variant="h7">PNG</Typography>, value: 'png' },
          { content: <Typography variant="h7">SVG</Typography>, value: 'svg' }
        ]}
      />
      <Plot
        divId={plotId}
        data={data}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: height }}
        config={{
          responsive: true,
          displaylogo: false,
          toImageButtonOptions: { format: downloadImageFormat }
        }}
        onHover={onHover}
      />
    </Box>
  )
}
