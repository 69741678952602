import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import has from 'lodash/has'
import { Fragment, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  rules = {},
  disabled = false,
  sx = {},
  ...props
}) => {
  const labelId = `${name}-label`
  return (
    <FormControl {...props} disabled={disabled}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={rules || null}
        render={({ field, fieldState }) => {
          const { onChange, value } = field
          return (
            <Select
              labelId={labelId}
              label={label}
              value={value || ''}
              onChange={onChange}
              sx={sx}
            >
              {children}
            </Select>
          )
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {has(props, 'error') && (
        <FormHelperText error>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

const FormSelectComponent = ({
  name,
  control,
  options,
  getOptionLabel,
  getOptionValue,
  defaultValue,
  label,
  rules = {},
  ...props
}) => {
  const [errors, setErrors] = useState(null)

  return (
    <FormControl {...props} error={!!errors}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        render={({ field, fieldState }) => {
          const { onChange, value } = field
          const { error } = fieldState
          useEffect(() => {
            setErrors(error)
          }, [error])
          return (
            <Fragment>
              <Select
                labelId={`${name}-label`}
                label={label}
                value={value || ''}
                onChange={onChange}
              >
                {options.map((option, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={getOptionValue ? getOptionValue(option) : option}
                    >
                      {getOptionLabel(option) || option}
                    </MenuItem>
                  )
                })}
              </Select>
              {error?.message && (
                <FormHelperText error>{error?.message || ''}</FormHelperText>
              )}
            </Fragment>
          )
        }}
        name={name}
        control={control}
        rules={rules || null}
        defaultValue={defaultValue}
      />
    </FormControl>
  )
}

export default ReactHookFormSelect
export { FormSelectComponent as FormSelect }
