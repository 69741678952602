import { createTheme } from '@mui/material/styles'

const VitalAppTheme = createTheme({
  typography: {
    fontFamily: 'inherit'
  },
  palette: {
    primary: {
      main: '#4FC1BE',
      hover: '#83d3d1'
    },
    secondary: {
      main: '#0B8DDE'
    },
    red: {
      main: '#CA7A85'
    },
    error: {
      main: '#CA7A85'
    },
    success: {
      main: '#4FC1BE'
    },
    greyed: {
      main: '#E1ECEC',
      hover: '#F3FEFF',
      muted: '#C0C0C0'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '20px'
          // boxShadow: 'none'
        },
        textSizeSmall: {
          fontSize: '1rem',
          minWidth: 'fit-content'
        },
        containedSizeSmall: {
          fontSize: '1rem',
          padding: '6px 10px'
        },
        containedPrimary: {
          color: 'white'
        },
        outlined: {
          color: '#4FC1BE',
          borderColor: '#4FC1BE',
          '&:hover': {
            borderColor: '#4FC1BE'
          }
        },
        outlinedSizeSmall: {
          fontSize: '1rem',
          padding: '5px 10px'
        },
        outlinedError: {
          color: '#CA7A85',
          borderColor: '#CA7A85',
          '&:hover': {
            borderColor: '#CA7A85'
          }
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'white'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          margin: '0px !important'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.MuiTypography-body1': {
            fontSize: '16px'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: '#999'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: { color: '#33403F', fontFamily: 'inherit', fontWeight: 500 },
        h1: {
          color: '#33403F',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '33px'
        },
        h2: {
          color: '#33403F',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '24px'
        },
        h5: {
          color: '#33403F',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '23px'
        },
        h6: {
          color: '#33403F',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '22px'
        },
        subtitle1: {
          color: '#33403F',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '19px'
        },
        subtitle2: {
          color: '#6B6B6B',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '1rem'
        },
        body1: {
          color: '#33403F',
          fontFamily: 'inherit',
          fontWeight: 500,
          fontSize: '19px'
        },
        caption: {
          color: '#6B6B6B',
          fontFamily: 'inherit',
          fontWeight: 600,
          fontSize: '14px'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000019'
        },
        colorPrimary: {
          color: '#33403F',
          backgroundColor: '#fff'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 3px 6px #00000019',
          borderRadius: '6px',
          padding: '20px',
          '&.MuiPopover-paper': {
            padding: 0
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: 'auto'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child  .MuiTableCell-body': {
            border: 'none'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '1rem'
        },
        sizeSmall: {
          padding: '5px 10px 5px 10px'
        },
        head: {
          fontSize: '18px',
          fontWeight: 600,
          borderBottom: '2px solid #33403F'
        },
        footer: {
          border: 'none'
        },
        stickyHeader: {
          backgroundColor: 'white'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          flex: 0
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '15px'
        },
        paperFullScreen: {
          backgroundColor: '#f5f7fa'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          foontSize: '30px',
          padding: '0px 16px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '10px 10px',
          overflowY: 'visible'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: 500
        },
        outlinedWarning: {
          fontWeight: 600
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: 'white',
          fontWeight: 500
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          marginRight: '5px',
          marginBottom: '5px',
          fontSize: '16px'
        },
        colorPrimary: {
          color: 'white',
          fontSize: '18px'
        },
        sizeSmall: {
          height: '20px'
        },
        labelSmall: {
          fontSize: '14px'
        }
      }
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        container: {
          '&  .MuiPaper-elevation1': {
            padding: '0px'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#333840',
          fontWeight: 500,
          fontSize: '18px',
          padding: '0px 8px',
          textTransform: 'capitalize',
          minWidth: 'fit-content',
          '@media (min-width: 600px)': {
            minWidth: 'fit-content'
          },
          '&.Mui-selected': {
            color: 'inherit',
            fontWeight: 600
          }
        },
        wrapper: {
          fontWeight: 500
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: '100%'
        },
        flexContainer: {
          height: '100%'
        },
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          height: '5px',
          '& > div': {
            maxWidth: 140,
            width: '100%',
            backgroundColor: '#4FC1BE'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        middle: {
          marginTop: 10,
          marginBottom: 10,
          backgroundColor: '#33403F20'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F7FA'
        },
        adornedStart: {
          paddingLeft: 0
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          color: 'white'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: '#33403F99',
          borderColor: '#4FC1BE',
          textTransform: 'capitalize',
          '&:hover': {
            color: 'white',
            backgroundColor: '#4FC1BE'
          },
          '&.Mui-selected': {
            '&:hover': {
              color: '#4FC1BE',
              backgroundColor: 'white'
            }
          }
        },
        label: {
          fontWeight: 600,
          fontSize: '14px',
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          color: 'black',
          textTransform: 'capitalize',
          borderColor: '#4FC1BE',
          '& .Mui-selected': {
            color: 'white',
            backgroundColor: '#4FC1BE',
            '&:hover': {
              color: 'white',
              backgroundColor: '#4FC1BE'
            }
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '10pt'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '14pt',
          '& a:-webkit-any-link': {
            color: '#0B8DDE'
          }
        },
        li: {
          color: '#0B8DDE',
          '& .MuiTypography-body1': {
            fontSize: 'inherit !important',
            fontWeight: 600
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorDisabled: {
          color: '#00000088'
        },
        fontSizeSmall: {
          fontSize: '1rem'
        },
        fontSizeMedium: {
          fontSize: '1.5rem'
        },
        fontSizeLarge: {
          fontSize: '2rem'
        },
        fontSizeInherit: {
          fontSize: 'inherit'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-dataContainer': {
            minHeight: '100%!important'
          },
          '& .MuiDataGrid-viewport': {
            overflow: 'initial'
          },
          '& .MuiDataGrid-renderingZone': {
            transform: 'none!important'
          },
          '& .MuiDataGrid-columnsContainer': {
            minHeight: '40px !important',
            maxHeight: '40px !important',
            lineHeight: '40px !important',
            borderBottom: '1px solid #33403F'
          },
          '& .MuiDataGrid-window': {
            top: '40px !important'
          },
          '& .MuiDataGrid-columnCell': {
            padding: '0px 8px'
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '18px',
            fontWeight: 600
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            minHeight: '40px',
            padding: 0
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-cell': {
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: 'initial !important',
            whiteSpace: 'normal',
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            minHeight: '35px !important',
            padding: '5px 10px'
          },
          '& .MuiDataGrid-cellCenter': {
            justifyContent: 'center'
          },
          '& .MuiDataGrid-colCell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-footer': {
            justifyContent: 'flex-start'
          },
          '& .MuiDataGrid-row': {
            maxHeight: '140px !important',
            minHeight: '35px !important'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: 'white'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0,0,0,0.08) !important',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.08)'
            }
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
          lineHeight: 'inherit',
          fontWeight: 600,
          fontSize: '1rem',
          paddingTop: '15px'
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            color: 'white',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#4FC1BE'
            }
          }
        },
        {
          props: { variant: 'primary' },
          style: {
            color: '#4FC1BE',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: '#F3FEFF'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: '10%'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          padding: '10px 0px'
        }
      }
    }
  }
})

export default VitalAppTheme
