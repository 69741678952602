import Add from '@mui/icons-material/Add'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Label, Modal } from 'components'
import LabelForm from 'forms/LabelForm'
import { getCumulativeStatistics, getValidationStatistics } from 'helpers'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { Flag, api, apiConfig } from 'services'
export default function InlineRecordForm({
  ids,
  refreshFunction,
  displayMean = true,
  includeLabels = true,
  resetAfterSubmit = false
}) {
  const lineLabel =
    apiConfig.currentApiInstituteType === 'courier' ? 'Route' : 'Line'
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, getValues, formState } = useForm()

  const [line, setLine] = useState('')
  const [helpText, setHelpText] = useState(
    'Assign a line to the record or click exclude.'
  )

  const [validation, setValidation] = useState('')

  const [selectedLabels, setSelectedLabels] = useState([])
  const [addLabelOpen, setAddLabelOpen] = useState(false)
  const [refreshLabels, setRefreshLabels] = useState(false)

  const history = useHistory()

  const handleLineChange = (event) => {
    setLine(event.target.value)
    if (event.target.value === '') {
      setHelpText('Assign a line to the record or click exclude.')
    } else {
      api.lines.get(event.target.value).then((data) => {
        const validation_statistics = getValidationStatistics(data)
        const cumulative_statistics = getCumulativeStatistics(data)
        const stats = validation_statistics || cumulative_statistics
        if (stats) {
          setHelpText(
            displayMean ? (
              <Box
                fontSize={'16px'}
                fontWeight={500}
                display="flex"
                flexDirection="row"
              >
                {'VitalMetric Mean:'}
                <Box pl={1} fontWeight={600}>
                  {stats.auc.mean}
                </Box>
              </Box>
            ) : (
              ''
            )
          )
        } else {
          setHelpText(
            displayMean
              ? `No stats available for ${lineLabel.toLowerCase()}`
              : ''
          )
        }
      })
    }
    setValue('line', event.target.value)
  }

  const handleValidationChange = (event) => {
    setValidation(event.target.value)
    setValue('validation', event.target.value)
  }

  const handleLabelChange = (event, labels) => {
    setSelectedLabels(labels)
    const newLabels = labels.map((label) => label.id)
    setValue('labels', newLabels)
  }

  const resetForm = () => {
    setValue('line', null)
    setValue('labels', null)
    setSelectedLabels([])
    setLine('')
    setHelpText('Assign a line to the record or click exclude.')
  }

  // Form Submission Handling
  const onSubmit = (data) => {
    if (!formState.isSubmitting) {
      let lineName
      const line = data.line
      const exclude = data.exclude === 'true'
      setValue('exclude', false)
      let recordsSavedCount = 0
      let recordsOutOfRangeCount = 0
      ids.forEach((id) => {
        api.records
          .update(id, data)
          .then(function (data) {
            lineName = data.line ? data.line.name : null
            recordsSavedCount++
            if (data.out_of_range.value) {
              recordsOutOfRangeCount++
            }
            if (ids.length === recordsSavedCount) {
              let message
              let snackbarSettings
              if (recordsSavedCount > 0) {
                message = data.exclude
                  ? `${recordsSavedCount} ${
                      recordsSavedCount <= 1 ? 'Record has' : 'Records have'
                    } been excluded.`
                  : `${recordsSavedCount} ${
                      recordsSavedCount <= 1 ? 'Record has' : 'Records have'
                    } been assigned to ${lineName}.`
                snackbarSettings = {
                  variant: exclude ? 'info' : 'success',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                  },
                  persist: false
                }
                enqueueSnackbar(message, snackbarSettings)
              }

              if (recordsOutOfRangeCount > 0) {
                const action = (key) => (
                  <React.Fragment>
                    <Button
                      onClick={() => {
                        closeSnackbar(key)
                        history.push({
                          pathname: `/line/${line}`,
                          state: { name: 'Transit Records ' }
                        })
                      }}
                      color="inherit"
                      size="small"
                    >
                      {'View Line Details'}
                    </Button>
                    <Button
                      onClick={() => closeSnackbar(key)}
                      color="inherit"
                      size="small"
                    >
                      {'Dismiss'}
                    </Button>
                  </React.Fragment>
                )

                message = `${recordsOutOfRangeCount} ${
                  recordsOutOfRangeCount <= 1 ? 'Record' : 'Records'
                } assigned to ${lineName} ${
                  recordsOutOfRangeCount <= 1 ? 'is' : 'are'
                } out of range.`
                snackbarSettings = {
                  variant: 'warning',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                  },
                  persist: true,
                  action: action
                }
                enqueueSnackbar(message, snackbarSettings)
              }
              if (resetAfterSubmit) resetForm()
            }
            refreshFunction(true)
          })
          .catch(function (status) {
            console.log(status)
          })
      })
    }
  }

  useEffect(() => {
    register('labels')
    if (selectedLabels.length > 0) {
      const newLabels = selectedLabels.map((label) => label.id)
      setValue('labels', newLabels)
    }
  }, [register, refreshLabels, selectedLabels, setValue])

  const { data: lineOptions } = useQuery(
    ['record-review-lines', apiConfig.currentApiInstitute],
    () =>
      api.lines.list({ size: 9999, archived: false }).then((data) => data.items)
  )
  const { data: labelOptions } = useQuery(
    ['record-review-labels', apiConfig.currentApiInstitute],
    () => api.labels.list()
  )
  const { data: validationOptions } = useQuery(
    ['record-review-validations', { line }, apiConfig.currentApiInstitute],
    () =>
      api.validations
        .list({
          size: 9999,
          status: 'inprogress',
          line_id: line && line !== '' ? line : null
        })
        .then((data) => {
          const validationIds = data.items.map((validation) => validation.id)
          if (!validationIds.includes(getValues('validation'))) {
            setValue('validation', null)
            setValidation('')
          }
          return data.items
        })
  )

  useEffect(() => {
    register('line')
    register('validation')
  }, [register])

  return (
    <>
      <form
        id={'inline-record-form'}
        data-testid="inline-record-form-test"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="exclude"
          defaultValue={false}
          {...register('exclude')}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box width={300} pr={1}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="line-input-label">{lineLabel}</InputLabel>
              <Select
                name="line"
                labelId="line-input-label"
                label={lineLabel}
                id="line"
                value={line}
                onChange={handleLineChange}
                SelectDisplayProps={{
                  'data-testid': 'line-select'
                }}
                inputProps={{
                  'data-testid': 'line-input'
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {lineOptions &&
                  lineOptions.map((line, index) => {
                    return (
                      <MenuItem key={index} value={line.id}>
                        {line.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              <FormHelperText component="div">{helpText}</FormHelperText>
            </FormControl>
          </Box>
          <Flag name="validation">
            <Box width={350} pr={1}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="line-input-label">{'Validation'}</InputLabel>
                <Select
                  name="validation"
                  labelId="validation-input-label"
                  id="validation"
                  value={validation}
                  label={'Validation'}
                  onChange={handleValidationChange}
                  SelectDisplayProps={{
                    'data-testid': 'validation-select'
                  }}
                  inputProps={{
                    'data-testid': 'validation-input'
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {validationOptions &&
                    validationOptions.map((validation, index) => {
                      return (
                        <MenuItem
                          key={`validation-${index}`}
                          value={validation.id}
                        >
                          {validation.name}
                        </MenuItem>
                      )
                    })}
                </Select>
                {validationOptions && validationOptions.length <= 0 && (
                  <FormHelperText component="div">
                    {
                      'There are no validations in progress for the selected line.'
                    }
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Flag>

          {includeLabels && (
            <Box flexGrow={1} pr={1}>
              {labelOptions && (
                <Autocomplete
                  multiple
                  id="labels"
                  name="labels"
                  size="small"
                  disableClearable={true}
                  limitTags={1}
                  value={selectedLabels}
                  options={labelOptions || []}
                  getOptionLabel={(option) => option.label}
                  onChange={handleLabelChange}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Label
                        key={`tag-${option.id}-${index}`}
                        label={option.label}
                        color={option.color}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Label
                        key={`label-${option.id}`}
                        label={option.label}
                        color={option.color}
                        size="small"
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Labels'}
                      variant="outlined"
                    />
                  )}
                />
              )}
              <Box display="flex" flexDirection="row" pt={1} fontSize={12}>
                <Box flexGrow={1}>
                  <Button
                    style={{ fontSize: 'inherit' }}
                    size="small"
                    color="secondary"
                    startIcon={<Add />}
                    onClick={() => setAddLabelOpen(true)}
                  >
                    {'New Label'}
                  </Button>
                </Box>
                <Box>
                  <Link
                    to={{
                      pathname: '/labels',
                      state: { name: 'Transit Records' }
                    }}
                  >
                    {' '}
                    <Button
                      style={{ fontSize: 'inherit' }}
                      color="secondary"
                      size="small"
                    >
                      Manage Labels
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
          <Box pr={1}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              label="Confirm"
              disabled={ids.length <= 0 || line === ''}
            >
              {ids.length > 0 ? `Confirm (${ids.length})` : 'Confirm'}
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              fullWidth
              label="Exclude"
              variant="outlined"
              style={{
                color: ids.length > 0 ? '#CA7A85' : 'rgba(0, 0, 0, 0.26)',
                borderColor: ids.length > 0 ? '#CA7A85' : 'rgba(0, 0, 0, 0.12)'
              }}
              size="small"
              disabled={ids.length <= 0 || formState.isSubmitting}
              onClick={() => {
                setValue('exclude', true)
              }}
            >
              {ids.length > 0 ? `Exclude (${ids.length})` : 'Exclude'}
            </Button>
          </Box>
        </Box>
      </form>
      {addLabelOpen && (
        <Modal maxWidth={'xs'} open={addLabelOpen} label={'Create New Label'}>
          <LabelForm
            exitFunction={setRefreshLabels}
            updateForm={{ labels: selectedLabels }}
          />
        </Modal>
      )}
    </>
  )
}
