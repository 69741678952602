import { Container } from '@mui/material'
import UserCreateEditForm from 'forms/UserCreateEditForm'
import { motion as m } from 'framer-motion'
import { useGetInstitutes } from 'hooks/institutes'
import { useGetUser } from 'hooks/useUserDataManager'
import { useParams } from 'react-router-dom'

const CreateEditUser = () => {
  const { id } = useParams()

  const { data: getUserData, isLoading: isGetUserDataLoading } = useGetUser({
    id: id
  })

  const { data: getInstitutesData, isLoading: isGetInstituteLoading } =
    useGetInstitutes()

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.3 }}
    >
      <Container>
        {id &&
          getUserData &&
          !isGetUserDataLoading &&
          getInstitutesData &&
          !isGetInstituteLoading && (
            <UserCreateEditForm
              user={getUserData}
              institutes={getInstitutesData?.items || []}
            />
          )}
        {!id && getInstitutesData && !isGetInstituteLoading && (
          <UserCreateEditForm institutes={getInstitutesData?.items || []} />
        )}
      </Container>
    </m.div>
  )
}

export default CreateEditUser
