import React, { useEffect, useState } from 'react'
import { withFromContext } from 'components'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import has from 'lodash/has'
import { format } from 'date-fns'
import { DatePicker } from '@mui/lab'
import { CancelRounded, PriorityHigh } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { api } from 'services'

const ThingsToNotice = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={3}
      flexGrow={1}
    >
      <Typography variant={'h5'}>Guide to Create Validation</Typography>
      <Grid container alignItems={'baseline'}>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Validation Name</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant={'body1'}>
            Name of the validation. This name has to be unique, which means you
            can't use the same name for another validation.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Start Date</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant={'body1'}>
            Start date of the validation.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Duration (Days)</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant={'body1'}>
            Duration of the validation in days. For each day we recommend at
            least 4 records to be collected
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>Update Baseline</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant={'body1'}>
            If checked, this validation's results for line summaries and as a
            baseline for monitoring.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  )
}

const ValidationDetails = (props) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    trigger
  } = props

  const [validationName, setValidationName] = useState(
    getValues('validationName') || ''
  )
  const [verifyValidationName, setVerifyValidationName] = useState(false)
  const [startDate, setStartDate] = useState(getValues('startDate'))
  const [isBaseline, setIsBaseline] = useState(getValues('isBaseline') || false)
  const [durationDays, setDurationDays] = useState(
    getValues('durationDays') || 1
  )

  const {
    data: getValidationByName,
    isLoading: isGetValidationByNameIsLoading
  } = useQuery({
    queryKey: ['getValidationByName', validationName],
    queryFn: async () => {
      const { items } = await api.validations.list({
        size: 1,
        name__iexact: validationName
      })

      // setVerifyValidationName(false)
      return items || []
    },
    enabled: verifyValidationName
  })

  const handleValidationNameBlur = async (e) => {
    setVerifyValidationName(true)
  }

  const handleValidationNameChange = (e) => {
    setValidationName(e.target.value)
    setValue('validationName', e.target.value)

    // Check if value is not empty string, if so clear the error
    if (e.target.value !== '') {
      clearErrors('validationName')
    }
  }

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue)
    setValue(
      'startDate',
      newValue !== null ? format(newValue, 'yyyy-MM-dd') : null
    )

    // Check if value is not empty string, if so clear the error
    if (newValue !== '') {
      clearErrors('startDate')
    }
  }

  const handleDurationDaysChange = (e) => {
    setDurationDays(e.target.value)
    setValue('durationDays', e.target.value)

    // Check if value is not empty string, if so clear the error
    if (e.target.value !== '') {
      clearErrors('durationDays')
    }
  }

  const handleIsBaselineChange = (e) => {
    setIsBaseline(e.target.checked)
    setValue('isBaseline', e.target.checked)
  }

  useEffect(() => {
    register('validationName', {
      required: 'Validation Name is required',
      validate: (value) => {
        if (getValidationByName && getValidationByName.length > 0) {
          return 'Validation Name already exists'
        }

        setVerifyValidationName(false)
      }
    })
    register('startDate', {
      required: 'Start Date is required'
    })
    register('isBaseline')
    register('durationDays', {
      required: 'Duration is required'
    })

    // Set the default values for the form
    setValue('durationDays', 1)
  }, [register, getValidationByName])

  useEffect(async () => {
    if (
      isGetValidationByNameIsLoading ||
      !getValidationByName ||
      !verifyValidationName
    )
      return
    trigger(['validationName'])
  }, [
    isGetValidationByNameIsLoading,
    getValidationByName,
    verifyValidationName
  ])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'auto'}
      sx={{
        height: 'calc(100vh - 28em)'
      }}
      width={'100%'}
      paddingY={1}
      gap={2}
    >
      <Box display={'flex'} flexGrow={1} gap={2} alignItems={'flex-start'}>
        <TextField
          value={validationName || ''}
          onChange={handleValidationNameChange}
          onBlur={handleValidationNameBlur}
          label={'Validation Name'}
          variant={'outlined'}
          size={'small'}
          error={has(errors, 'validationName')}
          helperText={errors.validationName && errors.validationName.message}
          sx={{
            width: '30%',
            '& .MuiInputBase-adornedEnd': {
              paddingX: 0
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position={'start'}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              >
                <IconButton
                  onClick={() => {
                    setValidationName('')
                    setValue('validationName', '')
                  }}
                >
                  <CancelRounded />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <DatePicker
          sx={{
            width: '30%',
            flexGrow: 1
          }}
          label={'Start Date'}
          value={startDate || null}
          onChange={handleStartDateChange}
          inputFormat="dd MMM yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              error={has(errors, 'startDate')}
              helperText={errors.startDate && errors.startDate.message}
              size="small"
            />
          )}
        />
        <FormControl
          sx={{
            width: '15%',
            flexGrow: 1
          }}
          error={has(errors, 'durationDays')}
        >
          <InputLabel id={'duration-days-label'}>Duration (Days)</InputLabel>
          <Select
            labelId={'duration-days-label'}
            label={'Duration (Days)'}
            value={durationDays || 1}
            onChange={handleDurationDaysChange}
            size={'small'}
          >
            <MenuItem value={1}>1 day</MenuItem>
            <MenuItem value={2}>2 days</MenuItem>
            <MenuItem value={3}>3 days</MenuItem>
          </Select>
          <FormHelperText>
            {errors.durationDays && errors.durationDays.message}
          </FormHelperText>
        </FormControl>
        <FormControlLabel
          sx={{
            width: '25%',
            flexGrow: 1
          }}
          control={
            <Checkbox
              checked={isBaseline || false}
              onChange={handleIsBaselineChange}
            />
          }
          label={'Update Baseline'}
        />
      </Box>
      <ThingsToNotice />
    </Box>
  )
}

export default withFromContext(ValidationDetails)
