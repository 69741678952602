import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import {
  Labels,
  MainContent,
  Modal,
  ServerSideDataGrid,
  StyledLink
} from 'components'
import { formatDistanceToNowStrict, parseJSON } from 'date-fns'
import { DeleteHemolysisDataForm, HemolysisDataForm } from 'forms'
import { formatDateRange } from 'helpers'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { api, hasRole } from 'services'

export default function TableView() {
  const queryClient = useQueryClient()
  const queryName = 'hiData'
  const refetch = () => queryClient.invalidateQueries(queryName)
  const history = useHistory()

  const columns = [
    { field: 'id', hide: true },
    { field: 'filename', hide: true },
    { field: 'measurement_summary', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        const title =
          params.getValue(params.id, 'title') ||
          params.getValue(params.id, 'filename')
        return (
          <StyledLink
            to={{
              pathname: `/hemolysis-index-data/${id}`,
              state: {
                name: 'Hemolysis Index Data',
                referrer: {
                  path: `${window.location.pathname}${window.location.search}`,
                  back: {
                    label: history.location.state
                      ? history.location.state.name
                      : null,
                    path:
                      history.location.state &&
                      history.location.state.referrer &&
                      history.location.state.referrer.path
                  }
                }
              }
            }}
          >
            {title}
          </StyledLink>
        )
      },
      width: 300
    },
    {
      field: 'line',
      headerName: 'Line',
      width: 250,
      renderCell: ({ value }) => {
        if (value) {
          return (
            <StyledLink
              to={{
                pathname: `/line/${value.id}`,
                state: {
                  name: 'Hemolysis Index Data',
                  referrer: {
                    path: `${window.location.pathname}${window.location.search}`,
                    back: {
                      label: history.location.state
                        ? history.location.state.name
                        : null,
                      path:
                        history.location.state &&
                        history.location.state.referrer &&
                        history.location.state.referrer.path
                    }
                  }
                }
              }}
            >
              {value.name}
            </StyledLink>
          )
        }
        return ''
      }
    },
    {
      field: 'upload_date',
      headerName: 'Upload Date',
      renderCell: ({ value }) => {
        return formatDistanceToNowStrict(parseJSON(value), { addSuffix: true })
      },
      width: 120
    },
    {
      field: 'period',
      headerName: 'Period',
      renderCell: (params) => {
        const { start, end } = params.getValue(params.id, 'measurement_summary')
        return formatDateRange(start, null, end)
      },
      width: 170,
      sortable: false
    },
    {
      field: 'data_count',
      headerName: 'Data Count',
      renderCell: (params) => {
        const { count } = params.getValue(params.id, 'measurement_summary')
        return count
      },
      width: 110,
      sortable: false
    },
    {
      field: 'labels',
      headerName: 'Labels',
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <Labels
            shownLabels={value && value.slice(0, 1)}
            hiddenLabels={value && value.slice(1)}
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="flex-start"
          />
        )
      }
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        return (
          <Modal
            disabled={hasRole('guest')}
            button={
              <Tooltip title={'Delete hemolysis data'}>
                <Button
                  color="error"
                  size="small"
                  disabled={hasRole('guest')}
                  data-testid="delete-hemolysis-data-button"
                >
                  <Delete />
                </Button>
              </Tooltip>
            }
            label={'Confirmation Required'}
            showCloseIcon={false}
          >
            <DeleteHemolysisDataForm id={id} exitFunction={refetch} />
          </Modal>
        )
      },
      sortable: false,
      width: 70
    }
  ]

  return (
    <MainContent>
      <Box display="flex" flexDirection="column" p={4}>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexGrow={1}>
            <Typography variant="h1">Hemolysis Index Data</Typography>
          </Box>
          <Box display="flex" alignSelf="center" mr={1}>
            <Modal
              disabled={hasRole('guest')}
              maxWidth={'sm'}
              button={
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  disabled={hasRole('guest')}
                  data-testid="add-hemolysis-data-button"
                >
                  {'Add Hemolysis Data'}
                </Button>
              }
              label={'Add Hemolysis Data'}
            >
              <HemolysisDataForm exitFunction={refetch} />
            </Modal>
          </Box>
        </Box>
        <ServerSideDataGrid
          apiCall={api.hemolysisData.list}
          queryName={queryName}
          columns={columns}
          noRowsText={'No Hemolysis Data'}
        />
      </Box>
    </MainContent>
  )
}
