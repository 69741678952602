import React from 'react'
import { Box, Button } from '@mui/material'

export default function InfoBox ({ children, onLearnMoreClick = null }) {
  return (
    <div>
      <Box p={1}>{children}</Box>
      {onLearnMoreClick && (
        <Button style={{ fontSize: 'inherit' }} color="primary" onClick={onLearnMoreClick}>
          Learn more
        </Button>
      )}
    </div>
  )
}
