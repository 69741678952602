import React from 'react'
import Box from '@mui/material/Box'
import Label from 'components/Label'

export const unlabeled = { id: 'unlabeled', color: 'grey', label: 'Unlabeled' }

export default function LabelToggle ({ labels, selectedLabels, handleSelectedLabels }) {
  const handleToggle = (event, clickedLabel) => {
    let newLabels
    const isSelected = selectedLabels.findIndex((selectedLabel) => selectedLabel.id === clickedLabel.id) >= 0
    if (isSelected) {
      newLabels = selectedLabels.filter((label) => label.id !== clickedLabel.id)
      handleSelectedLabels(event, newLabels)
    } else {
      newLabels = selectedLabels.concat(clickedLabel)
      handleSelectedLabels(event, newLabels)
    }
  }
  return (
    <Box>
      {selectedLabels &&
        labels
          .concat(unlabeled)
          .map((label, index) => (
            <Label
              key={`label-${label.id}-${index}`}
              label={label.label}
              color={label.color}
              size="small"
              value={label.id}
              variant={
                selectedLabels.findIndex((selectedLabel) => selectedLabel.id === label.id) >= 0 ? 'default' : 'outlined'
              }
              onClick={(event) => handleToggle(event, label)}
            />
          ))}
    </Box>
  )
}
