import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import makeStyles from '@mui/styles/makeStyles'
import styles from './styles'

const useStyles = makeStyles(styles)

export default function ToggleFlag (props) {
  const classes = useStyles()

  const { value, onChange, trueLabel, falseLabel } = props
  const [trueSelected, setTrueSelected] = useState()
  const [falseSelected, setFalseSelected] = useState()

  const handleTrue = (event) => {
    setTrueSelected(!trueSelected)
    if (!trueSelected) {
      setFalseSelected(false)
    }
    onChange(event, !trueSelected === true ? true : null)
  }

  const handleFalse = (event) => {
    setFalseSelected(!falseSelected)
    if (!falseSelected) {
      setTrueSelected(false)
    }
    onChange(event, !falseSelected === true ? false : null)
  }
  useEffect(() => {
    setTrueSelected(value === true || value === 'true')
    setFalseSelected(value === false || value === 'false')
  }, [value])
  return (
    <Box display="flex" flexDirection="row">
      <Box mr={1}>
        <ToggleButton
          value={'true'}
          selected={trueSelected}
          className={classes.root}
          size="small"
          onChange={handleTrue}
        >
          {trueLabel}
        </ToggleButton>
      </Box>
      <Box>
        <ToggleButton
          value={'false'}
          selected={falseSelected}
          className={classes.root}
          size="small"
          onChange={handleFalse}
        >
          {falseLabel}
        </ToggleButton>
      </Box>
    </Box>
  )
}
