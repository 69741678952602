import { datadogLogs } from '@datadog/browser-logs'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Button from '@mui/material/Button'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { SnackbarUtilsConfigurator } from 'helpers'
import { MainLayout } from 'layouts'
import { SnackbarProvider } from 'notistack'
import { createRef, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { AuthRoutes, PublicRoutes, authenticationService } from 'services'
import VitalAppTheme from 'theme.js'

// Init datadog logging in UI
const DATATDOG_TOKEN = 'pubde749baa2862132b4a62ea28a58f0323'

datadogLogs.init({
  clientToken: DATATDOG_TOKEN,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  silentMultipleInit: true,
  beforeSend: (log) => {
    // discard 401
    if (log.http && log.http.status_code === 401) {
      return false
    }
  }
})

datadogLogs.logger.setHandler(
  process.env.NODE_ENV === 'development' ? 'console' : 'http'
)
datadogLogs.setLoggerGlobalContext({
  env: process.env.NODE_ENV === 'development' ? 'development' : 'production'
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

const PrivateAppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const currentUser = authenticationService.currentUserValue
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <Layout>
          {!currentUser ? (
            <Redirect
              to={{ pathname: '/login', state: { referrer: props.location } }}
            />
          ) : (
            <Component {...props} />
          )}
        </Layout>
      )}
    />
  )
}

function App() {
  const [currentUser, setCurrentUser] = useState()
  useEffect(() => {
    authenticationService.currentUser.subscribe((user) => {
      setCurrentUser(user)
    })
  }, [currentUser, setCurrentUser])
  const notificationRef = createRef()
  const dismiss = (key) => () => {
    notificationRef.current.closeSnackbar(key)
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={VitalAppTheme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          ref={notificationRef}
          action={(key) => (
            <Button onClick={dismiss(key)} color="inherit" size="small">
              {'Dismiss'}
            </Button>
          )}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
        >
          <SnackbarUtilsConfigurator />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Switch>
                  {PublicRoutes.map((route, index) => {
                    return (
                      <AppRoute
                        key={`public-route-${index}`}
                        path={route.path}
                        layout={route.layout}
                        component={route.component}
                      />
                    )
                  })}

                  {!currentUser &&
                    AuthRoutes.map((route, index) => {
                      return (
                        <AppRoute
                          key={`auth-route-${index}`}
                          path={route.path}
                          layout={route.layout}
                          component={route.component}
                        />
                      )
                    })}

                  {currentUser && (
                    <PrivateAppRoute
                      path="/"
                      layout={'div'}
                      component={MainLayout}
                    />
                  )}
                </Switch>
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
