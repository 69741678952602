import React from 'react'
import { Link } from 'react-router-dom'

export default function StyledLink ({ fontWeight = 600, children, ...rest }) {
  return (
    <Link style={{ color: '#0B8DDE', fontWeight: fontWeight }} {...rest}>
      {children}
    </Link>
  )
}
