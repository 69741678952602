import { Box, Typography } from '@mui/material'
import { DropDownSelect } from 'components'
import {
  axisFont,
  colors,
  getCumulativeMetricStatistics,
  getValidationMetricStatistics
} from 'helpers'
import { useState } from 'react'
import Plot from 'react-plotly.js'
import { useHistory } from 'react-router-dom'

export default function Single({
  data,
  field = 'hi_limit',
  yRange = [0, 100],
  height = '500px',
  yLabel = '',
  unit = '',
  bottomPadding = 100
}) {
  const history = useHistory()
  const names = data.map((line) => line.name)
  const values = data.map(
    (line) =>
      getValidationMetricStatistics(line, field) ||
      getCumulativeMetricStatistics(line, field)
  )
  const traceColors = data.map((line, index) => colors[index % colors.length])
  const urls = data.map((line) => `/line/${line.id}`)
  const textValues = data.map(
    (line) =>
      `${yLabel}: ${
        getValidationMetricStatistics(line, field) ||
        getCumulativeMetricStatistics(line, field)
      }${''}`
  )
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')

  const traces = [
    {
      type: 'bar',
      x: names,
      y: values,
      url: urls,
      text: textValues,
      hoverinfo: 'text',
      marker: {
        color: traceColors
      }
    }
  ]

  const maxValue = Math.max(...values)

  if (maxValue > 100) {
    yRange = [0, maxValue]
  }

  const layout = {
    autosize: true,
    hovermode: 'closest',
    hoverlabel: { bgcolor: '#FFF' },
    showlegend: false,
    margin: {
      l: 75,
      r: 100,
      b: bottomPadding,
      t: 30,
      pad: 4
    },
    xaxis: {},
    yaxis: {
      title: {
        text: yLabel,
        font: axisFont
      },
      range: yRange
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'end',
        paddingTop: '10px'
      }}
    >
      <DropDownSelect
        labelId="image-format-select"
        label="Image Format"
        value={downloadImageFormat}
        onValueChange={(newValue) => {
          setDownloadImageFormat(newValue)
        }}
        items={[
          { content: <Typography variant="h7">PNG</Typography>, value: 'png' },
          { content: <Typography variant="h7">SVG</Typography>, value: 'svg' }
        ]}
      />

      <Plot
        data={traces}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: height }}
        config={{
          responsive: true,
          displaylogo: false,
          toImageButtonOptions: { format: downloadImageFormat }
        }}
        onClick={(event) => {
          const url = event.points[0].data.url[event.points[0].pointIndex]
          return history.push({ pathname: url, state: { includeLine: true } })
        }}
      />
    </Box>
  )
}
