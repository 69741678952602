import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { BackLink, MainContent } from 'components'
import { useParams, useHistory } from 'react-router-dom'
import { LineForm } from 'forms'

export default function LineSettingsView ({ lineData, refreshLineData }) {
  const { id } = useParams()
  const history = useHistory()

  return (
    <MainContent>
      <Box p={2}>
        <Container maxWidth="sm">
          <BackLink noBorder px={0} />
          <Box pb={2}>
            <Typography variant="h1">
              {`${lineData && lineData.name} Settings`}
            </Typography>
          </Box>
          <LineForm
            id={id}
            exitFunction={() => {
              refreshLineData(true)
              if (history) {
                history.goBack()
              } else {
                history.push(`/line/${id}`)
              }
            }}
          />
        </Container>
      </Box>
    </MainContent>
  )
}
