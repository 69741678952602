import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { SnackbarUtil } from '../../helpers'

const ServerDataGrid = (props) => {
  const {
    columns,
    numRowsPerPageOptions,
    queryKey = '',
    query,
    queryStaleTime = 0,
    queryParams = {},
    handlePageChange,
    handlePageSizeChange,
    handleSelectionModelChange,
    ...rest
  } = props
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(
    (numRowsPerPageOptions && numRowsPerPageOptions[0]) || 10
  )
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    numRowsPerPageOptions || [10, 25, 50, 100]
  )
  const [selectionModel, setSelectionModel] = useState([])

  const onQueryDataSuccess = (data) => {}

  const onQueryDataError = (error) => {
    SnackbarUtil.error('Error fetching data', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    })
  }

  const { data: queryData, isLoading: queryDataIsLoading } = query({
    onSuccess: onQueryDataSuccess,
    onError: onQueryDataError,
    params: {
      page,
      size: pageSize,
      ...queryParams
    },
    staleTime: queryStaleTime
  })

  const onPageChange = (newPage) => {
    setPage(newPage)

    if (handlePageChange) {
      handlePageChange(newPage)
    }
  }

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)

    if (handlePageSizeChange) {
      handlePageSizeChange(newPageSize)
    }
  }

  const onSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection)

    if (handleSelectionModelChange) {
      handleSelectionModelChange(newSelection)
    }
  }

  useEffect(() => {
    if (queryData && !queryDataIsLoading) {
      const { items, total } = queryData
      setRows(items)
      setRowCount(total)
    }
  }, [queryData, queryDataIsLoading])

  return (
    <DataGrid
      loading={queryDataIsLoading}
      columns={columns}
      rows={rows}
      rowCount={rowCount}
      rowsPerPageOptions={rowsPerPageOptions}
      page={page}
      onPageChange={onPageChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
      autoHeight
      paginationMode="server"
      width={'100%'}
      disableSelectionOnClick
      keepNonExistentRowsSelected
      sx={{
        //   Set min row height to 48px
        '& .MuiDataGrid-row': {
          minHeight: '48px !important'
        }
      }}
      components={{
        noRowsOverlay: () => {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Typography variant="h5">{'No Rows Found'}</Typography>
            </Box>
          )
        }
      }}
      {...rest}
    />
  )
}

export { ServerDataGrid }
