import React from 'react'
import { StyleSheet, Page, View, Image } from '@react-pdf/renderer'
import {
  Footer,
  StyledText
} from '../index.js'
import FullLogo from 'assets/images/full_logo.png'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#E4EDED',
    opacity: 0.38,
    padding: '10px 20px',
    justifyContent: 'space-between'
  },
  content: {
    flexGrow: 1,
    padding: '10x 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  section: {
    marginBottom: 20
  },
  analyzerValue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    minWidth: 50,
    marginRight: 20
  }
})

const BackPage = ({ analyzer }) => {
  return (
    <Page size="A4" style={ styles.page }>
      <View style={ styles.title }>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image style={{ opacity: 1, width: 125 }} src={ FullLogo } />
        </View>
        <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
          <StyledText fontSize={ 13 } fontWeight={ 500 }>{ 'Explanation of Performance and Rejection' }</StyledText>
        </View>
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <StyledText fontSize={ 13 } fontWeight={ 500 } margin={ '5px 0px' }>{ 'Analyzer Cutoffs' }</StyledText>
          <StyledText fontSize={ 11 } fontWeight={ 400 } margin={ '5px 0px' }>{ 'Manufacturers of clinical analyzers set thresholds, or "cutoff values" for a sample’s Hemolysis Index, above which hemolysis may interfere with the measurement of clinical analytes. The Rejection Probability calculated by VitalQC is dependent on these cutoff values; larger cutoffs translate to lower rejection probabilities and vice versa.' }</StyledText>
          <StyledText fontSize={ 11 } fontWeight={ 500 } margin={ '5px 0px' }>{ 'Your Cutoffs' }</StyledText>
          <View style={ styles.row }>
            <View style={ styles.column }>
              <View style={ styles.analyzerValue }>
                <StyledText fontSize={ 10 }>{ 'Supplier: ' }</StyledText>
                <StyledText fontWeight={ 600 } fontSize={ 10 }>{ analyzer?.make }</StyledText>
              </View>
              <View style={ styles.analyzerValue }>
                <StyledText fontSize={ 10 }>{ 'Model: ' }</StyledText>
                <StyledText fontWeight={ 600 } fontSize={ 10 }>{ analyzer?.model }</StyledText>
              </View>
            </View>
            <View style={ styles.column }>
              <View style={ styles.analyzerValue }>
                <StyledText fontSize={ 10 }>{ 'LDH: ' }</StyledText>
                <StyledText fontWeight={ 600 } fontSize={ 10 }>{ analyzer?.ldh_threshold }{ ' mg/dL' }</StyledText>
              </View>
              <View style={ styles.analyzerValue }>
                <StyledText fontSize={ 10 }>{ 'ASAT: ' }</StyledText>
                <StyledText fontWeight={ 600 } fontSize={ 10 }>{ analyzer?.asat_threshold }{ ' mg/dL' }</StyledText>
              </View>
              <View style={ styles.analyzerValue }>
                <StyledText fontSize={ 10 }>{ 'K: ' }</StyledText>
                <StyledText fontWeight={ 600 } fontSize={10}>{ analyzer?.potassium_threshold }{ ' mg/dL' }</StyledText>
              </View>
            </View>
          </View>
        </View>
        <View style={ styles.section }>
            <StyledText fontSize={ 13 } fontWeight={ 500 } margin={ '5px 0px' }>{ 'Rejection Probability' }</StyledText>
            <StyledText fontSize={ 11 } fontWeight={ 400 } margin={ '5px 0px' }>{ 'The probabilities provided in VitalQC are calculated using Motryx’s statistical models which leverage a database of nearly 2000 paired measurements of VitalMetric and Hemolysis Index. Measurements come from more than 70 distinct PTS lines, representing the most common PTS transport scenarios, with clinical analysis done on the most common clinical analyzers (Roche, Abbot, Siemens).' }</StyledText>
            <StyledText fontSize={ 11 } fontWeight={ 400 } margin={ '5px 0px' }>{ 'Samples used to build the statistical models come from a combination of controlled experiment and routine laboratory collection, and samples from donors with known pre-existing conditions or from bad blood draws are screened out. In the net, Motryx’s statistical models represent blood conditions without known preanalytical hemolysis, with a constrained error bar that tends to be smaller than the potential preanalytical variability in individual samples that can result from bad blood draws or compromised patient health. Statistical uncertainty in the rejection probabilities is between 5-14 percentage points for LDH and less than 1 percentage point for AST and Potassium.' }</StyledText>
        </View>
        <View style={ styles.section }>
            <StyledText fontSize={ 13 } fontWeight={ 500 } margin={ '5px 0px' }>{ 'Performance' }</StyledText>
            <StyledText fontSize={ 11 } fontWeight={ 400 } margin={ '5px 0px' }>{ 'Performance is an indicator of the vibration compared to the length of the line, where higher performance indicates less vibration per meter of a PTS line.' }</StyledText>
            <StyledText fontSize={ 11 } fontWeight={ 400 } margin={ '5px 0px' }>{ 'Lower performance means that the system has more vibration than typical for systems of that length. This may indicate a problem with the system or that maintenance is required. Consult the manufacturer or installer to be certain the system is operating within specification.' }</StyledText>
        </View>
      </View>
      <Footer/>
    </Page>
  )
}

export default BackPage
