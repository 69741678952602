import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { apiConfig } from 'services'

export default function InstituteLock ({ id = null, redirectUrl = '/dashboard', children }) {
  const [component, setComponent] = useState(null)
  useEffect(() => {
    if (id && apiConfig.currentApiInstitute !== id) {
      setComponent(<Redirect to={redirectUrl}/>)
    } else {
      setComponent(children)
    }
  }, [id, children])
  return component
}
