// Component styles
export default (theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #D6DAE0'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: theme.spacing(0, 1)
  },
  logo: {
    borderRadius: 0,
    height: 'inherit',
    backgroundColor: 'transparent',
    '& .MuiAvatar-img': {
      backgroundColor: 'transparent'
    }
  },
  listItem: {
    padding: theme.spacing(1),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark
    },
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  listItemText: {
    fontSize: '19px',
    color: '#33403F',
    fontWeight: 500,
    whiteSpace: 'nowrap'
  },
  navButton: {
    fontWeight: 500
  },
  logoutButton: {
    fontWeight: 600,
    color: theme.palette.red.main
  },
  tabs: {
    minHeight: '70px',
    '& > .MuiTabs-scroller': {
      display: 'flex'
    }
  },
  notConnectedText: {
    textTransform: 'uppercase',
    opacity: 0.37,
    fontSize: '14px',
    padding: theme.spacing(2)
  },
  notConnectedDrawerText: {
    textTransform: 'uppercase',
    opacity: 0.37
  },
  dropdownSelect: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 600,
    minHeight: 0,
    padding: '7px',
    backgroundColor: 'transparent'
  },
  dropdownIcon: {
    color: 'white'
  }
})
