import { Send } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { ServerDataGrid } from 'components'
import { motion as m } from 'framer-motion'
import { SnackbarUtil } from 'helpers'
import {
  useDeleteUser,
  useGetAllUsers,
  useResendInvite
} from 'hooks/useUserDataManager'
import { startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { authenticationService } from 'services'

const Users = (props) => {
  const history = useHistory()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [queryParams, setQueryParams] = useState({})

  const resendInviteMutation = useResendInvite({
    onSuccess: (data) => {
      SnackbarUtil.success('Invite resent successfully')
    },
    onError: (error) => {
      SnackbarUtil.error('Error resending invite')
    }
  })

  const deleteUserMutation = useDeleteUser({
    onSuccess: (data) => {
      SnackbarUtil.success('User deleted successfully')
    },
    onError: (error) => {
      SnackbarUtil.error('Error deleting user')
    }
  })

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const userDataColumns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => history.push(`/users/edit/${params.row.id}`)}
        >
          {params.row.email}
        </Button>
      )
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      renderCell: (params) => (
        <Typography variant={'body1'}>{params.row.first_name}</Typography>
      )
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      renderCell: (params) => (
        <Typography variant={'body1'}>{params.row.last_name}</Typography>
      )
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: (params) => {
        const role = params.row.role
        const chipColor =
          role === 'admin'
            ? '#ff5151'
            : role === 'member'
            ? '#71d171'
            : role === 'guest'
            ? '#7acfd1'
            : role === 'owner'
            ? '#FFA500'
            : role === 'staff'
            ? '#8500ff'
            : '#000000'
        return (
          <Chip
            label={startCase(role)}
            sx={{
              backgroundColor: chipColor,
              color: '#ffffff'
            }}
          />
        )
      }
    },
    {
      field: 'manage',
      headerName: 'Manage',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box display={'flex'} gap={1}>
          {authenticationService?.currentUserValue?.user_id !==
            params.row.id && (
            <Tooltip title={'Resend Invite'}>
              <IconButton
                color={'primary'}
                onClick={async () => {
                  await resendInviteMutation.mutate(params.row.email)
                }}
                disabled={!params.row.unregistered}
              >
                <Send />
              </IconButton>
            </Tooltip>
          )}
          {authenticationService?.currentUserValue?.user_id !==
            params.row.id && (
            <Tooltip title={'Delete User'}>
              <IconButton color={'error'} onClick={handleDeleteDialogOpen}>
                <DeleteIcon />
              </IconButton>
              <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle id="alert-dialog-title">
                  {'Delete User?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this user?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'outlined'}
                    color={'error'}
                    onClick={handleDeleteDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={async () => {
                      await deleteUserMutation.mutate(params.row.email)
                      handleDeleteDialogClose()
                    }}
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Tooltip>
          )}
        </Box>
      )
    }
  ]

  useEffect(async () => {
    if (search) {
      setQueryParams({
        filters: {
          search
        }
      })
    } else {
      setQueryParams({})
    }
  }, [search])

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.3 }}
    >
      <Box display="flex" flexDirection="column" p={2} gap={1}>
        <Box display={'flex'}>
          <Typography variant={'h1'} flexGrow={1}>
            Users
          </Typography>
          <Tooltip title={'Add New User'}>
            <IconButton
              color={'primary'}
              onClick={() => history.push('/users/create')}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <TextField
            label={'Search by Email/Fist Name/Last Name'}
            variant={'outlined'}
            value={search}
            size={'small'}
            sx={{
              minWidth: '320px'
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <ServerDataGrid
          columns={userDataColumns}
          query={useGetAllUsers}
          queryParams={queryParams}
          getRowId={(row) => row.id}
        />
      </Box>
    </m.div>
  )
}

export default Users
