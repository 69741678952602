import React from 'react'
import { View } from '@react-pdf/renderer'
import { StyledText } from '../index.js'

const BulletPoint = ({ children }) => (
  <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
    <View style={{ width: 20 }}><StyledText variant={'body1'}>{'-'}</StyledText></View>
    <View style={{ width: 625 }}>{children}</View>
  </View>
)

export default BulletPoint
