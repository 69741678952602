import React from 'react'
import ReactCalendarHeatmap from 'react-calendar-heatmap'
import ReactTooltip from 'react-tooltip'
import { useHistory } from 'react-router-dom'

import './style.css'

export default function CalendarHeatmap ({ ...props }) {
  const history = useHistory()

  return (
    <>
      <ReactCalendarHeatmap
        showWeekdayLabels={true}
        weekdayLabels={['Mon ', 'Tue ', 'Wed ', 'Thu ', 'Fri ', 'Sat ', 'Sun ']}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty'
          }
          return 'color-motryx-1'
        }}
        onClick={(value) => {
          if (value) {
            const start = value.date
            let end = new Date(value.date)
            end.setDate(end.getDate() + 1)
            end = end.toISOString().split('T')[0]
            history.push(`/records?date__lt=${end}&date__gte=${start}&exclude=false&hidden=false`)
          }
        }}
        {...props}
      />
      <ReactTooltip />
    </>
  )
}
