import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { EntryBox, InfoButton, UserRolesInfo } from 'components'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Role, api } from 'services'

export default function UserForm({
  email,
  instituteId = null,
  emailOnly = false,
  saveLabel = 'Save',
  exitFunction,
  closeModalFunction
}) {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      user_role: 'member'
    }
  })

  const unmounted = useRef(false)

  const [formEmail, setFormEmail] = useState(email)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [defaultInstitute, setDefaultInstitute] = useState(instituteId)
  const [instituteOptions, setInstituteOptions] = useState()
  const [isActive, setIsActive] = useState(true)
  const [isStaff, setIsStaff] = useState(false)
  const [userRole, setUserRole] = useState('guest')

  const handleInstituteChange = (event) => {
    setDefaultInstitute(event.target.value)
    setValue('default_institute', event.target.value)
  }

  const handleIsActive = (event) => {
    setIsActive(event.target.checked)
    setValue('is_active', event.target.checked)
  }

  const handleIsStaff = (event) => {
    setIsStaff(event.target.checked)
    setValue('is_staff', event.target.checked)
  }

  const handleClose = () => {
    if (exitFunction) exitFunction()
    if (closeModalFunction) closeModalFunction()
  }

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value)
    setValue('role', event.target.value)
  }

  const onSubmit = (data) => {
    console.log(data)
    const default_institute = data.default_institute
    delete data.default_institute

    if (email) {
      api.users
        .update(email, data)
        .then(function (data) {
          enqueueSnackbar(
            `Changes for ${
              emailOnly
                ? `${data.email}`
                : `${data.first_name} ${data.last_name}`
            } have been saved.`,
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          )
          handleClose()
        })
        .catch(function (status) {
          console.log(status)
        })
    } else {
      api.users
        .create(default_institute || instituteId, data)
        .then(function (data) {
          enqueueSnackbar(
            `Changes for ${
              emailOnly
                ? `${data.email}`
                : `${data.first_name} ${data.last_name}`
            } have been saved.`,
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          )
          if (exitFunction) exitFunction()
          closeModalFunction()
        })
        .catch((error) => {
          console.warn(error.response)
          if (error.response.status === 400) {
            enqueueSnackbar(error.response.data.detail, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
          }
        })
    }
  }

  useEffect(() => {
    register('default_institute', {
      required: 'You must assign a default organization to a new user.'
    })
    api.institutes
      .list({ size: 999 })
      .then((data) => setInstituteOptions(data.items))
    if (email) {
      api.users
        .get(email)
        .then((data) => {
          console.log(data)
          setValue('email', data.email)
          setFormEmail(data.email)
          setValue('first_name', data.first_name)
          setFirstName(data.first_name)
          setValue('last_name', data.last_name)
          setLastName(data.last_name)
          setValue('default_institute', data.default_institute.id)
          setDefaultInstitute(data.default_institute.id)
          setValue('is_active', data.is_active)
          setIsActive(data.is_active)
          setValue('is_staff', data.is_staff)
          setIsStaff(data.is_staff)
          setValue('role', data.role)
          setUserRole(data.role)
        })
        .catch((error) => {
          if (!unmounted.current) {
            console.warn(JSON.stringify(error, null, 2))
          }
        })
    } else {
      setValue('default_institute', instituteId)
    }
  }, [setValue, register, email, instituteId])
  return (
    <form
      id="invite_user_form"
      data-testid="user-form-test"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} direction="column">
        {emailOnly && (
          <Grid item display="flex" flexDirection="column">
            <Typography
              variant="body1"
              color={'red.main'}
              alignItems="center"
              display="flex"
              style={{
                fontStyle: 'italic'
              }}
              gap={1}
            >
              Default user role is set to Guest
              <InfoButton
                style={{
                  cursor: 'pointer'
                }}
              >
                <UserRolesInfo />
              </InfoButton>
            </Typography>
            <Typography
              variant="body1"
              color={'red.main'}
              style={{
                fontStyle: 'italic'
              }}
            >
              To change the user role, click on user email in the table and
              select the desired role.
            </Typography>
          </Grid>
        )}
        <Grid item>
          <EntryBox label="Email">
            <TextField
              {...register('email', {
                required: 'Please enter your e-mail.',
                pattern: {
                  value: /\S+@\S+\.\S+/i,
                  message: 'Please enter a valid e-mail address'
                }
              })}
              name="email"
              id="email"
              variant="outlined"
              size="small"
              value={formEmail || ''}
              onChange={(event) => {
                setFormEmail(event.target.value)
                setValue('email', event.target.value)
              }}
              inputProps={{ 'data-testid': 'email-test' }}
              error={errors.hasOwnProperty('email')}
              helperText={errors.email && errors.email.message}
              fullWidth
            />
          </EntryBox>
        </Grid>
        {!emailOnly && (
          <>
            <Grid item>
              <EntryBox label="First Name">
                <TextField
                  {...register('first_name', {
                    required: 'A first name is required.'
                  })}
                  name="first_name"
                  id="first_name"
                  variant="outlined"
                  value={firstName}
                  size="small"
                  onChange={(event) => {
                    setFirstName(event.target.value)
                    setValue('first_name', event.target.value)
                  }}
                  inputProps={{ 'data-testid': 'first-name-test' }}
                  error={errors.hasOwnProperty('first_name')}
                  helperText={errors.first_name && errors.first_name.message}
                  fullWidth
                />
              </EntryBox>
            </Grid>
            <Grid item>
              <EntryBox label="Last Name">
                <TextField
                  {...register('last_name', {
                    required: 'A last name is required.'
                  })}
                  name="last_name"
                  id="last_name"
                  variant="outlined"
                  value={lastName}
                  size="small"
                  onChange={(event) => {
                    setLastName(event.target.value)
                    setValue('last_name', event.target.value)
                  }}
                  inputProps={{ 'data-testid': 'last-name-test' }}
                  error={errors.hasOwnProperty('first_name')}
                  helperText={errors.last_name && errors.last_name.message}
                  fullWidth
                />
              </EntryBox>
            </Grid>
            <Grid item style={{ display: email ? 'none' : 'block' }}>
              <EntryBox label="Initial Organization">
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.hasOwnProperty('institute')}
                >
                  <Select
                    labelId="line-input-label"
                    size="small"
                    id="line-input"
                    value={defaultInstitute || ''}
                    onChange={handleInstituteChange}
                    SelectDisplayProps={{
                      'data-testid': 'institute-select'
                    }}
                    inputProps={{
                      'data-testid': 'institute-input'
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {instituteOptions &&
                      instituteOptions.map((institute, index) => {
                        return (
                          <MenuItem key={index} value={institute.id}>
                            {institute.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                  <FormHelperText>
                    {errors.default_institute &&
                      errors.default_institute.message}
                  </FormHelperText>
                </FormControl>
              </EntryBox>
            </Grid>
            <Grid item>
              <EntryBox label="Active">
                <Checkbox
                  {...register('is_active')}
                  name="is_active"
                  color="primary"
                  checked={isActive}
                  onChange={handleIsActive}
                  inputProps={{ 'data-testid': 'active-test' }}
                />
              </EntryBox>
            </Grid>
            <Grid item>
              <Role restrict={['staff']}>
                <EntryBox label="Motryx Staff">
                  <Checkbox
                    {...register('is_staff')}
                    name="is_staff"
                    color="primary"
                    checked={isStaff}
                    onChange={handleIsStaff}
                    inputProps={{ 'data-testid': 'motryx-staff-test' }}
                  />
                </EntryBox>
              </Role>
            </Grid>
            {!isStaff && (
              <Grid item>
                <FormControl component="fieldset">
                  <Box flexDirection="column">
                    <FormLabel
                      component="legend"
                      style={{
                        fontSize: '1.2rem',
                        color: '#000'
                      }}
                    >
                      <Box alignItems="center" display="flex" gap={1}>
                        User Role
                        <InfoButton
                          fullWidth
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          <UserRolesInfo />
                        </InfoButton>
                      </Box>
                    </FormLabel>
                    <Controller
                      rules={{ required: !isStaff }}
                      control={control}
                      name="role"
                      render={({ field }) => {
                        return (
                          <RadioGroup
                            row
                            {...field}
                            value={userRole || 'member'}
                            onChange={handleUserRoleChange}
                          >
                            <FormControlLabel
                              value="owner"
                              control={<Radio />}
                              label="Owner"
                            />
                            <FormControlLabel
                              value="admin"
                              control={<Radio />}
                              label="Admin"
                            />
                            <FormControlLabel
                              value="member"
                              control={<Radio />}
                              label="Member"
                            />
                            <FormControlLabel
                              value="guest"
                              control={<Radio />}
                              label="Guest"
                            />
                          </RadioGroup>
                        )
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
            )}
          </>
        )}
        <Grid item container justifyContent="flex-end">
          <Box pr={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              {saveLabel}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleClose}
          >
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
