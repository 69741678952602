import React from 'react'
import { StyleSheet, View, Image } from '@react-pdf/renderer'
import StyledText from '../StyledText/index.js'
import { parseISO, format } from 'date-fns'
import FullLogo from 'assets/images/full_logo.png'

const styles = StyleSheet.create({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#E4EDED',
    opacity: 0.38,
    padding: '10px 20px',
    justifyContent: 'space-between'
  }
})

const Title = ({ data }) => {
  return (
    <View style={ styles.title }>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image style={{ opacity: 1, width: 125 }} src={ FullLogo } />
      </View>
      <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <StyledText fontSize={ 9 }>{ 'Validation: ' }</StyledText>
        <StyledText fontWeight={ 500 } fontSize={ 10 }>{ data.name }</StyledText>
      </View>
      <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <StyledText fontSize={ 9 }>{ 'Started On: ' }</StyledText>
        <StyledText fontWeight={ 500 } fontSize={10}>{ data.start_date && format(parseISO(data.start_date), 'dd MMM yyyy') }</StyledText>
      </View>
      <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <StyledText fontSize={ 9 }>{ 'Recommended Next Validation: ' }</StyledText>
        <StyledText fontWeight={ 500 } fontSize={10}>{ data.next_validation_date && format(parseISO(data.next_validation_date), 'dd MMM yyyy') }</StyledText>
      </View>
    </View>
  )
}

export default Title
