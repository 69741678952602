import {
  addSeconds,
  differenceInSeconds,
  format,
  max,
  min,
  parseJSON,
  subSeconds
} from 'date-fns'
import { axisFont } from 'helpers'
import Plot from 'react-plotly.js'
import { useHistory } from 'react-router-dom'
import { useMetricsContext } from 'services'
function text(record) {
  const { metricType } = useMetricsContext()
  return (
    'Line : ' +
    record.line.name +
    '<br>Date : ' +
    format(parseJSON(record.date), 'dd MMM yyyy HH:mm') +
    '<br>VitalMetric : ' +
    record.metrics[metricType] +
    '<br>Mean Kinetic Temperature : ' +
    Number(record.metrics.mean_kinetic_temperature).toFixed(1)
  )
}

export default function LeveyJenningsPlot({
  records,
  variant,
  summarystats,
  lineName = 'Line',
  downloadImageFormat = 'png'
}) {
  const history = useHistory()
  const { currentMetricConfig, metricType } = useMetricsContext()

  const marker_color = '#fff'
  const line_color = records.map((record) =>
    record.validation ? '#4FC1BE' : '#367b79'
  )

  const marker_size = new Array(records.length).fill(10)
  marker_size[records.length - 1] = 14

  const records_variable = records.map((record) =>
    variant === 'temperature'
      ? Number(record.metrics.mean_kinetic_temperature).toFixed(1)
      : record.metrics[metricType]
  )
  const records_dates = records.map((record) => parseJSON(record.date))
  const { mean, stddev, range } = summarystats

  const first_date = records_dates.length > 0 ? min(records_dates) : new Date()
  const last_date = records_dates.length > 0 ? max(records_dates) : new Date()
  const date_padding = differenceInSeconds(first_date, last_date) * 1.1 || 30

  const xrange = [
    format(subSeconds(first_date, date_padding), 'yyyy-MM-dd HH:mm:ss'),
    format(addSeconds(last_date, date_padding), 'yyyy-MM-dd HH:mm:ss')
  ]

  const stats_lines =
    mean && stddev
      ? [
          {
            type: 'line',
            x: [xrange[0], xrange[1]],
            y: [mean + 1 * stddev, mean + 1 * stddev],
            mode: 'lines',
            yaxis: 'y2',
            hoverinfo: 'none',
            line: {
              dash: 'longdash',
              color: '#728093',
              width: 1
            }
          },
          {
            type: 'line',
            x: [xrange[0], xrange[1]],
            y: [mean + 2 * stddev, mean + 2 * stddev],
            mode: 'lines',
            hoverinfo: 'none',
            yaxis: 'y2',
            line: {
              dash: 'longdash',
              color: '#728093',
              width: 1
            }
          },
          {
            type: 'line',
            x: [xrange[0], xrange[1]],
            y: [mean + 3 * stddev, mean + 3 * stddev],
            mode: 'lines',
            yaxis: 'y2',
            hoverinfo: 'none',
            line: {
              dash: 'longdash',
              color: '#728093',
              width: 1
            }
          },
          {
            type: 'line',
            yaxis: 'y2',
            x: [xrange[0], xrange[1]],
            y: [mean, mean],
            hoverinfo: 'none',
            mode: 'lines',
            line: {
              color: '#000000',
              width: 2
            }
          },
          {
            type: 'line',
            x: [xrange[0], xrange[1]],
            y: [mean - 1 * stddev, mean - 1 * stddev],
            mode: 'lines',
            yaxis: 'y2',
            hoverinfo: 'none',
            line: {
              dash: 'longdash',
              color: '#728093',
              width: 1
            }
          },
          {
            type: 'line',
            x: [xrange[0], xrange[1]],
            y: [mean - 2 * stddev, mean - 2 * stddev],
            mode: 'lines',
            yaxis: 'y2',
            hoverinfo: 'none',
            line: {
              dash: 'longdash',
              color: '#728093',
              width: 1
            }
          },
          {
            type: 'line',
            x: [xrange[0], xrange[1]],
            y: [mean - 3 * stddev, mean - 3 * stddev],
            mode: 'lines',
            yaxis: 'y2',
            hoverinfo: 'none',
            line: {
              dash: 'longdash',
              color: '#728093',
              width: 1
            }
          }
        ]
      : [{}]
  const data = stats_lines.concat([
    {
      x: records_dates,
      y: records_variable,
      url: records.map((record) => `/record/${record.id}`),
      text: records.map((record) => text(record)),
      hoverinfo: 'text',
      type: 'scatter',
      connectgaps: true,
      mode: 'lines+markers',
      line: {
        color: '#4FC1BE',
        width: 2
      },
      yaxis: 'y',
      marker: {
        color: marker_color,
        size: 14,
        opacity: 1,
        line: {
          color: line_color,
          width: 2
        }
      }
    }
  ])

  const yrange =
    records.length > 2
      ? [
          Math.min(Math.max(0, mean + 2 * stddev), ...records_variable),
          Math.max(mean + 3 * stddev, ...records_variable)
        ]
      : [Math.min(...records_variable), Math.max(...records_variable)]
  if (stddev) {
    yrange[0] -= stddev * 0.5
    yrange[1] += stddev * 0.5
  } else {
    yrange[0] -= yrange[0] * 0.5
    yrange[1] += yrange[1] * 0.5
  }

  const shapes = [
    {
      type: 'rect',
      yref: 'y2',
      x0: xrange[0],
      y0: range && range.upper,
      x1: xrange[1],
      y1: range && range.upper * 3,
      fillcolor: '#ED546B30',
      layer: 'below',
      line: {
        width: 0
      }
    },
    {
      type: 'rect',
      yref: 'y2',
      x0: xrange[0],
      y0: range && range.lower,
      x1: xrange[1],
      y1: range && range.lower * -3,
      fillcolor: '#ED546B30',
      layer: 'below',
      line: {
        width: 0
      }
    }
  ]
  const layout = {
    autosize: true,
    hovermode: 'closest',
    showlegend: false,
    shapes: mean && stddev ? shapes : null,
    margin: {
      l: 75,
      r: 100,
      b: 100,
      t: 30,
      pad: 4
    },
    xaxis: {
      title: {
        font: axisFont
      },
      tickfont: {
        size: 13,
        color: '#000000'
      },
      tickformatstops: [
        {
          dtickrange: [null, 1000],
          value:
            " <b style='font-weight:600 !important'>%b %d<br>%H:%M:%S.%L</b>"
        },
        {
          dtickrange: [1000, 60000],
          value: "<b style='font-weight:600 !important'>%b %d<br>%H:%M:%S</b>"
        },
        {
          dtickrange: [60000, 86400000],
          value: "<b style='font-weight:600 !important'>%b %d<br>%H:%M</b>"
        },
        {
          dtickrange: [86400000, null],
          value: "<b style='font-weight:600 !important'>%b %d<br>%Y</b>"
        }
      ]
    },
    yaxis: {
      title: {
        text:
          variant === 'temperature'
            ? "<b style='font-weight:600 !important'>Temperature (°C)</b>"
            : variant === currentMetricConfig
            ? "<b style='font-weight:600 !important'>VitalMetric</b>"
            : "<b style='font-weight:600 !important'>VitalMetric (Beta)</b>",
        font: axisFont
      },
      overlaying: 'y2',
      range: yrange,
      fixedrange: true,
      zeroline: false
    },
    yaxis2: {
      title: {
        font: axisFont
      },
      range: yrange,
      fixedrange: true,
      zeroline: false,
      side: 'right',
      showgrid: false,
      tickvals: [
        mean - 3 * stddev,
        mean - 2 * stddev,
        mean - 1 * stddev,
        mean,
        mean + 1 * stddev,
        mean + 2 * stddev,
        mean + 3 * stddev
      ],
      showticklabels: records.length > 2,
      ticktext: [
        '-3 SD',
        '-2 SD',
        '-1 SD',
        '<b style="font-weight:600;">Mean</b>',
        '+1 SD',
        '+2 SD',
        '+3 SD'
      ]
    }
  }
  return (
    <Plot
      data={data}
      layout={layout}
      useResizeHandler={true}
      style={{ width: '100%', height: '500px' }}
      config={{
        responsive: true,
        displaylogo: false,
        toImageButtonOptions: {
          format: downloadImageFormat,
          filename: lineName
        }
      }}
      onClick={(event) => {
        const url = event.points[0].data.url[event.points[0].pointIndex]
        return history.push({ pathname: url, state: { name: lineName } })
      }}
    />
  )
}
