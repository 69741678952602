import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { LoadingOverlay, NoRowsOverlay } from 'components/ServerSideDataGrid'
import { RejectionProbabilityInfo, StyledLink, TableRejectionRates } from 'components'
import { Flag, useMetricsContext } from 'services'
import { useParams, Redirect } from 'react-router-dom'
import { formatDate } from 'helpers'

function Table ({ data, columns, ...rest }) {
  const [sortModel, setSortModel] = useState([
    {
      field: 'validation_start_date',
      sort: 'desc'
    }
  ])

  return (
    <Box height={300}>
      <DataGrid
        rows={data}
        getRowId={ (row) => row.validation_id}
        columns={columns}
        hideFooter
        rowHeight={78}
        disableColumnMenu
        autoHeight={true}
        components={{
          LoadingOverlay: () => <LoadingOverlay text={'Loading Validations...'} />,
          NoRowsOverlay: () => <NoRowsOverlay text={'No Validations'} />
        }}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        {...rest}
      />
    </Box>
  )
}
export default function ValidationsView ({ validationHistory, isLoading = false, lineName }) {
  const { id } = useParams()
  const { metricType, metricLabel } = useMetricsContext()
  const [columns, setColumns] = useState()

  useEffect(() => {
    setColumns([
      { field: 'validation_id', hide: true },
      { field: 'auc', hide: true },
      { field: 'auc_255', hide: true },
      { field: 'power', hide: true },
      { field: 'kinetic_energy', hide: true },
      {
        field: 'validation_name',
        flex: 0.8,
        headerName: 'Validation',
        renderCell: (params) => {
          return <StyledLink
            to={{
              pathname: `/validation/${params.getValue(params.id, 'validation_id')}`,
              state: { name: `${lineName}` }
            }}
          >
            {params.getValue(params.id, 'validation_name')}
          </StyledLink>
        }
      },
      {
        field: 'validation_start_date',
        headerName: 'Date',
        type: 'date',
        flex: 0.6,
        renderCell: (params) => {
          return formatDate(
            params.getValue(params.id, 'validation_start_date')
          )
        }
      },
      {
        field: 'mean',
        renderHeader: () => {
          return <Typography style={{ fontSize: '18px', fontWeight: 600 }}>{metricLabel}</Typography>
        },
        sortable: false,
        width: 120,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return statistics && statistics.mean !== null ? statistics.mean : '-'
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'cv',
        headerName: 'Variability',
        sortable: false,
        width: 110,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return statistics && statistics.mean !== null ? `${statistics.cv}%` : '-'
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'rejection_rates',
        headerName: (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            {'Rejection Probability'}
            <Box display="flex" pl={1}>
              <RejectionProbabilityInfo />
            </Box>
          </Box>
        ),
        sortable: false,
        flex: 0.8,
        renderCell: (params) => {
          const statistics = params.getValue(params.id, metricType)
          return statistics && statistics.rejection_rates !== null
            ? (
            <TableRejectionRates rejectionRates={statistics.rejection_rates} />)
            : '-'
        }
      }
    ])
  }, [metricType])

  return (
    <Flag name="validation" alternate={<Redirect to={`/line/${id}`} />}>
      <Box pt={3}>
        <Typography variant="h6">{'Validation History'}</Typography>
        {validationHistory && columns && <Table data={validationHistory} columns={columns} loading={isLoading} />}
      </Box>
    </Flag>
  )
}
