import {
  Autocomplete,
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  Link,
  TextField,
  Tooltip
} from '@mui/material'
import {
  Label,
  Labels,
  RejectionProbabilityInfo,
  ServerDataGrid,
  TableRejectionRates
} from 'components'
import { useGetInstituteLines } from 'hooks/useLineDataManager'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMetricsContext } from 'services'
import { useGetLabels } from '../../../hooks/useGetLabels'

const TableView = (props) => {
  const history = useHistory()
  const { metricType, metricLabel } = useMetricsContext()
  const [filters, setFilters] = React.useState({
    archived: false
  })
  const [labels, setLabels] = React.useState([])

  const { data: getLabelsData, isLoading: isGetLabelsDataIsLoading } =
    useGetLabels()

  const lineColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.name || ''} placement="right-end">
            <Link
              underline="none"
              fontSize={18}
              fontWeight={600}
              onClick={() => {
                history.push(`/line/${params.row.id}`)
              }}
              sx={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {params.row?.name || ''}
            </Link>
          </Tooltip>
        )
      }
    },
    {
      field: 'labels',
      headerName: 'Labels',
      flex: 1,
      renderCell: (params) => {
        const labels = params.row?.labels || []
        return (
          <Labels
            shownLabels={labels && labels.slice(0, 1)}
            hiddenLabels={labels && labels.slice(1)}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          />
        )
      }
    },
    {
      field: 'records',
      headerName: 'Record Count',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const line_statistics = params.row?.['line_statistics']
        return line_statistics ? line_statistics.record_count : '-'
      }
    },
    {
      field: 'vitalmetric',
      headerName: metricLabel,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const line_statistics = params.row?.['line_statistics']
        const validation = params.row?.['validation']
        const lineStats = validation || line_statistics
        return lineStats ? lineStats[metricType].mean : '-'
      }
    },
    {
      field: 'rejection',
      flex: 1,
      renderHeader: () => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontWeight={600}
            fontSize={18}
          >
            {'Rejection Probability'}
            <Box display="flex" pl={1}>
              <RejectionProbabilityInfo />
            </Box>
          </Box>
        )
      },
      sortable: false,
      renderCell: (params) => {
        const line_statistics = params.getValue(params.id, 'line_statistics')
        const validation = params.getValue(params.id, 'validation')
        const lineStats = validation || line_statistics
        return lineStats[metricType] &&
          lineStats[metricType].rejection_rates ? (
          <TableRejectionRates
            rejectionRates={lineStats[metricType].rejection_rates}
          />
        ) : (
          '-'
        )
      }
    }
  ]

  const handleLabelFilterChange = async (event, labels) => {
    if (labels.length === 0) {
      // delete labels key from filters
      const { labels, ...rest } = filters
      setFilters(rest)
      return
    }

    setFilters({
      ...filters,
      labels: labels.map((l) => l.id).join(',')
    })
  }

  const handleShowArchivedChange = (event) => {
    setFilters({
      ...filters,
      archived: event.target.checked
    })
  }

  return (
    <Fade in timeout={500}>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Box display={'flex'} paddingTop={2} gap={2}>
          <TextField
            label={'Line Name'}
            size={'small'}
            variant={'outlined'}
            onChange={(e) => {
              setFilters({
                ...filters,
                name__icontains: e.target.value
              })
            }}
          />
          <Autocomplete
            multiple
            size={'small'}
            options={isGetLabelsDataIsLoading ? [] : getLabelsData || []}
            getOptionLabel={(option) => option?.label || ''}
            onChange={handleLabelFilterChange}
            filterSelectedOptions={true}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Label
                    key={`label-option-${option?.id}-${index}`}
                    label={option?.label}
                    color={option?.color}
                    size="small"
                    {...getTagProps({ index })}
                  />
                )
              })
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Label
                  key={`label-option-${option?.id}`}
                  label={option?.label}
                  color={option?.color}
                  size="small"
                />
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Labels" />}
            sx={{
              flexGrow: 1
            }}
          />
          <FormControlLabel
            control={<Checkbox onChange={handleShowArchivedChange} />}
            label={'Show Archived'}
          />
        </Box>
        <ServerDataGrid
          columns={lineColumns}
          query={useGetInstituteLines}
          queryParams={filters}
          queryStaleTime={Infinity}
        />
      </Box>
    </Fade>
  )
}

export default TableView
