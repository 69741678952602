import { Edit } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import { FormSelect } from 'components/ReactHookFormSelect'
import { QUERIES, SnackbarUtil } from 'helpers'
import { useCreateUser, useUpdateUser } from 'hooks/useUserDataManager'
import has from 'lodash/has'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { hasRole } from 'services'

const UserCreateEditForm = ({
  user = {},
  institutes = [],
  elevation = 1,
  exitFunction = () => {},
  closeModalFunction = () => {}
}) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const userUpdateMutation = useUpdateUser({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERIES.GET_USER]
      })
      SnackbarUtil.success('User updated successfully')
    },
    onError: async (error) => {
      if (error?.request?.status === 403) {
        SnackbarUtil.error('You are not authorized to update this user')
        return
      }
      SnackbarUtil.error('Unable to update user')
    }
  })
  const userCreateMutation = useCreateUser({
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERIES.GET_ALL_USERS]
      })
      SnackbarUtil.success('User created successfully')

      history.push(`/users/edit/${data.id}`)
    },
    onError: async (error) => {
      if (error?.request?.status === 403) {
        SnackbarUtil.error('You are not authorized to create this user')
        return
      }
      SnackbarUtil.error('Unable to create user')
    }
  })

  const [showEditIcon, setShowEditIcon] = useState(false)
  const [userSelectedOrganizations, setUserSelectedOrganizations] = useState(
    user?.institutes?.filter((institute) => {
      return institutes.some(
        (instituteItem) => instituteItem.id === institute.id
      )
    }) || []
  )

  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      email: user?.email || '',
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      institutes: user?.institutes || [],
      defaultInstitute: user?.default_institute?.id || null,
      active: user?.is_active || true,
      role: user?.role || 'member'
    }
  })

  const onSubmit = async (data) => {
    if (user?.id) {
      await userUpdateMutation.mutate({
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        institutes: userSelectedOrganizations.map((institute) => institute.id),
        default_institute: data.defaultInstitute,
        is_active: data.active,
        role: data.role
      })
    } else {
      await userCreateMutation.mutate({
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        institutes: userSelectedOrganizations.map((institute) => institute.id),
        default_institute: data.defaultInstitute,
        is_active: data.active,
        role: data.role
      })
    }

    if (exitFunction) exitFunction()
    if (closeModalFunction) closeModalFunction()
  }

  useEffect(() => {
    const defaultInstitute = getValues('defaultInstitute')

    if (defaultInstitute) {
      const isDefaultInstituteInInstitutes = userSelectedOrganizations.find(
        (institute) => institute.id === defaultInstitute
      )

      if (!isDefaultInstituteInInstitutes) {
        setValue('defaultInstitute', null)
      }
    }

    // // If the selected organizations are empty, set the error for institutes field
    // if (userSelectedOrganizations && userSelectedOrganizations.length >= 0) {
    //   // If the error is set for institutes field, clear it
    //   if (errors.institutes) {
    //     clearErrors('institutes')
    //   }
    // }
  }, [userSelectedOrganizations])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={'center'}
      p={2}
      gap={1}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper
          sx={{
            // width: '40rem',
            paddingY: 2
          }}
          // variant="outlined"
          variant="elevation"
          elevation={elevation}
        >
          <Box>
            <Typography variant={'h1'}>
              {user?.id ? 'Edit User' : 'Create User'}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <input
              accept="image/*"
              id={'user-profile-image'}
              type="file"
              hidden
            />
            <IconButton
              sx={{
                borderRadius: '50%'
              }}
            >
              <label htmlFor={'user-profile-image'}>
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    position: 'relative',
                    cursor: 'pointer'
                  }}
                  onMouseEnter={() => {
                    setShowEditIcon(true)
                  }}
                  onMouseLeave={() => {
                    setShowEditIcon(false)
                  }}
                >
                  {showEditIcon && (
                    <Box position={'absolute'} zIndex={'10'}>
                      <Edit
                        sx={{
                          width: 40,
                          height: 40
                        }}
                      />
                    </Box>
                  )}
                  <Typography
                    variant={'h2'}
                    sx={{
                      opacity: showEditIcon ? 0.2 : 1
                    }}
                  >
                    {user && user?.first_name && user?.last_name
                      ? user?.first_name.charAt(0) + user?.last_name.charAt(0)
                      : 'Pic'}
                  </Typography>
                </Avatar>
              </label>
            </IconButton>
          </Box>
          <Box
            display={'flex'}
            flexGrow={1}
            justifyContent={'center'}
            paddingY={3}
          >
            {user && (
              <Typography variant={'subtitle1'}>{user?.email || ''}</Typography>
            )}
          </Box>

          <Box>
            <Box display={'flex'} flexDirection={'column'} gap={3}>
              <Box display={'flex'} gap={1}>
                {!user?.id && (
                  <Controller
                    rules={{
                      required: "Email can't be empty"
                    }}
                    render={({ field, fieldState, formState }) => {
                      const { onChange, value } = field
                      const { error } = fieldState
                      return (
                        <TextField
                          fullWidth={true}
                          size="small"
                          label={'Email'}
                          error={error !== undefined}
                          helperText={error?.message}
                          onChange={(e) => onChange(e.target.value)}
                          value={value || ''}
                        />
                      )
                    }}
                    control={control}
                    name={'email'}
                  />
                )}
                <Controller
                  control={control}
                  rules={{
                    required: "First Name can't be empty"
                  }}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field
                    const { error } = fieldState
                    return (
                      <TextField
                        fullWidth={true}
                        size="small"
                        label={'First Name'}
                        error={error !== undefined}
                        helperText={error?.message}
                        onChange={(e) => onChange(e.target.value)}
                        value={value || ''}
                      />
                    )
                  }}
                  name={'firstName'}
                />
                <Controller
                  control={control}
                  rules={{
                    required: "Last Name can't be empty"
                  }}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field
                    const { error } = fieldState
                    return (
                      <TextField
                        fullWidth={true}
                        size="small"
                        label={'Last Name'}
                        error={error !== undefined}
                        helperText={error?.message}
                        onChange={(e) => onChange(e.target.value)}
                        value={value || ''}
                      />
                    )
                  }}
                  name={'lastName'}
                />
              </Box>
              {institutes.length > 0 && (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Box
                    sx={{
                      overflowY: 'auto',
                      maxHeight: '200px'
                    }}
                  >
                    {userSelectedOrganizations?.map((institute) => (
                      <Chip
                        key={institute.id}
                        label={institute.name}
                        size="small"
                        clickable={false}
                        onDelete={() => {
                          setUserSelectedOrganizations(
                            userSelectedOrganizations.filter(
                              (userInstitute) =>
                                userInstitute.id !== institute.id
                            )
                          )
                        }}
                      />
                    ))}
                  </Box>
                  <Controller
                    rules={{
                      validate: (value) =>
                        userSelectedOrganizations.length > 0 ||
                        'Select at least one organization'
                    }}
                    render={({ field, fieldState }) => {
                      const { onChange, value } = field
                      const { error } = fieldState
                      return (
                        <Autocomplete
                          size="small"
                          multiple
                          value={[]}
                          getOptionLabel={(option) => option.name}
                          loadingText="Loading Organizations..."
                          id="institute-autocomplete"
                          options={
                            institutes?.filter(
                              (institute) =>
                                !userSelectedOrganizations?.some(
                                  (userInstitute) =>
                                    userInstitute.id === institute.id
                                )
                            ) || []
                          }
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Organizations"
                              error={error !== undefined}
                              helperText={error?.message}
                              size="small"
                              placeholder={'Select Organizations'}
                            />
                          )}
                          onChange={(e, newValue = []) => {
                            // add newValues to userSelectedOrganizations
                            setUserSelectedOrganizations([
                              ...userSelectedOrganizations,
                              ...newValue
                            ])

                            onChange(null)
                          }}
                        />
                      )
                    }}
                    name="institutes"
                    control={control}
                  />
                </Box>
              )}
              <Box display={'flex'} gap={1}>
                {institutes.length > 0 && (
                  <Box width={'50%'}>
                    <FormSelect
                      name="defaultInstitute"
                      control={control}
                      label={'Default Organization'}
                      rules={{
                        required: 'Default Organization is required'
                      }}
                      size={'small'}
                      fullWidth
                      options={institutes?.filter((institute) =>
                        userSelectedOrganizations?.some(
                          (userInstitute) => userInstitute.id === institute.id
                        )
                      )}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      errors={has(errors, 'defaultInstitute')}
                      defaultValue={getValues('defaultInstitute')?.id || ''}
                    />
                  </Box>
                )}
                <Box flexGrow={1}>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="active"
                        render={({ field }) => {
                          const { value, onChange } = field
                          return (
                            <Checkbox checked={value} onChange={onChange} />
                          )
                        }}
                      />
                    }
                    label={'Active'}
                  />
                </Box>
              </Box>
              <Box display={'flex'} flexGrow={1}>
                <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field }) => {
                      const { value, onChange } = field
                      return (
                        <RadioGroup
                          value={value || 'member'}
                          onChange={onChange}
                          row
                        >
                          <FormControlLabel
                            value="owner"
                            control={<Radio />}
                            label="Owner"
                          />
                          {hasRole('staff') && (
                            <FormControlLabel
                              value="staff"
                              control={<Radio />}
                              label="Staff"
                            />
                          )}
                          <FormControlLabel
                            value="admin"
                            control={<Radio />}
                            label="Admin"
                          />
                          <FormControlLabel
                            value="member"
                            control={<Radio />}
                            label="Member"
                          />
                          <FormControlLabel
                            value="guest"
                            control={<Radio />}
                            label="Guest"
                          />
                        </RadioGroup>
                      )
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'space-evenly'} paddingTop={5}>
            <Button
              variant={'outlined'}
              color={'error'}
              size={'small'}
              onClick={() => {
                if (hasRole('staff')) {
                  history.push('/users')
                } else {
                  history.push('/settings')
                  if (exitFunction) exitFunction()
                  if (closeModalFunction) closeModalFunction()
                }
              }}
              sx={{
                width: '8rem'
              }}
            >
              Cancel
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              size={'small'}
              sx={{
                width: '8rem'
              }}
            >
              {user?.id ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Paper>
      </form>
    </Box>
  )
}

export default UserCreateEditForm
