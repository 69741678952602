import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import Logo from 'assets/images/full_logo.png'
import { useSnackbar } from 'notistack'
import { authenticationService } from 'services'
import has from 'lodash/has'
// Component styles
import styles from './styles'

const useStyles = makeStyles(styles)

export default function PasswordResetConfirm (props) {
  const classes = useStyles()
  const history = useHistory()
  const { token } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  useEffect(() => {
    authenticationService.logout()
    setValue('token', token)
  }, [token])

  const onSubmit = (data) => {
    delete data.confirm_new_password
    authenticationService
      .passwordResetConfirm(token, data)
      .then(function (response) {
        if (response.status === 204) {
          enqueueSnackbar('Your password has been reset, please login with your new password.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          history.push('/login')
        } else {
          enqueueSnackbar('Oops! Something went wrong, please try again or contact support@motryx.com', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  return (
    <Box minHeight='80vh' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <Box>
        <Avatar className={classes.loginavatar} src={Logo.toString()} />
      </Box>
      <Box width={'100%'} maxWidth={'400px'}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h2">Reset Password Confirmation</Typography>
            <Typography variant="body1" className={classes.text}>
              {' '}
              {'Enter in your new password below and confirm it.'}
            </Typography>
            <form
              id="reset_password_confirm_form"
              data-testid="reset-password-confirm-test"
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={4} direction="column">
                <input name="token" type="hidden" value={token} {...register('token', { required: true })} />
                <Grid item>
                  <TextField
                    {...register('new_password', {
                      required: 'Enter a new password to reset it.',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                    id="new_password"
                    name="new_password"
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={newPassword}
                    onChange={ (event) => {
                      setNewPassword(event.target.value)
                      setValue('new_password', event.target.value)
                    }}
                    error={has(errors, 'new_password')}
                    helperText={errors.new_password && errors.new_password.message}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    {...register('confirm_new_password', {
                      required: 'Enter your new password again to reset it.',
                      validate: (value) => value === watch('new_password') || 'Passwords do not match.'
                    })}
                    id="confirm_password"
                    name="confirm_new_password"
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={ (event) => {
                      setConfirmPassword(event.target.value)
                      setValue('confirm_new_password', event.target.value)
                    }}
                    error={has(errors, 'confirm_new_password')}
                    helperText={errors.confirm_new_password && errors.confirm_new_password.message}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" fullWidth={true} variant="contained" color="primary">
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
