export default (theme) => ({
  searchLabel: { fontSize: '19px', fontWeight: 600 },
  searchSeperator: {
    fontSize: '12px',
    color: '#6b6b6b',
    fontWeight: 700,
    margin: 7
  },
  recordsMenu: {
    padding: 0,
    paddingLeft: theme.spacing(3),
    borderRadius: 0,
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.appBar,
    width: '100%'
  }
})
