import { authenticationService } from 'services'

export const getRoles = () => {
  let ROLES = []
  authenticationService.currentUser.subscribe((user) => {
    ROLES = user && user.roles
  })
  return ROLES
}

export const hasRole = (restrictRole) => {
  return getRoles()?.includes(restrictRole)
}

export const hasAnyRole = (restrict = []) => {
  return getRoles()?.some((role) => restrict.includes(role))
}

export const Role = ({ restrict = [], children, alternate = null }) => {
  if (hasAnyRole(restrict)) {
    return children
  }
  return alternate
}
