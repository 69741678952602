import { Box, Container, Typography } from '@mui/material'
import { InstituteForm } from 'forms'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { api } from 'services'

export default function InstituteSettingsView() {
  const { id } = useParams()
  const history = useHistory()
  const { data: instituteData } = useQuery(['institute-data', id], () =>
    api.institutes.get(id)
  )

  return (
    <Box p={2}>
      <Container maxWidth="md">
        <Box>
          <Typography variant="h1">
            {`${instituteData && instituteData.name} Settings`}
          </Typography>
        </Box>
        <InstituteForm
          id={id}
          exitFunction={() => {
            if (history) {
              history.goBack()
            } else {
              history.push(`/organization/${id}`)
            }
          }}
        />
      </Container>
    </Box>
  )
}
