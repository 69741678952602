import { Box, Button, Grid, MenuItem, TextField } from '@mui/material'
import { ReactHookFormSelect } from 'components'
import { QUERIES, SnackbarUtil } from 'helpers'
import { useGetInstitutes } from 'hooks/institutes'
import has from 'lodash/has'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { api } from 'services'
import useVitalVialManager from '../../hooks/useVitalVialManager'

const VitalVialForm = ({
  id = null,
  vial = null,
  exitFunction = null,
  closeModalFunction
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm()

  const { useCreateVitalVial, useUpdateVitalVial } = useVitalVialManager()
  const {
    data: vitalVialHardwareOptions,
    loading: vitalVialHardwareOptionsLoading
  } = useQuery({
    queryKey: [QUERIES.GET_VITAL_VIALS_HARDWARE_OPTIONS],
    queryFn: () => api.types.hardware()
  })

  const { data: getInstitutes, loading: getInstitutesLoading } =
    useGetInstitutes(null, null, { page: 0, size: 10000 })
  const createVitalVialMutation = useCreateVitalVial({
    onSuccess: () => {
      SnackbarUtil.success('Vital Vial created successfully', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    },
    onError: () => {
      SnackbarUtil.error('Error creating vital vial', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    }
  })

  const updateVitalVialMutation = useUpdateVitalVial({
    onSuccess: () => {
      SnackbarUtil.success('Vital Vial updated successfully', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    },
    onError: () => {
      SnackbarUtil.error('Error updating vital vial', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    }
  })

  const onSubmit = async (data) => {
    console.log(data)
    if (data.institute === '') {
      delete data.institute
    }
    if (data.notes === '') {
      data.notes = null
    }
    if (id) {
      await updateVitalVialMutation.mutateAsync({ id, data })
    } else {
      await createVitalVialMutation.mutateAsync(data)
    }

    exitFunction && exitFunction()
    closeModalFunction()
  }

  useEffect(() => {
    if (vial) {
      setValue('label', vial.label)
      setValue(
        'version',
        vial.version &&
          !vitalVialHardwareOptionsLoading &&
          vitalVialHardwareOptions
          ? () => {
              const vialOption = vitalVialHardwareOptions.find(
                (hardware) => hardware.value === vial.version
              )
              return vialOption.name
            }
          : ''
      )
      setValue(
        'institute',
        vial.institute && !getInstitutesLoading && getInstitutes?.items
          ? () => {
              return getInstitutes.items.find(
                (institute) => institute.id === vial.institute.id
              )
            }
          : ''
      )
      setValue('notes', vial.notes)
    }
  }, [
    vial,
    getInstitutes,
    getInstitutesLoading,
    vitalVialHardwareOptions,
    vitalVialHardwareOptionsLoading
  ])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('label', {
                required: 'Label is required'
              })}
              label={'Label'}
              variant={'outlined'}
              fullWidth
              size={'small'}
              error={has(errors, 'label')}
              helperText={has(errors, 'label') && errors.label.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormSelect
              disbaled={vitalVialHardwareOptionsLoading}
              name={'version'}
              label={'Version'}
              control={control}
              rules={{
                required: 'Version is required'
              }}
              defaultValue={''}
              fullWidth
              size={'small'}
              error={has(errors, 'version')}
              helperText={has(errors, 'version') && errors.version.message}
            >
              {vitalVialHardwareOptions &&
                vitalVialHardwareOptions.map(({ name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormSelect
              disbaled={getInstitutesLoading}
              name={'institute'}
              label={'Institute'}
              control={control}
              defaultValue={''}
              fullWidth
              size={'small'}
              error={has(errors, 'institute')}
              helperText={has(errors, 'institute') && errors.institute.message}
            >
              <MenuItem value={''}>None</MenuItem>
              {getInstitutes?.items &&
                getInstitutes.items.map((institute, index) => {
                  return (
                    <MenuItem key={index} value={institute}>
                      {institute.partner_id
                        ? `${institute.name} - ${institute.partner_id}`
                        : institute.name}
                    </MenuItem>
                  )
                })}
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <TextField
              {...register('notes')}
              name={'notes'}
              label={'Notes'}
              placeholder="Add notes related to this contract"
              multiline
              rows={7}
              maxRows={999}
              control={control}
              defaultValue={''}
              fullWidth
              error={has(errors, 'notes')}
              helperText={has(errors, 'version') && errors?.notes?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant={'contained'}
                size={'small'}
                type={'submit'}
                sx={{
                  width: '140px'
                }}
              >
                {vial ? 'Update Vial' : 'Create Vial'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default VitalVialForm
