import Add from '@mui/icons-material/Add'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { EntryBox, Label, Modal } from 'components'
import LabelForm from 'forms/LabelForm'
import has from 'lodash/has'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { api, apiConfig } from 'services'

export default function LineForm({
  id,
  closeModalFunction,
  exitFunction = () => null,
  updateExternalForm = null
}) {
  const label =
    apiConfig.currentApiInstituteType === 'courier' ? 'Route' : 'PTS Line'
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { manufacturer: null }
  })
  const unmounted = useRef(false)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [manufacturer, setManufacturer] = useState('')
  const [lineLength, setLineLength] = useState('')

  const [manufacturerOptions, setManufacturerOptions] = useState()

  const [selectedLabels, setSelectedLabels] = useState([])
  const [addLabelOpen, setAddLabelOpen] = useState(false)

  const [handCarried, setHandCarried] = useState(false)
  const [archived, setArchived] = useState(false)

  const handleManufacturerChange = (event) => {
    setManufacturer(event.target.value)
    setValue('manufacturer', event.target.value)
  }

  const handleHandCarried = (event) => {
    setHandCarried(event.target.checked)
    setValue('is_hand_carried', event.target.checked)
    if (event.target.checked) {
      setManufacturer('')
      setValue('manufacturer', null)
    }
  }
  const handleArchive = (event) => {
    setArchived(event.target.checked)
    setValue('archived', event.target.checked)
  }

  const handleLabelChange = (event, labels) => {
    setSelectedLabels(labels)
    const newLabels = labels.map((label) => label.id)
    setValue('labels', newLabels)
  }

  const handleClose = () => {
    if (exitFunction) exitFunction()
    if (closeModalFunction) closeModalFunction()
  }

  const handleError = (error) => {
    if (error.status === 422) {
      const field = error.data.detail[0].loc[1]
      const message = error.data.detail[0].msg
      setError(field, {
        type: 'manual',
        message: message
      })
    }
    console.log(error)

    enqueueSnackbar(
      'There was an issue with the form data. Please check the fields below.',
      {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }
    )
  }
  // Form Submission Handling
  const onSubmit = async (data) => {
    if (!isSubmitting) {
      if (data.length === '') data.length = null
      if (id) {
        api.lines
          .update(id, data)
          .then(function (data) {
            enqueueSnackbar(`Changes to ${data.name} have been saved.`, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            handleClose()
          })
          .catch((error) => {
            handleError(error)
          })
      } else {
        api.lines
          .create(data)
          .then(function (data) {
            enqueueSnackbar('Your new line has been saved.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            if (updateExternalForm) updateExternalForm(data.id)
            if (closeModalFunction) closeModalFunction()
            exitFunction()
          })
          .catch((error) => {
            handleError(error.response)
          })
      }
    }
  }

  const { data: labelOptions, refetch: refetchLabels } = useQuery(
    ['line-labels', apiConfig.currentApiInstitute],
    () =>
      api.labels.list().then((data) => {
        const newLabels = selectedLabels.map((label) => label.id)
        setValue('labels', newLabels)
        return data
      })
  )

  useEffect(() => {
    register('labels')
    register('manufacturer')
    api.types.manufacturers().then((data) => {
      setManufacturerOptions(data)
    })
  }, [register])

  useEffect(() => {
    if (id) {
      api.lines
        .get(id)
        .then((data) => {
          if (!unmounted.current) {
            setArchived(data.archived)
            setValue('archived', data.archived)
            setHandCarried(data.is_hand_carried)
            setValue('is_hand_carried', data.is_hand_carried)

            setValue('name', data.name)
            setName(data.name)
            setValue('description', data.description)
            setDescription(data.description)
            setValue('length', data.length)
            setLineLength(data.length)
            setManufacturer(data.manufacturer ? data.manufacturer : '')
            setValue(
              'manufacturer',
              data.manufacturer ? data.manufacturer : null
            )
            setSelectedLabels(data.labels)
            setValue(
              'labels',
              data.labels.map((label) => label.id)
            )
          }
        })
        .catch((error) => {
          if (!unmounted.current) {
            console.warn(JSON.stringify(error, null, 2))
          }
        })
    }

    return () => {
      unmounted.current = true
    }
  }, [setValue, register, id, getValues])
  return (
    <>
      <form
        id="line_form"
        data-testid="line-form-test"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" flexDirection="column">
          <Box py={1}>
            <EntryBox label="Name">
              <TextField
                {...register('name', {
                  required: 'Name is a required field.',
                  maxLength: {
                    value: 80,
                    message: 'Name is too long.'
                  },
                  pattern: {
                    value: /^[A-Za-z][A-Za-z0-9#\-_\s]*$/g,
                    message:
                      'Name must start with a alphabet A-Z. Rest of the characters can be letter, numbers and #, -, _.'
                  }
                })}
                name="name"
                id="name"
                variant="outlined"
                value={name || ''}
                onChange={(event) => {
                  setName(event.target.value)
                  setValue('name', event.target.value)
                }}
                error={has(errors, 'name')}
                helperText={errors.name ? errors.name.message : ''}
                inputProps={{ 'data-testid': 'name-test' }}
                fullWidth
              />
            </EntryBox>
          </Box>
          <Box py={1}>
            <EntryBox label="Description">
              <TextField
                {...register('description')}
                name="description"
                id="description"
                variant="outlined"
                value={description || ''}
                onChange={(event) => {
                  setDescription(event.target.value)
                  setValue('description', event.target.value)
                }}
                inputProps={{ 'data-testid': 'description-test' }}
                fullWidth
              />
            </EntryBox>
          </Box>
          <Box py={1}>
            <EntryBox label="Hand Carried">
              <Checkbox
                {...register('is_hand_carried')}
                name="is_hand_carried"
                id="is-hand-carried"
                checked={handCarried}
                onChange={handleHandCarried}
                inputProps={{ 'data-testid': 'hand-carried-test' }}
                color="primary"
                sx={{
                  padding: 0
                }}
              />
            </EntryBox>
          </Box>
          <Collapse in={!handCarried} data-testid="hand-carried-collapse">
            <Box py={1}>
              <EntryBox label="Manufacturer">
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={has(errors, 'manufacturer')}
                >
                  <Select
                    name="manufacturer"
                    labelId="manufacturer-input-label"
                    value={manufacturer}
                    onChange={handleManufacturerChange}
                    SelectDisplayProps={{
                      'data-testid': 'manufacturer-select'
                    }}
                    inputProps={{
                      'data-testid': 'manufacturer-input'
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {manufacturerOptions &&
                      manufacturerOptions.map((manufacturerOption) => {
                        return (
                          <MenuItem
                            key={`${manufacturerOption.value}`}
                            value={manufacturerOption.value}
                          >
                            {manufacturerOption.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                  <FormHelperText>
                    {errors.manufacturer &&
                      errors.manufacturer.type === 'required' &&
                      'Manufacturer is a required field.'}
                  </FormHelperText>
                </FormControl>
              </EntryBox>
            </Box>
          </Collapse>
          <Box display={id ? 'flex' : 'none'} py={1}>
            <EntryBox label="Archived">
              <Checkbox
                {...register('archived')}
                name="archived"
                id="archived"
                color="primary"
                checked={archived}
                inputProps={{ 'data-testid': 'archive-test' }}
                onChange={handleArchive}
              />
            </EntryBox>
          </Box>
          <Box py={1}>
            <EntryBox label="Length (m)">
              <TextField
                name="length"
                id="length"
                variant="outlined"
                {...register('length')}
                value={lineLength || ''}
                onChange={(event) => {
                  setLineLength(event.target.value)
                  setValue('length', event.target.value)
                }}
                inputProps={{ 'data-testid': 'length-test' }}
                type="number"
                fullWidth
                error={has(errors, 'length')}
                helperText={errors.length && errors.length.message}
              />
            </EntryBox>
          </Box>
          <Box py={1}>
            <EntryBox label="Labels">
              {labelOptions && (
                <Autocomplete
                  multiple
                  id="labels"
                  name="labels"
                  data-testid="test-labels-autocomplete"
                  value={selectedLabels}
                  options={labelOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={handleLabelChange}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Label
                        label={option.label}
                        color={option.color}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Label label={option.label} color={option.color} />
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<Add />}
                  onClick={() => setAddLabelOpen(true)}
                >
                  {'New Label'}
                </Button>
                <Link
                  to={{
                    pathname: '/labels',
                    state: { name: id ? getValues('name') : `${label}s` }
                  }}
                >
                  {' '}
                  <Button size="small" color="secondary">
                    Manage Labels
                  </Button>
                </Link>
              </Box>
            </EntryBox>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            py={1}
          >
            <Box pr={1}>
              <Button
                type="submit"
                role="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                size="small"
              >
                Save
              </Button>
            </Box>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
      {addLabelOpen && (
        <Modal
          maxWidth={'xs'}
          open={addLabelOpen}
          label={'Create New Label'}
          onClose={() => setAddLabelOpen(false)}
        >
          <LabelForm
            exitFunction={refetchLabels}
            updateForm={{ labels: selectedLabels }}
          />
        </Modal>
      )}
    </>
  )
}
