import { Box, Button, Divider, Typography } from '@mui/material'
import {
  Gauge,
  InfoBox,
  InfoButton,
  InlineError,
  NumberWidget,
  StyledDocLink,
  Validated
} from 'components'
import {
  ValidationDate,
  getCumulativeStatistics,
  getValidationStatistics,
  rejectionColor
} from 'helpers'
import { useHistory, useParams } from 'react-router-dom'
import { Flag, hasRole, useMetricsContext } from 'services'

function StatsRow({
  stats,
  lineData,
  title = 'Overall',
  showLastValidated = false
}) {
  const history = useHistory()
  const { metricType, metricLabel } = useMetricsContext()
  const { id } = useParams()

  return (
    stats && (
      <>
        <Box display="flex" flexDirection={'column'} gap={2}>
          <Box>
            <Box
              display={'flex'}
              width={'100%'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h6">{`${title} Metrics`}</Typography>
              <Box>
                {showLastValidated ? (
                  <Flag name="validation">
                    {lineData.validation && (
                      <Validated
                        justifyContent="flex-start"
                        childrenSpacing={1}
                      >
                        <ValidationDate line={lineData} />
                      </Validated>
                    )}
                  </Flag>
                ) : (
                  <Box>
                    {lineData.length !== null ? (
                      <Flag name="troubleshooting">
                        <Box display={'flex'} alignItems={'baseline'}>
                          {stats[metricType].performance !== null && (
                            <NumberWidget
                              numAlign={'center'}
                              size="small"
                              num={
                                <Gauge
                                  value={stats[metricType].performance}
                                  width={35}
                                />
                              }
                              label={
                                <Box
                                  display="flex"
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  {'Performance'}
                                </Box>
                              }
                            />
                          )}

                          <InfoButton>
                            <InfoBox>
                              {
                                'Performance rating, where higher performance indicates less vibration per meter of PTS line.'
                              }
                            </InfoBox>
                          </InfoButton>
                        </Box>
                      </Flag>
                    ) : (
                      <Box
                        display="flex"
                        gap={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          disabled={hasRole('guest')}
                          variant="contained"
                          size="small"
                          onClick={() => {
                            history.push({
                              pathname: `/line/${id}/settings`
                            })
                          }}
                          data-testid="add-length-button"
                        >
                          Add Line Length
                        </Button>
                        <InfoButton
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          <InfoBox>
                            <Flag
                              name="troubleshooting"
                              alternate={
                                'Add the length of this line to get the estimated speed.'
                              }
                            >
                              {
                                'Add the length of this line to get the estimated speed and performance.'
                              }
                            </Flag>
                          </InfoBox>
                        </InfoButton>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={'row'}
              width={'100%'}
              justifyContent={'space-between'}
            >
              {stats[metricType].mean !== null && (
                <NumberWidget
                  num={stats[metricType].mean}
                  label={metricLabel}
                  mr={3}
                />
              )}
              {stats[metricType].cv !== null && (
                <NumberWidget
                  num={`${stats[metricType].cv} %`}
                  label={'Variability'}
                  mr={2}
                />
              )}
              {stats.temperature.mean !== null && (
                <NumberWidget
                  num={`${stats.temperature.mean} °C`}
                  label={'Temperature'}
                  mr={2}
                />
              )}
              {stats.estimated_speed !== null && (
                <NumberWidget
                  num={`${stats.estimated_speed} m/s`}
                  label={
                    <Box display="flex" alignItems={'center'} gap={1}>
                      {'Average Speed'}
                      <InfoButton fontSize={'inherit'}>
                        <InfoBox>
                          {
                            'The average line speed, estimated using the line length and VitalVial acceleration measurements.'
                          }
                        </InfoBox>
                      </InfoButton>
                    </Box>
                  }
                />
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="h6">Rejection Probability</Typography>
            <Box display={'flex'} flexDirection={'row'} gap={2}>
              {stats &&
                stats[metricType].rejection_rates &&
                stats[metricType].rejection_rates !== null && (
                  <NumberWidget
                    num={`${stats[metricType].rejection_rates.ldh} %`}
                    label={'LDH'}
                    rejectionColor={rejectionColor(
                      stats[metricType].rejection_rates.ldh
                    )}
                    mr={2}
                  />
                )}

              {stats &&
                stats[metricType].rejection_rates &&
                stats[metricType].rejection_rates !== null && (
                  <NumberWidget
                    num={`${stats[metricType].rejection_rates.asat} %`}
                    label={'ASAT'}
                    rejectionColor={rejectionColor(
                      stats[metricType].rejection_rates.asat
                    )}
                    mr={2}
                  />
                )}

              {stats &&
                stats[metricType].rejection_rates &&
                stats[metricType].rejection_rates !== null && (
                  <NumberWidget
                    num={`${stats[metricType].rejection_rates.potassium} %`}
                    label={'K'}
                    rejectionColor={rejectionColor(
                      stats[metricType].rejection_rates.potassium
                    )}
                  />
                )}
            </Box>
          </Box>
        </Box>
      </>
    )
  )
}

export default function SummaryView({ lineData }) {
  const cumulativeStatistics = getCumulativeStatistics(lineData)
  const validationStatistics = getValidationStatistics(lineData)

  return (
    <Box display="flex" flexDirection="column">
      {cumulativeStatistics ? (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <StatsRow stats={cumulativeStatistics} lineData={lineData} />
          {validationStatistics && <Divider />}
          <StatsRow
            stats={validationStatistics}
            lineData={lineData}
            title="Validation"
            showLastValidated={true}
          />
        </Box>
      ) : (
        <InlineError
          title="No Data"
          detail={
            <Typography>
              {'There are no records associated with this line. '}
              <StyledDocLink to={'/getting-started'}>
                {'Add data by recording and uploading.'}
              </StyledDocLink>
            </Typography>
          }
        />
      )}
    </Box>
  )
}
