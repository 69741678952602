import { useFormContext } from 'react-hook-form'
import { Component } from 'react'

const withFromContext = (Component) => {
  return (props) => {
    const methods = useFormContext()
    return <Component {...props} {...methods} />
  }
}

export { withFromContext }
