import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import Gauge from '../Gauge/index.js'
import NumberWidget from '../NumberWidget/index.js'
import StyledText from '../StyledText/index.js'
import RejectionColumn from '../RejectionColumn/index.js'

const Row = ({ data = null, border = true }) => {
  const styles = StyleSheet.create({
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      padding: '10px 0px',
      borderBottom: border ? '0.5px solid #D6DAE0' : 'none'
    },
    section: {
      marginRight: 5
    }
  })
  return (
    <View style={ styles.content }>
      <View style={{ marginRight: 15, width: 170 }}>
        <StyledText fontSize={ 12 } fontWeight={ 600 } margin={ '0px 0px 5px 0px' }>{ data?.line_name }</StyledText>
        <StyledText fontSize={ 10 } fontWeight={ 600 } margin={ 0 } color={ '#6b6b6b' }>{ data?.line_length }{ data?.line_length ? ' m' : ' ' }</StyledText>
      </View>
      <View style={ styles.section }>
        <NumberWidget num={ data?.record_count } label={ 'Records' }/>
      </View>
      <View style={ styles.section }>
        <NumberWidget num={ data?.auc?.mean } label={ 'VitalMetric' }/>
      </View>
      <View style={ styles.section }>
        <NumberWidget num={ data?.auc?.cv } label={ 'Variability' } unit={ '%' }/>
      </View>
      { data?.auc?.performance &&
        <View style={ styles.section }>
          <NumberWidget num={ <Gauge value={ data?.auc?.performance } /> } label={ 'Performance' } isGauge={ true }/>
        </View>
      }
      <View>
        <StyledText fontSize={ 10 } margin="0px 0px 5px 0px">{ 'Rejection Probability' }</StyledText>
        <RejectionColumn data={ data?.auc?.rejection_rates }/>
      </View>
    </View>
  )
}

export default Row
