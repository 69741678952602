import Add from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  MainContent,
  Modal,
  ReportStatus,
  ValidationReportStatus
} from 'components'
import { Redirect, useHistory } from 'react-router-dom'
import { api, Flag, hasRole } from 'services'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Cancel, Check, Close } from '@mui/icons-material'
import { format, parseISO } from 'date-fns'

import { QUERIES } from 'helpers'

const DATE_FORMAT = 'yyyy MMM dd'

const ValidationFilters = ({ onChange }) => {
  return (
    <Box display="flex" flexDirection="row">
      <TextField
        label="Search by Name"
        variant="outlined"
        size="small"
        onChange={onChange}
      />
    </Box>
  )
}

const ValidationDataGrid = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [validationRows, setValidationRows] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [rowCount, setRowCount] = useState(0)
  const [searchValidationByName, setSearchValidationByName] = useState('')

  const {
    data: getValidationsData,
    isLoading: isGetValidationsLoading,
    refetch: refetchGetValidation
  } = useQuery({
    queryKey: [
      QUERIES.GET_VALIDATIONS,
      { page, pageSize, searchValidationByName }
    ],
    queryFn: async () => {
      let params = {
        page: page,
        size: pageSize,
        name__icontains: searchValidationByName || ''
      }

      const response = await api.validations.list(params)

      // Set total count for pagination
      setRowCount(response?.total || 0)
      return response?.items || []
    }
  })

  const handleValidationSearchByNameChange = (event) => {
    setSearchValidationByName(event.target.value)
  }

  const handleValidationRowRefresh = async (row) => {
    console.log('Calling Exit Function', row)
    await queryClient.refetchQueries([QUERIES.GET_VALIDATIONS])
    // Refetch the data for the given validation row
    // Update the validationRows state by only updating the row that was refreshed
  }

  const validationColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.name} placement="right-end">
            <Link
              underline="none"
              fontWeight={600}
              onClick={() => {
                history.push(`/validation/${params.row.id}`)
              }}
              sx={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {params.row.name}
            </Link>
          </Tooltip>
        )
      }
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      flex: 1,
      renderCell: (params) => {
        const startDate = parseISO(params.row.start_date)
        return `${format(startDate, DATE_FORMAT)}`
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      flex: 1,
      renderCell: (params) => {
        const startDate = parseISO(params.row.start_date)
        // Duration in days
        const duration = params.row.duration_days * 24 * 60 * 60 * 1000

        const endDate = new Date(startDate.getTime() + duration)
        return `${format(endDate, DATE_FORMAT)}`
      }
    },
    {
      field: 'baseline',
      headerName: 'Update Baseline',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.baseline ? (
          <Check
            sx={{
              color: 'success.main'
            }}
          />
        ) : (
          <Cancel
            sx={{
              color: 'error.main'
            }}
          />
        )
      }
    },
    {
      field: 'lines',
      headerName: 'Lines Count',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.value.length
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        switch (params.value) {
          case 'inprogress':
            return 'In Progress'
          case 'completed':
            return 'Completed'
          default:
            return 'Unknown'
        }
      }
    },
    {
      field: 'report-status',
      headerName: 'Report',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <ReportStatus
          validation={params.row}
          exitFunction={() => handleValidationRowRefresh(params.row)}
        />
      )
    }
  ]

  useEffect(async () => {
    await queryClient.invalidateQueries({
      queryKey: [QUERIES.GET_VALIDATIONS]
    })
  }, [searchValidationByName])

  useEffect(() => {
    if (!isGetValidationsLoading) {
      setValidationRows(getValidationsData || [])
    }
  }, [getValidationsData, isGetValidationsLoading])

  return (
    <Box display="flex" flexDirection="column">
      <ValidationFilters onChange={handleValidationSearchByNameChange} />
      <DataGrid
        rows={validationRows}
        columns={validationColumns}
        loading={isGetValidationsLoading}
        autoHeight
        page={page}
        onPageChange={(newPage) => {
          setPage(newPage)
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize)
        }}
        disableSelectionOnClick
        rowCount={rowCount}
        paginationMode="server"
        width={'100%'}
        rowsPerPageOptions={[10, 20, 50, 100]}
        sx={{
          //   Set min row height to 48px
          '& .MuiDataGrid-row': {
            minHeight: '48px !important'
          }
        }}
        components={{
          noRowsOverlay: () => {
            return (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Typography variant="h5">{'No Validations Found'}</Typography>
              </Box>
            )
          }
        }}
      />
    </Box>
  )
}

export default function Validations() {
  const history = useHistory()
  return (
    <MainContent>
      <Flag
        name={'validation'}
        alternate={<Redirect to={{ pathname: '/dashboard' }} />}
      >
        <Box paddingY={2} paddingX={4} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1}>
              <Typography variant={'h1'}>{'Validations'}</Typography>
            </Box>
            <Box display={'flex'} gap={2}>
              <Button
                disabled={hasRole('guest')}
                color="primary"
                variant="contained"
                size="small"
                startIcon={<Add />}
                onClick={() => {
                  history.push('/validation/new')
                }}
                data-testid="new-validation-button"
              >
                New Validation
              </Button>
            </Box>
          </Box>
          <ValidationDataGrid />
        </Box>
      </Flag>
    </MainContent>
  )
}
