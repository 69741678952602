import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { SnackbarUtil } from 'helpers'
import { authHeader } from 'helpers/auth-header'
import { BehaviorSubject } from 'rxjs'
import { authenticationService } from 'services'

const axios = require('axios')

const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
})

axios_instance.interceptors.request.use(
  (config) => {
    config.headers = authHeader()
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axios_instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const originalRequest = error.config
    if (!error.response || error.response.status === 504) {
      SnackbarUtil.error(
        'Connection Interrupted – Please check your internet connection or wait while we try to reconnect.',
        { preventDuplicate: true, persist: true }
      )
    } else if (error.response.status === 404 && !originalRequest._retry) {
      try {
        if (originalRequest.params instanceof URLSearchParams) {
          originalRequest.params.delete('page')
        } else {
          delete originalRequest.params.page
        }
        originalRequest._retry = true
        return axios(originalRequest)
      } catch (e) {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

const refreshAuthLogic = (failedRequest) =>
  authenticationService.refresh(failedRequest)
createAuthRefreshInterceptor(axios_instance, refreshAuthLogic)

// Create the API Config
const currentApiConfig = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentApi'))
)

function setApiConfig(
  institute_id,
  institute_type = 'pts',
  institute_name = ''
) {
  let config = null
  if (institute_id !== null) {
    config = {
      institute: institute_id,
      institute_type: institute_type,
      institute_name: institute_name
    }
    localStorage.setItem('currentApi', JSON.stringify(config))
  }
  currentApiConfig.next(config)
  return config
}

export const apiConfig = {
  setApiConfig,
  currentApi: currentApiConfig.asObservable(),
  get currentApiInstitute() {
    return (
      currentApiConfig?.value?.institute ||
      authenticationService?.currentUserValue?.institutes[0]
    )
  },
  get currentApiInstituteType() {
    return currentApiConfig.value && currentApiConfig.value.institute_type
      ? currentApiConfig.value.institute_type
      : 'pts'
  },
  get currentApiInstituteName() {
    return currentApiConfig.value && currentApiConfig.value.institute_name
      ? currentApiConfig.value.institute_name
      : null
  }
}

export const apiGet = (path, query = {}, timeout = 60000) =>
  axios_instance
    .get(path, { params: query, timeout: timeout })
    .then((response) => response.data)
export const apiPost = (path, data, timeout = 60000) =>
  axios_instance
    .post(path, data, { timeout: timeout })
    .then((response) => response.data)
export const apiPut = (path, data) =>
  axios_instance.put(path, data).then((response) => response.data)
export const apiPatch = (path, data) =>
  axios_instance.patch(path, data).then((response) => response.data)
export const apiDelete = (path) =>
  axios_instance.delete(path).then((response) => response.data)

const analyzer = {
  get: (id) => apiGet(`/v2/institutes/${id}/analyzer`),
  update: (id, data) => apiPatch(`/v2/institutes/${id}/analyzer`, data),
  getRejectionRates: (id) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/analyzer/rejection?value=${id}`
    )
}

const auth = {
  getRegisterToken: (token) => apiGet(`/v2/auth/register/${token}`),
  account: (data) => apiPost('/v2/auth/account', data),
  register: (data) => apiPost('/v2/auth/register', data)
}

const clinicalData = {
  list: (params) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/clinical-data`,
      params
    ),
  get: (id) => apiGet(`/v2/clinical-data/${id}`),
  create: (data) =>
    apiPost(
      `/v2/institutes/${apiConfig.currentApiInstitute}/clinical-data`,
      data
    ),
  update: (id, data) => apiPatch(`/v2/clinical-data/${id}`, data),
  delete: (id) => apiDelete(`/v2/clinical-data/${id}`)
}

const comments = {
  list: (resource, id) => apiGet(`/v2/${resource}/${id}/comments`),
  get: (id) => apiGet(`/v2/comments/${id}`),
  create: (id, data, resource) =>
    apiPost(`/v2/${resource}/${id}/comments`, data),
  update: (id, data) => apiPatch(`/v2/comments/${id}`, data),
  delete: (id) => apiDelete(`/v2/comments/${id}`)
}

const featureFlags = {
  getActiveInstitute: () =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}`),
  getDefaults: () => apiGet('/v2/feature-flags/defaults')
}

const hemolysisData = {
  list: (params) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/hemolysis-index-data`,
      params
    ),
  summary: (params) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/hemolysis-index/summary`,
      params
    ),
  get: (id) => apiGet(`/v2/hemolysis-index-data/${id}`),
  getMeasurements: (id, params) =>
    apiGet(`/v2/hemolysis-index-data/${id}/measurements`, params),
  create: (data) =>
    apiPost(
      `/v2/institutes/${apiConfig.currentApiInstitute}/hemolysis-index-data`,
      data
    ),
  update: (id, data) => apiPatch(`/v2/hemolysis-index-data/${id}`, data),
  delete: (id) => apiDelete(`/v2/hemolysis-index-data/${id}`)
}

const hemolysisMeasurements = {
  list: (params) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/hemolysis-index-measurement`,
      params
    ),
  get: (id) => apiGet(`/v2/hemolysis-index-measurement/${id}`),
  update: (id, data) => apiPatch(`/v2/hemolysis-index-measurement/${id}`, data)
}

const institutes = {
  list: (params) => apiGet('/v2/institutes', params),
  get: (id) => apiGet(`/v2/institutes/${id}`),
  getUploadSummary: (params) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/upload-summary`,
      params
    ),
  create: (data) => apiPost('/v2/institutes', data),
  update: (id, data) => apiPatch(`/v2/institutes/${id}`, data),
  delete: (id) => apiDelete(`/v2/institutes/${id}`)
}

const labels = {
  list: (params) =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}/labels`, params),
  get: (id) => apiGet(`/v2/labels/${id}`),
  create: (data) =>
    apiPost(`/v2/institutes/${apiConfig.currentApiInstitute}/labels`, data),
  update: (id, data) => apiPatch(`/v2/labels/${id}`, data),
  delete: (id) => apiDelete(`/v2/labels/${id}`)
}

const lines = {
  list: (params) =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}/lines`, params),
  get: (id) => apiGet(`/v2/lines/${id}`),
  create: (data) =>
    apiPost(`/v2/institutes/${apiConfig.currentApiInstitute}/lines`, data),
  update: (id, data) => apiPatch(`/v2/lines/${id}`, data),
  getValidations: (id) => apiGet(`/v2/lines/${id}/validations`)
}

const reports = {
  list: (params) =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}/reports`, params),
  get: (id) => apiGet(`/v2/reports/${id}`),
  update: (id, data) => apiPatch(`/v2/reports/${id}`, data),
  create: (data) =>
    apiPost(`/v2/institutes/${apiConfig.currentApiInstitute}/reports`, data),
  createValidationReport: (instituteId, validationId, data) => {
    console.log('Creating Validation Report', instituteId, validationId, data)
    return apiPost(
      `/v2/reports/institutes/${instituteId}/validations/${validationId}`,
      data
    )
  },
  delete: (id) => apiDelete(`/v2/reports/${id}`)
}

const records = {
  list: (params) =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}/records`, params),
  listReviewedOnly: (params) =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}/records`, {
      ...params,
      is_reviewed: true,
      is_processed: true
    }),
  summary: () =>
    apiGet(`/v2/institutes/${apiConfig.currentApiInstitute}/records/summary`),
  get: (id) => apiGet(`/v2/records/${id}`),
  update: (id, data) => apiPatch(`/v2/records/${id}`, data),
  upload: (institute_id, data) =>
    axios_instance.post(`/v2/institutes/${institute_id}/record-upload`, data, {
      timeout: 0
    }),
  acceleration: (id) =>
    apiGet(`/v2/records/${id}/data?type=acceleration`, null, 300000),
  temperature: (id) =>
    apiGet(`/v2/records/${id}/data?type=temperature`, null, 300000),
  cumulative: (id, params) =>
    apiGet(`v2/records/${id}/cumulative`, params, 300000)
}

const types = {
  hardware: () => apiGet('/v2/types/vials/versions'),
  manufacturers: () => apiGet('/v2/types/manufacturers'),
  analyzers: () => apiGet('/v2/types/analyzer/makes'),
  instituteRelationships: () => apiGet('/v2/types/institutes/relationship')
}

const users = {
  listAll: (params) => apiGet('/v2/users', params),
  list: (id, params) => apiGet(`/v2/institutes/${id}/users`, params),
  get: (email) => apiGet(`/v2/users/${email}`),
  getById: (id) => apiGet(`/v2/users/id/${id}`),
  create: (institute_id, data) =>
    apiPost(`/v2/institutes/${institute_id}/users`, data),
  update: (email, data) => apiPatch(`/v2/users/${email}`, data),
  delete: (email) => apiDelete(`/v2/users/${email}`),
  revoke: (id, email) => apiDelete(`/v2/institutes/${id}/users/${email}`),
  resendInvite: (email) => apiPost(`/v2/users/${email}/resend`)
}

const validations = {
  list: (params) =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/validations`,
      params
    ),
  latest: () =>
    apiGet(
      `/v2/institutes/${apiConfig.currentApiInstitute}/validations/latest`
    ),
  get: (id) => apiGet(`/v2/validations/${id}`),
  getSummary: (id) => apiGet(`/v2/validations/${id}/summary`),
  getLines: (id) => apiGet(`/v2/validations/${id}/lines`),
  completeValidation: (id) => apiPost(`/v2/validations/${id}/complete`),
  requestReport: (id) => apiPost(`/v2/validations/${id}/request-report`),
  create: (data) =>
    apiPost(
      `/v2/institutes/${apiConfig.currentApiInstitute}/validations`,
      data
    ),
  update: (id, data) => apiPatch(`/v2/validations/${id}`, data),
  delete: (id) => apiDelete(`/v2/validations/${id}`)
}

const vials = {
  list: (params) => apiGet('/v2/vials', params),
  get: (id) => apiGet(`/v2/vials/${id}`),
  create: (data) => apiPost('/v2/vials', data),
  update: (id, data) => apiPatch(`/v2/vials/${id}`, data),
  delete: (id) => apiDelete(`/v2/vials/${id}`)
}

const contracts = {
  list: (params) => apiGet('/v2/contracts', params),
  get: (id) => apiGet(`/v2/contracts/${id}`),
  create: (data) => apiPost('/v2/contracts', data),
  update: (id, data) => apiPatch(`/v2/contracts/${id}`, data),
  delete: (id) => apiDelete(`/v2/contracts/${id}`),
  uploadFiles: (id, contractFiles) =>
    apiPost(`/v2/contracts/${id}/upload`, contractFiles),
  deleteFile: (id, filenames) => apiPost(`/v2/contracts/${id}/files`, filenames)
}

export const api = {
  analyzer,
  auth,
  clinicalData,
  contracts,
  comments,
  featureFlags,
  hemolysisData,
  hemolysisMeasurements,
  institutes,
  labels,
  lines,
  records,
  reports,
  types,
  users,
  validations,
  vials
}
