import { Box, Container, Typography } from '@mui/material'
import { MainContent } from 'components'
import { HemolysisDataForm } from 'forms'
import { useHistory, useParams } from 'react-router-dom'

export default function SettingsView({ hemolysisData, refresh }) {
  const { id } = useParams()
  const history = useHistory()

  return (
    <MainContent>
      <Box p={2}>
        <Container maxWidth="sm">
          <Box pb={2}>
            {hemolysisData && (
              <Typography variant="h1">
                {`${hemolysisData.title || hemolysisData.filename} Settings`}
              </Typography>
            )}
          </Box>
          <HemolysisDataForm
            id={id}
            exitFunction={() => {
              refresh()
              if (history) {
                history.goBack()
              } else {
                history.push(`/hemolysis-index-data/${id}`)
              }
            }}
          />
        </Container>
      </Box>
    </MainContent>
  )
}
