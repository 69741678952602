import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { EntryBox, StyledDropzone } from 'components'
import has from 'lodash/has'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { api, hasRole } from 'services'

export default function ReportForm({ id, exitFunction, closeModalFunction }) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm()
  const [title, setTitle] = useState('')
  const [file, setFile] = useState()
  const [validation, setValidation] = useState('')
  const [validationOptions, setValidationOptions] = useState()

  const dropzone = useDropzone({
    accept:
      '.pdf,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onDrop: (files) => {
      setFile(files[0])
      setValue('file', files[0])
    }
  })

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
    setValue('file', event.target.files[0])
  }

  const clearFile = () => {
    setFile()
    setValue('file', null)
  }

  const handleValidationChange = (event) => {
    setValidation(event.target.value)
    setValue('validation', event.target.value)
  }

  const handleClose = () => {
    if (closeModalFunction) closeModalFunction()
    if (exitFunction) exitFunction()
  }

  // Form Submission Handling
  const onSubmit = (data) => {
    if (id) {
      Object.keys(data).forEach(function (key) {
        if (data[key] === '') data[key] = null
      })
      api.reports
        .update(id, data)
        .then((data) => {
          handleClose()
        })
        .catch((error) => {
          console.warn(error)
        })
    } else {
      const formData = new FormData()
      const file = getValues('file')
      formData.append('file', file, file.name)
      formData.append('title', getValues('title'))
      if (getValues('validation'))
        formData.append('validation', getValues('validation'))
      api.reports
        .create(formData)
        .then((data) => {
          handleClose()
        })
        .catch((error) => {
          console.warn(error)
        })
    }
  }

  useEffect(() => {
    if (!id)
      register('file', { required: 'A PDF or Word Document must be attached.' })
    register('validation')
  }, [register, id])

  useEffect(() => {
    let unmounted = false
    if (id) {
      api.reports
        .get(id)
        .then((data) => {
          if (!unmounted) {
            setValue('title', data.title)
            setTitle(data.title)
            setValue('validation', data.validation && data.validation.id)
            setValidation(data.validation ? data.validation.id : '')
          }
        })
        .catch((error) => {
          console.warn(error)
        })
    }

    return () => {
      unmounted = true
    }
  }, [id, setValue])

  useEffect(() => {
    let unmounted = false
    const params = { size: 9999, status: 'completed' }
    api.validations
      .list(params)
      .then((data) => {
        if (!unmounted) {
          setValidationOptions(data.items)
        }
      })
      .catch((error) => {
        console.warn(error)
      })
    return () => {
      unmounted = true
    }
  }, [])

  return (
    <form
      id="report_form"
      data-testid={'report-form-test'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <EntryBox label="Title">
            <TextField
              {...register('title', {
                required: 'Please enter a title for the report.'
              })}
              name="title"
              id="title"
              variant="outlined"
              value={title || ''}
              onChange={(event) => {
                setTitle(event.target.value)
                setValue('title', event.target.value)
              }}
              inputProps={{
                'data-testid': 'report-title-test'
              }}
              error={has(errors, 'title')}
              helperText={errors.title && errors.title.message}
              fullWidth
            />
          </EntryBox>
        </Box>
        <Box my={3}>
          <EntryBox label="Validation">
            <FormControl variant="outlined" fullWidth>
              <Select
                name="validation"
                labelId="validation-input-label"
                id="validation"
                value={validation}
                onChange={handleValidationChange}
                SelectDisplayProps={{
                  'data-testid': 'validation-select'
                }}
                inputProps={{
                  'data-testid': 'validation-input',
                  disabled: !hasRole('staff')
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {validationOptions &&
                  validationOptions.map((validation, index) => {
                    return (
                      <MenuItem
                        key={`validation-${index}`}
                        value={validation.id}
                      >
                        {validation.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </EntryBox>
        </Box>
        {!id && (
          <Box mb={3}>
            <StyledDropzone
              dropzone={dropzone}
              file={file}
              clearFiles={clearFile}
              onChange={handleFileChange}
            />
            {errors.file && (
              <FormHelperText error={has(errors, 'file')}>
                {errors.file.message}
              </FormHelperText>
            )}
          </Box>
        )}
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box pr={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              {id ? 'Save Changes' : 'Upload'}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={closeModalFunction}
          >
            {'Cancel'}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
