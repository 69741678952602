import { Box, Container } from '@mui/material'
import { Navigation } from 'components'
import { ErrorBoundary } from 'helpers'
import assignIn from 'lodash/assignIn'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  AdminNavigationRoutes,
  FeatureFlagsProvider,
  FlaggedRoutes,
  HiddenRoutes,
  MetricsProvider,
  NavigationRoutes,
  api,
  authenticationService,
  hasAnyRole,
  hasRole
} from 'services'
import {
  CompareRecords,
  CreateValidation,
  Hemolysis,
  HemolysisDetail,
  InstituteDetail,
  LineDetail,
  NewValidation,
  RecordCompare,
  RecordDetail,
  Reports,
  ValidationDetail
} from 'views'
import CreateEditContract from 'views/CreateEditContract'

const ErrorBoundaryRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      )}
    />
  )
}

export default function MainLayout(props) {
  const [defaultFlags, setDefaultFlags] = useState()
  const [flags, setFlags] = useState()
  const [overrides, setOverrides] = useState(
    JSON.parse(localStorage.getItem('featureFlagOverrides'))
  )
  const [metricType, setMetricType] = useState('auc')
  const [metricLabel, setMetricLabel] = useState('VitalMetric')
  const [multiInstitute, setMultiInstitute] = useState()

  const getFlag = (flagName) => {
    if (overrides && overrides[flagName] !== undefined) {
      return overrides[flagName]
    } else if (flags && flags[flagName] !== undefined) {
      return flags[flagName]
    } else {
      return null
    }
  }

  useEffect(() => {
    if (authenticationService) {
      setMultiInstitute(authenticationService.multiInstitute)
    }
  }, [authenticationService, multiInstitute])

  useEffect(() => {
    let unmounted = false
    api.featureFlags
      .getDefaults()
      .then((data) => {
        if (!unmounted) {
          setDefaultFlags(data.flags)
        }
      })
      .catch((error) => {
        if (!unmounted) {
          console.warn(error)
        }
      })
    return () => {
      unmounted = true
    }
  }, [])

  useEffect(() => {
    let unmounted = false
    if (!unmounted && defaultFlags) {
      api.featureFlags
        .getActiveInstitute()
        .then((data) => {
          setFlags(
            data.feature_flags && data.feature_flags !== null
              ? assignIn({}, defaultFlags, data.feature_flags.flags)
              : defaultFlags
          )
        })
        .catch((error) => {
          if (!unmounted) {
            console.warn(error)
          }
        })
    }
    return () => {
      unmounted = true
    }
  }, [defaultFlags])
  return (
    <FeatureFlagsProvider
      value={{ flags, setFlags, overrides, setOverrides, getFlag: getFlag }}
    >
      <MetricsProvider
        value={{
          currentMetricConfig: 'auc',
          metricType,
          setMetricType,
          metricLabel,
          setMetricLabel
        }}
      >
        <Box display="flex">
          <Container maxWidth={false} disableGutters={true}>
            <Navigation />
            <div style={{ minHeight: '117px' }} />

            <Switch>
              {NavigationRoutes.map((route, index) => {
                return (
                  <ErrorBoundaryRoute
                    exact
                    path={
                      route.pathWithParam ? route.pathWithParam : route.path
                    }
                    component={route.component}
                    key={`main-layout-route-${index}`}
                  />
                )
              })}

              <ErrorBoundaryRoute exact path={'/reports'} component={Reports} />

              {FlaggedRoutes.map((route, index) => {
                return (
                  <ErrorBoundaryRoute
                    exact
                    path={
                      route.pathWithParam ? route.pathWithParam : route.path
                    }
                    component={route.component}
                    key={`main-feature-route-${index}`}
                  />
                )
              })}

              {hasRole('staff') &&
                AdminNavigationRoutes.map((route, index) => {
                  return (
                    <ErrorBoundaryRoute
                      exact
                      path={
                        route.pathWithParam ? route.pathWithParam : route.path
                      }
                      component={route.component}
                      key={`admin-layout-route-${index}`}
                    />
                  )
                })}

              {HiddenRoutes.map((route, index) => {
                return (
                  <ErrorBoundaryRoute
                    exact
                    path={route.path}
                    component={route.component}
                    key={`admin-layout-route-${index}`}
                  />
                )
              })}
              <ErrorBoundaryRoute path="/record/:id" component={RecordDetail} />
              <ErrorBoundaryRoute
                path="/line/:id/records/compare"
                component={RecordCompare}
              />
              <ErrorBoundaryRoute
                path="/line/compare"
                component={CompareRecords}
              />
              <ErrorBoundaryRoute
                path="/line/:id/:view?"
                component={LineDetail}
              />
              <ErrorBoundaryRoute
                path="/validation/new"
                component={CreateValidation}
              />
              <ErrorBoundaryRoute
                path="/validation/:id"
                component={ValidationDetail}
              />
              <ErrorBoundaryRoute
                path="/hemolysis-index"
                component={Hemolysis}
              />
              <ErrorBoundaryRoute
                path="/hemolysis-index-data/:id"
                component={HemolysisDetail}
              />
              <ErrorBoundaryRoute
                path="/settings"
                component={InstituteDetail}
              />
              {hasAnyRole(['owner', 'staff']) && (
                <ErrorBoundaryRoute
                  path="/organization/:id/:view?"
                  component={InstituteDetail}
                />
              )}
              <ErrorBoundaryRoute
                path="/contracts/:id/edit"
                component={CreateEditContract}
              />

              <Route path="*">
                <Redirect to={{ pathname: '/dashboard' }} />
              </Route>
            </Switch>
          </Container>
        </Box>
      </MetricsProvider>
    </FeatureFlagsProvider>
  )
}
