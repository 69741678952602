export default (theme) => ({
  root: {
    color: '#728093',
    fontWeight: 600,
    borderWidth: 1,
    textTransform: 'capitalize',
    border: '1px solid #728093',
    borderRadius: '19px',
    '&:hover': {
      color: 'white',
      fontWeight: 600,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    '&.Mui-selected': {
      color: 'white',
      fontWeight: 600,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    '&.Mui-selected:hover': {
      fontWeight: 600,
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      color: 'white'
    }
  }
})
