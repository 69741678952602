import { Button, Grid, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from 'services'

export default function CommentForm({
  id,
  resourceId,
  resource = 'records',
  exitFunction,
  closeModalFunction
}) {
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, reset } = useForm()
  const [comment, setComment] = useState('')
  // Form Submission Handling
  const onSubmit = (data) => {
    if (id) {
      api.comments
        .update(id, data)
        .then((data) => {
          enqueueSnackbar('Comment edits have been saved.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          if (closeModalFunction) closeModalFunction()
          exitFunction(true)
        })
        .catch(function (status) {
          console.log(status)
        })
    } else {
      api.comments
        .create(resourceId, data, resource)
        .then((data) => {
          enqueueSnackbar('Comment edits have been saved.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          setComment('')
          if (closeModalFunction) closeModalFunction()
          exitFunction(true)
          reset()
        })
        .catch(function (status) {
          console.log(status)
        })
    }
  }

  useEffect(() => {
    if (id) {
      api.comments
        .get(id)
        .then((data) => {
          setComment(data.comment)
        })
        .catch((error) => {
          console.warn(JSON.stringify(error, null, 2))
        })
    }
  }, [id, setValue])

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      // Check if "Shift + Enter" is pressed
      if (event.key === 'Enter' && event.shiftKey === true) {
        handleSubmit(onSubmit)()
      }
    })

    return () => {
      window.removeEventListener('keydown', () => {})
    }
  }, [])

  return (
    <form
      id="comment_form"
      data-testid={id ? `comment-form-test-${id}` : 'comment-form-test'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container
        sx={{
          paddingBottom: '10px'
        }}
        direction="column"
      >
        <Grid item>
          <TextField
            size="small"
            variant="outlined"
            value={comment}
            {...register('comment', { required: true })}
            onChange={(event) => {
              setComment(event.target.value)
              setValue('comment', event.target.value)
            }}
            inputProps={{
              'data-testid': id ? `comment-test-${id}` : 'comment-test'
            }}
            multiline
            fullWidth
            rows={2}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption">
            Press <strong>Shift + Enter</strong> to add comment
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="small"
          >
            {id > 0 ? 'Update Comment' : 'Comment'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
