import React, { useEffect, useState } from 'react'
import {
  Labels,
  Modal,
  RejectionProbabilityInfo,
  TableRejectionRates,
  withFromContext
} from 'components'
import { useQuery } from 'react-query'
import { QUERIES } from 'helpers'
import { api, useMetricsContext } from 'services'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Typography, IconButton, Tooltip, Button } from '@mui/material'
import has from 'lodash/has'
import { Add } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { LineForm } from '../../../forms'

const AddLines = (props) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    clearErrors
  } = props
  const { metricType, metricLabel } = useMetricsContext()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [totalLines, setTotalLines] = useState(0)
  const [lineSelectionModel, setLineSelectionModel] = useState(
    getValues('lines') || []
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        return <span>{params.value}</span>
      }
    },
    {
      field: 'labels',
      headerName: 'Labels',
      flex: 1,
      renderCell: (params) => {
        const labels = params.getValue(params.id, 'labels')
        return (
          <Labels
            shownLabels={labels && labels.slice(0, 2)}
            hiddenLabels={labels && labels.slice(2)}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          />
        )
      }
    },
    {
      field: 'rejection',
      flex: 1,
      renderHeader: () => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontWeight={600}
            fontSize={18}
          >
            {'Rejection Probability'}
            <Box display="flex" pl={1}>
              <RejectionProbabilityInfo />
            </Box>
          </Box>
        )
      },
      sortable: false,
      renderCell: (params) => {
        const line_statistics = params.getValue(params.id, 'line_statistics')
        const validation = params.getValue(params.id, 'validation')
        const lineStats = validation || line_statistics
        return lineStats[metricType] &&
          lineStats[metricType].rejection_rates ? (
          <TableRejectionRates
            rejectionRates={lineStats[metricType].rejection_rates}
          />
        ) : (
          '-'
        )
      }
    }
  ]

  const {
    data: getLinesData,
    isLoading: isGetLineDataLoading,
    refetch: refetchGetLineData
  } = useQuery({
    queryKey: [QUERIES.GET_ALL_LINES, page, pageSize],
    queryFn: async () => {
      const { items, total } = await api.lines.list({
        page: page,
        size: pageSize
      })
      setTotalLines(total)
      return items
    }
  })

  useEffect(() => {
    register('lines', {
      required: 'Select at least one line for the validation'
    })
  }, [register])

  useEffect(() => {
    setValue('lines', lineSelectionModel)
    if (lineSelectionModel.length > 0) {
      clearErrors('lines')
    }
  }, [lineSelectionModel])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      overflow={'auto'}
      sx={{
        height: 'calc(100vh - 28em)'
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexGrow={1}>
          {has(errors, 'lines') ? (
            <Typography variant={'body2'} color={'error'}>
              {errors.lines.message}
            </Typography>
          ) : (
            lineSelectionModel.length === 0 && (
              <Typography variant={'body2'}>
                Select the lines you want to add to the validation and click
                Next
              </Typography>
            )
          )}
        </Box>
        <Modal
          maxWidth="sm"
          button={
            <Tooltip title={'Add New Line'}>
              <IconButton color={'primary'}>
                <Add />
              </IconButton>
            </Tooltip>
          }
          label={'Add New Line'}
          refreshState={() => null}
        >
          <LineForm exitFunction={refetchGetLineData} />
        </Modal>
      </Box>
      <DataGrid
        checkboxSelection
        disableRowSelectionOnClick
        keepNonExistentRowsSelected
        autoHeight
        paginationMode="server"
        rowsPerPageOptions={[5, 20, 50, 100]}
        rowCount={totalLines || 0}
        page={page}
        getRowHeight={() => 'auto'}
        onPageChange={(page) => setPage(page)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        columns={columns}
        rows={getLinesData || []}
        loading={isGetLineDataLoading}
        selectionModel={lineSelectionModel}
        onSelectionModelChange={(newSelection) =>
          setLineSelectionModel(newSelection)
        }
      />
    </Box>
  )
}

export default withFromContext(AddLines)
