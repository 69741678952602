import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { MainContent, Loader } from 'components'
import UploadActivity from './UploadActivity'
import SummaryCalendar from './SummaryCalendar'
import LatestValidation from './LatestValidation'
import Welcome from './Welcome'
import { Flag, useRecordSummary } from 'services'

export default function Dashboard (props) {
  const [noData, setNoData] = useState()
  const { data: recordSummary } = useRecordSummary()

  useEffect(() => {
    if (recordSummary) {
      const record_count = recordSummary.reviewed + recordSummary.not_reviewed + recordSummary.unprocessed
      setNoData(record_count === 0)
    }
  }, [recordSummary])
  return (
    <MainContent>
      {noData === undefined
        ? (
        <Loader />
          )
        : (
        <Box p={1} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" flexGrow={1}>
            {noData
              ? <>
                <Box m={6} justifyContent="center" flexGrow={1}>
                  <Welcome />
                </Box>
                <Flag name="validation">
                  <Box display="flex" my={4}>
                    <Divider orientation="vertical" flexItem />
                  </Box>
                  <Box m={6} flexGrow={1}>
                    <LatestValidation />
                  </Box>
                </Flag>
              </>
              : <>
                <Flag name="validation">
                  <Box m={6} width={'30%'}>
                    <LatestValidation />
                  </Box>
                  <Box display="flex" my={4}>
                    <Divider orientation="vertical" />
                  </Box>
                </Flag>
                <Box m={6} flexGrow={1}>
                  <UploadActivity />
                </Box>
              </>
            }
          </Box>
          {noData === false && (
            <Box mx={6}>
              <SummaryCalendar />
            </Box>
          )}
        </Box>
          )}
    </MainContent>
  )
}
