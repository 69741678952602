import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Avatar, Box, Button, Divider, Link, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Logo from 'assets/images/logo.png'
import styles from './styles'

const useStyles = makeStyles(styles)

export default function AppLaunch () {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box minHeight='80vh' display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box>
        <Avatar className={classes.logo} src={Logo.toString()} />
      </Box>
      <Typography variant='h1' m={5}>{'Launching VitalApp'}</Typography>
      <Box display="flex" flexDirection="row" mb={2}>
        {'Click'}
        <Box fontWeight={600} mx={0.5}>{'Open VitalApp'}</Box>
        {'in the dialog to launch the VitalQC desktop app. You can close this window when the app is launched.'}
      </Box>
      <Box display="flex" flexDirection="row">
        {'Not working? Click'}
        <Box fontWeight={600} mx={0.5}>{'Launch VitalApp'}</Box>
        {'below.'}
      </Box>
      <Box m={5}><Button variant="contained"><a href="motryx://open" style={{ color: 'white' }}>Launch VitalApp</a></Button></Box>
      <Divider variant="fullWidth" style={{ width: '70%' }} />
      <Box display="flex" flexDirection="row" fontSize='14px' m={'50px 0px 20px 0px'}>
        {'Do not have VitalApp installed?'}
        <Link color="secondary.main" href="https://motryx.com/download" underline='none' mx={1}>Download Now</Link>
      </Box>
      <Box display="flex" flexDirection="row" fontSize='14px'>
        {'Having issues with opening VitalApp?'}
        <Box color="secondary.main" onClick={() => history.goBack()} underline='none' mx={1}>Use VitalQC in the browser</Box>
      </Box>
    </Box>
  )
}
