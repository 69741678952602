import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Loader, NumberWidget, StyledDocLink } from 'components'
import { formatDate, rejectionColor, statusColor } from 'helpers'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { api, apiConfig, hasRole } from 'services'

const BeginValidationButton = ({ ...rest }) => {
  const history = useHistory()
  return (
    <Button
      color="primary"
      variant="contained"
      {...rest}
      onClick={() => {
        history.push('/validation/new')
      }}
      disabled={hasRole('guest')}
    >
      {'Start Validation'}
    </Button>
  )
}
export default function LatestValidation() {
  const [summary, setSummary] = useState()
  const {
    isLoading,
    data: validation,
    refetch
  } = useQuery(
    ['latest-validation', apiConfig.currentApiInstitute],
    () => api.validations.latest(),
    {
      retry: 1
    }
  )

  const completeValidation = () => {
    api.validations
      .completeValidation(validation.id)
      .then((data) => {
        refetch()
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  useEffect(() => {
    let unmounted = false
    if (validation && validation.id) {
      api.validations
        .getSummary(validation.id)
        .then((data) => {
          if (!unmounted) {
            setSummary(data)
          }
        })
        .catch((error) => {
          console.warn(error)
        })
    }

    return () => {
      unmounted = true
    }
  }, [validation])

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Typography variant="h2">Latest Validation</Typography>
      {isLoading ? (
        <Loader title="" message="Loading latest validation..." />
      ) : validation ? (
        <Box display="flex" flexDirection="column">
          <Box py={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box display="flex" width={'70%'}>
                <Typography variant="subtitle1">{validation.name}</Typography>
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                {formatDate(validation.start_date)}
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" fontSize={'19px'}>
              <Box display="flex" mr={1}>
                {'Status:'}
              </Box>
              <Box
                display="flex"
                fontWeight={600}
                color={statusColor(validation.status)}
              >
                {validation.status === 'completed'
                  ? 'Completed'
                  : 'In Progress'}
              </Box>
            </Box>
            {validation.status === 'completed' ? (
              <Box py={1}>
                <Link
                  to={{
                    pathname: `/validation/${validation.id}`,
                    state: { name: 'Dashboard' }
                  }}
                >
                  <Button color="primary" variant="outlined">
                    View Validation Results
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column">
                <Box display="flex" py={1} justifyContent="flex-start">
                  {summary && (
                    <NumberWidget
                      num={summary.total_records_remaining}
                      label={'Records Remaining'}
                      rejectionColor={rejectionColor(
                        summary.total_records_remaining,
                        1,
                        1
                      )}
                      numAlign="center"
                    />
                  )}
                </Box>
                <Box py={1}>
                  {summary && summary.total_records_remaining <= 0 ? (
                    <Button
                      onClick={completeValidation}
                      variant="contained"
                      color="primary"
                    >
                      Complete Validation
                    </Button>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-evenly"
                    >
                      <Box width="100%">
                        <Link to={`/validation/${validation.id}`}>
                          <Button color="primary" variant="outlined" fullWidth>
                            View Validation
                          </Button>
                        </Link>
                      </Box>
                      <Box pl={1} width="100%">
                        <Link to={'records/to-review'}>
                          <Button color="primary" variant="contained" fullWidth>
                            Review Records
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          {validation.next_validation_date && (
            <Box display="flex" flexDirection="column">
              <Divider />
              <Box display="flex" py={1} flexDirection="column">
                <Box py={2}>
                  {'Next Validation: '}
                  <Box display="inline" fontWeight={600}>
                    {formatDate(validation.next_validation_date)}
                  </Box>
                </Box>
                <Box display="flex" pt={1} flexDirection="row">
                  <Link to={'/validations'}>
                    <Button color="primary" variant="outlined">
                      View All Validations
                    </Button>
                  </Link>
                  <Box flexGrow={1} pl={1}>
                    <BeginValidationButton fullWidth />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box my={1}>
            <Typography variant="body1" fontSize={16}>
              {'No validations have been started. Consult the '}
              <StyledDocLink to="/validation">
                {'validation guide'}
              </StyledDocLink>
              {' to begin your PTS validation.'}
            </Typography>
          </Box>
          <Box my={1}>
            <BeginValidationButton />
          </Box>
        </Box>
      )}
    </Box>
  )
}
