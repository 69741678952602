import { createContext, useContext } from 'react'

export const FeaturesContext = createContext({
  flags: {},
  setFlags: () => {},
  overrides: JSON.parse(localStorage.getItem('featureFlagOverrides')),
  setOverrides: () => {},
  getFlag: (flagName) => {
    console.log('Not implemented')
  }
})

export const useFeaturesContext = () => useContext(FeaturesContext)

export const FeatureFlagsProvider = FeaturesContext.Provider

export const Flag = ({ name, children, alternate = null }) => {
  const { flags, overrides } = useFeaturesContext()

  if (children) {
    if (overrides) {
      return overrides[name] ? children : alternate
    } else if (flags) {
      return flags[name] ? children : alternate
    }
  }
  return null
}
