import React from 'react'
import Box from '@mui/material/Box'
import { NumberWidget } from 'components'
import { rejectionColor } from 'helpers'

export default function TableRejectionRates ({ rejectionRates }) {
  return (
    <Box display="flex" flexDirection="column" >
      <Box display="flex" flexDirection="row">
        <NumberWidget
          num={`${rejectionRates.ldh}%`}
          label={'LDH'}
          rejectionColor={rejectionColor(rejectionRates.ldh)}
          size="small"
        />
        <NumberWidget
          num={`${rejectionRates.asat}%`}
          label={'AST'}
          rejectionColor={rejectionColor(rejectionRates.asat)}
          size="small"
        />
        <NumberWidget
          num={`${rejectionRates.potassium}%`}
          label={'K'}
          rejectionColor={rejectionColor(rejectionRates.potassium)}
          size="small"
        />
      </Box>
    </Box>
  )
}
