import React from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Typography } from '@mui/material'
import { api } from 'services'

export default function CompleteValidationForm ({ id, message, closeModalFunction, exitFunction }) {
  const { handleSubmit, formState: { isSubmitting } } = useForm()

  const onSubmit = (data) => {
    if (id) {
      api.validations
        .completeValidation(id)
        .then((data) => {
          exitFunction()
          closeModalFunction()
        })
        .catch((error) => {
          console.warn(error)
        })
    }
  }

  return (
    <form id="complete_validation_form" data-testid="complete-validation-test" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Box py={1}>
          <Typography variant="subtitle1" color="error">
            {`${message} Are you sure you want to complete the validation?`}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end' py={1}>
          <Box pr={1}>
            <Button
              type="submit"
              role="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              size="small"
            >
              Complete Validation
            </Button>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={closeModalFunction}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  )
}
