import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Logo from 'assets/images/full_logo.png'
import { Alert, AlertTitle, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { authenticationService } from 'services'
import has from 'lodash/has'
// Component styles
import styles from './styles'

const useStyles = makeStyles(styles)

export default function PasswordReset(props) {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()
  const [email, setEmail] = useState()
  // Form Submission Handling
  const onSubmit = (data) => {
    authenticationService
      .passwordReset(data)
      .then(function (response) {
        if (response.status === 204) {
          setSubmitted(true)
        } else {
          enqueueSnackbar(
            'Oops! Something went wrong, please try again or contact support@motryx.com',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          )
        }
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.detail) {
          enqueueSnackbar(error.response.data.detail, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      })
  }

  return (
    <Box
      minHeight="80vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Avatar className={classes.loginavatar} src={Logo.toString()} />
      </Box>
      <Box width={'100%'} maxWidth={'400px'}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            {submitted ? (
              <div>
                <Alert severity="success" variant="filled" elevation={1}>
                  <AlertTitle>{'E-mail Sent'}</AlertTitle>
                  {'You should receive a password reset e-mail shortly.'}
                </Alert>
                <Box className={classes.alt_link} mt={2}>
                  <Link to="/login">Go to Login</Link>
                </Box>
              </div>
            ) : (
              <div>
                <Typography variant="h2">Reset Password</Typography>
                <Typography variant="body1" className={classes.text}>
                  {' '}
                  Enter your e-mail address below.
                </Typography>
                <form
                  id="reset_password_form"
                  data-testid="reset-password-form-test"
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={4} direction="column">
                    <Grid item>
                      <TextField
                        {...register('email', {
                          required: 'Please enter your e-mail.',
                          pattern: {
                            value: /\S+@\S+\.\S+/i,
                            message: 'Please enter a valid e-mail address.'
                          }
                        })}
                        id="email"
                        name="email"
                        label="Email"
                        value={email || ''}
                        onChange={(event) => {
                          setEmail(event.target.value)
                          setValue('email', event.target.value)
                        }}
                        variant="outlined"
                        error={has(errors, 'email')}
                        helperText={errors.email && errors.email.message}
                        fullWidth
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        type="submit"
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                      >
                        Send Password Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Box className={classes.alt_link}>
                        <Link href="/login" underline="none" fontWeight={600}>
                          Go to Login
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
