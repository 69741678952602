import { Box } from '@mui/material'
import { Comments, Loader, MainContent, Sidebar } from 'components'
import { InstituteLock } from 'helpers'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { api, authenticationService } from 'services'
import Completed from './Completed'
import InProgress from './InProgress'

export default function MainView({ id }) {
  const multiInstitute = authenticationService.multiInstitute
  const { data: validation, refetch: refreshValidation } = useQuery(
    ['validation', id],
    () => api.validations.get(id)
  )
  const { data: comments, refetch: refreshComments } = useQuery(
    ['comments', id],
    () => api.comments.list('validations', id)
  )

  return (
    <InstituteLock
      id={validation && validation.institute.id}
      redirectUrl="/validations"
    >
      <Box display="flex" mt={multiInstitute ? 0 : 6}>
        <Sidebar>
          <Comments
            comments={comments}
            resource="validations"
            resourceId={id}
            activeUserId={authenticationService.currentUserValue.user_id}
            refreshState={refreshComments}
          />
        </Sidebar>
      </Box>
      <MainContent sidebar="right">
        <Box p={4}>
          {validation ? (
            validation.status === 'completed' ? (
              <Completed
                validation={validation}
                refreshValidation={refreshValidation}
              />
            ) : (
              <InProgress
                validation={validation}
                setRefreshState={refreshValidation}
              />
            )
          ) : (
            <Loader />
          )}
        </Box>
      </MainContent>
    </InstituteLock>
  )
}
