import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

function DropDownSelect(props) {
  const { value, onValueChange, styles, items, label, labelId } = props
  const handleOnchange = (event) => {
    onValueChange(event.target.value)
  }
  return (
    <>
      <FormControl>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          autoWidth={true}
          labelId={labelId}
          id="demo-simple-select"
          label={label + ' *'}
          value={value}
          onChange={handleOnchange}
          sx={{
            ...styles,
            minWidth: '105px',
            maxHeight: '30px'
          }}
        >
          {items.map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
              sx={{
                whiteSpace: 'nowrap',
                width: '100%'
              }}
            >
              {item.content}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

// Props
DropDownSelect.propTypes = {
  labelId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  styles: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired
    })
  ).isRequired
}

export default DropDownSelect
