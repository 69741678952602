import React from 'react'
import { CirclePicker } from 'react-color'
import { Box } from '@mui/material'
import { labelColors } from 'helpers'

export const ColorPicker = ({ defaultColor, ...rest }) => {
  const handleChange = (newColor, event) => {
    rest.onChange(newColor.hex)
  }
  return (
    <Box pt={1} pb={1}>
      <CirclePicker color={{ hex: defaultColor }} colors={labelColors} width="100%" onChange={handleChange} />
    </Box>
  )
}
