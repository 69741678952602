import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import {
  BlurWrapper,
  Loader,
  StyledTab,
  StyledTabs,
  TabPanel,
  TemperaturePlot,
  VibrationPlot
} from 'components'
import { formatDuration } from 'helpers'
import { api, useFeaturesContext } from 'services'

export default function ReviewDataDetail({ id, closeModalFunction }) {
  const { flags, overrides, getFlag } = useFeaturesContext()
  const [blurData, setBlurData] = useState(false)
  const { data: recordData } = useQuery(['record-review-data', id], () =>
    api.records.get(id)
  )
  const { data: accelerationData } = useQuery(['acceleration-data', id], () =>
    api.records.acceleration(id)
  )
  const { data: temperatureData } = useQuery(['temperature-data', id], () =>
    api.records.temperature(id)
  )
  const label = `record-review-${id}`
  const [timeAxis, setTimeAxis] = useState(
    localStorage.getItem('recordTimeAxis') === null
      ? 'seconds'
      : localStorage.getItem('recordTimeAxis')
  )

  const handleTimeAxis = (event, newTimeAxis) => {
    setTimeAxis(newTimeAxis)
    localStorage.setItem('recordTimeAxis', newTimeAxis)
  }

  const [recordPlotNavValue, setRecordPlotNavValue] = useState(0)

  const handleRecordPlotNavChange = (event, newValue) => {
    setRecordPlotNavValue(newValue)
  }

  useEffect(() => {
    if (getFlag) {
      setBlurData(!getFlag('troubleshooting'))
    }
  }, [flags, overrides, getFlag])

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <StyledTabs
              value={recordPlotNavValue}
              onChange={handleRecordPlotNavChange}
            >
              <StyledTab label="Acceleration" />
              <StyledTab label="Temperature" />
            </StyledTabs>
          </Box>
          <Box>
            <StyledTabs
              value={timeAxis}
              onChange={handleTimeAxis}
              style={{ maxHeight: '39px' }}
            >
              <StyledTab label="Elapsed" value="seconds" disableRipple />
              <StyledTab label="Clock" value="timestamp" disableRipple />
            </StyledTabs>
          </Box>
        </Box>
        <TabPanel value={recordPlotNavValue} label={label} index={0} p={1}>
          <Box id="acceleration-panel" data-testid="acceleration-panel">
            {accelerationData && recordData ? (
              <BlurWrapper doBlur={blurData}>
                <VibrationPlot
                  acceleration={accelerationData.acceleration}
                  startdate={recordData.date}
                  time={accelerationData.acceleration_time}
                  duration={recordData.metrics && recordData.metrics.duration}
                  timeFormat={timeAxis}
                />
              </BlurWrapper>
            ) : (
              <Loader />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={recordPlotNavValue} label={label} index={1} p={1}>
          <Box
            id="temperature-panel"
            data-testid="temperature-panel"
            display={
              recordData && recordData.mean_kinetic_temperature !== 'NaN'
                ? 'block'
                : 'none'
            }
          >
            {temperatureData && recordData ? (
              <BlurWrapper doBlur={blurData}>
                <TemperaturePlot
                  temperature={temperatureData.temperature}
                  startdate={recordData.date}
                  time={temperatureData.temperature_time}
                  duration={recordData.duration}
                  min={temperatureData.minimum_temperature}
                  max={temperatureData.maximum_temperature}
                  mean={recordData.metrics.mean_kinetic_temperature}
                  temperaturetype={recordData.temperature_type}
                  timeFormat={timeAxis}
                  showAnnotation={false}
                />
              </BlurWrapper>
            ) : (
              <Loader />
            )}
          </Box>
        </TabPanel>
      </Box>
      <Box display="flex" flexDirection="column" mt={6}>
        <Box>
          <Typography variant="subtitle1">
            {'Duration (days, h:m:s)'}
          </Typography>
          {recordData &&
          recordData.metrics &&
          recordData.metrics.duration !== null ? (
            <BlurWrapper
              doBlur={blurData}
              showCTAComponent={false}
              blurLevel={10}
            >
              <Typography>
                {formatDuration(recordData.metrics.duration)}
              </Typography>
            </BlurWrapper>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          <Typography variant="subtitle1">{'VitalMetric'}</Typography>
          {recordData &&
          recordData.metrics &&
          recordData.metrics.auc !== null ? (
            <BlurWrapper
              doBlur={blurData}
              showCTAComponent={false}
              blurLevel={10}
            >
              <Typography>{recordData.metrics.auc.toFixed(0)}</Typography>
            </BlurWrapper>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          <Typography variant="subtitle1">{'Mean Temperature'}</Typography>
          {recordData &&
          recordData.metrics.mean_kinetic_temperature &&
          recordData.metrics.mean_kinetic_temperature !== 'NaN' ? (
            <BlurWrapper
              doBlur={blurData}
              showCTAComponent={false}
              blurLevel={10}
            >
              <Typography>
                {Number(recordData.metrics.mean_kinetic_temperature).toFixed(
                  1
                ) + ' °C'}
              </Typography>
            </BlurWrapper>
          ) : (
            '-'
          )}
        </Box>
        <Box display="flex" flexGrow={1} alignItems="flex-end" mb={1}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={closeModalFunction}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
