import React, { useState } from 'react'
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useHistory } from 'react-router-dom'
import ValidationDetails from './ValidationDetails'
import AddLines from './AddLines'
import ReviewAndConfirm from './ReviewAndConfirm'
import { FormProvider, useForm } from 'react-hook-form'
import Paper from '@mui/material/Paper'
import { useMutation } from 'react-query'
import { QUERIES, SnackbarUtil } from 'helpers'
import { api } from 'services'

const CreateValidation = (props) => {
  const history = useHistory()
  const methods = useForm()
  const [activeStep, setActiveStep] = React.useState(0)
  const [steps, setSteps] = useState([
    {
      label: 'Validation Details',
      component: <ValidationDetails />
    },
    {
      label: 'Add Lines',
      component: <AddLines />
    },
    {
      label: 'Review & Confirm',
      component: <ReviewAndConfirm />
    }
  ])

  const createValidationMutation = useMutation({
    mutationKey: QUERIES.CREATE_VALIDATION,
    mutationFn: async (data) => {
      await api.validations.create({
        name: data.validationName,
        start_date: data.startDate,
        baseline: data.isBaseline,
        duration_days: data.durationDays,
        lines: data.lines
      })
    },
    onError: (error) => {
      console.log('error', error)
      SnackbarUtil.error('Unable to create new validation', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    },
    onSuccess: (data) => {
      SnackbarUtil.success('Validation created successfully', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      history.push('/validations')
    }
  })

  const handleNextCreateButtonClick = async () => {
    const stepsFields = [
      ['validationName', 'startDate', 'isBaseline', 'durationDays'],
      ['lines'],
      ['reviewAndConfirm']
    ]

    // Check if we are on the last step, if so, submit the form
    if (activeStep === steps.length - 1) return

    await methods.trigger(stepsFields[activeStep]).then((valid) => {
      if (!valid) return
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    })
  }

  const handleCancelBackButtonClick = () => {
    // Check if we are on the first step, if so, cancel the process and go back to the Validation List
    if (activeStep === 0) {
      history.push('/validations')
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const onSubmit = async (data) => {
    await createValidationMutation.mutateAsync(data)
  }

  return (
    <Container maxWidth={'md'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            gap={3}
            paddingTop={2}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              gap={0}
              flexDirection={'column'}
            >
              <Typography variant={'h1'}>Create Validation</Typography>
              <Typography variant={'caption'}>
                Enter the details to create new Validation
              </Typography>
            </Box>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 23em)',
                gap: 3
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => {
                  return (
                    <Step key={index}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              <Container>{steps && steps[activeStep].component}</Container>
            </Paper>
            <Box
              marginTop={'auto'}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Button
                size={'small'}
                variant={'contained'}
                color={'error'}
                onClick={() => {
                  history.push('/validations')
                }}
                sx={{
                  width: '6em'
                }}
              >
                Cancel
              </Button>
              <Box display={'flex'} gap={2}>
                {activeStep !== 0 && (
                  <Button
                    size={'small'}
                    variant={'contained'}
                    onClick={handleCancelBackButtonClick}
                    startIcon={<ChevronLeftIcon />}
                    sx={{
                      width: '6em'
                    }}
                  >
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    key={'createButton'}
                    size={'small'}
                    disabled={createValidationMutation.isLoading}
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    sx={{
                      width: '6em'
                    }}
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    key={'nextButton'}
                    size={'small'}
                    variant={'contained'}
                    color={'primary'}
                    type={'button'}
                    onClick={handleNextCreateButtonClick}
                    endIcon={<ChevronRightIcon />}
                    sx={{
                      width: '6em'
                    }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Container>
  )
}

export default CreateValidation
