import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import Add from '@mui/icons-material/Add'
import Search from '@mui/icons-material/Search'
import { useQuery } from 'react-query'
import { DataGrid } from '@mui/x-data-grid'
import { LoadingOverlay, NoRowsOverlay } from 'components/ServerSideDataGrid'
import { BackLink, Label, MainContent, Modal } from 'components'
import { LabelForm, DeleteLabelForm } from 'forms'
import { api, apiConfig } from 'services'

export default function Labels (props) {
  const [search, setSearch] = useState('')

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const { data: labelData, refetch, isLoading } = useQuery(['labels', apiConfig.currentApiInstitute, { search }], () => api.labels.list().then(data => {
    return data.filter((label) => label.label.toLowerCase().includes(search.toLowerCase()))
  }))

  const columns = [
    { field: 'id', hide: true },
    { field: 'color', hide: true },
    {
      field: 'label',
      headerName: 'Label',
      flex: 1,
      renderCell: (params) => {
        const label = params.getValue(params.id, 'label')
        const color = params.getValue(params.id, 'color')
        return <Label label={label} color={color} />
      }
    },
    {
      field: 'edit',
      headerName: '',
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        return <Modal
              maxWidth={'xs'}
              button={<Button size="small" color="secondary">Edit</Button>}
              label={'Edit Label'}
            >
              <LabelForm id={id} exitFunction={refetch} />
            </Modal>
      }
    },
    {
      field: 'delete',
      headerName: '',
      renderCell: (params) => {
        return <Modal
          button={
            <Button size="small" color="error">
              Delete
            </Button>
          }
          label={'Confirmation Required'}
          showCloseIcon={false}
        >
          <DeleteLabelForm label={params} exitFunction={refetch} />
        </Modal>
      }
    }
  ]
  return (
    <MainContent>
      <Box p={4}>
        <Container maxWidth={'lg'}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <BackLink noBorder px={0} py={0} />
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="row" spacing={3}>
                <Box alignSelf="center" flexGrow={1}>
                  <Typography variant="h1">{'Label Management'}</Typography>
                </Box>

                <Box>
                  <Modal
                    maxWidth={'xs'}
                    button={
                      <Button color="primary" variant="contained" size="small" startIcon={<Add />}>
                        {'Add Label'}
                      </Button>
                    }
                    label={'Add Label'}
                  >
                    <LabelForm exitFunction={refetch} />
                  </Modal>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pb={2} flexGrow={1}>
                <TextField
                  name="filter"
                  variant="outlined"
                  value={search}
                  placeholder={'Search for label'}
                  InputProps={{
                    onChange: handleSearch,
                    startAdornment: (
                      <InputAdornment position="start" style={{ marginLeft: '8px' }}>
                        <Search htmlColor="grey" />
                      </InputAdornment>
                    )
                  }}
                  size="small"
                  fullWidth
                />
              </Box>
              <Box>
                <DataGrid
                  rows={labelData || []}
                  columns={columns}
                  hideFooter
                  disableColumnMenu
                  autoHeight={true}
                  loading={isLoading}
                  components={{
                    LoadingOverlay: () => <LoadingOverlay text={'Loading Labels...'} />,
                    NoRowsOverlay: () => <NoRowsOverlay text={'No LAbels'} />
                  }}
                />
              </Box>

            </Grid>
          </Grid>
        </Container>
      </Box>
    </MainContent>
  )
}
