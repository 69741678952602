import React from 'react'
import { StyleSheet, Page, View, Image } from '@react-pdf/renderer'
import {
  Footer,
  StyledText
} from '../index.js'
import FullLogo from 'assets/images/full_logo.png'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#E4EDED',
    opacity: 0.38,
    padding: '10px 20px',
    justifyContent: 'space-between'
  },
  content: {
    flexGrow: 1,
    padding: '40x 70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
})

const TextPage = ({ title, children }) => {
  return (
    <Page size = "A4" style={StyleSheet.page}>
      <View style={styles.title}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image style={{ opacity: 1, width: 125 }} src={ FullLogo } />
        </View>
        <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
          <StyledText fontSize={13} fontWeight={500}>{title}</StyledText>
        </View>
      </View>
      <View style={styles.content}>
        {children}
      </View>
      <Footer/>
    </Page>
  )
}

export default TextPage
