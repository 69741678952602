import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ClickAwayListener, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export default function InfoButton({
  children = null,
  style = null,
  placement = 'top',
  fontSize = 'small'
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        open={open}
        onClose={handleTooltipClose}
        placement={placement}
        interactive={'true'}
        title={children}
      >
        <InfoOutlinedIcon
          className={classes.infoOutlinedIcon}
          style={style}
          fontSize={fontSize}
          onClick={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  )
}
