import {
  Account,
  AppLaunch,
  ClinicalData,
  Dashboard,
  Hemolysis,
  InstituteLines,
  Institutes,
  Labels,
  Login,
  PasswordReset,
  PasswordResetConfirm,
  Records,
  Register,
  RejectionCalculator,
  Reports,
  Users,
  Validations,
  VitalVials
} from 'views'

import MainLayout from 'layouts/MainLayout'
import NoAuthLayout from 'layouts/NoAuthLayout'
import { CreateEditContract, CreateEditUser } from 'views'
import Contracts from 'views/Contracts'

export const AuthRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: NoAuthLayout
  },
  {
    path: '/reset-password',
    name: 'Password Reset',
    component: PasswordReset,
    layout: NoAuthLayout
  },
  {
    path: '/reset-password-confirm/:token',
    name: 'Password Reset Confirmation',
    component: PasswordResetConfirm,
    layout: NoAuthLayout
  },
  {
    path: '/Register/:token',
    name: 'User Register',
    component: Register,
    layout: NoAuthLayout
  },
  {
    path: '*',
    name: 'Login',
    component: Login,
    layout: NoAuthLayout
  }
]

export const NavigationRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    layout: MainLayout
  },
  {
    path: '/lines',
    pathWithParam: '/lines/:view?',
    navId: 'line',
    name: 'Lines',
    component: InstituteLines,
    layout: MainLayout
  },
  // {
  //   path: '/institute-lines',
  //   name: 'Institute Lines',
  //   component: InstituteLines,
  //   layout: MainLayout
  // },
  {
    path: '/records',
    pathWithParam: '/records/:view?',
    navId: 'record',
    name: 'Records',
    component: Records,
    layout: MainLayout
  }
]

export const FlaggedRoutes = [
  {
    path: '/validations',
    pathWithParam: '/validations/:view?',
    name: 'Validations',
    component: Validations,
    layout: MainLayout,
    featureFlag: 'validation'
  },
  {
    path: '/clinical-data',
    pathWithParam: '/clinical-data/:view?',
    name: 'Clinical Data',
    component: ClinicalData,
    layout: MainLayout,
    featureFlag: 'clinicalData'
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    layout: MainLayout,
    featureFlag: 'validation'
  },
  {
    path: '/rejection-insights',
    name: 'Rejection Insights',
    navId: 'rejection-insights',
    component: Hemolysis,
    layout: MainLayout,
    featureFlag: 'hemolysisTracking'
  }
]

export const AdminNavigationRoutes = [
  {
    path: '/vials',
    name: 'VitalVials',
    component: VitalVials,
    layout: MainLayout
  },
  // {
  //   path: '/users',
  //   pathWithParam: '/users/:view?',
  //   name: 'Users',
  //   component: Users,
  //   layout: MainLayout
  // },
  {
    path: '/users',
    pathWithParam: '/users',
    name: 'Users',
    component: Users,
    layout: MainLayout
  },
  {
    path: '/users/create',
    name: 'Create User',
    component: CreateEditUser,
    layout: MainLayout
  },
  {
    path: '/rejection-calculator',
    name: 'Rejection Probability Calculator',
    component: RejectionCalculator,
    layout: MainLayout
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: Institutes,
    layout: MainLayout
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Contracts,
    layout: MainLayout
  },
  {
    path: '/contracts/add',
    name: 'New Contract',
    component: CreateEditContract,
    layout: MainLayout
  }
]

export const HiddenRoutes = [
  {
    path: '/account',
    name: 'Account',
    component: Account,
    layout: MainLayout
  },
  {
    path: '/labels',
    name: 'Label Management',
    component: Labels,
    layout: MainLayout
  },
  {
    path: '/users/edit/:id',
    name: 'Edit User',
    component: CreateEditUser,
    layout: MainLayout
  },
  {
    path: '/reset-password-confirm/:token',
    name: 'Password Reset Confirmation',
    component: PasswordResetConfirm,
    layout: NoAuthLayout
  }
]

export const PublicRoutes = [
  {
    path: '/launch',
    name: 'App Launch',
    component: AppLaunch,
    layout: NoAuthLayout
  }
]
