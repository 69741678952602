import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { api } from 'services'
import { useSnackbar } from 'notistack'

export default function DeleteVialForm({
  label,
  closeModalFunction,
  exitFunction
}) {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm()

  const [disabled, setDisabled] = useState(true)
  const [deleteLabel, setDeleteLabel] = useState('')

  // Form Submission Handling
  const onSubmit = (data) => {
    api.vials
      .delete(data.label)
      .then(function (response) {
        enqueueSnackbar(`${data.label} has been deleted.`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        if (exitFunction) exitFunction()
        closeModalFunction()
      })
      .catch(function (status) {
        console.log(status)
      })
  }
  return (
    <form
      id="admin_delete_vial_form"
      data-testid="delete-vial-test"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography
            variant="subtitle1"
            color="error"
          >{`Deleting ${label} from the system which will prevent further uploads from this vial.`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {'Enter the vial label ('}
            <Box display="inline" fontWeight={700}>
              {label}
            </Box>
            {') to continue.'}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            {...register('label', {
              required: 'The vial label is required.',
              validate: (value) =>
                value === label ||
                'The entered value does not match label exactly.'
            })}
            name="label"
            id="label"
            label={'Vial Label'}
            variant="outlined"
            value={deleteLabel}
            onChange={(event) => {
              setDeleteLabel(event.target.value)
              setValue('label', event.target.value)
              setDisabled(!(event.target.value === label))
            }}
            error={errors.hasOwnProperty('label')}
            helperText={errors.label && errors.label.message}
            fullWidth
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Box pr={2}>
            <Button
              type="submit"
              disabled={disabled}
              variant="contained"
              color="error"
              size="small"
            >
              Delete Vial
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={closeModalFunction}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
