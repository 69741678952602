import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import StyledText from '../StyledText/index.js'

const styles = StyleSheet.create({
  footer: {
    justifyContent: 'center',
    backgroundColor: '#2AAAA7',
    padding: '15px 20px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
})

const Footer = () => {
  return (
    <View style={ styles.footer }>
      <View style={ styles.row }>
        <View style={{ flexGrow: 1 }}>
          <StyledText fontSize={ 10 } fontWeight={ 600 } color='white'>{ 'motryx.com' }</StyledText>
        </View>
        <StyledText fontSize={ 10 } fontWeight={ 600 } color='white'>{ 'For questions please contact Motryx at ' }</StyledText>
        <StyledText fontSize={ 10 } fontWeight={ 600 } underline={ true } color='white'>{ 'info@motryx.com' }</StyledText>
      </View>
    </View>
  )
}

export default Footer
