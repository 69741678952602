import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import NumberWidget from '../NumberWidget/index.js'

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'row'
  }
})

const RejectionColumn = ({ data = null }) => {
  return (
    <View style={ styles.content }>
      <NumberWidget num={ data?.ldh } unit={ '%' } label={ 'LDH' } isRejection={ true } />
      <NumberWidget num={ data?.asat } unit={ '%' } label={ 'ASAT' } isRejection={ true} />
      <NumberWidget num={ data?.potassium } unit={ '%' } label={ 'K' } isRejection={ true } />
    </View>
  )
}

export default RejectionColumn
