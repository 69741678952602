import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  ListItemButton,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CalculateIcon from '@mui/icons-material/Calculate'
import CloseIcon from '@mui/icons-material/Close'
import EmailIcon from '@mui/icons-material/Email'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import FlagIcon from '@mui/icons-material/Flag'
import HelpIcon from '@mui/icons-material/Help'
import PeopleIcon from '@mui/icons-material/People'
import ReplayIcon from '@mui/icons-material/Replay'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { Modal, VitalVialStatus, VitalVialUpload } from 'components'
import { FeatureFlagOverrides } from 'forms'
import VialIcon from 'icons/Vial'
import { useQuery } from 'react-query'
import {
  Flag,
  FlaggedRoutes,
  HiddenRoutes,
  NavigationRoutes,
  Role,
  api,
  apiConfig,
  authenticationService,
  hasRole,
  useDeviceScan,
  useFeaturesContext,
  useMetricsContext,
  useRecordSummary
} from 'services'

import makeStyles from '@mui/styles/makeStyles'
import Logo from 'assets/images/logo.png'
import { QUERIES, isElectron, openDocsPage } from 'helpers'
import { Link, useHistory, withRouter } from 'react-router-dom'
import styles from './styles'

import { RequestQuote } from '@mui/icons-material'
import assignIn from 'lodash/assignIn'

const useStyles = makeStyles(styles)
const badgeStyles = makeStyles((theme) => ({
  root: {
    verticalAlign: 'bottom'
  },
  customBadge: {
    right: 12,
    color: '#4FC1BE'
  }
}))

const drawerStyles = makeStyles((theme) => ({
  drawer: {
    color: 'white',
    padding: '20px',
    marginTop: '46px',
    backgroundColor: '#152736',
    width: 360
  },
  button: {
    color: 'white',
    fontSize: '18px',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap'
  },
  group: {
    fontWeight: 600,
    fontSize: '19px',
    color: '#b6b6b6',
    marginTop: theme.spacing(2),
    paddingBottom: 5,
    borderBottom: '1px solid #FFFFFF23'
  },
  option: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 500,
    overflowWrap: 'break-word',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFFFFF10'
    }
  },
  optionMeta: {
    color: '#b6b6b6',
    paddingLeft: theme.spacing(1)
  },
  search: {
    backgroundColor: '#FFFFFF2F',
    border: '1px solid #FFFFFF39',
    borderRadius: '4px',
    fontWeight: 500,
    color: 'white',
    paddingLeft: '6px'
  }
}))

const vialDrawerStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: authenticationService.multiInstitute ? '117px' : '71px',
    boxShadow:
      '0px 0px 3px -2px rgb(0 0 0 / 20%), 0px 4px 4px 0px rgb(0 0 0 / 14%), 0px 0px 8px 0px rgb(0 0 0 / 12%)',
    width: 360
  },
  option: {
    backgroundColor: '#33403F8F',
    border: '1px solid #white',
    borderRadius: '4px',
    fontWeight: 500,
    color: 'white',
    paddingLeft: '6px'
  }
}))

function AdminLink({ fontWeight = 500, children, color = 'white', ...rest }) {
  return (
    <Link style={{ color: color, fontWeight: fontWeight }} {...rest}>
      {children}
    </Link>
  )
}

function InstituteSelector(props) {
  const [currentInstituteName, setCurrentInstituteName] = useState()
  const [currentInstitutePartnerId, setCurrentInstitutePartnerId] = useState()
  const { setFlags } = useFeaturesContext()
  const history = useHistory()

  const { data: defaultFlags } = useQuery(['default-flags'], () =>
    api.featureFlags.getDefaults().then((data) => data.flags)
  )

  const classes = drawerStyles()

  const [search, setSearch] = useState('')
  const [displayedInstitutes, setDisplayedInstitutes] = useState()

  const { data: instituteRelations } = useQuery(
    ['institute-relationships'],
    () =>
      api.types.instituteRelationships().then((data) => {
        data.push({ value: null, name: 'No Relationship' })
        return data
      }),
    { enabled: hasRole('staff') }
  )

  const { data: instituteOptions, isLoading: instituteOptionsIsLoading } =
    useQuery(
      [QUERIES.GET_INSTITUTES, 'institute-selector'],
      () =>
        api.institutes
          .list({ size: 9999, ordering: 'name', exclude_archived: false })
          .then((data) => {
            let institute = data.items[0]
            if (apiConfig.currentApiInstitute) {
              institute = data.items.filter(
                (institute) => institute.id === apiConfig.currentApiInstitute
              )[0]
            }
            setCurrentInstituteName(institute.name)
            setCurrentInstitutePartnerId(institute.partner_id || '')
            apiConfig.setApiConfig(
              institute.id,
              institute.metadata_type,
              institute.name
            )
            return data.items
          }),
      {
        refetchInterval: 3000
      }
    )

  const handleInstituteSearch = (event) => {
    setSearch(event.target.value)
  }

  const [open, setOpen] = useState(false)
  const openDrawer = () => setOpen(true)
  const closeDrawer = () => {
    setOpen(false)
    setSearch('')
  }

  const handleInstituteChange = (id) => {
    const institute = instituteOptions.filter(
      (institute) => institute.id === id
    )[0]
    setFlags(
      defaultFlags && institute.feature_flags !== null
        ? assignIn({}, defaultFlags, institute.feature_flags.flags)
        : defaultFlags
    )
    apiConfig.setApiConfig(
      institute.id,
      institute.metadata_type,
      institute.name
    )
    setCurrentInstituteName(institute.name)
    closeDrawer()
    history.push('/')
    history.goBack()
  }
  useEffect(() => {
    if (instituteOptions && search !== '') {
      const filteredInstitutes = instituteOptions.filter(
        (institute) =>
          institute.name.toLowerCase().includes(search.toLowerCase()) ||
          institute.partner_id?.toLowerCase().includes(search.toLowerCase())
      )
      setDisplayedInstitutes(filteredInstitutes)
    } else {
      setDisplayedInstitutes(instituteOptions)
    }
  }, [instituteOptions, search])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {instituteOptionsIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress size={25} />
        </Box>
      ) : (
        <ClickAwayListener onClickAway={closeDrawer}>
          <div>
            <Button
              className={classes.button}
              endIcon={
                open ? <CloseIcon /> : <ArrowDropDownIcon size="default" />
              }
              onClick={open ? closeDrawer : openDrawer}
              size="small"
              disableRipple
              fullWidth
            >
              {currentInstituteName}
              {currentInstitutePartnerId && (
                <span className={classes.optionMeta}>
                  #{currentInstitutePartnerId}
                </span>
              )}
            </Button>
            <Drawer
              anchor="left"
              variant="persistent"
              open={open}
              classes={{ paper: classes.drawer }}
            >
              <Box mb={2}>
                <InputBase
                  placeholder="Search"
                  variant="outlined"
                  size="small"
                  value={search || ''}
                  fullWidth
                  onChange={handleInstituteSearch}
                  classes={{ root: classes.search }}
                  inputProps={{ 'data-testid': 'institute-select' }}
                  inputRef={(input) => {
                    if (input != null) {
                      input.focus()
                    }
                  }}
                  startAdornment={
                    <InputAdornment position={'start'}>
                      <SearchIcon htmlColor="white" />
                    </InputAdornment>
                  }
                  endAdornment={
                    search !== '' && (
                      <InputAdornment
                        onClick={() => setSearch('')}
                        position={'end'}
                      >
                        <IconButton aria-label="clear" size="large">
                          <CloseIcon htmlColor="white" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </Box>
              <Box height={'calc(100vh - 150px)'} overflow={'scroll'} mb={2}>
                {displayedInstitutes && (
                  <Role
                    restrict={['staff']}
                    alternate={
                      <Box>
                        {displayedInstitutes &&
                          displayedInstitutes.map((institute, index) => {
                            return (
                              <Box
                                key={index}
                                className={classes.option}
                                onClick={() =>
                                  handleInstituteChange(institute.id)
                                }
                              >
                                {institute.name}
                                {institute.partner_id && (
                                  <span className={classes.optionMeta}>
                                    {`#${institute.partner_id}`}
                                  </span>
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    }
                  >
                    {instituteRelations &&
                      instituteRelations.map((relation, relationIndex) => {
                        const group = displayedInstitutes.filter(
                          (institute) =>
                            institute.relationship === relation.value
                        )
                        if (group.length > 0) {
                          return (
                            <Box key={relationIndex}>
                              <Box className={classes.group}>
                                {relation.name}
                              </Box>
                              {group.map((institute, index) => {
                                return (
                                  <Box
                                    key={index}
                                    className={classes.option}
                                    onClick={() =>
                                      handleInstituteChange(institute.id)
                                    }
                                  >
                                    {institute.name}
                                    {institute.partner_id && (
                                      <span className={classes.optionMeta}>
                                        {`#${institute.partner_id}`}
                                      </span>
                                    )}
                                  </Box>
                                )
                              })}
                            </Box>
                          )
                        }
                        return null
                      })}
                  </Role>
                )}
              </Box>
            </Drawer>
          </div>
        </ClickAwayListener>
      )}
    </Box>
  )
}

function MetricSelector(props) {
  const [currentMetric, setCurrentMetricType] = useState('auc')
  const { setMetricType, setMetricLabel } = useMetricsContext()
  const badgeClasses = badgeStyles()

  const handleMetricChange = (event) => {
    setCurrentMetricType(event.target.value)
    setMetricType(event.target.value)
    if (event.target.value !== 'auc') {
      setMetricLabel(
        <Badge
          classes={{ badge: badgeClasses.customBadge }}
          className={badgeClasses.root}
          badgeContent={'Beta'}
        >
          {'VitalMetric'}
        </Badge>
      )
    } else {
      setMetricLabel('VitalMetric')
    }
  }
  const classes = useStyles()
  return (
    <FormControl fullWidth variant="standard">
      <Select
        labelId="metric-context-input-label"
        id="metric-context-input"
        value={currentMetric}
        onChange={handleMetricChange}
        IconComponent={ArrowDropDownIcon}
        SelectDisplayProps={{ 'data-testid': 'metric-select' }}
        disableUnderline
        classes={{ select: classes.dropdownSelect, icon: classes.dropdownIcon }}
      >
        <MenuItem value={'auc'}>{'AUC'}</MenuItem>
        <MenuItem value={'auc_225hz'}>{'AUC 225'}</MenuItem>
        <MenuItem value={'power'}>{'Power'}</MenuItem>
        {/* <MenuItem value={'kinetic_energy'}>{'Kinetic Energy'}</MenuItem> */}
      </Select>
    </FormControl>
  )
}

function VialStatusUpload(props) {
  const classes = useStyles()
  const history = useHistory()
  const drawerClasses = vialDrawerStyles()
  const multiInstitute = authenticationService.multiInstitute

  const { devices, readyToUploadDevices, uploadCompleteDevices } =
    useDeviceScan()
  const readyToUploadDevicesLabels =
    readyToUploadDevices &&
    readyToUploadDevices.map((device) => device.vialLabel)
  const [prevReadyToUploadDevices, setPrevReadyToUploadDevices] = useState([])
  const [open, setOpen] = useState(false)
  const openDrawer = () => setOpen(true)
  const closeDrawer = () => setOpen(false)

  useEffect(() => {
    if (
      readyToUploadDevicesLabels &&
      !readyToUploadDevicesLabels.every((device) =>
        prevReadyToUploadDevices.includes(device)
      )
    ) {
      openDrawer()
    }
    if (
      JSON.stringify(readyToUploadDevicesLabels) !==
      JSON.stringify(prevReadyToUploadDevices)
    ) {
      setPrevReadyToUploadDevices(readyToUploadDevicesLabels)
    }
  }, [readyToUploadDevices])

  return (
    <ClickAwayListener onClickAway={closeDrawer} mouseEvent={'onMouseUp'}>
      <div>
        <Tooltip
          title={isElectron() ? 'Connected Vials' : 'Upload Not Available'}
        >
          <ListItemButton
            className={classes.listItem}
            onClick={open ? closeDrawer : openDrawer}
          >
            <VialIcon fontSize="inherit" htmlColor="#728093" />
            <Box
              pl={1}
              pr={1}
              color={open ? '#4FC1BE' : '#33403F'}
              fontWeight={500}
              alignSelf="center"
            >
              Vials
            </Box>
            {isElectron() && devices && devices.length > 0 && (
              <Chip
                color="primary"
                size="small"
                label={devices.length}
                style={{ margin: 'auto' }}
              />
            )}
          </ListItemButton>
        </Tooltip>

        <Drawer
          anchor="right"
          variant="persistent"
          open={open}
          classes={{ paper: drawerClasses.drawer }}
          PaperProps={{ elevation: 3 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={
              multiInstitute ? 'calc(100vh - 157px)' : 'calc(100vh - 103px)'
            }
            m={2}
          >
            <Box
              display={devices && devices.length <= 0 ? 'flex' : 'none'}
              flexDirection="column"
              flexGrow={1}
              alignItems="center"
              justifyContent="center"
            >
              {isElectron() ? (
                <Typography className={classes.notConnectedDrawerText}>
                  No Vials Connected
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Typography variant="h5" mb={3} style={{ opacity: 0.65 }}>
                    Trying to upload records?
                  </Typography>
                  <Typography variant="body1" style={{ opacity: 0.65 }}>
                    {'Upload is not available in the browser'}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    m={3}
                    onClick={() => history.push('/launch')}
                  >
                    <Typography variant="h5">
                      <a href="motryx://open" style={{ color: '#0B8DDE' }}>
                        Launch the desktop app to upload data
                      </a>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box mb={2}>
              <VitalVialStatus uploadCompleteDevices={uploadCompleteDevices} />
            </Box>
            <Box
              display={
                readyToUploadDevices && readyToUploadDevices.length > 0
                  ? 'flex'
                  : 'none'
              }
            >
              <VitalVialUpload
                devices={devices}
                readyToUploadDevices={readyToUploadDevices}
              />
            </Box>
          </Box>
        </Drawer>
      </div>
    </ClickAwayListener>
  )
}

function StaffSettingsMenu() {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id="staff-settings-button"
        aria-controls={open ? 'staff-settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="transparent"
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        id="staff-settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'staff-settings-button'
        }}
      >
        <Role restrict={['staff', 'owner']}>
          <AdminLink to="/organizations" color="black">
            <MenuItem onClick={handleClose}>
              <Box display="flex" gap={1} alignItems="center">
                <RoomPreferencesIcon
                  fontSize="small"
                  style={{ paddingRight: 20, color: '#728093' }}
                />
                <Typography fontSize={'medium'}>
                  Manage Organizations
                </Typography>
              </Box>
            </MenuItem>
          </AdminLink>
        </Role>
        <Role restrict={['staff']}>
          <AdminLink to="/users" color="black">
            <MenuItem onClick={handleClose}>
              <Box display="flex" gap={1} alignItems="center">
                <PeopleIcon
                  fontSize="small"
                  style={{ paddingRight: 20, color: '#728093' }}
                />
                <Typography fontSize={'medium'}>Manage Users</Typography>
              </Box>
            </MenuItem>
          </AdminLink>
        </Role>
        <Role restrict={['staff']}>
          <AdminLink to="/contracts" color="black">
            <MenuItem onClick={handleClose}>
              <Box display="flex" gap={1} alignItems="center">
                <RequestQuote
                  fontSize="small"
                  style={{ paddingRight: 20, color: '#728093' }}
                />
                <Typography fontSize={'medium'}>Manage Contracts</Typography>
              </Box>
            </MenuItem>
          </AdminLink>
        </Role>
        <Role restrict={['staff']}>
          <AdminLink to="/vials" color="black">
            <MenuItem onClick={handleClose}>
              <Box display="flex" gap={1} alignItems="center">
                <VialIcon
                  fontSize="small"
                  style={{ paddingRight: 20, color: '#728093' }}
                />
                <Typography fontSize={'medium'}>Manage Vials</Typography>
              </Box>
            </MenuItem>
          </AdminLink>
        </Role>
        <Role restrict={['staff']}>
          <AdminLink to="/rejection-calculator" color="black">
            <MenuItem onClick={handleClose}>
              <Box display="flex" gap={1} alignItems="center">
                <CalculateIcon
                  fontSize="small"
                  style={{ paddingRight: 20, color: '#728093' }}
                />
                <Typography fontSize={'medium'}>
                  Rejection Calculator
                </Typography>
              </Box>
            </MenuItem>
          </AdminLink>
        </Role>
        <Divider />
        <MenuItem
          className={classes.navButton}
          onClick={() => {
            history.push('/account')
            handleClose()
          }}
        >
          <Box display="flex" gap={1} alignItems="center">
            <VpnKeyIcon
              fontSize="small"
              style={{ paddingRight: 20, color: '#728093' }}
            />
            Account Settings
          </Box>
        </MenuItem>
        <Role restrict={['admin', 'member', 'owner']}>
          <MenuItem
            onClick={() => {
              openDocsPage('/support/contact')
              handleClose()
            }}
            className={classes.navButton}
          >
            <Box display="flex" gap={1} alignItems="center">
              <EmailIcon
                fontSize="small"
                style={{ paddingRight: 20, color: '#728093' }}
              />
              Contact Us
            </Box>
          </MenuItem>
          <Divider />
        </Role>
        <MenuItem
          className={classes.logoutButton}
          onClick={() => {
            authenticationService.logout()
            history.push('/login')
          }}
        >
          <Box display="flex" gap={1} alignItems="center">
            <ExitToAppIcon fontSize="small" style={{ paddingRight: 20 }} />
            Logout
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}

function Navigation(props) {
  const classes = useStyles()
  const { flags, overrides } = useFeaturesContext()
  const [routes, setRoutes] = useState(NavigationRoutes)
  const [anchorEl, setAnchorEl] = useState(null)
  const openAnchorEl = Boolean(anchorEl)

  const [multiInstitute, setMultiInstitute] = useState()

  useEffect(() => {
    if (authenticationService) {
      setMultiInstitute(authenticationService.multiInstitute)
    }
  }, [authenticationService, multiInstitute])

  const history = useHistory()
  const { pathname } = props.location

  const [showIndicator, setShowIndicator] = useState(true)

  const { data: recordSummary } = useRecordSummary()

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLogout() {
    authenticationService.logout()
    history.push('/login')
  }
  const [navValue, setNavValue] = useState(0)

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `main-tab-${index}`,
      'aria-controls': `main-tabpanel-${index}`
    }
  }

  useEffect(() => {
    const settingsRoute = {
      path: '/settings',
      name: 'Settings'
    }
    let featureRoutes = []
    if (flags) {
      featureRoutes = FlaggedRoutes.filter((route) => flags[route.featureFlag])
    }
    if (overrides) {
      featureRoutes = FlaggedRoutes.filter(
        (route) => overrides[route.featureFlag]
      )
    }
    setRoutes(NavigationRoutes.concat(featureRoutes).concat([settingsRoute]))
  }, [flags, overrides, apiConfig])

  useEffect(() => {
    let unmounted = false

    let navIndex = routes.findIndex((route) => {
      const match = pathname.split('/')[1]
      return route.path.includes(match) || match.includes(route.navId)
    })

    if (HiddenRoutes.find((route) => route.path === pathname)) {
      setShowIndicator(false)
      navIndex = false
    }
    if (!unmounted) {
      if (navIndex >= 0) {
        setShowIndicator(true)
        setNavValue(navIndex)
      } else {
        setNavValue(false)
      }
    }

    return () => {
      unmounted = true
    }
  }, [pathname, navValue, routes])

  return (
    <Box display="flex">
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Box
          bgcolor={'#152736'}
          maxHeight={'30px'}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          py={1}
          px={4}
          sx={{
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Avatar
              onClick={() => {
                history.push('/dashboard')
              }}
              className={classes.logo}
              src={Logo.toString()}
              sx={{
                cursor: 'pointer'
              }}
            />
            <IconButton
              onClick={() => {
                history.goBack()
              }}
              style={{
                color: history.action === 'POP' ? '#728093' : 'white'
              }}
              variant="transparent"
              disabled={history.action === 'POP'}
            >
              <Tooltip title="Go Back">
                <ArrowBackIcon />
              </Tooltip>
            </IconButton>
            <Tooltip title="Reload">
              <IconButton onClick={() => history.go(0)} variant="transparent">
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" gap={3} justifyContent="center">
            <InstituteSelector location={props.location} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Role restrict={['staff']}>
              <Box alignSelf="center" justifyContent="center">
                <Modal
                  label={'Feature Overrides'}
                  showCancel={false}
                  button={
                    <Tooltip title="Feature Flag Overrides">
                      <MenuItem
                        className={classes.navButton}
                        onClick={handleClose}
                      >
                        <FlagIcon
                          htmlColor={
                            localStorage.getItem('featureFlagOverrides')
                              ? '#4FC1BE'
                              : 'white'
                          }
                          size="large"
                        />
                      </MenuItem>
                    </Tooltip>
                  }
                >
                  <FeatureFlagOverrides />
                </Modal>
              </Box>
            </Role>
            <StaffSettingsMenu />
            <Role restrict={['staff']}>
              <Flag name={'betaMetrics'}>
                <MetricSelector />
              </Flag>
            </Role>
          </Box>
        </Box>
        <Toolbar className={classes.toolbar}>
          <Box display={'flex'} justifyContent="space-between" width="100%">
            <Tabs
              value={navValue}
              onChange={handleNavChange}
              TabIndicatorProps={showIndicator ? { children: <div /> } : {}}
              className={classes.tabs}
            >
              {routes.map((route, index) => {
                let label = route.name
                let path = route.path
                if (
                  route.path === '/records' &&
                  recordSummary &&
                  recordSummary.not_reviewed > 0
                ) {
                  path = '/records/to-review'
                  label = (
                    <span>
                      {route.name}{' '}
                      <Chip
                        color="primary"
                        size="small"
                        label={recordSummary.not_reviewed}
                        style={{ margin: 0 }}
                      />
                    </span>
                  )
                }
                if (route.path === '/lines') {
                  label =
                    apiConfig.currentApiInstituteType === 'courier'
                      ? 'Routes'
                      : 'Lines'
                }
                return (
                  <Tab
                    key={`main-nav-route-${index}`}
                    label={label}
                    component={Link}
                    to={path}
                    {...a11yProps(index)}
                  />
                )
              })}
            </Tabs>
            <Box display={'flex'} alignItems="center" gap={1}>
              {!hasRole('guest') && (
                <>
                  <VialStatusUpload />
                  <Divider
                    orientation="vertical"
                    style={{
                      height: '21px',
                      alignSelf: 'center',
                      color: '#D6DAE0'
                    }}
                  />
                </>
              )}
              <Tooltip title="Help">
                <IconButton
                  variant="primary"
                  onClick={() => {
                    openDocsPage()
                  }}
                >
                  <HelpIcon size="large" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default withRouter(Navigation)
