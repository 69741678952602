import { Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { Modal } from 'components'
import { format, parseJSON } from 'date-fns'
import { CommentForm, DeleteCommentForm } from 'forms'
import { useState } from 'react'

const Comment = ({ comment, displayActions = false, refreshState = null }) => {
  const isSender = displayActions // Assuming displayActions=true for sender messages
  const [showActionButtons, setShowActionButtons] = useState(false)
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={isSender ? 'flex-end' : 'flex-start'}
      my={1}
      position="relative"
    >
      <Box
        px={2}
        py={1}
        maxWidth="65%"
        minWidth="50%"
        style={{ whiteSpace: 'pre-wrap' }}
        sx={{
          borderRadius: isSender ? '20px 0px 20px 20px' : '0px 20px 20px 20px',
          color: isSender ? 'white' : 'black',
          backgroundColor: isSender ? 'primary.main' : 'greyed.main'
        }}
      >
        {isSender && (
          <Box
            position="absolute"
            top={0}
            left={0}
            transform="translateY(-50%)"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Modal
              button={
                <Tooltip title="Edit Comment">
                  <IconButton
                    color="primary"
                    size="small"
                    data-testid={`edit-comment-button-test-${comment.id}`}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              }
              label={'Edit Comment'}
              refreshState={refreshState}
            >
              <CommentForm id={comment.id} exitFunction={refreshState} />
            </Modal>

            <Modal
              button={
                <Tooltip title="Delete Comment">
                  <IconButton
                    color="error"
                    size="small"
                    data-testid={`delete-comment-button-test-${comment.id}`}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              }
              refreshState={refreshState}
              label={'Confirmation Requred'}
              showCloseIcon={false}
            >
              <DeleteCommentForm id={comment.id} exitFunction={refreshState} />
            </Modal>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '0.9rem',
              maxWidth: '80%',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: isSender ? '#ececec' : 'grey'
            }}
          >
            {comment.commenter_name}
          </Box>
          <Box
            sx={{
              fontSize: '1.1rem'
            }}
          >
            {comment.comment}
          </Box>
          <Box
            as="div"
            sx={{
              fontSize: '0.8rem',
              display: 'flex',
              justifyContent: 'flex-end',
              color: isSender ? 'white' : 'grey'
            }}
          >
            {format(parseJSON(comment.created_date), 'dd MMM yyyy')}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function CommentOld({ comment, displayActions = false, refreshState = null }) {
  return (
    <Box display="flex" fontSize={16} flexDirection="column" mt={2} mb={2}>
      <Box display="flex" flexDirection="row">
        <Box flexGrow={1} fontWeight={600}>
          {comment.commenter_name}
        </Box>
        <Box color="#728093">
          {format(parseJSON(comment.created_date), 'dd MMM yyyy')}
        </Box>
      </Box>
      <Box style={{ whiteSpace: 'pre-wrap' }}>{comment.comment}</Box>
      <Box display={displayActions ? 'flex' : 'none'} flexDirection="row">
        <Box>
          <Modal
            button={
              <Button
                size="small"
                style={{ padding: 0 }}
                color="secondary"
                data-testid={`edit-comment-button-test-${comment.id}`}
              >
                {'Edit'}
              </Button>
            }
            label={'Edit Comment'}
            refreshState={refreshState}
          >
            <CommentForm id={comment.id} exitFunction={refreshState} />
          </Modal>
        </Box>
        <Box flexGrow={1}></Box>
        <Box>
          <Modal
            button={
              <Button
                color="error"
                size="small"
                style={{ padding: 0 }}
                data-testid={`delete-comment-button-test-${comment.id}`}
              >
                {'Delete'}
              </Button>
            }
            refreshState={refreshState}
            label={'Confirmation Requred'}
            showCloseIcon={false}
          >
            <DeleteCommentForm id={comment.id} exitFunction={refreshState} />
          </Modal>
        </Box>
      </Box>
    </Box>
  )
}

export default Comment
