import React from 'react'
import { Document, StyleSheet, Page, View } from '@react-pdf/renderer'
import {
  BackPage,
  Footer,
  TitlePage,
  Title,
  Row
} from './components'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: '10x 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
})

export default function ValidationReport ({ validation, lineStats, analyzer }) {
  const lineNumberPerPage = 10
  const lines = lineStats?.reduce((lines, item, index) => {
    const chunkIndex = Math.floor(index / lineNumberPerPage)

    if (!lines[chunkIndex]) {
      lines[chunkIndex] = [] // start a new chunk
    }

    lines[chunkIndex].push(item)

    return lines
  }, [])

  return (
      <Document>
        <TitlePage data={validation} />
        {
          lines?.map((item, index) =>
            <Page key={index} size="A4" style={styles.page}>
              <Title data={validation} />
              <View style={styles.content}>
                { item.map((line, index) => <Row key={line.line_id} data={line} border={index !== (item.length - 1)} />) }
              </View>
              <Footer />
            </Page>
          )
        }
        <BackPage analyzer={analyzer} />
      </Document>
  )
}
