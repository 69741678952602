// The following is what is suggested to show snackbars outside of components within the app
// Source from https://github.com/iamhosseindhv/notistack/issues/30
// This can revisited if it is added as a feature in the future
import { useSnackbar } from 'notistack'
import React from 'react'

const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export const SnackbarUtil = {
  success (message, options = {}) {
    this.toast(message, { ...options, variant: 'success' })
  },
  warning (message, options = {}) {
    this.toast(message, { ...options, variant: 'warning' })
  },
  info (message, options = {}) {
    this.toast(message, { ...options, variant: 'info' })
  },
  error (message, options = {}) {
    this.toast(message, { ...options, variant: 'error' })
  },
  toast (message, options = {}) {
    useSnackbarRef.enqueueSnackbar(message, options)
  }
}
