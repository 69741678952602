import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import Loader from 'components/Loader'
import { useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { apiConfig } from 'services'

export function NoRowsOverlay({ text = 'No Results' }) {
  return (
    <GridOverlay>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Box alignItems="center">
          <Typography variant="body1">{text}</Typography>
        </Box>
      </Box>
    </GridOverlay>
  )
}

export function LoadingOverlay({ text = 'Retrieving Data...' }) {
  return (
    <GridOverlay>
      <Loader title={text} message={null} />
    </GridOverlay>
  )
}

export function paramToSortModel(param) {
  const sort = param.startsWith('-') ? 'desc' : 'asc'
  const field = param.startsWith('-') ? param.substring(1) : param
  return { field: field, sort: sort }
}

export function sortModelToParam(sortModel) {
  const direction = sortModel.sort === 'asc' ? '' : '-'
  return `${direction}${sortModel.field}`
}

export function paramsToSearchObject(entries) {
  const search = {}
  for (const [key, value] of entries) {
    if (key !== 'page' && key !== 'ordering') search[key] = value
  }
  return search
}

export default function ServerSideDataGrid({
  apiCall,
  queryName,
  searchParams = {},
  noRowsText = null,
  loadingText = null,
  updateTotal = () => null,
  pageSize = 10,
  ...rest
}) {
  const queryClient = useQueryClient()
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const tableRef = useRef()
  const [tableHeight, setTableHeight] = useState(400)
  const [totalCount, setTotalCount] = useState(0)
  const [sortModel, setSortModel] = useState(
    urlParams.has('ordering')
      ? [paramToSortModel(urlParams.get('ordering'))]
      : []
  )
  const [page, setPage] = useState(
    urlParams.has('page') ? urlParams.get('page') : 1
  )

  const handleSortModelChange = (model) => {
    setSortModel(model)
  }

  const handlePageChange = (page) => {
    setPage(page + 1)
  }

  const { isLoading, data, isFetching } = useQuery(
    [
      queryName,
      {
        currentApiInstitute: apiConfig.currentApiInstitute,
        sortModel,
        page,
        searchParams,
        size: pageSize
      }
    ],
    () => {
      let ordering = null
      if (sortModel[0]) {
        const direction = sortModel[0].sort === 'asc' ? '' : '-'
        ordering = `${direction}${sortModel[0].field}`
      }
      return apiCall({
        ...searchParams,
        page: page - 1,
        size: pageSize,
        ordering: ordering
      }).then((data) => {
        const queryString = Object.keys(searchParams)
          .map((key) => key + '=' + searchParams[key])
          .join('&')
        history.replace({
          pathname: location.pathname,
          search: `page=${page}${ordering ? `&ordering=${ordering}` : ''}${
            queryString.length > 0 ? `&${queryString}` : ''
          }`
        })
        setTotalCount(data.total)
        if (updateTotal) updateTotal(data.total)
        return data.items
      })
    }
  )

  useEffect(() => {
    // Invalidate the query when the page size changes
    queryClient.invalidateQueries(queryName)
  }, [pageSize])

  const handleTableHeight = () => {
    if (tableRef.current) {
      setTableHeight(window.innerHeight - tableRef.current.offsetTop - 35)
    }
  }

  useEffect(() => {
    handleTableHeight()
  }, [data, tableRef?.current?.offsetTop, window.innerHeight])

  return (
    <Box ref={tableRef} height={tableHeight}>
      <DataGrid
        {...rest}
        rows={data || []}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        page={page <= 0 ? 0 : page - 1}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={handlePageChange}
        rowCount={totalCount}
        loading={isLoading || isFetching}
        rowsPerPageOptions={[pageSize]}
        hideFooterRowCount={true}
        hideFooter={totalCount <= 0}
        disableSelectionOnClick
        disableColumnMenu
        disableVirtualization={true}
        density="comfortable"
        autoHeight
        components={{
          LoadingOverlay: (params) => {
            return loadingText ? (
              <LoadingOverlay text={loadingText} />
            ) : (
              <LoadingOverlay />
            )
          },
          NoRowsOverlay: (params) => {
            return noRowsText ? (
              <NoRowsOverlay text={noRowsText} />
            ) : (
              <NoRowsOverlay />
            )
          }
        }}
      />
    </Box>
  )
}
