import React from 'react'
import Box from '@mui/material/Box'

export default function StepProgress ({ current, total, height = 25, width = 400, multipleColors = false }) {
  const values = [...Array(total).keys()]

  const getColor = (value) => {
    const colors = ['#4FC1BE', '#2FA6A1', '#13948F']
    let color = colors[0]

    if (multipleColors) {
      switch (multipleColors) {
        case (value >= 4 && value < 8):
          color = colors[1]
          break
        case (value >= 8 && value < 12):
          color = colors[2]
          break
        default:
          color = colors[0]
          break
      }
    }

    return color
  }

  return (
    <Box display="flex" flexDirection="row" style={{ width: width }}>
      {values.map((value, index) => {
        const color = getColor(index)
        return (
          <Box
            flexGrow={1}
            key={`step-progress-value-${value}`}
            style={{
              backgroundColor: value < current ? color : '#ECEEF0',
              marginRight: value >= total - 1 ? '0px' : '2px'
            }}
            height={height}
          />
        )
      })}
    </Box>
  )
}
