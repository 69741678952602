import { Image, Page, StyleSheet, View } from '@react-pdf/renderer'
import FullLogo from 'assets/images/full_logo.png'
import FullLogoWhite from 'assets/images/full_logo_white.png'
import StyledText from '../StyledText'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  title: {
    justifyContent: 'center',
    backgroundColor: '#2AAAA7',
    paddingLeft: 100,
    height: '35%'
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 100,
    paddingTop: 50
  }
})

const TitlePage = ({ data }) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.title}>
        <Image style={{ opacity: 1, width: 200 }} src={FullLogoWhite} />
      </View>
      <View style={styles.content}>
        <View style={{ paddingBottom: 10 }}>
          <StyledText fontSize={30} fontWeight={450} color="black">
            {'PTS Validation Report'}
          </StyledText>
        </View>
        <View style={{ paddingBottom: 90 }}>
          <StyledText fontSize={22} fontWeight={400} color="#6B6B6B">
            {data.name}
          </StyledText>
        </View>
        <View style={{ paddingBottom: 140 }}>
          <StyledText fontSize={12} fontWeight={400} color="black">
            {data.start_date}
          </StyledText>
        </View>
        <View style={{ paddingBottom: 5 }}>
          <Image style={{ opacity: 1, width: 100 }} src={FullLogo} />
        </View>
        <View style={{ paddingBottom: 5 }}>
          <StyledText fontSize={10} fontWeight={400} color="black">
            {'CONFIDENTIAL - For Authorized Use Only'}
          </StyledText>
        </View>
        <View style={{ paddingBottom: 5 }}>
          <StyledText
            fontSize={10}
            fontWeight={400}
            color="black"
          >{`© Motryx ${new Date().getFullYear()}`}</StyledText>
        </View>
        <StyledText fontSize={10} fontWeight={400} color="black">
          {'motryx.com'}
        </StyledText>
      </View>
    </Page>
  )
}

export default TitlePage
