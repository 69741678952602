import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import {
  EntryBox,
  MainContent,
  NumberWidget,
  TableRejectionRates
} from 'components'
import { api } from 'services'

function TableThreshold ({ thresholds }) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <NumberWidget
          num={`${thresholds.ldh}`}
          label={'LDH'}
          size="small"
        />
        <NumberWidget
          num={`${thresholds.asat}`}
          label={'AST'}
          size="small"
        />
        <NumberWidget
          num={`${thresholds.potassium}`}
          label={'K'}
          size="small"
        />
      </Box>
    </Box>
  )
}

export default function RejectionCalculator () {
  const [vitalMetric, setVitalMetric] = useState(0)
  const { data: rejections } = useQuery(['rejections', vitalMetric], () => api.analyzer.getRejectionRates(vitalMetric))

  const handleVitalMetricChange = (event) => {
    setVitalMetric(event.target.value)
  }

  return (
    <MainContent>
      <Box display="flex" p={4}>
        <Container maxWidth="md">
          <Box pb={4}>
            <Typography variant="h1">{'Rejection Probability Calculator'}</Typography>
          </Box>
          <Box pb={2}>
            <EntryBox label="Vital Metric">
              <TextField
                name="viatalMetric"
                id="vitalMetric"
                size="small"
                variant="outlined"
                value={vitalMetric}
                onChange={handleVitalMetricChange}
                fullWidth
              />
            </EntryBox>
          </Box>
          <Box pt={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 300 }}>Analyzer</TableCell>
                    <TableCell>Rejection Probability</TableCell>
                    <TableCell>Threshold</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { rejections && rejections.map((rejection, index) => {
                    return (
                      <TableRow key={`rejection-${rejection.analyzer}=${index}`}>
                        <TableCell>{rejection.analyzer}</TableCell>
                        <TableCell>
                          <TableRejectionRates
                            rejectionRates={{
                              ldh: rejection.analytes.ldh.probability,
                              asat: rejection.analytes.asat.probability,
                              potassium: rejection.analytes.potassium.probability
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TableThreshold
                            thresholds={{
                              ldh: rejection.analytes.ldh.cutoff,
                              asat: rejection.analytes.asat.cutoff,
                              potassium: rejection.analytes.potassium.cutoff
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </MainContent>
  )
}
