import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Box, Typography } from '@mui/material'
import { api } from 'services'
import { useSnackbar } from 'notistack'

export default function DeleteCommentForm ({ id, closeModalFunction, exitFunction }) {
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit } = useForm()

  // Form Submission Handling
  const onSubmit = () => {
    if (id) {
      api.comments
        .delete(id)
        .then(function (response) {
          enqueueSnackbar('Comment has been deleted.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          if (closeModalFunction) closeModalFunction()
          exitFunction(true)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
  return (
    <form id="delete_comment_form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <Box flexGrow={1}>
          <Typography variant="subtitle1" color="error">
            {'Are you sure you want to delete this comment?'}
          </Typography>
        </Box>
        <Box pr={2}>
          <Button type="submit" variant="contained" color="error" size="small">
            {'Delete Comment'}
          </Button>
        </Box>
        <Button color="primary" variant='contained' size="small" onClick={closeModalFunction}>
          {'Cancel'}
        </Button>
      </Box>
    </form>
  )
}
