import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { StyledDocLink, withFromContext } from 'components'

const ReviewAndConfirm = (props) => {
  const { getValues } = props
  const [startDate, setStartDate] = React.useState(
    getValues('startDate') || format(new Date(), 'yyyy-MM-dd')
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      sx={{
        height: 'calc(100vh - 28em)'
      }}
    >
      <Typography>{`Data collection is scheduled to begin on ${startDate}.`}</Typography>
      <Typography>
        {'Please complete fields in your '}
        <StyledDocLink to={'/validation_log_book.pdf'}>
          {'workbook'}
        </StyledDocLink>
        {' as you go. See '}
        <StyledDocLink to={'/validation'}>
          {'our validation guide'}
        </StyledDocLink>
        {' or '}
        <StyledDocLink to={'/support/contact'}>{'contact us'}</StyledDocLink>
        {' if you need any other assistance.'}
      </Typography>
    </Box>
  )
}

export default withFromContext(ReviewAndConfirm)
