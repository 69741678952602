import React from 'react'
import InlineError from 'components/InlineError'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { datadogLogs } from '@datadog/browser-logs'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.title = props.title ? props.title : 'Sorry, something went wrong on our end.'
    this.detail = props.detail
      ? props.detail
      : 'Something unexpected has occured. Please try again later or contact us at support@motryx.com'
    this.state = { error: null }
  }

  componentDidCatch (error) {
    console.log(error, window.location)
    datadogLogs.logger.error(error, window.location)
    this.setState({
      error: error
    })
  }

  render () {
    if (this.state.error) {
      return (
        <div data-testid="errorBoundary">
          <InlineError title={this.title} detail={this.detail} icon={ErrorOutlineIcon} />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
