import React from 'react'
import { Document, StyleSheet, Page, View } from '@react-pdf/renderer'
import {
  BackPage,
  Footer,
  TitlePage,
  Title,
  Row,
  TextPage,
  StyledText,
  BulletPoint,
  BulletPointDefinition
} from './components'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: '10x 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  header: {
    marginBottom: 10
  },
  section: {
    marginBottom: 15
  },
  paragraph: {
    marginBottom: 5
  },
  indent: {
    marginLeft: 20
  },
  in_text_reference: {
    display: 'flex',
    borderTop: '0.5px solid #D6DAE0'
  }
})

function parseDate (input) {
  const parts = input.split('-')
  return new Date(parts[0], parts[1] - 1, parts[2])
}

function getRejectionQuality (value) {
  const low = 3
  const acceptable = 15

  if (value < low) {
    return 'low'
  }
  if (value <= acceptable) {
    return 'acceptable'
  }
  return 'substantial'
}

export default function ValidationReportAerocom ({ relationship = 'internal', validation, lineStats, analyzer }) {
  const lineNumberPerPage = 10
  const lines = []
  const lineNumber = lineStats.length
  const summary = lineStats?.reduce((summary, line, index) => {
    const chunkIndex = Math.floor(index / lineNumberPerPage)
    if (!lines[chunkIndex]) {
      lines[chunkIndex] = []
    }
    lines[chunkIndex].push(line)

    return {
      ...summary,
      lines: lines,
      min: summary.min ? (line.auc?.mean < summary.min.auc?.mean ? line : summary.min) : line,
      max: summary.max ? (line.auc?.mean > summary.max.auc?.mean ? line : summary.max) : line,
      conclusion: summary.max?.auc?.rejection_rates?.ldh <= 15 && summary.max?.auc?.rejection_rates?.asat <= 15 && summary.max?.auc?.rejection_rates?.potassium <= 15 ? 'rejection probability of all validated PTS lines are within the acceptable range for common analytes' : summary.max?.auc?.rejection_rates?.ldh <= 15 || summary.max?.auc?.rejection_rates?.asat <= 15 || summary.max?.auc?.rejection_rates?.potassium <= 15 ? 'rejection probability of some validated PTS lines are within the acceptable range for common analytes' : 'rejection probability of validated PTS lines are substantial for common analytes',
      ldh_quality_count: {
        low: summary.ldh_quality_count ? (line.auc?.rejection_rates?.ldh < 3 ? summary.ldh_quality_count.low + 1 : summary.ldh_quality_count.low) : (line.auc?.rejection_rates?.ldh < 3 ? 1 : 0),
        acceptable: summary.ldh_quality_count ? (line.auc?.rejection_rates?.ldh >= 3 && line.auc?.rejection_rates?.ldh <= 15 ? summary.ldh_quality_count.acceptable + 1 : summary.ldh_quality_count.acceptable) : (line.auc?.rejection_rates?.ldh >= 3 && line.auc?.rejection_rates?.ldh <= 15 ? 1 : 0),
        substantial: summary.ldh_quality_count ? (line.auc?.rejection_rates?.ldh > 15 ? summary.ldh_quality_count.substantial + 1 : summary.ldh_quality_count.substantial) : (line.auc?.rejection_rates?.ldh > 15 ? 1 : 0)
      },
      asat_quality_count: {
        low: summary.asat_quality_count ? (line.auc?.rejection_rates?.asat < 3 ? summary.asat_quality_count.low + 1 : summary.asat_quality_count.low) : (line.auc?.rejection_rates?.asat < 3 ? 1 : 0),
        acceptable: summary.asat_quality_count ? (line.auc?.rejection_rates?.asat >= 3 && line.auc?.rejection_rates?.asat <= 15 ? summary.asat_quality_count.acceptable + 1 : summary.asat_quality_count.acceptable) : (line.auc?.rejection_rates?.asat >= 3 && line.auc?.rejection_rates?.asat <= 15 ? 1 : 0),
        substantial: summary.asat_quality_count ? (line.auc?.rejection_rates?.asat > 15 ? summary.asat_quality_count.substantial + 1 : summary.asat_quality_count.substantial) : (line.auc?.rejection_rates?.asat > 15 ? 1 : 0)
      },
      potassium_quality_count: {
        low: summary.potassium_quality_count ? (line.auc?.rejection_rates?.potassium < 3 ? summary.potassium_quality_count.low + 1 : summary.potassium_quality_count.low) : (line.auc?.rejection_rates?.potassium < 3 ? 1 : 0),
        acceptable: summary.potassium_quality_count ? (line.auc?.rejection_rates?.potassium >= 3 && line.auc?.rejection_rates?.potassium <= 15 ? summary.potassium_quality_count.acceptable + 1 : summary.potassium_quality_count.acceptable) : (line.auc?.rejection_rates?.potassium >= 3 && line.auc?.rejection_rates?.potassium <= 15 ? 1 : 0),
        substantial: summary.potassium_quality_count ? (line.auc?.rejection_rates?.potassium > 15 ? summary.potassium_quality_count.substantial + 1 : summary.potassium_quality_count.substantial) : (line.auc?.rejection_rates?.potassium > 15 ? 1 : 0)
      }
    }
  }, {})

  return (
      <Document>
        <TitlePage data={validation} />

        <TextPage>
          <View style={styles.section}>
            <View style={styles.header}>
              <StyledText variant={'h1'}>{'1. Introduction'}</StyledText>
            </View>
            <StyledText variant={'body1'}>{`${relationship === 'partner_aerocom' ? `Aerocom visited ${validation.name}` : `Pneumatic tube system validation, ${validation.name}, was performed`} on ${parseDate(validation.start_date).toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })} ${relationship === 'partner_aerocom' ? 'to perform a pneumatic tube system (PTS) validation' : ''} by measuring transit forces related to system performance and clinical outcomes. A set of ${validation.lines.length} PTS lines[1] were validated. On each line, cumulative vibrations were measured with a VitalVial four times. This report describes the forces measured in the PTS and outlines the potential for blood sample hemolysis resulting from transport in the selcted PTS lines.`}</StyledText>
          </View>
          <View style={{ display: 'flex', flexGrow: 1 }}>
            <View style={styles.section}>
              <View style={styles.header}>
                <StyledText variant={'h1'}>{'2. Executive Summary'}</StyledText>
              </View>
              <View style={styles.paragraph}>
                <StyledText variant={'body1'}>{`The line with the least cumulative vibrations was ${summary.min.line_name}. The line had ${getRejectionQuality(summary.min.auc?.rejection_rates?.ldh)} probability (${summary.min.auc?.rejection_rates?.ldh}%) of sample rejection for LDH, ${getRejectionQuality(summary.min.auc?.rejection_rates?.asat)} probability (${summary.min.auc?.rejection_rates?.asat}%) of sample rejection for ASAT, ${getRejectionQuality(summary.min.auc?.rejection_rates?.potassium)} probability (${summary.min.auc?.rejection_rates?.potassium}%) of sample rejection for Potassium.[2]`}</StyledText>
              </View>
              <View style={styles.paragraph}>
                <StyledText variant={'body1'}>{`The line with the most cumulative vibrations was ${summary.max.line_name}. The line had ${getRejectionQuality(summary.max.auc?.rejection_rates?.ldh)} probability (${summary.max.auc?.rejection_rates?.ldh}%) of sample rejection for LDH, ${getRejectionQuality(summary.max.auc?.rejection_rates?.asat)} probability (${summary.max.auc?.rejection_rates?.asat}%) of sample rejection for ASAT, ${getRejectionQuality(summary.max.auc?.rejection_rates?.potassium)} probability (${summary.max.auc?.rejection_rates?.potassium}%) of sample rejection for Potassium.`}</StyledText>
              </View>
              <View style={styles.paragraph}>
                <StyledText variant={'body1'}>{`The tests performed in this validation indicate that ${summary.conclusion}, given the observed conditions.`}</StyledText>
              </View>
            </View>
          </View>
          <View style={styles.in_text_reference}>
            <StyledText variant={'reference'}>
              {`[1] A pneumatic tube system "line" is predefined route between any two sending and receiving stations.
              [2] The rejection probability is based on the likelihood that hemolysis will exceed manufacturer-defined thresholds on the ${analyzer.make} ${analyzer.model} clinical analyzer. Acceptance thresholds may vary by analyzer and by laboratory. Consult Appendix B - Rejection Probability and Performance for detailed information on rejection probabilities and quantitative definitions on the terms "low", "acceptaable", "high".`}
            </StyledText>
          </View>
        </TextPage>

        <TextPage>
          <View style={styles.section}>
            <View style={styles.header}>
              <StyledText variant={'h1'}>{'3. Results'}</StyledText>
            </View>
            <View style={styles.paragraph}>
              <StyledText variant={'body1'}>{'The following information is available for each validated line:'}</StyledText>
            </View>
            <View style={styles.indent}>
              <BulletPointDefinition name={'VitalMetric'}>{'A measure of the net cumulative vibrations on a PTS line.'}</BulletPointDefinition>
              <BulletPointDefinition name={'Variability'}>{'The Coefficient of Variation (CV), or relative standard deviation, of the VitalMetric. This value is a percentage that represents how variable the VitalMetric is on a single line across the four measurements.'}</BulletPointDefinition>
              <BulletPointDefinition name={'Performance'}>{'An indicator of the vibration compared to the length of the line, where higher performance indeicates less vibration per meter of a PTS line.'}</BulletPointDefinition>
              <BulletPointDefinition name={'Rejection Probability'}>
                  <View style={styles.paragraph}>
                    <StyledText variant={'body1'}>{`The probability that samples will exceed manufacturer provided hemolysis thresholds. The rejection probabilities for LDH, ASAT, and Potassium shown here use threshold value from ${analyzer.make} ${analyzer.model} analyzer. Threshold values may vary by analyzer and by laboratory; consult Appendix B - Rejection Probability and Performance for information on how these rates are calculated and on using alternate threshold values.
                    All projections for rejection rates pertain to the portion of pre-analytical hemolysis caused by transport in the validated PTS routes only. Other pre-analytical factors, patient health, and external factors also contribute to blood hemolysis and clinical interference. Therefore, actual interference rates observed in the laboratory may differ from those presented here.`}</StyledText>
                  </View>
              </BulletPointDefinition>
            </View>
          </View>
        </TextPage>

        {
          lines?.map((item, index) =>
            <Page key={index} size="A4" style={styles.page}>
              <Title data={validation} />
              <View style={styles.content}>
                { item.map((line, index) => <Row key={line.line_id} data={line} border={index !== (item.length - 1)} />) }
              </View>
              <Footer />
            </Page>
          )
        }

        <TextPage>
          <View style={styles.section}>
            <View style={styles.header}>
              <StyledText variant={'h1'}>{'4. Conclusion'}</StyledText>
            </View>
            <View style={styles.indent}>
              <BulletPoint><StyledText variant={'body1'}>{`This validation indicates that ${summary.conclusion}, assuming transport conditions and analyzer settings remain as tested.`}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{`${(summary.ldh_quality_count.substantial === 0) && (summary.asat_quality_count.substantial === 0) && (summary.potassium_quality_count.substantial === 0) ? 'None' : (summary.ldh_quality_count.substantial === lineNumber) && (summary.asat_quality_count.substantial === lineNumber) && (summary.potassium_quality_count.substantial === lineNumber) ? 'All' : 'Some'} of the tested routes are expected to cause substantial hemolysis from the measured transport vibrations. ${((summary.ldh_quality_count.substantial === 0) && (summary.asat_quality_count.substantial === 0) && (summary.potassium_quality_count.substantial === 0)) ? 'There was no evidence to indicate that mitigation strategies are required for routine sample transport as long as the transport conditions and analyzer settings remain as tested.' : 'Some mitigation strategies may be required (Appendix A)'} `}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{`The transport-related rejection probability for LDH is ${summary.ldh_quality_count.low === lineNumber ? 'small (less than 3%)' : (summary.ldh_quality_count.substantial === 0) ? 'not small, but still acceptable (less than 15%)' : 'substantial (more than 15%)'} for blood samples transported by the validated PTS lines. ${summary.ldh_quality_count.substantial > 0 ? 'We recommend careful monitoring of LDH rejection rates on the lines and implementing mitigation strategies if needed.' : ''}`}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{`The transport-related rejection probability for ASAT is ${summary.asat_quality_count.low === lineNumber ? 'small (less than 3%)' : (summary.asat_quality_count.substantial === 0) ? 'not small, but still acceptable (less than 15%)' : 'substantial (more than 15%)'} for blood samples transported by the validated PTS lines. ${summary.asat_quality_count.substantial > 0 ? 'We recommend careful monitoring of ASAT rejection rates on the lines and implementing mitigation strategies if needed.' : ''}`}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{`The transport-related rejection probability for Potassium is ${summary.potassium_quality_count.low === lineNumber ? 'small (less than 3%)' : (summary.potassium_quality_count.substantial === 0) ? 'not small, but still acceptable (less than 15%)' : 'substantial (more than 15%)'} for blood samples transported by the validated PTS lines. ${summary.potassium_quality_count.substantial > 0 ? 'We recommend careful monitoring of Potassium rejection rates on the lines and implementing mitigation strategies if needed.' : ''}`}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{`Recommended date for the next validation is ${parseDate(validation.next_validation_date).toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })}, or after maintenance affecting blowers or system speed.`}</StyledText></BulletPoint>
            </View>
          </View>
        </TextPage>

        <BackPage analyzer={analyzer} />
      </Document>
  )
}
