import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import {
  TextPage,
  StyledText,
  BulletPoint,
  BulletPointDefinition
} from '../index.js'

const styles = StyleSheet.create({
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  header: {
    marginBottom: 10
  },
  section: {
    marginBottom: 7
  },
  paragraph: {
    marginBottom: 5
  },
  indent: {
    marginLeft: 20
  },
  analyzerValue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    minWidth: 80,
    marginRight: 20
  },
  in_text_reference: {
    display: 'flex',
    borderTop: '0.5px solid #D6DAE0'
  },
  reference: {
    marginBottom: 25
  },
  reference_indent: {
    marginLeft: 40
  }
})

const BackPage = ({ analyzer }) => {
  return (
    <>
      <TextPage title={'Appendix A - Mitigation Strategies'}>
        <View style={styles.section}>
          <StyledText variant={'body1'}>{'Options exist to reduce the potential for hemolysis resulting from PTS. These aim to reduce the influence of net vibrations and sudden impacts on the transport canister.'}</StyledText>
        </View>
        <View style={styles.section}>
          <View style={styles.paragraph}>
            <StyledText variant={'body2'}>{'Insert Padding in the Transport Canister:'}</StyledText>
          </View>
          <View style={styles.indent}>
            <BulletPoint><StyledText variant={'body1'}>{'Plastic bags or envelopes can offer between 60-90 % reduction in total vibration (Cadamuro et al., In Prep).'}</StyledText></BulletPoint>
            <BulletPoint><StyledText variant={'body1'}>{'Bubble wrap can reduce vibration by up to 77% (Cadamuro et al., In Prep). A recent study at Sheffield Teaching Hospitals revealed that bubble wrap in PTS canisters reduced vibration by 11.3%, leading to a 40% reduction in PTS-induced hemolysis and improved sample rejection rates (Cooper et al., Under Review).'}</StyledText></BulletPoint>
            <BulletPoint><StyledText variant={'body1'}>{'Baby diapers can be a surprisingly useful padding material because they are inexpensive and absorbent in the event of any leakage; they are commonly used at University Hospital Salzburg (Austria). Sending samples wrapped in a single diaper reduced vibration by 49%; wrapping samples in two diapers reduced vibration by 75% (Cadamuro et al., In Prep).'}</StyledText></BulletPoint>
            <BulletPoint><StyledText variant={'body1'}>{'Foam has shown variable success in reducing measured vibration and subsequent hemolysis. Some studies show up to 60% reduction in vibration, while others show no change. At Yale New Haven Hospital (USA), padding carriers resulted in a 30% decrease in VitalMetric, and sample rejection due to hemolysis was reduced from 11% (17/150 samples) to 0% (0/56) with foam padding (Koch et al., 2020). Different densities and material characteristics of the foam are a likely factor in these different success rates.'}</StyledText></BulletPoint>
            <BulletPoint><StyledText variant={'body1'}>{'Avoid rigid inserts, as they transfer the vibration directly from the canister to the samples themselves. A recent study has shown that vials transported with rigid inserts show equal to or higher vibration compared to those sent without inserts (Cadamuro et al. In Prep).'}</StyledText></BulletPoint>
            <BulletPoint><StyledText variant={'body1'}>{'Padding at the arrival station or otherwise ensuring a “soft landing” is another procedure used in many laboratories.'}</StyledText></BulletPoint>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.paragrph}>
            <StyledText variant={'body2'}>{'Reduce Transport Speed:'}</StyledText>
          </View>
          <View style={styles.indent}>
          <BulletPoint><StyledText variant={'body1'}>{'Reducing speed in the pneumatic system can reduce total vibration and hemolysis. For example, in one study a 1.2 m/s speed reduction in the PTS reduced the frequency of rejection for hemolysis from 16.4% to 12.9% (Gils et al. 2020).'}</StyledText></BulletPoint>
          </View>
        </View>
      </TextPage>

      <TextPage title={'Appendix B - Rejection Probability and Performance'}>
        <View style={{ display: 'flex', flexGrow: 1 }}>
          <View style={styles.section}>
            <View style={styles.header}>
              <StyledText variant={'h3'}>{'Rejection Probability'}</StyledText>
            </View>
            <View style={styles.paragraph}>
              <StyledText variant={'body1'}>{'The Rejection Praobability for a given analyte is the likelihood that the Hemolysis Index of a sample transported on a specific PTS line will exceed a pre-defined hemolysis cut off. The probabilities provided in VitalQC are calculated using Motryx’s statistical models which leverage a database of nearly 2000 paired measurements of VitalMetric and Hemolysis Index. Measurements come from more than 70 distinct PTS lines, representing the most common PTS transport scenarios, with clinical analysis done on the most common clinical analyzers (Roche, Abbot, Siemens).'}</StyledText>
            </View>
            <View style={styles.paragraph}>
              <StyledText variant={'body1'}>{'Samples used to build the statistical models come from a combination of controlled experiment and routine laboratory collection, and samples from donors with known pre-existing conditions or from bad blood draws are screened out. In the net, Motryx’s statistical models represent blood conditions without known preanalytical hemolysis, with a constrained error bar that tends to be smaller than the potential preanalytical variability in individual samples that can result from bad blood draws or compromised patient health. Statistical uncertainty in the rejection probabilities is between 5-14 percentage points for LDH and less than 1 percentage point for ASAT and Potassium.'}</StyledText>
            </View>
            <StyledText variant={'body1'}>{'The following qualitative descriptors are used in this document to describe rejection probabilities:'}</StyledText>
            <View style={styles.indent}>
              <BulletPoint><StyledText variant={'body1'}>{'less than 3% is a low probability of rejection'}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{'between 3% and 15% is an acceptable probability of rejection'}</StyledText></BulletPoint>
              <BulletPoint><StyledText variant={'body1'}>{'greater than 15% is a substantial probability of rejection'}</StyledText></BulletPoint>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.header}>
              <StyledText variant={'h3'}>{'Analyzer Cutoffs'}</StyledText>
            </View>
            <View style={styles.paragraph}>
              <StyledText variant={'body1'}>{'Manufacturers of clinical analyzers set thresholds, or "cutoff values" for a sample’s Hemolysis Index, above which hemolysis may interfere with the measurement of clinical analytes.[3] The Rejection Probability calculated by VitalQC is dependent on these cutoff values; larger cutoffs translate to lower rejection probabilities and vice versa.'}</StyledText>
            </View>
            <View style={styles.header}>
              <StyledText variant={'h4'}>{'Your Cutoffs'}</StyledText>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={styles.analyzerValue}>
                  <StyledText variant={'body1'}>{'Supplier: '}</StyledText>
                  <StyledText variant={'body2'}>{analyzer.make}</StyledText>
                </View>
                <View style={styles.analyzerValue}>
                  <StyledText variant={'body1'}>{'Model: '}</StyledText>
                  <StyledText variant={'body2'}>{analyzer.model}</StyledText>
                </View>
              </View>
              <View style={styles.column}>
                <View style={styles.analyzerValue}>
                  <StyledText variant={'body1'}>{'LDH: '}</StyledText>
                  <StyledText variant={'body2'}>{analyzer.ldh_threshold}{' mg/dL'}</StyledText>
                </View>
                <View style={styles.analyzerValue}>
                  <StyledText variant={'body1'}>{'ASAT: '}</StyledText>
                  <StyledText variant={'body2'}>{analyzer.asat_threshold}{' mg/dL'}</StyledText>
                </View>
                <View style={styles.analyzerValue}>
                  <StyledText variant={'body1'}>{'K: '}</StyledText>
                  <StyledText variant={'body2'}>{analyzer.potassium_threshold}{' mg/dL'}</StyledText>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.in_text_reference}>
          <StyledText variant={'reference'}>{'[3] Interference is defined as changes in analyte measurements that exceed the total allowable error established by the Clinical Laboratory Improvement Amendments (1998: K+, ±0.5 mmol/L; AST, 20%; LDH, 20%), RiliBÄK (K+, 4.5%; AST, 11.5%; LDH, 9%), or other local authorities'}</StyledText>
        </View>
      </TextPage>

      <TextPage title={'Appendix B - Rejection Probability and Performance'}>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h3'}>{'Performance'}</StyledText>
          </View>
          <View style={styles.paragraph}>
            <StyledText variant={'body1'}>{'Performance is an indicator of the vibration compared to the length of the line, where higher performance indicates less vibration per meter of a PTS line.'}</StyledText>
          </View>
          <StyledText variant={'body1'}>{'Lower performance means that the system has more vibration than typical for systems of that length. This may indicate a problem with the system or that maintenance is required. Consult the manufacturer or installer to be certain the system is operating within specification.'}</StyledText>
        </View>
      </TextPage>

      <TextPage title={'Appendix C - Frequently Asked Questions'}>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h3'}>{'Why perform a PTS validation?'}</StyledText>
          </View>
          <StyledText variant={'body1'}>{'Validation is required by International Standards (e.g., ISO 15189) and is recommended by regional organizations (e.g., AABB, CAP, EFLM). Validation ensures that PTS transport has no significant impact on the diagnostic outcome of the blood samples and/or products, and to determine a baseline level of PTS performance to use as benchmarks for ongoing system monitoring.'}</StyledText>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h3'}>{'What is VitalMetric?'}</StyledText>
          </View>
          <StyledText variant={'body1'}>{'VitalMetric measures cumulative vibration, based on approaches found in peer-reviewed literature. It is calculated from the distribution of peaks in the vector sum of the three-dimensional high-frequency acceleration record (Streichert et al., 2011, Farnsworth et al., 2019, Gils et al. 2020). This parameter relates to the vibration and shear forces that lead to transport-related hemolysis (Brooks et al. 1970) and to indicators of hemolysis such as elevated LDH and potassium (Streichert et al., 2011, Farnsworth et al., 2019, Gils et al. 2020). This report uses the VitalMetric to predict clinical impact using the Hemolysis Index Limit.'}</StyledText>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h3'}>{'Why send VitalVials in separate canisters?'}</StyledText>
          </View>
          <StyledText variant={'body1'}>{'The validation data protocol requires four VitalVials from each sending to receiving station to be sent in separate canisters. Since canisters can take different routes from a sending to a receiving station, multiple measurements are required to capture transport variability.'}</StyledText>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h3'}>{'What is variability?'}</StyledText>
          </View>
          <StyledText variant={'body1'}>{'Canisters can take different routes from a sending to a receiving station, often depending on the number of transactions in the system. As a result, the canisters can be transported different distances, at different speeds, and experience different levels of vibration. Transport variability reflects how different the four measurements of vibration are on the same line and therefore measures the stability of the transport system.'}</StyledText>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h3'}>{'Why continue monitoring after validation?'}</StyledText>
          </View>
          <View style={styles.paragraph}>
            <StyledText variant={'body1'}>{'Validation establishes a level of system performance for each PTS line, including expected levels of hemolysis and specimen rejection. Monitoring is essential to ensure the system continues to perform as was established as appropriate during validation and provides routine system testing that does not rely on blood samples or products.'}</StyledText>
          </View>
          <StyledText variant={'body1'}>{'Vibration measurements collected for verification after system maintenance can be compared to the system performance and the QC limits set during the first validation. In this way, new measurements can be compared to initial values to guarantee safe transport before and after changes to the system.'}</StyledText>
        </View>
      </TextPage>

      <TextPage title={'Appendix D - Glossary'}>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h2'}>{'Definitions'}</StyledText>
          </View>
          <View style={styles.indent}>
            <BulletPointDefinition name={'Hemolysis'}>{'Hemolysis is the destruction of red blood cells in whole blood. In-vitro (outside of the body) hemolysis can occur due to different causes (e.g., shock or vibration) and leads to the release of cell-bound hemoglobin and other analytes into the blood.'}</BulletPointDefinition>
            <BulletPointDefinition name={'PTS'}>{'Pneumatic Tube System.'}</BulletPointDefinition>
            <BulletPointDefinition name={'VitalApp'}>{'VitalApp is a cloud-based data storage, management and processing software that interacts with the VitalVial to compute relevant statistics and performance values for PTS systems.'}</BulletPointDefinition>
            <BulletPointDefinition name={'VitalVial'}>{'The VitalVial is a measurement unit that consists of a vibration sensor embedded in a standard 10 ml BD Vacutainer. Being weight-matched to blood sample, it mimics a specimen and records what samples experience in transport.'}</BulletPointDefinition>
            <BulletPointDefinition name={'VitalQC'}>{'VitalQC is a quality control solution for the blood transportation through PTS. VitalQC consists of VitalApp and VitalVial.'}</BulletPointDefinition>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <StyledText variant={'h2'}>{'Measurements'}</StyledText>
          </View>
          <View style={styles.indent}>
            <BulletPointDefinition name={'VitalMetric'}>{'The VitalMetric expresses the cumulative vibration measured by the VitalVial and is based on the acceleration record throughout the total transit period within the PTS.'}</BulletPointDefinition>
            <BulletPointDefinition name={'CV (%)'}>{'Coefficient of Variation, or the relative standard deviation. A relative measure used to express precision and repeatability. It is the ratio of the standard deviation to the mean.'}</BulletPointDefinition>
            <BulletPointDefinition name={'ASAT (U/L)'}>{'Measured in routine clinical chemistry as an indicator of liver damage. This parameter has been shown to be sensitive to in-vitro hemolysis.'}</BulletPointDefinition>
            <BulletPointDefinition name={'LDH (U/L)'}>{'Measured in routine clinical chemistry as a surrogate for tissue breakdown. Widely accepted as the most sensitive parameter to vibration-linked hemolysis.'}</BulletPointDefinition>
            <BulletPointDefinition name={'Potassium (mmol/L, mg/dL)'}>{'Potassium is a parameter sensitive to the effect of hemolysis and levels of potassium are often used as an indicator of in-vitro hemolysis.'}</BulletPointDefinition>
            <BulletPointDefinition name={'Hemolysis Index'}>{'A common metric generated by chemical analyzers, which standardizes the process of identifying hemolytic specimens and estimating hemolysis interferences quantitatively.'}</BulletPointDefinition>
          </View>
        </View>
      </TextPage>

      <TextPage title={'Appendix E - References'}>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Brooks, D.E., J.W. Goodwin, and G.V. Seaman, Interactions among erythrocytes under shear.'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'Journal of Applied Physiology, 1970. 28(2): 172-177.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Cadamuro, J., von Meyer, A., Haschke-Becher, E., Keppel, M., Streichert, T. Effect of five different'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'pneumatic tube carrier inserts on mechanical sample stress. A multicentre evaluation. In Preparation for Submission at Clinical Chemistry.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Cooper, J., van der Hoop, J., Broell, F., and Kitchen S. (In Prep). Identifying and reducing the'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'induction of haemolysis from sample transport in Pneumatic Tube Systems.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Douhal Y, Benito Zamorano V‡, Rivas Chacón LDM, Cuadrado Galván E, Pascual Durán T.'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'Study of Interference Produced by Haemolysis In 73 Analytical Tests. Biomed J Sci & Tech Res 24(3)-2020. BJSTR. MS.ID.004069.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Farnsworth, C.W., et al., Parameters for validating a hospital pneumatic tube system. Clinical'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'Chemistry, 2019. 65(5): 1-9.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Gils C., Broell, F., Vinholt, P.J., Nielsen, C., Nybo, M. (2020). Use of Clinical Data and Acceleration'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'Profiles to Validate Pneumatic Transportation Systems. Clin Chem Lab Med 26; 58(4):560-568. doi: 10.1515/cclm-2019-0881.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Goyal, T. and Schmotzer, C.L., 2015. Validation of hemolysis index thresholds optimizes detection'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'of clinically significant hemolysis. American Journal of Clinical Pathology, 143(4), pp.579-583.'}</StyledText></View>
        </View>
        <View style={styles.reference}>
          <StyledText variant={'body1'} margin={0}>{'Koch, C.D., van der Hoop, J., Broell, F., El-Khoury, J.M. Foam padding in pneumatic tube carriers'}</StyledText>
          <View style={styles.reference_indent}><StyledText variant={'body1'} margin={0}>{'reduces g-forces and sample hemolysis rates. 72nd AACC Annual Scientific Meeting & Lab Expo.'}</StyledText></View>
        </View>
      </TextPage>
    </>
  )
}

export default BackPage
