import FilterListIcon from '@mui/icons-material/FilterList'
import { DatePicker } from '@mui/lab'

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { BlurWrapper, Labels, Loader, MainContent, Sidebar } from 'components'
import { format, parseJSON } from 'date-fns'
import { COMPARE_RECRODS_GRAPH_COLORS } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js'
import { useQueries, useQuery, useQueryClient } from 'react-query'
import { api, apiConfig, useFeaturesContext } from 'services'

const EXEPMTED_INSTITUTES = [399]

const LinesFilters = ({
  options = [],
  onSelectedChanged,
  selectedOptions,
  isLoading
}) => {
  const handleSelectedChanged = (event, value) => {
    onSelectedChanged(value)
  }

  return (
    <Autocomplete
      size="small"
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedOptions}
      renderInput={(params) => <TextField {...params} label="Select Lines" />}
      onChange={handleSelectedChanged}
      filterSelectedOptions
      autoHighlight
      loading={isLoading}
      loadingText="Loading Lines..."
    />
  )
}
const ValidationsFilters = ({
  options = [],
  onSelectedChanged,
  selectedOptions
}) => {
  const handleSelectedChanged = (event, value) => {
    onSelectedChanged(value)
  }

  return (
    <Autocomplete
      size="small"
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedOptions}
      renderInput={(params) => (
        <TextField {...params} label="Select Validations" />
      )}
      onChange={handleSelectedChanged}
      filterSelectedOptions
      autoHighlight
    />
  )
}

const RecordsGraphPlot = ({
  recordsDetailedDataQueryResults = [],
  selectedRecordsIds = [],
  showLegends,
  downloadImageType = 'svg'
}) => {
  const { flags, overrides, getFlag } = useFeaturesContext()
  const [blurInfo, setBlurInfo] = useState(false)
  // Filter the recordsDetailedDataQueryResults to only include the selectedRecordsIds
  recordsDetailedDataQueryResults = recordsDetailedDataQueryResults.filter(
    (result) => {
      if (result.isSuccess) {
        return selectedRecordsIds.some(
          (selectedRecordId) => selectedRecordId === result.data.record.id
        )
      }
    }
  )

  const isLoading = recordsDetailedDataQueryResults.some(
    (result) => result.isLoading
  )

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress
          size={50}
          sx={{
            color: 'primary.main'
          }}
        />
      </Box>
    )
  }

  useEffect(() => {
    if (EXEPMTED_INSTITUTES.includes(apiConfig.currentApiInstitute)) {
      setBlurInfo(false)
      return
    }
    if (getFlag) {
      setBlurInfo(!getFlag('troubleshooting'))
    }
  }, [flags, overrides, getFlag])

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      px={2}
    >
      <BlurWrapper doBlur={blurInfo} showCTAComponent={true} blurLevel={40}>
        <Plot
          data={[
            ...recordsDetailedDataQueryResults.map((result, index) => {
              return {
                ...result.data.cumulative,
                type: 'line',
                xaxis: 'x0',
                yaxis: 'y0',
                marker: {
                  color: recordsDetailedDataQueryResults[index].data.color
                }
              }
            })
          ]}
          useResizeHandler
          layout={{
            height: 350,
            grid: {
              // Get number of rows with isSuccess
              rows: 1,
              columns: 1,
              pattern: 'independent'
            },
            margin: {
              t: 20,
              b: 35,
              l: 50,
              r: 20
            },
            legend: {
              orientation: 'h',
              yanchor: 'bottom',
              y: 1.4
            },
            showlegend: showLegends,
            xaxis: {
              title: 'Time (s)'
            },
            yaxis: {
              title: 'VitalMetric'
            }
          }}
          config={{
            toImageButtonOptions: {
              format: downloadImageType
            }
          }}
        />
        <Plot
          data={[
            ...recordsDetailedDataQueryResults.map((result, index) => {
              return {
                ...result.data.acceleration,
                type: 'scatter',
                hoverinfo: 'text',
                xaxis: 'x' + (index + 1),
                yaxis: 'y' + (index + 1),
                marker: {
                  color: recordsDetailedDataQueryResults[index].data.color
                }
              }
            })
          ]}
          useResizeHandler
          layout={{
            height:
              recordsDetailedDataQueryResults.length * 350 -
              (recordsDetailedDataQueryResults.length - 1) * 150,
            grid: {
              // Get number of rows with isSuccess
              rows: recordsDetailedDataQueryResults.length,
              columns: 1,
              pattern: 'independent'
            },
            margin: {
              t: 30,
              b: 50,
              l: 50,
              r: 20
            },
            legend: {
              orientation: 'h',
              yanchor: 'bottom',
              y: 1.04
            },
            showlegend: showLegends,
            xaxis: {
              title: 'Time (s)'
            },
            yaxis: {
              title: 'Acceleration (g)'
            },
            // Add xaxis and yaxis for each record
            ...recordsDetailedDataQueryResults.reduce(
              (acc, result, index) => ({
                ...acc,
                ['xaxis' + (index + 1)]: {
                  title: 'Time (s)'
                },
                ['yaxis' + (index + 1)]: {
                  title: 'Acceleration (g)'
                }
              }),
              {}
            )
          }}
          config={{
            toImageButtonOptions: {
              format: downloadImageType
            }
          }}
        />
      </BlurWrapper>
    </Box>
  )
}

const CompareRecords = () => {
  const queryClient = useQueryClient()
  const sidebarElm = useRef(null)
  const [showFilters, setShowFilters] = useState(true)
  const [validations, setValidations] = useState([])
  const [selectedValidations, setSelectedValidations] = useState([])
  const [selectedLines, setSelectedLines] = useState([])
  const [selectedRecordsIds, setSelectedRecordsIds] = useState([])
  const [recordsDataGridPageSize, setRecordsDataGridPageSize] = useState(10)
  const [showLegends, setShowLegends] = useState(true)
  const [downLoadImageType, setDownLoadImageType] = useState('svg')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const recordsDataColumns = [
    {
      field: 'labels',
      headerName: 'Labels',
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const labels = params.getValue(params.id, 'labels')
        return (
          <Labels
            shownLabels={labels && labels.slice(0, 2)}
            hiddenLabels={labels && labels.slice(2)}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="flex-start"
          />
        )
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 120,
      renderCell: ({ value }) => {
        return (
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.875rem',
              // Wrap the text to the next line
              whiteSpace: 'normal'
            }}
          >
            {format(parseJSON(value), 'yyyy-MM-dd HH:mm:ss')}
          </Typography>
        )
      }
    },
    {
      field: 'metrics',
      headerName: 'VMetric',
      flex: 1,
      minWidth: 120,
      renderCell: ({ value }) => {
        return (
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.875rem'
            }}
          >
            {value?.auc || 'NA'}
          </Typography>
        )
      }
    },
    {
      field: 'line',
      headerName: 'Line',
      flex: 1,
      minWidth: 120,
      renderCell: ({ value }) => {
        return (
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.875rem'
            }}
          >
            {value?.name || 'NA'}
          </Typography>
        )
      }
    },
    {
      field: 'validation',
      headerName: 'Validation',
      flex: 1,
      minWidth: 120,
      renderCell: ({ value }) => {
        return (
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.875rem'
            }}
          >
            {value?.name || 'NA'}
          </Typography>
        )
      }
    }
  ]

  // Fetch the lines data
  const { data: lines, isLoading: isLinesLoading } = useQuery({
    queryKey: ['line-list', apiConfig.currentApiInstitute],
    queryFn: async () => {
      const linesResponse = await api.lines.list({
        size: 9999
      })
      return linesResponse
    }
  })

  // Fetch the records for each line
  const {
    data: recordsData,
    isLoading: isRecordsLoading,
    refetch: refetchRecrodsData
  } = useQuery({
    queryKey: [
      'record-list',
      [
        selectedLines.map((line) => line.id).join(','),
        selectedValidations.map((validation) => validation.id).join(',')
      ]
    ],
    queryFn: async () => {
      const recordsResponse = await api.records.list({
        size: 9999,
        // line ids as string sperated by comma
        line_ids: selectedLines.map((line) => line.id).join(','),
        // validation ids as string sperated by comma
        validation_ids:
          selectedValidations.map((validation) => validation.id).join(',') ||
          null,
        // add start date if not null
        date__gte: startDate ? startDate : null,
        // add end date if not null
        date__lt: endDate ? endDate : null,
        // add order by date
        ordering: '-date',
        // exclude false
        exclude: false
      })
      return recordsResponse
    },
    enabled: false
  })

  const recordsDetailedDataQueryResults = useQueries(
    recordsData?.items?.map((record, index) => {
      return {
        queryKey: ['record-detail', record.id],
        queryFn: async () => {
          const accelerationResponse = await api.records.acceleration(record.id)
          const cumulativeResponse = await api.records.cumulative(record.id)
          return {
            color: COMPARE_RECRODS_GRAPH_COLORS[index % 10],
            record: record,
            acceleration: {
              x: accelerationResponse.acceleration_time,
              y: accelerationResponse.acceleration,
              name: `${record?.line?.name} - ${format(
                parseJSON(record?.date),
                'd MMM yyyy HH:mm'
              )}`,
              text: accelerationResponse.acceleration_time.map(
                (time, index) =>
                  `${
                    record?.line?.name || 'NA'
                  } ${accelerationResponse.acceleration[index].toFixed(
                    2
                  )}g at ${time.toFixed(2)} seconds`
              ),
              maximum_acceleration: accelerationResponse.maximum_acceleration
            },
            cumulative: {
              x: cumulativeResponse.time,
              y: cumulativeResponse.cumulative_metric,
              name: `${record?.line?.name} - ${format(
                parseJSON(record?.date),
                'd MMM yyyy HH:mm'
              )}`,
              text: cumulativeResponse.time.map(
                (time, index) =>
                  `${
                    record?.line?.name || 'NA'
                  } ${cumulativeResponse.cumulative_metric[index].toFixed(
                    2
                  )}g at ${time.toFixed(2)} seconds`
              ),
              hoverinfo: 'text'
            }
          }
        }
      }
    }) || []
  )

  const handleShowRecordsButtonClick = () => {
    refetchRecrodsData()
  }

  const handleValidationSelectChanged = (value) => {
    setSelectedValidations(value)
  }

  const handleLineSelectChanged = (value) => {
    if (lines?.items?.length > 0) {
      // Get all the objects in value that are in lines
      const selectedLines = lines.items.filter((line) =>
        value.some((v) => v.id === line.id)
      )
      setSelectedLines(selectedLines)
    }
  }

  const handleShowLegendsSwitchChange = (event) => {
    setShowLegends(event.target.checked)
  }

  const handleDownloadImageTypeSelectChange = (event) => {
    setDownLoadImageType(event.target.value)
  }

  // Prefetch the validations for each line
  useEffect(() => {
    if (lines?.items?.length > 0 && !isLinesLoading) {
      lines.items.forEach((line) => {
        queryClient.prefetchQuery(['validation-list', line.id], async () => {
          const validationsResponse = await api.validations.list({
            size: 9999,
            line_id: line.id
          })
          return validationsResponse
        })
      })
    }
  }, [lines, isLinesLoading])

  // Update the Validations  when selectedLines changes
  useEffect(() => {
    setSelectedValidations([])
    // Get the validations for the selected lines
    if (selectedLines.length > 0) {
      const newValidations = []

      selectedLines.forEach((line) => {
        const newValidationData = queryClient.getQueryData([
          'validation-list',
          line.id
        ])

        if (newValidationData?.items?.length > 0) {
          newValidations.push(...newValidationData.items)
        }
      })

      // Remove duplicates within the newValiadations list
      const uniqueValidations = newValidations.filter(
        (validation, index, self) =>
          index === self.findIndex((v) => v.id === validation.id)
      )

      setValidations(uniqueValidations)
    } else {
      setValidations([])
    }
  }, [selectedLines])

  return (
    <>
      <Sidebar ref={sidebarElm}>
        <Box
          display="flex"
          flexDirection="column"
          padding={1}
          justifyContent="center"
          gap={1}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h6">Filters</Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterListIcon />}
              onClick={() => setShowFilters(!showFilters)}
            >
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </Box>
          <Collapse in={showFilters}>
            <Box display="flex" flexDirection="column" gap={1}>
              <LinesFilters
                options={lines?.items || []}
                selectedOptions={selectedLines}
                onSelectedChanged={handleLineSelectChanged}
                isLoading={isLinesLoading}
              />
              <ValidationsFilters
                options={validations || []}
                selectedOptions={selectedValidations}
                onSelectedChanged={handleValidationSelectChanged}
              />
              <Box display="flex" flexDirection="row" gap={1}>
                <DatePicker
                  label="From Date"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue)
                  }}
                  disableFuture
                  inputFormat="dd MMM yyyy"
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
                <DatePicker
                  label="To Date"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue)
                  }}
                  disableFuture
                  inputFormat="dd MMM yyyy"
                  minDate={startDate || null}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  disabled={selectedLines.length === 0}
                  onClick={handleShowRecordsButtonClick}
                >
                  Get Records
                </Button>
              </Box>
            </Box>
          </Collapse>
          <Divider orientation="horizontal" />
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h6">Records</Typography>
            <DataGrid
              rows={recordsData?.items || []}
              autoHeight
              width="100%"
              columns={recordsDataColumns}
              loading={isRecordsLoading}
              pageSize={recordsDataGridPageSize}
              onPageSizeChange={(newPageSize) =>
                setRecordsDataGridPageSize(newPageSize)
              }
              componentsProps={{
                pagination: {
                  labelRowsPerPage: 'Per Page'
                }
              }}
              rowsPerPageOptions={[10, 25, 50, 90]}
              checkboxSelection
              disableSelectionOnClick
              hideFooterSelectedRowCount
              onSelectionModelChange={(newSelectionModel) => {
                // newSelectionModel is an array of ids of the records selected
                setSelectedRecordsIds(newSelectionModel)
              }}
            />
          </Box>
        </Box>
      </Sidebar>
      <MainContent sidebar="right">
        <Box
          display="flex"
          px={2}
          justifyContent="space-between"
          alignItems="center"
          pt={1}
        >
          <Typography variant="h6">Compare Records</Typography>
          <FormControl>
            <InputLabel id="download-image-type-label">Image Type</InputLabel>
            <Select
              size="small"
              label="Image Type"
              labelId="download-image-type-label"
              value={downLoadImageType}
              onChange={handleDownloadImageTypeSelectChange}
              sx={{
                height: 30,
                width: 100
              }}
            >
              <MenuItem value="png">
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="body1"
                  fontSize={16}
                >
                  SVG
                </Typography>
              </MenuItem>
              <MenuItem value="svg">
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="body1"
                  fontSize={16}
                >
                  PNG
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={handleShowLegendsSwitchChange}
                checked={showLegends}
              />
            }
            label="Show Graph Info"
          />
        </Box>
        {selectedRecordsIds.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70%"
          >
            <Typography variant="body1">
              Please filter and select Records to view data
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            height="100%"
            alignItems="center"
          >
            {recordsDetailedDataQueryResults.some(
              (result) => result.isLoading
            ) ? (
              <Loader />
            ) : (
              <RecordsGraphPlot
                recordsDetailedDataQueryResults={
                  recordsDetailedDataQueryResults || []
                }
                selectedRecordsIds={selectedRecordsIds}
                showLegends={showLegends}
                downloadImageType={downLoadImageType}
              />
            )}
          </Box>
        )}
      </MainContent>
    </>
  )
}

export default CompareRecords
