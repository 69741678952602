import React from 'react'
import { Grid, Box } from '@mui/material'

export default function EntryBox ({ children, label, alignment = 'center' }) {
  return (
    <Grid container item direction="row" alignItems={alignment} spacing={2}>
      <Grid item xs={4}>
        <Box fontSize={19} fontWeight={600} color={'#33403F'}>
          {label}
        </Box>
      </Grid>
      <Grid item xs={8}>
        {children}
      </Grid>
    </Grid>
  )
}
