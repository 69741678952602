// Create empty arrow function component

import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from 'services'
import Button from '@mui/material/Button'
import { QUERIES, SnackbarUtil } from 'helpers'
import { pdf } from '@react-pdf/renderer'
import { ValidationReportAerocom } from 'components'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { CloudDownload, PriorityHigh } from '@mui/icons-material'

const ReportStatus = ({ validation = null, ...rest }) => {
  const { exitFunction } = rest
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [generateReport, setGenerateReport] = useState(false)

  // Fetch institution data
  const { data: institutionData, isLoading: institutionIsLoading } = useQuery({
    queryKey: [QUERIES.GET_INSTITUTE, validation?.institute?.id],
    queryFn: async () => await api.institutes.get(validation?.institute?.id),
    enabled: !!validation?.institute?.id
  })

  // Fetch Line Stats
  const { data: lineStatsData, isLoading: isLoadingLineStats } = useQuery({
    queryKey: [QUERIES.GET_LINE_STATS, validation.id],
    queryFn: () => api.validations.getLines(validation.id),
    enabled: !!validation?.id
  })

  // Fetch Analyzer info
  const { data: analyzerData, isLoading: isLoadingAnalyzer } = useQuery({
    queryKey: [QUERIES.GET_ANALYZER],
    queryFn: () => api.analyzer.get(validation.institute.id),
    enabled: !!validation?.institute?.id
  })

  const createReport = useMutation({
    mutationFn: async (data) => {
      await api.reports.createValidationReport(
        data.instituteId,
        data.validationId,
        data.formData
      )
    },
    onSuccess: async (data) => {
      SnackbarUtil.success('Report successfully created', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })

      // Update validation row locally. Call exitFunction
      if (exitFunction) {
        exitFunction(validation)
      }
    },
    onError: async (error) => {
      SnackbarUtil.error('Error creating report', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    }
  })

  const createReportWorker = useCallback(async () => {
    return await pdf(
      <ValidationReportAerocom
        validation={validation}
        relationship={institutionData?.relationship}
        lineStats={lineStatsData}
        analyzer={analyzerData}
      />
    ).toBlob()
  }, [validation, institutionData, lineStatsData, analyzerData])

  const handleOnConfirm = async () => {
    setGenerateReport(true)
  }

  const handleOnCloseDialog = (event, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    setShowConfirmModal(false)
  }

  useEffect(async () => {
    if (generateReport) {
      // Create new report locally
      const newReport = await createReportWorker()

      if (!newReport) {
        // Show error message
        SnackbarUtil.error('Error creating report', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        return
      }

      const reportName = `PTS Validation Report-${
        institutionData?.relationship === 'partner_aerocom'
          ? 'aerocom'
          : validation.name
      }.pdf`
      const formData = new FormData()
      formData.append('file', newReport, reportName)
      formData.append('title', reportName)

      // Make a create report request to server
      await createReport.mutateAsync({
        instituteId: validation.institute.id,
        validationId: validation.id,
        formData
      })

      // Reset generateReport
      setGenerateReport(false)

      // Close modal
      setShowConfirmModal(false)
    }
  }, [generateReport])

  return validation?.report ? (
    <Button
      variant="contained"
      size="small"
      sx={{
        width: '140px'
      }}
      download={validation?.report?.download_url}
      href={validation?.report?.download_url}
      endIcon={<CloudDownload />}
    >
      Download
    </Button>
  ) : (
    validation?.completed_at && (
      <>
        <Button
          variant="contained"
          size={'small'}
          color={'warning'}
          onClick={() => {
            setShowConfirmModal(true)
          }}
        >
          Generate Report
        </Button>
        <Dialog
          open={showConfirmModal}
          onClose={handleOnCloseDialog}
          disableEscapeKeyDown={true}
        >
          <DialogTitle
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <PriorityHigh color="error" />
            {'Confirm Report Generation'}
            <PriorityHigh color="error" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Validation report for ${validation?.name} will now be generated with current lines and analyzer's settings. Are you sure you want to generate report?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size={'small'}
              onClick={handleOnConfirm}
              disabled={generateReport}
              endIcon={
                generateReport && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: 'white'
                    }}
                  />
                )
              }
            >
              {'Confirm'}
            </Button>
            <Button
              variant="contained"
              size={'small'}
              color={'error'}
              disabled={generateReport}
              onClick={() => {
                setShowConfirmModal(false)
              }}
            >
              {'Cancel'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default ReportStatus
