import React from 'react'
import { Box } from '@mui/material'
import Plotly from 'plotly.js/dist/plotly'
import { VibrationPlot } from 'components'

export default function MultiVibrationPlot ({ recordData, accelerationData, cumulativeData, timeAxis }) {
  function TriggerHover (coupledPlotTimes, coupledPlotId, plotTimes, plotTimeIndex) {
    const timeIndex = coupledPlotTimes.findIndex((time) => time > plotTimes[plotTimeIndex])
    return (
      timeIndex && Plotly.Fx.hover(coupledPlotId, [{ curveNumber: 0, pointNumber: timeIndex }])
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      { cumulativeData &&
        <Box>
          <VibrationPlot
            plotId='cumulative-accel'
            acceleration={cumulativeData.cumulative_metric}
            time={cumulativeData.time}
            startdate={recordData.date}
            duration={recordData.duration}
            timeFormat={timeAxis}
            showXaxis={false}
            margin={{ l: 75, r: 75, b: 0, t: 30, pad: 4 }}
            height='175px'
            yRange={[]}
            yaxisLabel = 'VitalMetric'
            plotUnit=''
            color = '#FF964A'
            onHover = {(data) => {
              TriggerHover(accelerationData.acceleration_time, 'raw-accel', cumulativeData.time, data.points[0].pointIndex)
            }}
          />
        </Box>
     }
      <Box>
        <VibrationPlot
          plotId='raw-accel'
          acceleration={accelerationData.acceleration}
          time={accelerationData.acceleration_time}
          startdate={recordData.date}
          duration={recordData.duration}
          timeFormat={timeAxis}
          height={cumulativeData && '250px'}
          onHover = {(data) => {
            TriggerHover(cumulativeData.time, 'cumulative-accel', accelerationData.acceleration_time, data.points[0].pointIndex)
          }}
        />
      </Box>
    </Box>
  )
}
