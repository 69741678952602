import { Box, Typography } from '@mui/material'
import { DropDownSelect } from 'components'
import {
  axisFont,
  colors,
  getCumulativeMetricStatistics,
  getValidationMetricStatistics
} from 'helpers'
import { useState } from 'react'
import Plot from 'react-plotly.js'
import { useHistory } from 'react-router-dom'

export default function Grouped({
  data,
  field = 'rejection_rates',
  metric = 'auc',
  keys = [],
  keyLabels = {},
  yRange = [0, 100],
  height = 500,
  yLabel = 'Rejection Probability (%)',
  unit = '',
  lineTraces = [],
  rejectionAnnotations = false
}) {
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')
  const history = useHistory()
  const xEnd = 2.5
  const annotationFont = {
    family:
      'neue-haas-grotesk-display, Helvetica Neue, Segoe UI, Roboto, sans-serif',
    size: 18,
    color: '#33403F'
  }
  const lines = lineTraces.map((value) => {
    return {
      type: 'line',
      x0: -1,
      y0: value,
      x1: xEnd,
      y1: value,
      line: {
        color: 'gray',
        width: 1,
        dash: 'dashdot'
      }
    }
  })
  const traces = data.map((line, index) => {
    const statistics =
      line[metric] ||
      getValidationMetricStatistics(line, metric) ||
      getCumulativeMetricStatistics(line, metric)
    return {
      type: 'bar',
      x: keys.map((key) => keyLabels[key]),
      y: keys.map((key) => {
        return statistics && statistics[field] !== null
          ? statistics[field][key]
          : null
      }),
      url: keys.map((key) => `/line/${line.line_id || line.id}`),
      text: keys.map(
        (key) =>
          `${keyLabels[key]}: ${
            statistics && statistics[field] !== null
              ? statistics[field][key]
              : null
          }${unit}`
      ),
      hoverinfo: 'text',
      name: line.line_name || line.name,
      marker: {
        color: data.length === 1 ? colors : colors[index]
      }
    }
  })

  const layout = {
    autosize: true,
    hovermode: 'closest',
    hoverlabel: { bgcolor: '#FFF' },
    legend: {
      x: 0,
      y: -0.3,
      yanchor: 'top',
      xanchor: 'left',
      orientation: 'h',
      font: annotationFont,
      tracegroupgap: 20
    },
    margin: {
      l: 75,
      r: 75,
      b: 100,
      t: 50,
      pad: 4
    },
    xaxis: {
      range: [-0.5, xEnd],
      title:
        data.length === 1
          ? {
              text: data[0].name,
              font: axisFont
            }
          : {}
    },
    yaxis: {
      title: {
        text: yLabel,
        font: axisFont
      },
      range: yRange
    },
    shapes: lines,
    annotations: rejectionAnnotations && [
      {
        x: xEnd,
        y: 15,
        xref: 'x',
        yref: 'y',
        text: ' 15% ',
        showarrow: false,
        xanchor: 'left',
        align: 'left',
        clicktoshow: 'onoff',
        font: annotationFont
      },
      {
        x: xEnd,
        y: 3,
        xref: 'x',
        yref: 'y',
        text: ' 3% ',
        align: 'right',
        showarrow: false,
        xanchor: 'left',

        font: annotationFont
      }
    ]
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'end',
        paddingTop: '10px'
      }}
    >
      <DropDownSelect
        labelId="image-format-select"
        label="Image Format"
        value={downloadImageFormat}
        onValueChange={(newValue) => {
          setDownloadImageFormat(newValue)
        }}
        items={[
          { content: <Typography variant="h7">PNG</Typography>, value: 'png' },
          { content: <Typography variant="h7">SVG</Typography>, value: 'svg' }
        ]}
      />

      <Plot
        data={traces}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: height }}
        config={{
          responsive: true,
          displaylogo: false,
          toImageButtonOptions: { format: downloadImageFormat }
        }}
        onClick={(event) => {
          const url = event.points[0].data.url[event.points[0].pointIndex]
          return history.push({ pathname: url, state: { includeLine: true } })
        }}
      />
    </Box>
  )
}
