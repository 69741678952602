import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QUERIES } from '../helpers'
import { api } from '../services'

const useGetAllUsers = ({ onSuccess = null, onError = null, params = {} }) => {
  return useQuery({
    queryKey: [QUERIES.GET_ALL_USERS, { ...params }],
    queryFn: async () => {
      const { page, size, filters } = params
      return await api.users.listAll({
        ...filters,
        page,
        size
      })
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useGetAllInstituteUsers = ({
  onSuccess = null,
  onError = null,
  params = {}
}) => {
  return useQuery({
    queryKey: [QUERIES.GET_ALL_USERS, { ...params }],
    queryFn: async () => {
      const { id, ...rest } = params
      return await api.users.list(id, rest)
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useGetUser = ({
  onSuccess = null,
  onError = null,
  email = null,
  id = null
}) => {
  return useQuery({
    queryKey: [
      QUERIES.GET_USER,
      {
        identifier: id || email
      }
    ],
    queryFn: async () => {
      if (id) {
        return await api.users.getById(id)
      } else if (email) {
        return await api.users.get(email)
      } else {
        return null
      }
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useUpdateUser = ({ onSuccess = null, onError = null }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [QUERIES.UPDATE_USER],
    mutationFn: async (data) => {
      const { email, ...rest } = data
      return await api.users.update(email, rest)
    },
    onSuccess: async (data) => {
      onSuccess && onSuccess(data)
      await queryClient.invalidateQueries(QUERIES.GET_ALL_USERS)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useCreateUser = ({ onSuccess = null, onError = null }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [QUERIES.CREATE_USER],
    mutationFn: async (data) => {
      return await api.users.create(data.default_institute, data)
    },
    onSuccess: async (data) => {
      onSuccess && onSuccess(data)
      await queryClient.invalidateQueries(QUERIES.GET_ALL_USERS)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useResendInvite = ({ onSuccess = null, onError = null }) => {
  return useMutation({
    mutationKey: [QUERIES.RESEND_INVITE],
    mutationFn: async (data) => {
      return await api.users.resendInvite(data)
    },
    onSuccess: async (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useRevokeAccess = ({ onSuccess = null, onError = null }) => {
  return useMutation({
    mutationKey: [QUERIES.REVOKE_ACCESS],
    mutationFn: async (data) => {
      const { instituteId, email } = data
      return await api.users.revoke(instituteId, email)
    },
    onSuccess: async (data) => {
      onSuccess && onSuccess(data)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

const useDeleteUser = ({ onSuccess = null, onError = null }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [QUERIES.DELETE_USER],
    mutationFn: async (data) => {
      return await api.users.delete(data)
    },
    onSuccess: async (data) => {
      onSuccess && onSuccess(data)
      await queryClient.invalidateQueries(QUERIES.GET_ALL_USERS)
    },
    onError: (error) => {
      onError && onError(error)
    }
  })
}

export {
  useCreateUser,
  useDeleteUser,
  useGetAllInstituteUsers,
  useGetAllUsers,
  useGetUser,
  useResendInvite,
  useRevokeAccess,
  useUpdateUser
}
