import { Box } from '@mui/material'
import { BarPlot, BoxPlot, Loader, RejectionInfo } from 'components'
import { getCumulativeMetricStatistics } from 'helpers'
import { useGetInstituteLines } from 'hooks/useLineDataManager'
import { useMetricsContext } from 'services'

const GraphView = (props) => {
  const { metric } = props

  const { data: lineData, isLoading: isLineDataIsLoading } =
    useGetInstituteLines({
      params: {
        page: 0,
        size: 9999
      }
    })
  const { metricType } = useMetricsContext()

  return (
    <Box paddingY={2}>
      {!isLineDataIsLoading && lineData ? (
        <>
          {(metric === 'vitalMetricView' || metric === 'temperatureView') && (
            <BoxPlot
              data={
                lineData &&
                lineData.items.filter((line, index) =>
                  getCumulativeMetricStatistics(
                    line,
                    metric === 'temperatureView' ? 'temperature' : metricType
                  )
                )
              }
              variant={
                metric === 'temperatureView' ? 'temperature' : metricType
              }
            />
          )}
          {metric === 'rejectionView' && (
            <>
              <Box display="flex" justifyContent="flex-end">
                <RejectionInfo />
              </Box>
              <BarPlot
                data={
                  lineData &&
                  lineData.items.filter((line, index) => {
                    if (line.validation !== null) {
                      return line.validation
                    } else if (
                      line.line_statistics &&
                      line.line_statistics.record_count > 0
                    ) {
                      return line.line_statistics
                    } else {
                      return false
                    }
                  })
                }
                field={'rejection_rates'}
                metric={metricType}
                variant={'grouped'}
                yLabel={'Rejection Probability (%)'}
                unit={'%'}
                keyLabels={{ ldh: 'LDH', asat: 'AST', potassium: 'K' }}
                keys={['ldh', 'asat', 'potassium']}
                lineTraces={[3, 15]}
                rejectionAnnotations={true}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  )
}

export default GraphView
