import React from 'react'
import Typography from '@mui/material/Typography'
import { parseISO, format } from 'date-fns'
import { StyledLink } from 'components'

export function getCumulativeStatistics (line) {
  if (line.line_statistics && line.line_statistics.record_count > 0) {
    return line.line_statistics
  }
  return null
}

export function getCumulativeMetricStatistics (line, metric) {
  const stats = getCumulativeStatistics(line)
  if (stats) {
    return stats[metric]
  }
  return null
}

export function getValidationStatistics (line) {
  if (line) {
    if (line.validation && line.validation.record_count > 2) {
      return line.validation
    } else if (line.validation_statistics && line.validation_statistics.record_count > 2) {
      return line.validation_statistics
    }
  }
  return null
}

export function getValidationMetricStatistics (line, metric) {
  const stats = getValidationStatistics(line)
  if (stats) {
    return stats[metric]
  }
  return null
}

export function ValidationDate ({ line, variant = null }) {
  if (line.validation) {
    return (
      <Typography variant='subtitle1' style={{ color: variant === 'tooltip' ? 'white' : 'inherit' }}>
        {'  Last Validated on '}
        { variant === 'tooltip'
          ? format(parseISO(line.validation.validation_start_date), 'd MMM yyyy')
          : <StyledLink to={{ pathname: `/validation/${line.validation.validation_id}`, state: { name: line.name } }}>
            {format(parseISO(line.validation.validation_start_date), 'd MMM yyyy')}
          </StyledLink>
        }
      </Typography>
    )
  }
  return null
}
