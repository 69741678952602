import jwt from 'jwt-decode'
import { BehaviorSubject } from 'rxjs'
import { apiConfig } from 'services'

const axios = require('axios')

const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
})

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
)

export const authenticationService = {
  login,
  logout,
  refresh,
  passwordReset,
  passwordResetConfirm,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value
  },
  get isGuest() {
    return (
      currentUserSubject.value &&
      currentUserSubject.value.roles.includes('guest')
    )
  },
  get isAdmin() {
    return (
      currentUserSubject.value &&
      currentUserSubject.value.roles.includes('admin')
    )
  },
  get isOwner() {
    return (
      currentUserSubject.value &&
      currentUserSubject.value.roles.includes('owner')
    )
  },
  get isStaff() {
    return (
      currentUserSubject.value &&
      currentUserSubject.value.roles.includes('staff')
    )
  },
  get multiInstitute() {
    if (!currentUserSubject?.value?.institutes) {
      logout()
    }
    return (
      currentUserSubject.value &&
      (currentUserSubject.value.institutes.length > 1 ||
        currentUserSubject.value.roles.includes('admin') ||
        currentUserSubject.value.roles.includes('owner') ||
        currentUserSubject.value.roles.includes('staff'))
    )
  }
}

function login(username, password) {
  logout()
  const data = new FormData()
  data.append('username', username)
  data.append('password', password)
  return axios_instance.post('/v2/auth/token', data).then(
    (data) => {
      const user = data.data
      const user_token = jwt(user.access_token)
      const current = JSON.parse(localStorage.getItem('currentApi')) || null
      user_token.access = user.access_token
      user_token.refresh = user.refresh_token
      localStorage.setItem('currentUser', JSON.stringify(user_token))

      const org =
        current?.institute && user_token.institutes.includes(current.institute)
          ? current.institute
          : user_token.institutes[0]
      apiConfig.setApiConfig(org)
      currentUserSubject.next(user_token)
      return user_token
    },
    (error) => Promise.reject(error.response)
  )
}

function refresh(failedRequest = null) {
  const refresh_token =
    currentUserSubject.value && currentUserSubject.value.refresh
  return axios_instance
    .post('/v2/auth/refresh', {
      refresh: refresh_token
    })
    .then(
      (data) => {
        const user = data.data
        const user_token = jwt(user.access_token)
        user_token.access = user.access_token
        user_token.refresh = refresh_token
        localStorage.setItem('currentUser', JSON.stringify(user_token))
        currentUserSubject.next(user_token)
        if (failedRequest)
          failedRequest.response.config.headers.Authorization =
            'Bearer ' + user.access_token
        return Promise.resolve()
      },
      (error) => {
        logout()
        return Promise.reject(error.response)
      }
    )
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser')
  localStorage.removeItem('featureFlagOverrides')
  currentUserSubject.next(null)
  apiConfig.setApiConfig(null)
}

function passwordReset(data) {
  return axios_instance.post('/v2/auth/request-password-reset', data)
}

function passwordResetConfirm(token, data) {
  return axios_instance.post('/v2/auth/reset-password', data)
}
