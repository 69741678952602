import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { FlagSwitch } from 'components'
import { useFeaturesContext } from 'services'
import assignIn from 'lodash/assignIn'

export default function FeatureFlagOverrides () {
  const { flags, overrides, setOverrides } = useFeaturesContext()

  const handleFlagChange = (event) => {
    const newOverrides = assignIn({}, overrides, { [event.target.name]: event.target.checked })
    setOverrides(newOverrides)
    localStorage.setItem('featureFlagOverrides', JSON.stringify(newOverrides))
  }

  const clearOverrides = (event) => {
    setOverrides()
    localStorage.removeItem('featureFlagOverrides')
  }

  useEffect(() => {
    if (!localStorage.getItem('featureFlagOverrides')) {
      setOverrides(flags)
    }
  }, [flags, overrides, setOverrides])

  return (
    <Box flexDirection="column" display="flex" justifyContent="space-between">
      <FlagSwitch
        value={overrides?.clinicalData}
        title={'Clinical Data'}
        name={'clinicalData'}
        description={'Gives users access to create, view, edit, and delete their own clinical data.'}
        testid={'clinical-data-test'}
        handleFlagChange={handleFlagChange}
        />
      <FlagSwitch
        value={overrides?.monitoring}
        title={'Monitoring'}
        name={'monitoring'}
        description={'Enables Monitoring in the lines detail view.'}
        testid={'monitoring-test'}
        handleFlagChange={handleFlagChange}
        />
      <FlagSwitch
        value={overrides?.troubleshooting}
        title={'Troubleshooting'}
        name={'troubleshooting'}
        description={'Enables Troubleshooting features, and Performance metrics on the line details view.'}
        testid={'troubleshooting-test'}
        handleFlagChange={handleFlagChange}
        />
      <FlagSwitch
        value={overrides?.validation}
        title={'Validation'}
        name={'validation'}
        description={'Enables the Validation views in the UI allowing the insitute to create validations and assign them to lines and records.'}
        testid={'validation-test'}
        handleFlagChange={handleFlagChange}
        />
      <FlagSwitch
        value={overrides?.hemolysisTracking}
        title={'Rejection Insights'}
        name={'hemolysisTracking'}
        description={'Enables the Rejection Insights tab at the top giving access to upload CSV hemolysis measurments and explore the data.'}
        testid={'hemolysis-test'}
        handleFlagChange={handleFlagChange}
        />
      <FlagSwitch
        value={overrides?.betaMetrics}
        title={'Beta Metrics'}
        name={'betaMetrics'}
        description={'Enables the metric selector at the top to enable uses to select among AUC 225, Power, and Kinetic Energy.'}
        testid={'beta-metrics-test'}
        handleFlagChange={handleFlagChange}
        />
      <FlagSwitch
        value={overrides?.alpha}
        title={'Alpha Testing (Internal Only)'}
        name={'alpha'}
        description={'Allow prototyping features to be shown. These features are not ready to be shown to costumers.'}
        testid={'alpha-test'}
        handleFlagChange={handleFlagChange}
        divider={false}
        />
      <Box mt={2} flexDirection="row" display="flex" justifyContent="flex-end">
        <Button size="small" color="secondary" onClick={clearOverrides}>
          Clear Overrides
        </Button>
      </Box>
    </Box>
  )
}
