export const instituteRelations = [
  { value: null, name: 'No Relationship' },
  { value: 'direct', name: 'Direct Customer' },
  { value: 'partner_aerocom', name: 'Partner: Aerocom' },
  { value: 'partner_labquality', name: 'Partner: Labquality' },
  { value: 'internal', name: 'Motryx Internal' },
  { value: 'poc', name: 'POC / Demo' },
  { value: 'archive', name: 'Archived' }
]

export const instituteSubscriptions = [
  { value: null, name: 'No Subscription' },
  { value: 'one_time', name: 'One Time' },
  { value: 'annual', name: 'Annual' }
]
