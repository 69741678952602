import React from 'react'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

export default function NumberWidget ({ num, range = false, label, size = 'big', numAlign = 'flex-start', rejectionColor = '#333A40', ...rest }) {
  const useStyles = makeStyles((theme) => ({
    big: {
      fontSize: '36px',
      fontWeight: 600,
      color: rejectionColor,
      display: 'flex',
      alignItems: 'flex-start',
      borderRadius: 45,
      paddingBottom: 1,
      width: '100%'
    },
    small: {
      fontSize: '18px',
      fontWeight: 600,
      color: rejectionColor,
      display: 'flex',
      alignItems: 'flex-start',
      borderRadius: 35,
      width: '100%'
    },
    label: {
      fontSize: size === 'small' ? '12px' : '18px',
      fontWeight: 600,
      color: '#333A40',
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%'
    }
  }))
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" minWidth={70} {...rest}>
      <Box className={size === 'small' ? classes.small : classes.big} justifyContent={numAlign}>
        {num}
      </Box>
      <Box className={classes.label}>
        {label}
      </Box>
    </Box>
  )
}
