import styled from '@emotion/styled'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import withTheme from '@mui/styles/withTheme'

const getColor = (props) => {
  if (props.isDragAccept) {
    return props.theme.palette.primary.main
  } else if (props.isDragReject) {
    return props.theme.palette.red.main
  } else if (props.isDragActive) {
    return '#0B8DDE'
  }
  return '#eeeeee'
}

const Container = withTheme(styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`)

export default function StyledDropzone({
  dropzone,
  onChange,
  file,
  clearFiles,
  text = 'Drag and drop a finalized report here.',
  emText = '(Only *.pdf or *.doc will be accepted)'
}) {
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getInputProps,
    getRootProps
  } = dropzone

  const handleClear = () => {
    clearFiles()
  }

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      {file ? (
        <Box
          p={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" fontWeight={600}>
            {'File: '}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexGrow={1}
          >
            <Box>{file.name}</Box>
            <IconButton color="error" onClick={handleClear}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Container
          style={{
            borderRadius: '10px'
          }}
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        >
          <input
            {...getInputProps()}
            onChange={onChange}
            data-testid="dropzone-test"
          />
          <p>{text}</p>
          <em>{emText}</em>
        </Container>
      )}
    </div>
  )
}
