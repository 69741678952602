import React from 'react'
import { useQuery } from 'react-query'
import { useParams, Route, Switch } from 'react-router-dom'
import SettingsView from './SettingsView'
import MainView from './MainView'
import { api } from 'services'
import { InstituteLock } from 'helpers'

export default function LineDetail () {
  const { id } = useParams()
  const { data: lineData, refetch: refreshLineData } = useQuery(['line-data', id], () => api.lines.get(id))

  return (

    <InstituteLock id={lineData && lineData.institute.id} redirectUrl="/lines">
      <Switch>
        {lineData && (
          <Route
            path="/line/:id/settings"
            children={<SettingsView lineData={lineData} refreshLineData={refreshLineData} />}
          />
        )}
        {lineData && (
          <Route
            path="/line/:id/:view?"
            children={<MainView lineData={lineData} refreshLineData={refreshLineData} />}
          />
        )}
      </Switch>
    </InstituteLock>
  )
}
