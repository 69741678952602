import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import OutOfRangeIcon from '@mui/icons-material/ReportProblemOutlined'
import Alarm from '@mui/icons-material/Alarm'
import { Loader, StyledLink, Validated } from 'components'
import { api, apiConfig, Flag, useMetricsContext } from 'services'
import { format, parseJSON } from 'date-fns'
import { useQuery } from 'react-query'

export default function UploadActivity (props) {
  const [outOfRangeData, setOutOfRangeData] = useState()
  const [tableData, setTableData] = useState()

  const [outOfRangeOnly, setOutOfRangeOnly] = useState(
    localStorage.getItem('outOfRangeOnly') === null ? false : localStorage.getItem('outOfRangeOnly') === 'true'
  )

  const { metricType, metricLabel } = useMetricsContext()

  const handleOutOfRangeOnly = (event) => {
    setOutOfRangeOnly(event.target.checked)
    localStorage.setItem('outOfRangeOnly', event.target.checked)
    if (event.target.checked) {
      setTableData(outOfRangeData)
    } else {
      setTableData(recordData)
    }
  }

  const { data: recordData, isLoading } = useQuery(['recent-records', apiConfig.currentApiInstitute], () => {
    return api.records.list({
      size: 20,
      ordering: '-date',
      exclude: false,
      is_processed: true
    }).then(data => data.items)
  }, {
    refetchInterval: 3000
  })

  useEffect(() => {
    if (recordData) {
      const filtered_data = recordData.filter((record) => record.out_of_range.value === true)
      setOutOfRangeData(filtered_data)
      setTableData(outOfRangeOnly ? filtered_data : recordData)
    }
  }, [recordData, outOfRangeOnly])

  return (
    <Box height={'100%'}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h2">{'Latest Records'}</Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={outOfRangeOnly}
                onChange={handleOutOfRangeOnly}
                id="outOfRangeOnly"
                name="outOfRangeOnly"
              />
            }
            label="Out of Range Only"
          />
        </Box>
      </Box>
      { isLoading
        ? <Loader title="" message="Loading latest records..." />
        : recordData && recordData.length > 0
          ? (
          <TableContainer style={{ maxHeight: '30vh' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{'Date'}</TableCell>
                  <TableCell>{'Line'}</TableCell>
                  <TableCell align="right">{metricLabel}</TableCell>
                  <TableCell align="right">{'Range'}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                    tableData.map((record, index) => {
                      const recordDate = format(parseJSON(record.date), 'd MMM yyyy HH:mm')
                      return (
                        <TableRow key={`record-row-${record.id}`}>
                          <TableCell>
                            <Flag name="troubleshooting" alternate={recordDate}>
                              <StyledLink
                                to={{
                                  pathname: `/record/${record.id}`,
                                  state: { name: 'Dashboard' }
                                }}
                              >
                                {recordDate}
                              </StyledLink>
                            </Flag>
                          </TableCell>
                          <TableCell>
                            {record.line && (
                              <StyledLink
                                to={{
                                  pathname: `/line/${record.line.id}/`,
                                  state: {
                                    name: 'Dashboard',
                                    referrer: {
                                      path: `${window.location.pathname}${window.location.search}`
                                    }
                                  }
                                }}
                              >
                                {record.line && record.line.name}
                              </StyledLink>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                              {record.out_of_range.value && (
                                <Tooltip title="Record out of range for the line.">
                                  <Box style={{ verticalAlign: 'middle' }} pr={1}>
                                    <OutOfRangeIcon fontSize="small" color="error" />
                                  </Box>
                                </Tooltip>
                              )}
                              <Box
                                fontWeight={600}
                                justifyContent="flex-end"
                                width={40}
                                color={record.out_of_range.value ? '#CA7A85' : 'inherit'}
                              >
                                {record.metrics[metricType]}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Validated
                              childrenSpacing={1}
                              justifyContent="flex-end"
                              validated={record.out_of_range.validated}
                              iconSize="small"
                            >
                              <Box width={70}>
                                {record.out_of_range.range
                                  ? `${record.out_of_range.range.lower} - ${record.out_of_range.range.upper}`
                                  : '-'}
                              </Box>
                            </Validated>
                          </TableCell>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </TableContainer>
            )
          : (
          <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center" height={'30vh'}>
            <Box p={1}>
              <Alarm color="primary" fontSize="large" />
            </Box>
            <Box p={1} alignItems="center">
              <Typography variant="body1"> {'No records in the last 14 days'}</Typography>
            </Box>
          </Box>
            )
      }
      <Box mt={3}>
        <Link to={'/records'}>
          <Button color="primary" variant="contained">
            View All Records
          </Button>
        </Link>
      </Box>
    </Box>
  )
}
