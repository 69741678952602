import PropTypes from 'prop-types'

import { Button, Typography } from '@mui/material'
import { openDocsPage } from 'helpers'

const BlurWrapper = (props) => {
  const { doBlur, children, showCTAComponent, blurLevel } = props
  return (
    <>
      {doBlur ? (
        <div
          style={{
            position: 'relative'
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
              minWidth: '100%',
              zIndex: 1
            }}
          >
            {showCTAComponent && doBlur && (
              <>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'center'
                  }}
                >
                  This information is part of an upgraded package, please
                  contact us for more details.
                </Typography>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    openDocsPage('/support/contact')
                  }}
                >
                  Contact Us
                </Button>
              </>
            )}
          </div>
          <div
            style={{
              filter: doBlur ? 'blur(' + blurLevel + 'px)' : 'none'
            }}
          >
            {children}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

BlurWrapper.defaultProps = {
  showCTAComponent: true,
  blurLevel: 20
}

BlurWrapper.propTypes = {
  doBlur: PropTypes.bool,
  children: PropTypes.node,
  showCTAComponent: PropTypes.bool,
  blurLevel: PropTypes.number
}

export default BlurWrapper
