import styled from '@emotion/styled'
import { Close } from '@mui/icons-material'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import withTheme from '@mui/styles/withTheme'

const getColor = (props) => {
  if (props.isDragAccept) {
    return props.theme.palette.primary.main
  } else if (props.isDragReject) {
    return props.theme.palette.red.main
  } else if (props.isDragActive) {
    return '#0B8DDE'
  }
  return '#eeeeee'
}

const Container = withTheme(styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`)
const MultipleFilesStyledDropzone = ({
  dropzone,
  onChange,
  files,
  clearFile,
  fileUploadDisabled = false,
  text = 'Drag and drop some files here, or click to select files',
  emText = '(Only *.pdf or *.doc will be accepted)'
}) => {
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getInputProps,
    getRootProps
  } = dropzone

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={1}>
      <Container
        style={{
          borderRadius: '10px'
        }}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input
          {...getInputProps()}
          onChange={onChange}
          data-testid="dropzone-test"
          disabled={fileUploadDisabled}
        />
        <p>{text}</p>
        <em>{emText}</em>
      </Container>
      {files.length > 0 && (
        <Grid container spacing={2}>
          {files.map((file, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Tooltip title={file.name}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flexGrow={1}
                    pl={1}
                    bgcolor="#eeeeee"
                    borderRadius="10px"
                  >
                    <Typography
                      variant="body1"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {file.name}
                    </Typography>
                    <IconButton color="error" onClick={() => clearFile(file)}>
                      <Close />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

export default MultipleFilesStyledDropzone
