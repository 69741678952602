import { authenticationService } from 'services'

export function authHeader () {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue

  const header = {}
  if (currentUser && currentUser.access) {
    header.Authorization = `Bearer ${currentUser.access}`
  }
  return header
}
