import { Box, Typography } from '@mui/material'
import { DropDownSelect } from 'components'
import { addMilliseconds, parseJSON } from 'date-fns'
import { axisFont } from 'helpers'
import { useState } from 'react'
import Plot from 'react-plotly.js'

const temperature_bounds = {
  ambient: {
    upper_controlled_limit: 25,
    lower_controlled_limit: 20,
    upper_excursion_limit: 30,
    lower_excursion_limit: 15
  },
  refrigerated: {
    upper_controlled_limit: 8,
    lower_controlled_limit: 2,
    upper_excursion_limit: 15,
    lower_excursion_limit: 0
  },
  frozen: {
    upper_controlled_limit: -10,
    lower_controlled_limit: -25,
    upper_excursion_limit: -10,
    lower_excursion_limit: -25
  }
}

export default function TemperaturePlot({
  temperature,
  time,
  duration,
  startdate,
  mean,
  min,
  max,
  temperaturetype,
  timeFormat = 'seconds',
  showAnnotation = true
}) {
  let localized_time = time
  let xrange = [localized_time.at(0), localized_time.at(-1) * 1.2]
  let marker_text = localized_time.map(
    (time, index) =>
      `${temperature[index].toFixed(1)} °C at ${time.toFixed(4)} seconds`
  )

  const greater_than_an_hour = duration > 3600
  if (greater_than_an_hour || timeFormat === 'timestamp') {
    const time_padding = xrange[1] - localized_time.at(-1)
    localized_time = time.map(function (seconds) {
      const new_time = addMilliseconds(parseJSON(startdate), seconds * 1000)
      return new_time
    })

    xrange = [
      localized_time.at(0),
      addMilliseconds(localized_time.at(-1), time_padding * 1000)
    ]
    marker_text = localized_time.map(
      (time, index) => `${temperature[index].toFixed(1)} °C at ${time}`
    )
  }

  const data = [
    {
      x: localized_time,
      y: temperature,
      text: marker_text,
      hoverinfo: 'text',
      mode: 'lines',
      name: 'Temperature'
    },
    {
      visible: showAnnotation,
      x: xrange,
      y: [mean, mean],
      mode: 'lines',
      line: {
        dash: 'dashdot',
        color: 'gray',
        width: 2
      },
      name: 'Mean Kinetic Temperature: ' + Number(mean).toFixed(1) + ' °C'
    }
  ]

  let temperature_yrange = [min - 5, max + 5]

  let temperature_shapes = []
  if (temperaturetype && temperaturetype !== '') {
    temperaturetype = temperaturetype.toLowerCase()
    temperature_yrange = [
      Math.min(
        temperature_bounds[temperaturetype].lower_excursion_limit,
        temperature_yrange[0]
      ) - 5,
      Math.max(
        temperature_bounds[temperaturetype].upper_excursion_limit,
        temperature_yrange[1]
      ) + 5
    ]
    temperature_shapes = [
      {
        type: 'rect',
        yref: 'y',
        x0: localized_time[0],
        y0: temperature_bounds[temperaturetype].upper_controlled_limit,
        x1: localized_time.slice(-1)[0],
        y1: temperature_bounds[temperaturetype].upper_excursion_limit,
        fillcolor: 'rgba(255, 151, 74, 0.15)',
        layer: 'below',
        line: {
          width: 0
        }
      },
      {
        type: 'rect',
        yref: 'y',
        x0: localized_time[0],
        y0: temperature_bounds[temperaturetype].upper_excursion_limit,
        x1: localized_time.slice(-1)[0],
        y1: temperature_yrange[1],
        fillcolor: 'rgba(244, 53, 53, 0.15)',
        layer: 'below',
        line: {
          width: 0
        }
      },
      {
        type: 'rect',
        yref: 'y',
        x0: localized_time[0],
        y0: temperature_bounds[temperaturetype].lower_controlled_limit,
        x1: localized_time.slice(-1)[0],
        y1: temperature_bounds[temperaturetype].lower_excursion_limit,
        fillcolor: 'rgba(255, 151, 74, 0.15)',
        layer: 'below',
        line: {
          width: 0
        }
      },
      {
        type: 'rect',
        yref: 'y',
        x0: localized_time[0],
        y0: temperature_bounds[temperaturetype].lower_excursion_limit,
        x1: localized_time.slice(-1)[0],
        y1: temperature_yrange[0],
        fillcolor: 'rgba(244, 53, 53, 0.15)',
        layer: 'below',
        line: {
          width: 0
        }
      }
    ]
  }

  const layout = {
    autosize: true,
    hovermode: 'closest',
    showlegend: false,
    shapes: temperature_shapes,
    margin: {
      l: 75,
      r: showAnnotation ? 150 : 75,
      b: 75,
      t: 30,
      pad: 4
    },
    xaxis: {
      range: xrange,
      zeroline: false,
      title: {
        text:
          greater_than_an_hour || timeFormat === 'timestamp'
            ? "<b style='font-weight:500 !important'>Time (h:m:s)</b>"
            : "<b style='font-weight:500 !important'>Time (s)</b>",
        font: axisFont
      }
    },
    yaxis: {
      title: {
        text: "<b style='font-weight:500 !important'>Temperature (°C)</b>",
        font: axisFont
      },
      range: temperature_yrange
    },
    annotations: [
      {
        visible: showAnnotation,
        x: xrange[1],
        y: mean,
        xref: 'x',
        yref: 'y',
        text: `<b style='font-weight:600 !important'>Mean: ${
          mean || '-'
        } °C</b>`,
        showarrow: false,
        xanchor: 'left',
        xshift: 10,
        font: axisFont
      }
    ]
  }
  const [downloadImageFormat, setDownloadImageFormat] = useState('png')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'end',
        paddingTop: '10px'
      }}
    >
      <DropDownSelect
        labelId="image-format-select"
        label="Image Format"
        value={downloadImageFormat}
        onValueChange={(newValue) => {
          setDownloadImageFormat(newValue)
        }}
        items={[
          { content: <Typography variant="h7">PNG</Typography>, value: 'png' },
          { content: <Typography variant="h7">SVG</Typography>, value: 'svg' }
        ]}
      />
      <Plot
        data={data}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: '400px' }}
        config={{
          responsive: true,
          displaylogo: false,
          toImageButtonOptions: { format: downloadImageFormat }
        }}
      />
    </Box>
  )
}
