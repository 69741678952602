import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import has from 'lodash/has'
import { EntryBox } from 'components'
import { useQuery } from 'react-query'
import { api, authenticationService } from 'services'

export default function AccountForm ({ exitFunction }) {
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, formState: { errors }, watch, setError } = useForm()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const email = authenticationService.currentUserValue.sub
  const { data: userData } = useQuery(['user-account', email], () => api.users.get(email))

  useEffect(() => {
    if (userData) {
      setFirstName(userData.first_name)
      setValue('first_name', userData.first_name)
      setLastName(userData.last_name)
      setValue('last_name', userData.last_name)
    }
  }, [userData])

  // Form Submission Handling
  const onSubmit = (data) => {
    delete data.confirm_new_password
    api.users.update(email, data)
      .then(function (response) {
        enqueueSnackbar('Your changes have been saved.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        exitFunction()
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setError('current_password', {
            type: 'manual',
            message: error.response.data.detail
          })

          enqueueSnackbar(error.response.data.detail, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      })
  }
  return (
    <form id="change_password_form" data-testid="account-form-test" onSubmit={handleSubmit(onSubmit)}>
      <Box display='flex' flexDirection="column" pt={2}>
        <Box pb={2}>
          <EntryBox label='First Name'>
            <TextField
              {...register('first_name', {
                required: 'A first name is required.'
              })}
              name="first_name"
              id="first_name"
              variant="outlined"
              value={firstName}
              onChange={ (event) => {
                setFirstName(event.target.value)
                setValue('first_name', event.target.value)
              }}
              inputProps={{ 'data-testid': 'first-name-test' }}
              error={errors.hasOwnProperty('first_name')}
              helperText={errors.first_name && errors.first_name.message}
              fullWidth
            />
          </EntryBox>
        </Box>
        <Box pb={2}>
          <EntryBox label='Last Name'>
            <TextField
              {...register('last_name', {
                required: 'A last name is required.'
              })}
              name="last_name"
              id="last_name"
              variant="outlined"
              value={lastName}
              onChange={ (event) => {
                setLastName(event.target.value)
                setValue('last_name', event.target.value)
              }}
              inputProps={{ 'data-testid': 'last-name-test' }}
              error={errors.hasOwnProperty('first_name')}
              helperText={errors.last_name && errors.last_name.message}
              fullWidth
            />
          </EntryBox>
        </Box>
        <Box my={2} style={{ borderBottom: '1px solid #b6b6b6' }}>
          <Typography variant='h6'>{'Change Password'}</Typography>
        </Box>
        <Box pb={2}>
          <EntryBox label="Current Password">
            <TextField
              key="_current_password"
              name="current_password"
              id="current_password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              inputProps={{ 'data-testid': 'current-password-test' }}
              {...register('current_password', { required: watch('new_password') && 'Enter your current password to make changes.' })}
              value={currentPassword}
              onChange={ (event) => {
                setCurrentPassword(event.target.value)
                setValue('current_password', event.target.value)
              }}
              error={has(errors, 'current_password')}
              helperText={
                errors.current_password &&
                errors.current_password.message
              }
              fullWidth
            />
          </EntryBox>
        </Box>
        <Box pb={2}>
          <EntryBox label="New Password">
            <TextField
              key="_new_password"
              name="new_password"
              id="new_password"
              type="password"
              variant="outlined"
              inputProps={{ 'data-testid': 'new-password-test' }}
              {...register('new_password', {
                required: watch('current_password') && 'Enter a new password to make changes.',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters'
                },
                pattern: {
                  value: /^(?=.{8,}).*$/g,
                  message: 'Password must be at least 8 characters long.'
                }
              })}
              value={newPassword}
              onChange={ (event) => {
                setNewPassword(event.target.value)
                setValue('new_password', event.target.value)
              }}
              error={has(errors, 'new_password')}
              helperText={errors.new_password && errors.new_password.message}
              fullWidth
            />
          </EntryBox>
        </Box>
        <Box pb={2}>
          <EntryBox label="Confirm New Password">
            <TextField
              key="_confirm_new_password"
              name="confirm_new_password"
              id="confirm_new_password"
              type="password"
              variant="outlined"
              inputProps={{ 'data-testid': 'confirm-new-password-test' }}
              {...register('confirm_new_password', {
                required: watch('new_password') && 'Enter your new password again to reset it.',
                validate: (value) => value === watch('new_password') || 'Passwords do not match.'
              })}
              value={confirmPassword}
              onChange={ (event) => {
                setConfirmPassword(event.target.value)
                setValue('confirm_new_password', event.target.value)
              }}
              error={has(errors, 'confirm_new_password')}
              helperText={errors.confirm_new_password && errors.confirm_new_password.message}
              fullWidth
            />
          </EntryBox>
        </Box>
        <Box display='flex' flexDirection="row" justifyContent='flex-end' pb={2}>
          <Box pr={1}>
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
            >
              {'Save Changes'}
            </Button>
          </Box>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={exitFunction}
          >
            {'Cancel'}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
