import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { hasRole } from 'services'

const AccessWrapper = ({ children, deniedRoles = [] }) => {
  const [accessGranted, setAccessGranted] = React.useState(true)
  useEffect(() => {
    deniedRoles.forEach((role) => {
      if (hasRole(role)) {
        setAccessGranted(false)
      }
    })
  }, [])
  return (
    <>
      {' '}
      {accessGranted ? (
        <>{children}</>
      ) : (
        <Box>
          <Typography display="flex" justifyContent="center" variant="h5">
            Permission Required
          </Typography>
          <Typography variant="body1">
            You do not have permission to access this page. Please contact your
            organization&apos;s Administrator to request access.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default AccessWrapper
