import { Close, CloudDownload, Delete } from '@mui/icons-material'
import { DatePicker } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { InfoBox, InfoButton } from 'components'
import MultipleFilesStyledDropzone from 'components/MultipleFileSyledDropzone'
import { parseJSON } from 'date-fns'
import { QUERIES, SnackbarUtil } from 'helpers'
import { CONTRACT_STATUSES } from 'helpers/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { api } from 'services'

const MAX_NUMBER_NOTES_LETTERS = 10000

const DeleteFileConfirmationDialog = ({
  isOpen,
  setIsOpen,
  handleConfirmationClick
}) => {
  const handleDeleteButtonClicked = () => {
    handleConfirmationClick()
    setIsOpen(false)
  }
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="delete-file-dialog"
        aria-describedby="delete-file-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure?'}
          <IconButton
            color="error"
            aria-label="close"
            onClick={() => setIsOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-file-dialog-description">
            Deleted files are not recoverable. Please make a backup before
            proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            sx={{
              width: '100px'
            }}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            sx={{
              width: '100px'
            }}
            onClick={handleDeleteButtonClicked}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const FileDownloads = ({ filesToDownload = [] }) => {
  const queryClient = useQueryClient()
  const { id } = useParams()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [filenameToDelete, setFileNameToDelete] = useState(null)

  const deleteContractFileMutation = useMutation({
    mutationFn: async (filename) => {
      await api.contracts.deleteFile(id, {
        filenames: [filename]
      })
      await queryClient.invalidateQueries([QUERIES.GET_CONTRACT, id])
    }
  })

  const handleFileDelete = () => {
    deleteContractFileMutation.mutate(filenameToDelete)
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        <Typography variant="h6">Files to download</Typography>
        <InfoButton>
          <InfoBox>
            <div>
              <li>Download the files previously uploaded</li>
              <li>Click on the &quot;Download&quot; icon</li>
              <li>
                Click on the &quot;Delete&quot; icon to delete uploaded file
              </li>
              <li>
                Deleted are not recoverable. Please make a backup before
                proceeding
              </li>
            </div>
          </InfoBox>
        </InfoButton>
      </Box>
      <Grid
        container
        sx={{
          display: 'flex',
          flexGrow: 1
        }}
        gap={1}
      >
        <DeleteFileConfirmationDialog
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          handleConfirmationClick={handleFileDelete}
        />
        {filesToDownload.map((fileToDownload, index) => (
          <Grid
            item
            key={index}
            border="1px solid #ddd"
            borderRadius="10px"
            backgroundColor="#fafafa"
            width={'49.2%'}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexGrow={1}
              pl="0.75em"
              borderRadius="10px"
            >
              <Tooltip title={fileToDownload.filename}>
                <Typography
                  variant="body1"
                  fontSize="14px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {fileToDownload.filename}
                </Typography>
              </Tooltip>
              <Box display="flex" gap={0.5} alignItems="center">
                <Link
                  download={fileToDownload.filename}
                  href={fileToDownload.url}
                >
                  <IconButton color="primary">
                    <CloudDownload />
                  </IconButton>
                </Link>
                <IconButton
                  color="error"
                  onClick={() => {
                    // Set the filename to delete
                    setFileNameToDelete(fileToDownload.filename)
                    // Open confirmation dialog
                    setIsDialogOpen(true)
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

const CreateEditContract = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { id } = useParams()
  const [numberOfLettersNotes, setNumberOfLettersNotes] = useState(0)
  const [contractFiles, setContractFiles] = useState([])
  const {
    formState: { isSubmitting },
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    watch
  } = useForm({
    defaultValues: {
      openContract: false
    }
  })

  const dropzone = useDropzone({
    accept: '.pdf',
    onDrop: (files) => {
      files.forEach((file, index) => {
        setContractFiles((prev) => [...prev, file])
        setValue(`files`, [...contractFiles, file])
      })
    },
    multiple: true
  })

  const { data: institutes, isLoading: isInstituteLoading } = useQuery(
    [QUERIES.GET_INSTITUTES],
    async () => {
      const institutesData = await api.institutes.list({
        size: 9999,
        ordering: 'name',
        exclude_archived: true
      })
      return institutesData.items || []
    }
  )

  const {
    data: contractData,
    isLoading: isContractLoading,
    refetch: refetchContractData,
    isRefetching: isContractRefetching
  } = useQuery({
    queryKey: [QUERIES.GET_CONTRACT, id],
    queryFn: async () => {
      const contractData = await api.contracts.get(id)
      // Set the number of letters in notes field
      setNumberOfLettersNotes(contractData?.notes?.length || 0)
      setValue('openContract', !!contractData.endDate)

      return (
        {
          customerSince: contractData?.institute?.customer_since || null,
          downloadUrls: contractData?.download_urls || [],
          endDate: contractData?.end_date || '',
          notes: contractData?.notes || '',
          organization: contractData?.institute || '',
          startDate: contractData?.start_date || '',
          status: contractData?.status || '',
          title: contractData?.title || ''
        } || {}
      ) // return empty object if contract not found
    },
    enabled: !!id, // enable query only if id is not null or undefined
    refetchOnWindowFocus: false // disable refetch on window focus to avoid refetching on page load
  })

  const createContractMutation = useMutation({
    mutationFn: async (data) => {
      const requestData = {
        customer_since: data.customerSince || null,
        end_date: data.endDate || null,
        institute: data.organization,
        notes: data.notes,
        start_date: data.startDate,
        status: data.status || 'draft',
        title: data.title
      }

      return await api.contracts.create(requestData)
    },
    onSuccess: () => {
      SnackbarUtil.success('Contract created successfully', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    },
    onError: (error) => {
      const status = error?.response?.status
      handleRequestError('Unable to create contract', status, enqueueSnackbar)
    }
  })

  // Update contract mutation
  const updateContractMutation = useMutation({
    mutationFn: async (data) => {
      const requestData = {
        customer_since: data.customerSince,
        end_date: data.endDate || null,
        institute: data.organization,
        notes: data.notes,
        start_date: data.startDate,
        status: data.status,
        title: data.title
      }

      return await api.contracts.update(id, requestData) // return updated contract object if contract is updated successfully
    },
    onError: (error) => {
      const status = error?.response?.status
      handleRequestError('Unable to update contract', status, enqueueSnackbar)
    },
    onSuccess: () => {
      SnackbarUtil.success('Contract updated successfully', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    }
  })

  // Upload files mutation
  const uploadContractFilesMutation = useMutation({
    mutationFn: async ({ id, data }) => {
      if (data.length === 0) return

      const formData = new FormData()
      data.forEach((file, index) => {
        formData.append(`files-${index}`, file)
      })

      await api.contracts.uploadFiles(id, formData)
    },
    onError: (error) => {
      const status = error?.response?.status
      handleRequestError(
        'Unable to upload contract files',
        status,
        enqueueSnackbar
      )
    }
  })

  const handleRequestError = (message, status, enqueueSnackbar) => {
    if (status >= 400 && status < 500) {
      SnackbarUtil.error(message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'middle'
        }
      })
    }
  }

  const handleContractFileChange = (event) => {
    const changedFiles = [...event.target.files]
    changedFiles.forEach((file) => {
      setContractFiles((prev) => [...prev, file])
    })
  }

  const clearContractFile = (file) => {
    setContractFiles((prev) => prev.filter((f) => f !== file))
  }

  const onSubmit = async (data) => {
    if (id) {
      await updateContractMutation.mutateAsync(data)
      await uploadContractFilesMutation.mutateAsync({
        id: id,
        data: contractFiles
      })
      await refetchContractData()
    } else {
      const newContract = await createContractMutation.mutateAsync(data)
      await uploadContractFilesMutation.mutateAsync({
        id: newContract.id,
        data: contractFiles
      })
      history.push(`/contracts/${newContract.id}/edit`)
    }

    setContractFiles([])
    await queryClient.invalidateQueries([QUERIES.GET_CONTRACT, id])
  }

  useEffect(() => {
    if (id) {
      reset({
        ...contractData,
        startDate: parseJSON(contractData?.startDate || null),
        endDate: parseJSON(contractData?.endDate || null),
        customerSince: parseJSON(contractData?.customerSince || null),
        openContract: !contractData?.endDate || false
      })
    }
  }, [contractData, isContractRefetching])

  return (
    <>
      <Container
        sx={{
          flexGrow: 1,
          width: '41em'
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {id ? 'Update' : 'Create New'} Contract
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="50%" height={40} />
              ) : (
                <Controller
                  rules={{
                    required: 'Organization is required'
                  }}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field
                    const { error } = fieldState
                    return (
                      <Autocomplete
                        disabled={!!id}
                        size="small"
                        value={value || null}
                        getOptionLabel={(option) => {
                          return option.partner_id
                            ? option.name + ' - ' + option.partner_id
                            : option.name
                        }}
                        loading={isInstituteLoading}
                        loadingText="Loading Institutes..."
                        id="institute-autocomplete"
                        options={institutes || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Organization"
                            error={error !== undefined}
                            helperText={error?.message}
                            size="small"
                          />
                        )}
                        sx={{
                          width: '100%'
                        }}
                        onChange={(e, newValue) => {
                          onChange(newValue)
                        }}
                      />
                    )
                  }}
                  name="organization"
                  control={control}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="50%" height={40} />
              ) : (
                <Controller
                  rules={{
                    required: 'Title is required'
                  }}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field
                    const { error } = fieldState
                    return (
                      <TextField
                        size="small"
                        disabled={updateContractMutation.isLoading}
                        label={'Title'}
                        error={error !== undefined}
                        helperText={error?.message}
                        onChange={(e) => onChange(e.target.value)}
                        value={value || ''}
                        sx={{
                          width: '100%'
                        }}
                      />
                    )
                  }}
                  control={control}
                  name="title"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="50%" height={40} />
              ) : (
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    const { onChange, value } = field
                    return (
                      <FormControl
                        sx={{
                          width: '100%'
                        }}
                      >
                        <InputLabel id="status-label-id">Status</InputLabel>
                        <Select
                          labelId="status-label-id"
                          label="Status"
                          size="small"
                          disabled={updateContractMutation.isLoading}
                          variant="outlined"
                          value={value || 'draft'}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          {Object.keys(CONTRACT_STATUSES).map((status) => {
                            return (
                              <MenuItem key={status} value={status || 'draft'}>
                                {CONTRACT_STATUSES[status]}
                              </MenuItem>
                            ) // return empty object if contract not found
                          })}
                        </Select>
                      </FormControl>
                    )
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="100%" height={40} />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    width: '100%'
                  }}
                >
                  <FormControlLabel
                    label="Open Contract"
                    control={
                      <Controller
                        control={control}
                        name="openContract"
                        render={({ field }) => {
                          const { onChange, value } = field
                          return (
                            <Switch
                              checked={value}
                              onChange={(e) => {
                                const newCheckValue = e.target.checked
                                onChange(newCheckValue)
                                setValue('endDate', '')
                              }}
                            />
                          )
                        }}
                      />
                    }
                    sx={{
                      width: '100%'
                    }}
                  />
                  <InfoButton>
                    <InfoBox>
                      <div>
                        <li>Open Contracts does not have an End Date.</li>
                        <li>
                          If Open Contract is enabled, End Date will be set to
                          null.
                        </li>
                      </div>
                    </InfoBox>
                  </InfoButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={4}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="100%" height={40} />
              ) : (
                <Controller
                  rules={{
                    required: 'Start Date is required'
                  }}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field
                    const { error } = fieldState
                    return (
                      <DatePicker
                        label={'Start Date'}
                        disabled={updateContractMutation.isLoading}
                        value={value || null}
                        onChange={onChange}
                        inputFormat="dd MMM yyyy"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={error !== undefined}
                            helperText={error?.message}
                            size="small"
                            sx={{
                              width: '100%'
                            }}
                          />
                        )}
                      />
                    )
                  }}
                  control={control}
                  name="startDate"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="100%" height={40} />
              ) : (
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    validate: (value) => {
                      const startDate = getValues('startDate')
                      const openContract = getValues('openContract')
                      if (openContract) return true
                      // Get if value is of type Date
                      const isDate = value instanceof Date
                      // If value is not of type Date, return true
                      if (!isDate) {
                        return 'End Date is required or Open Contract should be enabled'
                      }
                      if (startDate && value < startDate) {
                        return 'End Date cannot be before Start Date'
                      }
                    }
                  }}
                  render={({ field, fieldState }) => {
                    const { onChange, value } = field
                    const { error } = fieldState
                    const openContract = watch('openContract')

                    return (
                      <DatePicker
                        label="End Date"
                        value={openContract ? null : value || null}
                        disabled={
                          updateContractMutation.isLoading || openContract
                        }
                        onChange={onChange}
                        inputFormat="dd MMM yyyy"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error !== undefined}
                            helperText={error?.message}
                            sx={{
                              width: '100%'
                            }}
                          />
                        )}
                      />
                    )
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="32.5%" height={40} />
              ) : (
                <Box width={'100%'} display={'flex'}>
                  <Controller
                    control={control}
                    name="customerSince"
                    rules={{
                      validate: (value) => {
                        //   Value should be less than today's date or null
                        if (!value) return true

                        const today = new Date()
                        if (value > today) {
                          return 'Customer Since Date cannot be in the future'
                        }
                      }
                    }}
                    render={({ field, fieldState }) => {
                      const { onChange, value } = field
                      const { error } = fieldState

                      return (
                        <DatePicker
                          label={'Customer Since'}
                          disabled={updateContractMutation.isLoading}
                          value={value || null}
                          onChange={onChange}
                          inputFormat="dd MMM yyyy"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={error !== undefined}
                              helperText={error?.message}
                              size="small"
                              sx={{
                                flexGrow: 1
                              }}
                            />
                          )}
                        />
                      )
                    }}
                  />
                  <InfoButton>
                    <InfoBox>
                      <div>
                        If not set, Customer Since Date will be set to the date
                        of first contract for this Institute
                      </div>
                    </InfoBox>
                  </InfoButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {isContractLoading ? (
                <Skeleton variant="rounded" width="100%" height={200} />
              ) : (
                <Controller
                  render={({ field }) => {
                    const { onChange, value } = field
                    return (
                      <Box width="100%" position="relative">
                        <TextField
                          placeholder="Add notes related to this contract"
                          disabled={updateContractMutation.isLoading}
                          multiline
                          rows={7}
                          maxRows={999}
                          onChange={(e) => {
                            onChange(e.target.value)
                            setNumberOfLettersNotes(e.target.value.length)
                          }}
                          value={value || ''}
                          sx={{
                            width: '100%'
                          }}
                          inputProps={{
                            maxLength: MAX_NUMBER_NOTES_LETTERS
                          }}
                          label="Notes"
                        />
                        <Typography
                          position="absolute"
                          right={5}
                          bottom={0}
                          fontSize={14}
                          color="greyed.muted"
                        >
                          {numberOfLettersNotes}/{MAX_NUMBER_NOTES_LETTERS}
                        </Typography>
                      </Box>
                    )
                  }}
                  control={control}
                  name="notes"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isContractLoading ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  <Skeleton variant="rounded" width="100%" height={80} />
                  <Skeleton variant="rounded" width="100%" height={100} />
                </Box>
              ) : (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <FileDownloads
                    filesToDownload={contractData?.downloadUrls || []}
                  />
                  <MultipleFilesStyledDropzone
                    dropzone={dropzone}
                    files={contractFiles}
                    setFiles={setContractFiles}
                    clearFile={clearContractFile}
                    onChange={handleContractFileChange}
                    fileUploadDisabled={updateContractMutation.isLoading}
                    text="Click or Drag & Drop the contract related files here. Multiple files are allowed"
                    emText="(Only *.pdf will be accepted)"
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'center'}>
              <Button
                type="button"
                variant="outlined"
                color="error"
                onClick={() => history.push('/contracts')}
                disabled={isSubmitting || isContractLoading}
                sx={{
                  width: '10em'
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'center'}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting || isContractLoading}
                endIcon={
                  isSubmitting && <CircularProgress size={20} color="inherit" />
                }
                sx={{
                  width: '10em'
                }}
              >
                {id ? 'Update Contract' : 'Create Contract'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  )
}

export default CreateEditContract
