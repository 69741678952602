import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { Add, Edit } from '@mui/icons-material'
import {
  BlurWrapper,
  Comments,
  InlineError,
  Loader,
  MainContent,
  Modal,
  MultiVibrationPlot,
  Sidebar,
  StyledTab,
  StyledTabs,
  TabPanel,
  TemperaturePlot
} from 'components'
import { format, parseJSON } from 'date-fns'
import { LabelForm, LabelsOnlyForm, RecordForm } from 'forms'
import { InstituteLock, formatDuration } from 'helpers'
import { Link, useParams } from 'react-router-dom'
import {
  Role,
  api,
  authenticationService,
  hasRole,
  useFeaturesContext,
  useMetricsContext
} from 'services'

export default function RecordDetail(props) {
  const { flags, overrides, getFlag } = useFeaturesContext()
  const { metricType, metricLabel } = useMetricsContext()
  const { id } = useParams()
  const [blurInfo, setBlurInfo] = useState(false)
  const multiInstitute = authenticationService.multiInstitute

  const { data: recordData, refetch: refreshRecordData } = useQuery(
    ['record', id],
    () => api.records.get(id)
  )
  const { data: recordComments, refetch: refreshRecordComments } = useQuery(
    ['record-comments', id],
    () => api.comments.list('records', id),
    { enabled: !!recordData }
  )
  const { isLoading: accelIsLoading, data: accelerationData } = useQuery(
    ['record-accel', id],
    () => api.records.acceleration(id),
    { enabled: !!recordData }
  )
  const { isLoading: tempIsLoading, data: temperatureData } = useQuery(
    ['record-temp', id],
    () => api.records.temperature(id),
    { enabled: !!recordData }
  )
  const { isLoading: cumulativeIsLoading, data: cumulativeData } = useQuery(
    ['cumulative', id, metricType],
    () => api.records.cumulative(id, { metric: metricType }),
    { enabled: !!recordData && !recordData.exclude }
  )

  const [labelsEditMode, setLabelsEditMode] = useState(false)

  const [timeAxis, setTimeAxis] = useState(
    localStorage.getItem('recordTimeAxis') === null
      ? 'seconds'
      : localStorage.getItem('recordTimeAxis')
  )

  const handleTimeAxis = (event, newTimeAxis) => {
    setTimeAxis(newTimeAxis)
    localStorage.setItem('recordTimeAxis', newTimeAxis)
  }

  const [view, setView] = useState('accel')

  const handleViewChange = (event, newView) => {
    setView(newView)
  }

  const commentDividerRef = useRef()

  useEffect(() => {
    if (getFlag) {
      setBlurInfo(!getFlag('troubleshooting'))
    }
  }, [flags, overrides, getFlag])

  return (
    <InstituteLock
      id={recordData && recordData.institute.id}
      redirectUrl="/records"
    >
      <Sidebar>
        <Box
          display="flex"
          flexDirection="row"
          m={2}
          mt={multiInstitute ? 1 : 6}
          justifyContent="space-between"
        >
          <Box flexGrow={1}>
            <Typography variant="subtitle1">{'Transit Record'}</Typography>
          </Box>
          <Role restrict={['staff']}>
            <Modal
              button={
                <Button
                  variant="contained"
                  size="small"
                  data-testid="admin-option-button-testid"
                >
                  {'Staff Options'}
                </Button>
              }
              label={'Edit Record'}
            >
              <RecordForm id={id} exitFunction={refreshRecordData} />
            </Modal>
          </Role>
        </Box>

        <Box m={1} mr={2} ml={2} pb={1}>
          <RecordForm id={id} variant="onChange" />
        </Box>
        <Divider variant="middle" style={{ backgroundColor: '#D6DAE0' }} />
        <ClickAwayListener
          onClickAway={() => {
            setLabelsEditMode(false)
            refreshRecordData()
          }}
        >
          <Box display="flex" flexDirection="column" m={1} mr={2} ml={2} pb={1}>
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{'Labels'}</Typography>
              </Box>
              <Box>
                <Tooltip
                  title={`${
                    recordData &&
                    recordData.labels &&
                    recordData.labels.length > 0
                      ? 'Modify Labels'
                      : 'Add Labels'
                  }`}
                >
                  <IconButton
                    variant="primary"
                    size="small"
                    color="secondary"
                    onClick={() => setLabelsEditMode(!labelsEditMode)}
                    disabled={hasRole('guest')}
                    data-testid="change-labels-button"
                  >
                    {recordData &&
                    recordData.labels &&
                    recordData.labels.length > 0 ? (
                      <Edit />
                    ) : (
                      <Add />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box>
              {recordData && (
                <LabelsOnlyForm
                  id={recordData.id}
                  labels={recordData.labels}
                  resourceApi={api.records}
                  editMode={labelsEditMode}
                />
              )}
            </Box>
            <Box
              display={labelsEditMode ? 'flex' : 'none'}
              flexDirection="row"
              pt={1}
            >
              <Box flexGrow={1}>
                <Modal
                  maxWidth={'xs'}
                  button={
                    <Button size="small" color="secondary" startIcon={<Add />}>
                      {'New Label'}
                    </Button>
                  }
                  label={'Create New Label'}
                  refreshState={refreshRecordData}
                >
                  {recordData && (
                    <LabelForm
                      exitFunction={refreshRecordData}
                      updateResource={{
                        id: recordData.id,
                        labels: recordData.labels,
                        api: api.records
                      }}
                    />
                  )}
                </Modal>
              </Box>
              <Box>
                <Link
                  to={{
                    pathname: '/labels',
                    state: {
                      name: `Record from ${
                        recordData &&
                        format(parseJSON(recordData.date), 'd MMM yyyy HH:mm')
                      }`
                    }
                  }}
                >
                  {' '}
                  <Button size="small" color="secondary">
                    Manage Labels
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>

        <Divider
          variant="middle"
          style={{ backgroundColor: '#D6DAE0' }}
          ref={commentDividerRef}
        />
        <Comments
          comments={recordComments}
          resource="records"
          resourceId={id}
          activeUserId={authenticationService.currentUserValue.user_id}
          refreshState={refreshRecordComments}
          parentElOffsetTop={
            commentDividerRef.current && commentDividerRef.current.offsetTop
          }
        />
      </Sidebar>
      <MainContent sidebar="right">
        <Box
          py={2}
          px={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h1">
            {recordData && recordData.date
              ? `Records from ${format(
                  parseJSON(recordData.date),
                  'd MMM yyyy HH:mm'
                )}`
              : 'Unprocessed Record'}
          </Typography>
        </Box>
        <Box
          px={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <StyledTabs
              value={view}
              onChange={handleViewChange}
              style={{ maxHeight: '39px' }}
            >
              <StyledTab label="Acceleration" value="accel" disableRipple />
              <StyledTab
                label="Temperature"
                value="temperature"
                disableRipple
              />
            </StyledTabs>
          </Box>
          <Box>
            <StyledTabs
              value={timeAxis}
              onChange={handleTimeAxis}
              style={{ maxHeight: '39px' }}
            >
              <StyledTab label="Elapsed" value="seconds" disableRipple />
              <StyledTab label="Clock" value="timestamp" disableRipple />
            </StyledTabs>
          </Box>
        </Box>
        <Box px={4} py={2}>
          <TabPanel value={view} index="accel">
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box
                  pr={3}
                  alignSelf="center"
                  fontWeight={500}
                  fontSize="20px"
                  display="flex"
                  flexDirection="row"
                >
                  <Box pr={2} alignSelf="center">
                    <Typography variant="inherit" display="inline">
                      {metricLabel}
                    </Typography>
                  </Box>
                  <Box alignSelf="center">
                    <Typography variant="h6" display="inline">
                      {recordData &&
                      recordData.metrics &&
                      recordData.metrics.auc
                        ? recordData.metrics[metricType]
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  pr={3}
                  alignSelf="center"
                  fontWeight={500}
                  fontSize="20px"
                  display="flex"
                  flexDirection="row"
                >
                  <Box pr={2} alignSelf="center">
                    <Typography variant="inherit" display="inline">
                      Record Duration
                    </Typography>
                  </Box>
                  <Box alignSelf="center">
                    <BlurWrapper
                      doBlur={blurInfo}
                      showCTAComponent={false}
                      blurLevel={8}
                    >
                      <Typography variant="h6" display="inline">
                        {recordData &&
                        recordData.metrics &&
                        recordData.metrics.duration
                          ? formatDuration(recordData.metrics.duration)
                          : '-'}
                      </Typography>
                    </BlurWrapper>
                  </Box>
                </Box>
                <Box
                  alignSelf="center"
                  fontWeight={500}
                  fontSize="20px"
                  display="flex"
                  flexDirection="row"
                >
                  <Box pr={2} alignSelf="center">
                    <Typography variant="inherit" display="inline">
                      VitalVial
                    </Typography>
                  </Box>
                  <Box alignSelf="center">
                    <Typography variant="h6" display="inline">
                      {recordData && recordData.vial ? recordData.vial : '-'}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {!accelIsLoading && !cumulativeIsLoading && recordData ? (
                accelerationData ? (
                  <BlurWrapper doBlur={blurInfo}>
                    <MultiVibrationPlot
                      recordData={recordData}
                      accelerationData={accelerationData}
                      cumulativeData={cumulativeData}
                      timeAxis={timeAxis}
                    />
                  </BlurWrapper>
                ) : (
                  <InlineError detail={'No acceleration data to show.'} />
                )
              ) : (
                <Loader />
              )}
            </Box>
          </TabPanel>

          <TabPanel value={view} index="temperature">
            <Box display="flex" flexDirection="column">
              <Box
                mb={10}
                display={recordData && temperatureData ? 'block' : 'none'}
              >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Box display="flex" flexDirection="row">
                      <Box
                        pr={20}
                        alignSelf="center"
                        fontWeight={500}
                        fontSize="20px"
                        display="flex"
                        flexDirection="row"
                      >
                        <Box pr={2} alignSelf="center">
                          <Typography variant="inherit" display="inline">
                            Temperature Min
                          </Typography>
                        </Box>
                        <Box alignSelf="center">
                          <BlurWrapper
                            doBlur={blurInfo}
                            showCTAComponent={false}
                            blurLevel={8}
                          >
                            <Typography variant="h6" display="inline">
                              {temperatureData &&
                              temperatureData.minimum_temperature
                                ? `${temperatureData.minimum_temperature.toFixed(
                                    1
                                  )} °C`
                                : '-'}
                            </Typography>
                          </BlurWrapper>
                        </Box>
                      </Box>
                      <Box
                        alignSelf="center"
                        fontWeight={500}
                        fontSize="20px"
                        display="flex"
                        flexDirection="row"
                      >
                        <Box pr={2} alignSelf="center">
                          <Typography variant="inherit" display="inline">
                            Temperature Max
                          </Typography>
                        </Box>
                        <Box alignSelf="center">
                          <BlurWrapper
                            doBlur={blurInfo}
                            showCTAComponent={false}
                            blurLevel={8}
                          >
                            <Typography variant="h6" display="inline">
                              {temperatureData &&
                              temperatureData.maximum_temperature
                                ? `${temperatureData.maximum_temperature.toFixed(
                                    1
                                  )} °C`
                                : '-'}
                            </Typography>
                          </BlurWrapper>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    {!tempIsLoading ? (
                      temperatureData ? (
                        <BlurWrapper doBlur={blurInfo}>
                          <TemperaturePlot
                            temperature={temperatureData.temperature}
                            startdate={recordData.date}
                            time={temperatureData.temperature_time}
                            duration={recordData.duration}
                            min={temperatureData.minimum_temperature}
                            max={temperatureData.maximum_temperature}
                            mean={recordData.metrics.mean_kinetic_temperature}
                            temperaturetype={recordData.temperature_type}
                            timeFormat={timeAxis}
                          />
                        </BlurWrapper>
                      ) : (
                        <InlineError detail={'No temperature data to show.'} />
                      )
                    ) : (
                      <Loader />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </MainContent>
    </InstituteLock>
  )
}
