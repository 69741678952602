export const QUERIES = {
  GET_INSTITUTE: 'get-institute',
  GET_INSTITUTES: 'get-institutes',
  GET_CONTRACT: 'get-contract',
  GET_ALL_CONTRACTS: 'get-all-contracts',
  GET_VALIDATIONS: 'get-validations',
  GET_ANALYZER: 'get-analyzer',
  GET_LINE_STATS: 'get-line-stats',
  GET_ALL_LINES: 'get-all-lines',
  CREATE_VALIDATION: 'create-validation',
  GET_INSTITUTE_LABELS: 'get-institute-labels',
  GET_VITAL_VIALS: 'get-vital-vials',
  GET_VITAL_VIALS_HARDWARE_OPTIONS: 'get-vital-vials-hardware-options',
  CREATE_VITAL_VIAL: 'create-vital-vial',
  UPDATE_VITAL_VIAL: 'update-vital-vial',
  GET_ALL_USERS: 'get-all-users',
  GET_USER: 'get-user',
  UPDATE_USER: 'update-user',
  CREATE_USER: 'create-user',
  RESEND_INVITE: 'resend-invite',
  DELETE_USER: 'delete-user',
  REVOKE_ACCESS: 'revoke-access',
  GET_ANALYZER_MAKES: 'get-analyzer-makes'
}
