import { Box, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Label from 'components/Label'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { api, apiConfig } from 'services'
import { QUERIES } from '../../helpers'

export default function LabelsOnlyForm({
  id,
  labels,
  resourceApi,
  editMode = false
}) {
  const queryClient = useQueryClient()
  const { register, handleSubmit, setValue, getValues } = useForm()
  const [selectedLabels, setSelectedLabels] = useState([])
  const handleLabelChange = (event, labels) => {
    setSelectedLabels(labels)
    const newLabels = labels.map((label) => label.id)
    setValue('labels', newLabels)
    onSubmit(getValues())
  }

  // Form Submission Handling
  const onSubmit = async (data) => {
    await resourceApi
      .update(id, data)
      .then()
      .catch(function (status) {
        console.log(status)
      })

    await queryClient.invalidateQueries(QUERIES.GET_ALL_LINES)
  }

  useEffect(() => {
    register('labels')
    if (labels) setSelectedLabels(labels)
  }, [labels])

  const { data: labelOptions } = useQuery(
    ['labels', apiConfig.currentApiInstitute],
    () => api.labels.list()
  )

  return (
    <>
      {labelOptions && editMode ? (
        <form
          id={'labels_only_form'}
          data-testid="labels-only-form-test"
          onSubmit={handleSubmit}
        >
          <Autocomplete
            multiple
            id="labels"
            name="labels"
            size="small"
            data-testid="labels-only-form-autocomplete"
            value={selectedLabels}
            options={labelOptions}
            getOptionLabel={(option) => option.label}
            onChange={handleLabelChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Label
                    key={`label-option-${option.id}-${index}`}
                    label={option.label}
                    color={option.color}
                    size="small"
                    {...getTagProps({ index })}
                  />
                )
              })
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Label label={option.label} color={option.color} size="small" />
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Labels" />}
          />
        </form>
      ) : (
        <div>
          {labels && labels.length > 0 ? (
            labels.map((label, index) => (
              <Label
                key={`label-${id}-${index}`}
                label={label.label}
                color={label.color}
                size="small"
              />
            ))
          ) : (
            <Box display="flex" justifyContent="center">
              <Typography variant="caption">No Labels</Typography>
            </Box>
          )}
        </div>
      )}
    </>
  )
}
