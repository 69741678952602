import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import SettingsIcon from '@mui/icons-material/Settings'
import { StaticDatePicker } from '@mui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { MainContent } from 'components'
import { addDays, format, parseJSON } from 'date-fns'
import { formatDateRange } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { Flag, api, hasRole } from 'services'

export default function MeasurmentsView({ hemolysisData }) {
  const history = useHistory()
  const { id } = useParams()
  const [day, setDay] = useState()

  const tableRef = useRef()
  const [tableHeight, setTableHeight] = useState(400)
  const handleTableHeight = () => {
    if (tableRef.current) {
      setTableHeight(window.innerHeight - tableRef.current.offsetTop - 35)
    }
  }

  useEffect(() => {
    if (hemolysisData) {
      setDay(parseJSON(hemolysisData?.measurement_summary.start))
      handleTableHeight()
    }
  }, [hemolysisData])

  const handleDateChange = (newValue) => {
    setDay(newValue)
  }

  const { data: measurementData, isLoading } = useQuery(
    ['measurment-data', id, day],
    () => {
      const start = format(day, 'yyyy-MM-dd 00:00:00X')
      const end = format(addDays(day, 1), 'yyyy-MM-dd 00:00:00X')
      return api.hemolysisData.getMeasurements(id, {
        timestamp__gte: start,
        timestamp__lte: end
      })
    },
    { enabled: !!day }
  )

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'timestamp',
      headerName: 'Date & Time',
      type: 'dateTime',
      renderCell: ({ value }) => format(parseJSON(value), 'dd MMM yyyy HH:mm'),
      flex: 1
    },
    {
      field: 'value',
      headerName: 'Hemolysis Index',
      width: 180
    }
  ]

  return (
    <Flag
      name={'hemolysisTracking'}
      alternate={<Redirect to={{ pathname: '/dashboard' }} />}
    >
      <MainContent>
        <Container maxWidth={'lg'}>
          <Box display="flex" flexDirection="column" pt={4}>
            <Typography variant="h1" display="flex" justifyContent="center">
              {!hemolysisData ? (
                <CircularProgress size={35} />
              ) : (
                hemolysisData?.title || hemolysisData?.filename
              )}
            </Typography>
            <Box
              py={1}
              display="flex"
              flexDirection="row"
              flexGrow={1}
              alignItems="center"
            >
              <Box display="flex" mr={3}>
                <Tooltip title={'View Line Details'}>
                  <Button
                    size="medium"
                    onClick={() => {
                      history.push({
                        pathname: `/line/${hemolysisData?.line?.id}`
                      })
                    }}
                    endIcon={<KeyboardDoubleArrowRight />}
                  >
                    {hemolysisData?.line?.name || hemolysisData?.filename}
                  </Button>
                </Tooltip>
              </Box>
              <Box display="flex">
                {`${
                  hemolysisData &&
                  formatDateRange(
                    hemolysisData?.measurement_summary.start,
                    null,
                    hemolysisData?.measurement_summary.end
                  )
                }`}
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Tooltip title={'HI Data Settings'}>
                  <IconButton
                    disabled={hasRole('guest')}
                    variant="primary"
                    onClick={() => {
                      history.push({
                        pathname: `/hemolysis-index-data/${id}/settings`
                      })
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row">
            <Box>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                id="day"
                name="day"
                label={'Day'}
                value={day}
                minDate={parseJSON(hemolysisData?.measurement_summary?.start)}
                maxDate={parseJSON(hemolysisData?.measurement_summary?.end)}
                onChange={handleDateChange}
                disableMaskedInput={true}
                orientation="portrait"
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box ref={tableRef} height={tableHeight} flexGrow={1} ml={2}>
              <DataGrid
                columns={columns}
                loading={isLoading}
                rows={measurementData || []}
                disableSelectionOnClick
                disableColumnMenu
              />
            </Box>
          </Box>
        </Container>
      </MainContent>
    </Flag>
  )
}
