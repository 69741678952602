import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { InlineError, Loader } from 'components'
import { axisFont, colors } from 'helpers'
import { useEffect, useState } from 'react'
import Plot from 'react-plotly.js'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { api, apiConfig } from 'services'

export default function GraphView({
  analyte,
  refreshState = false,
  setRefreshState,
  url
}) {
  const history = useHistory()
  const [traces, setTraces] = useState()
  const { data: clinicalData } = useQuery(
    [
      'clinical-data-graph',
      apiConfig.currentApiInstitute,
      { refreshState, setRefreshState, url }
    ],
    () => {
      const params = new URLSearchParams(url.search)
      params.set('size', 9999)
      return api.clinicalData.list(params).then((data) => {
        setRefreshState(false)
        return data.items
      })
    }
  )
  const [yRange, setYRange] = useState([0, 100])

  const theme = useTheme()
  const column = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    setTraces(null)
    let unmounted = false
    if (!unmounted && clinicalData) {
      const filteredData = clinicalData.filter(
        (sample) => sample.record && sample.record.line !== null
      )
      const groupedData = filteredData.reduce((lines, sample) => {
        const key = sample.record.line.name
        if (!lines[key]) {
          lines[key] = { values: [], id: sample.record.line.id }
        }
        if (sample[analyte.key]) lines[key].values.push(sample[analyte.key])
        return lines
      }, {})
      const maxValues = []
      const lineTraces = []
      Object.keys(groupedData).forEach(function (key, index) {
        const boxTrace = {
          y: groupedData[key].values,
          type: 'box',
          hoverinfo: 'y',
          url: `/line/${groupedData[key].id}`,
          name: key,
          marker: {
            color: colors[index % colors.length]
          }
        }
        const maxGroupPoint = Math.max(...groupedData[key].values)
        maxValues.push(maxGroupPoint)
        if (groupedData[key].values.length > 0) lineTraces.push(boxTrace)
      })
      const maxPoint = Math.max(...maxValues)
      setYRange([0, maxPoint * 1.05])
      if (analyte.key === 'h_index')
        setYRange([0, Math.max(100, maxPoint) * 1.05])
      setTraces(lineTraces)
    }
    return () => {
      unmounted = true
    }
  }, [analyte, clinicalData])

  const boxPlotLayout = {
    autosize: true,
    hovermode: 'closest',
    showlegend: false,
    margin: {
      l: 75,
      r: 50,
      t: 30,
      pad: 4
    },
    yaxis: {
      title: {
        text: analyte.label,
        font: axisFont
      },
      range: yRange
    }
  }

  return traces ? (
    traces.length > 0 ? (
      <Box
        display="flex"
        flexDirection={column ? 'column' : 'row'}
        justifyContent="space-evenly"
        style={{ minHeight: '65vh' }}
      >
        <Plot
          data={traces}
          layout={boxPlotLayout}
          useResizeHandler={true}
          style={{ width: '100%', height: '100%' }}
          config={{ responsive: true, displaylogo: false }}
          onClick={(event) => {
            const url = event.points[0].data.url
            return history.push(url)
          }}
        />
      </Box>
    ) : (
      <InlineError
        title="No Data"
        detail={
          <Box>
            <Box>There is no data for {analyte.label}.</Box>
            {/* <Box alignSelf="center" pt={2}>
              <Modal
                button={
                  <Button color="primary" variant="contained" size="small" startIcon={<Add />}>
                    {'Add Clinical Data'}
                  </Button>
                }
                label={'New Clinical Data'}
              >
                <ClinicalDataForm exitFunction={() => { setRefreshState(true) }} />
              </Modal>
            </Box> */}
          </Box>
        }
        color="#6B6B6B"
      />
    )
  ) : (
    <Loader />
  )
}
