import React from 'react'
import Container from '@mui/material/Container'
import makeStyles from '@mui/styles/makeStyles'
import { Footer } from 'components'
import styles from './styles'

const useStyles = makeStyles(styles)

export default function NoAuthLayout (props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth={false} disableGutters={true}>
        {props.children}
        <Footer />
      </Container>
    </div>
  )
}
