const UserRolesInfo = () => {
  return (
    <div>
      <li>
        <b>Owner: </b>
        Can manage all aspects of the organization
      </li>
      <li>
        <b>Admin: </b>
        Can manage all aspects of the organization except creating new
        organizations
      </li>
      <li>
        <b>Member: </b>
        Can manage all aspects of the organization except creating new
        organizations and managing users
      </li>
      <li>
        <b>Guest: </b>
        Can only view data and cannot create/edit/delete any data
      </li>
    </div>
  )
}

export default UserRolesInfo
