import Tabs from '@mui/material/Tabs'
import withStyles from '@mui/styles/withStyles'

const tabsStyles = (theme) => ({
  root: {
    backgroundColor: '#F5F7FA',
    borderRadius: 5,
    border: '1px solid #D6DAE0',
    width: 'fit-content',
    minHeight: 'fit-content'
  },
  indicator: {
    height: '100%',
    zIndex: 1,
    backgroundColor: theme.palette.primary.main
  }
})

const StyledTabs = withStyles(tabsStyles)(Tabs)

export default StyledTabs
