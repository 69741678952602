import { QUERIES } from 'helpers'
import { useQuery } from 'react-query'
import { api } from 'services'

export const useGetInstituteLines = ({
  onSuccess = () => {},
  onError = () => {},
  params = {}
}) => {
  console.log('useGetInstituteLines', params)
  const { staleTime = 0 } = params
  // remove staleTime from params
  delete params.staleTime
  return useQuery({
    queryKey: [QUERIES.GET_ALL_LINES, params],
    queryFn: async () => {
      return await api.lines.list(params)
    },
    onSuccess: (data) => {
      onSuccess(data)
    },
    onError: (error) => {
      onError(error)
    },
    staleTime: staleTime
  })
}
