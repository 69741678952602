import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'
import Logo from 'assets/images/full_logo.png'
import has from 'lodash/has'
import { useSnackbar } from 'notistack'
import { api } from 'services'
import { useQuery } from 'react-query'

export default function Register () {
  const { token } = useParams()
  const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { data: tokenData } = useQuery(['register-token', token], () => api.auth.getRegisterToken(token))

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const onSubmit = (data) => {
    delete data.confirm_password
    api.auth.register(data)
      .then((response) => {
        enqueueSnackbar('You have been registered, please log in to view your dashboard.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        history.push('/login')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          enqueueSnackbar(error.response.data.detail, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      })
  }
  return (
    <Container maxWidth={'xs'} style={{ height: '80vh' }}>
      <Box display='flex' flexDirection='column'height={'100%'} width={1}>
        <Box p={7} maxWidth={'-webkit-fill-available'}>
          <img style={{ width: '-webkit-fill-available' }} src={Logo.toString()} />
        </Box>
        <Box pb={2}>
          <Typography variant="h2">{'Register for VitalQC'}</Typography>
          <Typography variant="body1">{ tokenData && tokenData.email}</Typography>
        </Box>

        <Box width={1} pb={2}>
          <form
            id="register_form"
            data-testid="register-test"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input name="token" type="hidden" value={token} {...register('token', { required: true })} />
            <Box pb={2}>
              <TextField
                {...register('first_name', {
                  required: 'A first name is required.'
                })}
                name="first_name"
                id="first_name"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={ (event) => {
                  setFirstName(event.target.value)
                  setValue('first_name', event.target.value)
                }}
                inputProps={{ 'data-testid': 'first-name-test' }}
                error={has(errors, 'first_name')}
                helperText={errors.first_name && errors.first_name.message}
                fullWidth
              />
            </Box>
            <Box pb={2}>
              <TextField
                {...register('last_name', {
                  required: 'A last name is required.'
                })}
                name="last_name"
                id="last_name"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={ (event) => {
                  setLastName(event.target.value)
                  setValue('last_name', event.target.value)
                }}
                inputProps={{ 'data-testid': 'last-name-test' }}
                error={has(errors, 'last_name')}
                helperText={errors.last_name && errors.last_name.message}
                fullWidth
              />
            </Box>
            <Box pb={2}>
              <TextField
                {...register('password', {
                  required: 'You must enter a password.',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  }
                })}
                id="password"
                name="password"
                type="password"
                variant="outlined"
                label="Password"
                value={password}
                onChange={ (event) => {
                  setPassword(event.target.value)
                  setValue('password', event.target.value)
                }}
                error={has(errors, 'password')}
                helperText={errors.password && errors.password.message}
                fullWidth
              />
            </Box>
            <Box pb={2}>
              <TextField
                {...register('confirm_password', {
                  required: 'You must enter your new password again.',
                  validate: (value) => value === watch('password') || 'Passwords do not match.'
                })}
                id="confirm_password"
                name="confirm_password"
                label="Confirm Password"
                type="password"
                variant="outlined"
                value={confirmPassword}
                onChange={ (event) => {
                  setConfirmPassword(event.target.value)
                  setValue('confirm_password', event.target.value)
                }}
                error={has(errors, 'confirm_password')}
                helperText={errors.confirm_password && errors.confirm_password.message}
                fullWidth
              />
            </Box>
            <Box pb={2}>
              <Button type="submit" fullWidth={true} variant="contained" color="primary">
                Register
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>

  )
}
