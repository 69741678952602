import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import Logo from 'assets/images/full_logo.png'
import { useSnackbar } from 'notistack'
import { authenticationService } from 'services'
import { isElectron } from 'helpers'
import has from 'lodash/has'

// Component styles
import styles from './styles'

const useStyles = makeStyles(styles)

export default function Login(props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const params = new URLSearchParams(window.location.search)
  const redirect = params.get('redirect')
  const history = useHistory()
  const [showAppLaunchBanner, setShowAppLaunchBanner] = useState(
    isElectron() ? 'none' : 'flex'
  )

  if (!localStorage.getItem('rememberMe')) {
    localStorage.setItem('rememberMe', true)
  }

  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe') === 'true'
  )

  const [username, setUsername] = useState(
    localStorage.getItem('rememberMeName') !== null && rememberMe
      ? localStorage.getItem('rememberMeName')
      : ''
  )
  const [password, setPassword] = useState('')

  const handleRememberMe = (event) => {
    const checked = event.target.checked
    setRememberMe(checked)
    setValue('remember_me', checked)
    localStorage.setItem('rememberMe', checked)
    if (!checked) {
      localStorage.removeItem('rememberMeName')
    }
  }

  const handleAppLaunchBannerClose = () => {
    setShowAppLaunchBanner('none')
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    setValue('remember_me', localStorage.getItem('rememberMe'))
    if (localStorage.getItem('rememberMe'))
      setValue('username', localStorage.getItem('rememberMeName'))
  }, [])

  const onSubmit = (data) => {
    if (data.remember_me) {
      localStorage.setItem('rememberMeName', data.username)
    }
    authenticationService
      .login(data.username, data.password)
      .then((data) => {
        const referrer =
          props.location && props.location.state
            ? props.location.state.referrer.pathname
            : '/dashboard'
        history.replace(redirect || referrer)
      })
      .catch((error) => {
        console.log('error', error)

        if (error?.status && error.status === 400) {
          enqueueSnackbar(
            error.data.detail || 'Incorrect e-mail/username or password.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          )
        } else {
          enqueueSnackbar(
            'There was an error logging in. Please try again later.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          )
        }
      })
  }

  return (
    <Box minHeight="80vh" display="flex" flexDirection="column">
      <Box
        display={showAppLaunchBanner}
        flexDirection="row"
        alignItems="center"
        bgcolor="secondary.main"
        style={{ color: 'white' }}
      >
        <Box
          display="flex"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
        >
          {'Upload is not available in the web browser.'}
          <Button
            onClick={() => history.push('/launch')}
            size="small"
            variant="outlined"
            style={{
              borderColor: 'white',
              padding: '0px 15px',
              margin: '10px'
            }}
          >
            <Typography variant="body1">
              <a
                href="motryx://open"
                style={{ color: 'white', fontWeight: 500 }}
              >
                Click here
              </a>
            </Typography>
          </Button>
          {'to launch the VitalQC desktop app.'}
        </Box>
        <IconButton
          aria-label="Close"
          onClick={handleAppLaunchBannerClose}
          style={{ color: 'white', margin: 3 }}
          size="small"
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <Avatar className={classes.loginavatar} src={Logo.toString()} />
        </Box>
        <Box width={'100%'} maxWidth={'400px'}>
          <Typography variant="h2" className={classes.title}>
            Login
          </Typography>
          <form
            id="login_form"
            data-testid="login-form-test"
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <TextField
                  {...register('username', { required: true })}
                  id="username"
                  name="username"
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value)
                    setValue('username', event.target.value)
                  }}
                  label="Email or Username"
                  autoComplete="current-password"
                  variant="outlined"
                  error={has(errors, 'username')}
                  helperText={
                    errors.username &&
                    errors.username.type === 'required' &&
                    'E-mail or Username is required.'
                  }
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  {...register('password', { required: true })}
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value)
                    setValue('password', event.target.value)
                  }}
                  error={has(errors, 'password')}
                  helperText={
                    errors.password &&
                    errors.password.type === 'required' &&
                    'Password is required.'
                  }
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register('remember_me')}
                      id="remember_me"
                      name="remember_me"
                      color="primary"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                      style={{ padding: '0px 9px 0px 9px' }}
                    />
                  }
                  label={
                    <Box fontSize={'14px'} alignSelf="center">
                      {'Remember Me'}
                    </Box>
                  }
                />
              </Grid>
              <Grid item>
                <Box pt={2}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                  >
                    Login
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.alt_link}>
                  <Link
                    href="/reset-password"
                    underline="none"
                    fontWeight={600}
                  >
                    Forgot Password?
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
