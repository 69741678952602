import { Add, Clear, Edit, RemoveRedEye } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { motion as m } from 'framer-motion'
import { QUERIES } from 'helpers'
import { CONTRACT_STATUSES } from 'helpers/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { api } from 'services'

const PAGINATION_SIZE = 10

const Contracts = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const history = useHistory()
  const [totalContractCount, setTotalContractCount] = useState(0)
  const [currentContractsDataGridPage, setCurrentContractsDataGridPage] =
    useState(0)
  const [contractsDataGridPageSize, setContractsDataGridPageSize] =
    useState(PAGINATION_SIZE)
  const [showArchiveContractButton, setShowArchiveContractButton] =
    useState(false)
  const [contractDataGridSelectionModel, setContractDataGridSelectionModel] =
    useState([])

  // Search field states
  const [getContractsQueryParams, setGetContractsQueryParams] = useState({
    size: contractsDataGridPageSize,
    page: currentContractsDataGridPage,
    exclude_archived: true
  })

  const [searchTitle, setSearchTitle] = useState('')
  const [searchOrganization, setSearchOrganization] = useState(null)
  const [excludeArchived, setExcludeArchived] = useState(true)

  const { data: contractsData, isLoading: contractsLoading } = useQuery(
    [QUERIES.GET_ALL_CONTRACTS, getContractsQueryParams],
    async () => {
      const response = await api.contracts.list(getContractsQueryParams)
      // Set total contract count
      setTotalContractCount(response?.total || 0)
      return response?.items || []
    }
  )

  const { data: institutes, isLoading: isInstituteLoading } = useQuery({
    queryKey: [QUERIES.GET_INSTITUTES, { excludeArchived: excludeArchived }],
    queryFn: async () => {
      const institutesData = await api.institutes.list({
        size: 9998,
        ordering: 'name',
        exclude_archived: getContractsQueryParams['exclude_archived']
      })
      return institutesData.items || []
    },
    staleTime: 0
  })

  const contractsDataGridColumns = [
    {
      field: 'institute',
      headerName: 'Organization',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" width="100%">
            <Tooltip title="Organization Settings">
              <IconButton
                color="primary"
                onClick={() => {
                  history.push(`/organization/${params.value?.id}/settings`)
                }}
              >
                <RemoveRedEye />
              </IconButton>
            </Tooltip>
            <Tooltip title={params.value?.name || 'NA'} placement="right-start">
              <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
                {params.value?.name || 'NA'}
              </Typography>
            </Tooltip>
          </Box>
        )
      }
    },
    {
      field: 'contract_type',
      headerName: 'Type',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const featureFlags = params.row?.institute?.feature_flags?.flags
        let contractType = 'NA'
        if (featureFlags?.troubleshooting) {
          contractType = 'Troubleshooting'
        } else if (featureFlags?.validation) {
          contractType = 'Validation'
        }
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {contractType}
          </Typography>
        )
      }
    },
    {
      field: 'institute_customer_since',
      headerName: 'Customer Since',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {(params.row?.institute?.customer_since &&
              format(
                parseJSON(params.row?.institute?.customer_since),
                'yyyy-MM-dd'
              )) ||
              'NA'}
          </Typography>
        )
      }
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {format(parseJSON(params.value), 'yyyy-MM-dd')}
          </Typography>
        )
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {(params.value && format(parseJSON(params.value), 'yyyy-MM-dd')) ||
              null}
          </Typography>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {CONTRACT_STATUSES[params.value] || 'NA'}{' '}
          </Typography>
        )
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            {params.value || 'NA'}
          </Typography>
        )
      }
    },
    {
      field: 'id',
      headerName: 'Edit',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            variant="contained"
            color="error"
            size="small"
            sx={{ fontSize: '0.875rem' }}
            onClick={() => {
              history.push(`/contracts/${params.value}/edit`)
            }}
          >
            <Edit />
          </IconButton>
        )
      }
    }
  ]

  const handleContractsDataGridPageSizeChange = (newPageSize) => {
    setContractsDataGridPageSize(newPageSize)
    setCurrentContractsDataGridPage(0)

    // Update the query params
    setGetContractsQueryParams({
      ...getContractsQueryParams,
      page: 0,
      size: newPageSize
    })
  }

  const handleContractsDataGridPageChange = (newPage) => {
    setCurrentContractsDataGridPage(newPage)

    // Update the query params
    setGetContractsQueryParams({
      ...getContractsQueryParams,
      page: newPage
    })
  }

  useEffect(() => {
    if (searchTitle !== '' || searchOrganization !== null) {
      setGetContractsQueryParams({
        ...getContractsQueryParams,
        title__icontains: searchTitle
      })
    }
  }, [searchTitle])

  useEffect(() => {
    if (searchOrganization !== null) {
      setGetContractsQueryParams({
        ...getContractsQueryParams,
        institute__id: searchOrganization?.id
      })
    }
  }, [searchOrganization])

  useEffect(() => {
    setGetContractsQueryParams({
      ...getContractsQueryParams,
      exclude_archived: excludeArchived
    })
  }, [excludeArchived])

  useEffect(() => {
    queryClient
      .invalidateQueries({
        queryKey: [QUERIES.GET_ALL_CONTRACTS, getContractsQueryParams]
      })
      .then((r) => {
        window.scrollTo(0, 0)
      })
    queryClient.invalidateQueries({
      queryKey: [QUERIES.GET_INSTITUTES, { excludeArchived: excludeArchived }]
    })
  }, [getContractsQueryParams])

  useEffect(() => {
    setShowArchiveContractButton(contractDataGridSelectionModel.length > 1)
  }, [contractDataGridSelectionModel])

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 1 }}
    >
      <Box display="flex" flexDirection="column" p={2} gap={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography
            variant="h5"
            display="flex"
            flexGrow={1}
            justifyContent="flex-start"
          >
            Contracts
          </Typography>
          <Box display="flex" gap={2}>
            {showArchiveContractButton && (
              <Button
                variant="contained"
                size="small"
                color="error"
                startIcon={<Edit />}
                onClick={() => {
                  alert('This feature is not implemented yet')
                }}
              >
                Bulk Edit
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<Add />}
              onClick={() => {
                history.push('/contracts/add')
              }}
              sx={{
                width: '150px'
              }}
            >
              New Contract
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" flexGrow={1} justifyContent="flex-start" gap={1}>
            <Autocomplete
              size="small"
              value={searchOrganization || null}
              getOptionLabel={(option) => option.name}
              loading={isInstituteLoading}
              loadingText="Loading Institutes..."
              id="institute-autocomplete"
              options={institutes || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  placeholder="Search By Organization Name"
                  size="small"
                />
              )}
              sx={{
                width: '350px'
              }}
              onChange={(e, newValue) => {
                setSearchOrganization(newValue)
              }}
            />
            <TextField
              label="Title"
              placeholder="Search By Title"
              variant="outlined"
              size="small"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              InputProps={{
                endAdornment: searchTitle && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchTitle('')
                    }}
                    sx={{
                      padding: '5px'
                    }}
                  >
                    <Clear
                      sx={{
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </IconButton>
                )
              }}
              sx={{
                width: '200px'
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={excludeArchived}
                  onChange={(e) => {
                    console.log('Exclude Archived', e.target.checked)
                    setExcludeArchived(e.target.checked)
                  }}
                />
              }
              label="Exclude Archived"
            />
          </Box>
        </Box>
        <Box>
          <DataGrid
            rows={contractsData || []}
            rowCount={totalContractCount}
            columns={contractsDataGridColumns}
            loading={contractsLoading}
            checkboxSelection
            autoHeight
            paginationMode="server"
            width={'100%'}
            page={currentContractsDataGridPage}
            onPageChange={handleContractsDataGridPageChange}
            pageSize={contractsDataGridPageSize}
            onPageSizeChange={handleContractsDataGridPageSizeChange}
            rowsPerPageOptions={[10, 20, 50, 100]}
            selectionModel={contractDataGridSelectionModel}
            onSelectionModelChange={(selectionModel) => {
              setContractDataGridSelectionModel(selectionModel)
            }}
          />
        </Box>
      </Box>
    </m.div>
  )
}

export default Contracts
