import { useQuery } from 'react-query'
import { api } from 'services'
import { QUERIES } from '../helpers'

export const useGetLabels = (staleTime = Infinity) => {
  return useQuery({
    queryKey: [QUERIES.GET_INSTITUTE_LABELS],
    queryFn: async () => {
      return await api.labels.list()
    },
    onSuccess: (data) => {},
    onError: (error) => {
      console.log('error', error)
    },
    staleTime: staleTime
  })
}
