import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { MainContent, Modal, ServerSideDataGrid, StyledLink } from 'components'
import { DeleteReportForm, ReportForm } from 'forms'
import { useQueryClient } from 'react-query'
import { Role, api, hasAnyRole, hasRole } from 'services'

export default function Reports() {
  const queryClient = useQueryClient()
  const queryName = 'reportData'
  const columns = [
    { field: '', hide: true },
    {
      field: 'title',
      headerName: 'Title',
      width: 500,
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        const title = params.getValue(params.id, 'title')
        if (hasAnyRole(['staff', 'admin', 'owner'])) {
          return (
            <Modal
              button={
                <Tooltip title={`edit ${title}`}>
                  <Button size="small" color="secondary">
                    {title}
                  </Button>
                </Tooltip>
              }
              label={'Edit Report'}
              maxWidth={'sm'}
            >
              <ReportForm
                id={id}
                exitFunction={() => queryClient.invalidateQueries(queryName)}
              />
            </Modal>
          )
        }
        return title
      }
    },
    {
      field: 'validation',
      headerName: 'Validation',
      width: 250,
      renderCell: ({ value }) => {
        if (value) {
          return (
            <StyledLink
              to={{
                pathname: `/validation/${value.id}`,
                state: {
                  name: 'Reports',
                  referrer: {
                    path: `${window.location.pathname}${window.location.search}`
                  }
                }
              }}
            >
              {value.name}
            </StyledLink>
          )
        }
        return ''
      }
    },
    {
      field: 'create_date',
      headerName: 'Date',
      width: 200,
      renderCell: ({ value }) => {
        const date = new Date(value)
        const options = { month: 'short', day: 'numeric', year: 'numeric' }
        return date.toLocaleDateString('en-GB', options)
      }
    },
    {
      field: 'download_url',
      headerName: ' ',
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <Button href={value} size="small" color="secondary">
            Download
          </Button>
        )
      }
    },
    {
      field: 'delete',
      headerName: ' ',
      sortable: false,
      hide: !hasRole('staff'),
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        const title = params.getValue(params.id, 'title')
        return (
          <Modal
            button={
              <Tooltip title={`Delete ${title}`}>
                <Button color="error" size="small">
                  <Delete />
                </Button>
              </Tooltip>
            }
            label={'Confirmation Required'}
            showCloseIcon={false}
          >
            <DeleteReportForm
              id={id}
              title={title}
              exitFunction={() => queryClient.invalidateQueries(queryName)}
            />
          </Modal>
        )
      }
    }
  ]

  return (
    <MainContent>
      <Box p={4}>
        <Box display="flex" flexDirection="row" spacing={3}>
          <Box flexGrow={1} alignSelf="center">
            <Typography variant="h1">Reports</Typography>
          </Box>
          <Role restrict={['staff']}>
            <Box>
              <Modal
                button={
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<Add />}
                  >
                    {' '}
                    {'Upload Report'}
                  </Button>
                }
                label={'Upload Report'}
              >
                <ReportForm
                  exitFunction={() => queryClient.invalidateQueries(queryName)}
                />
              </Modal>
            </Box>
          </Role>
        </Box>
        <ServerSideDataGrid
          apiCall={api.reports.list}
          queryName={queryName}
          columns={columns}
          noRowsText={'No Reports'}
        />
      </Box>
    </MainContent>
  )
}
