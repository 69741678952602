import React from 'react'
import { Box } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import makeStyles from '@mui/styles/makeStyles'

export default function NumberChangeWidget ({ num = null, label = null, rejectionColor = '#333A40' }) {
  const icon = num > 0 ? <ArrowDropUpIcon htmlColor={rejectionColor}/> : num < 0 ? <ArrowDropDownIcon htmlColor={rejectionColor}/> : null
  const useStyles = makeStyles((theme) => ({
    small: {
      fontSize: '18px',
      fontWeight: 600,
      color: rejectionColor,
      display: 'flex',
      alignItems: 'flex-start',
      borderRadius: 35,
      width: '100%'
    },
    label: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#333A40',
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%'
    }
  }))
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="row" >
      <Box minWidth={24}>
        {icon}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" minWidth={50}>
        <Box className={classes.small}>
          {`${Math.abs(num)} %`}
        </Box>
        <Box className={classes.label}>
          {label}
        </Box>
      </Box>
    </Box>
  )
}
