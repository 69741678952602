export default (theme) => ({
  root: {
    flex: 1
  },
  logingrid: {
    minHeight: ' 80vh'
  },
  loginavatar: {
    borderRadius: 0,
    height: 'auto',
    width: 'auto',
    margin: '20px',
    backgroundColor: 'transparent',
    '& .MuiAvatar-img': {
      backgroundColor: 'transparent'
    },
    maxWidth: 400
  },

  title: {
    marginBottom: theme.spacing(3)
  },
  alt_link: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    textAlign: 'right'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    '& .hidden': {
      display: 'none'
    }
  }
})
